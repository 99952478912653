import { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { BoundingBoxType, ScreenCaptureListenerProps } from './types';
import {
  computeBorderWidth,
  calculateClickPositionRelativeToParent,
  getCaptureAreaAsPercentageOfParent,
} from './utils';

const DEFAULT_BOUNDING_BOX: BoundingBoxType = {
  Left: 0,
  Top: 0,
  Width: 0,
  Height: 0,
};

export default function ScreenCaptureListener({
  width: parentWidth,
  height: parentHeight,
  handleCapture,
  on,
}: ScreenCaptureListenerProps) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [boundingBox, setBoundingBox] = useState<BoundingBoxType>(DEFAULT_BOUNDING_BOX);
  const captureAreaRef = useRef<any>(null);

  const handleMouseDown = (e: MouseEvent) => {
    setIsMouseDown(true);
    const [positionX, positionY] = calculateClickPositionRelativeToParent(e, captureAreaRef);
    setBoundingBox({
      Left: positionX,
      Width: positionX,
      Top: positionY,
      Height: positionY,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isMouseDown) {
      return;
    }
    const [positionX, positionY] = calculateClickPositionRelativeToParent(e, captureAreaRef);
    setBoundingBox({
      ...boundingBox,
      Width: positionX,
      Height: positionY,
    });
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    const percentageBoundingBox = getCaptureAreaAsPercentageOfParent(
      boundingBox,
      parentWidth,
      parentHeight,
    );
    handleCapture(percentageBoundingBox);
    setBoundingBox(DEFAULT_BOUNDING_BOX);
  };

  useEffect(() => {
    if (!captureAreaRef.current || !on) {
      return;
    }

    captureAreaRef.current.addEventListener('mousedown', handleMouseDown);
    captureAreaRef.current.addEventListener('mousemove', handleMouseMove);
    captureAreaRef.current.addEventListener('mouseup', handleMouseUp);

    return () => {
      if (captureAreaRef.current) {
        captureAreaRef.current.removeEventListener('mousedown', handleMouseDown);
        captureAreaRef.current.removeEventListener('mousemove', handleMouseMove);
        captureAreaRef.current.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [boundingBox, captureAreaRef, handleMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <Box
      ref={captureAreaRef}
      className="screen-capture-listener"
      sx={{
        width: '100%',
        height: '100%',
        cursor: on ? 'crosshair' : 'default',
        borderWidth: computeBorderWidth(boundingBox, parentWidth, parentHeight),
        borderColor: on ? 'rgba(0, 0, 0, 0.35)' : 'rgba(0, 0, 0, 0)',
        borderStyle: 'solid',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          border: isMouseDown ? '2px dashed' : 'none',
          borderColor: 'secondary.main',
        }}
      />
    </Box>
  );
}
