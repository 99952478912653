import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { logUserActivity, TimeStampedUserActivity, UserActivity } from '../../api';
import { useBatchedRequest } from '../../hooks/useBatchedRequest';

const ActivityTrackerContext = React.createContext<(activity: UserActivity) => void>(() => {});

export type ActivityTrackerProps = {};

export function UserSessionTracker({ children }: React.PropsWithChildren<ActivityTrackerProps>) {
  const [isActive, setIsActive] = useState(document.hasFocus());
  const [activity, setActivity] = useState<UserActivity | undefined>();
  const { addDataToBatch } = useBatchedRequest<TimeStampedUserActivity>({
    flushInterval: 10 * 1000,
    maxBatchSize: 10,
    request: logUserActivity,
  });

  // Handle tab focus/unfocus and log user activity
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hasFocus()) {
        setIsActive(true);
      } else {
        setIsActive(false);
        if (activity) {
          addDataToBatch({ ...activity, inactive: true, timestamp: new Date().toISOString() });
        }
        updateLastActiveTime();
      }
    };

    window.addEventListener('blur', handleVisibilityChange);
    window.addEventListener('focus', handleVisibilityChange);
    return () => {
      window.removeEventListener('blur', handleVisibilityChange);
      window.removeEventListener('focus', handleVisibilityChange);
    };
  }, [activity]);

  // Log activity when activity or isActive changes and every minute
  useEffect(() => {
    function sendActivityLog() {
      if (activity && isActive) {
        updateLastActiveTime();
        addDataToBatch({
          ...activity,
          timestamp: new Date().toISOString(),
        });
      }
    }

    sendActivityLog();
    const interval = setInterval(sendActivityLog, 1000 * 60);
    window.addEventListener('beforeunload', sendActivityLog);

    return () => {
      clearInterval(interval);
      window.removeEventListener('beforeunload', sendActivityLog);
    };
  }, [activity, isActive]);

  const debouncedSetActivity = debounce(setActivity, 1000);

  return (
    <ActivityTrackerContext.Provider value={debouncedSetActivity}>
      {children}
    </ActivityTrackerContext.Provider>
  );
}

export function useActivityLog() {
  return React.useContext(ActivityTrackerContext);
}

const updateLastActiveTime = () => {
  localStorage.setItem('lastActiveTime', Date.now().toString());
};
