import axios from 'axios';
import apiUrl from '../../library/utilities/apiUrl';

export function deleteDocument(caseID, documentID) {
  return axios.post(
    `${apiUrl}deleteDocument`,
    {
      caseID,
      documentID,
    },
    { baseURL: apiUrl },
  );
}
