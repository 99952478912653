import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { toast } from 'react-toastify';
import { FileStatus } from '../../../api';
import { FileProcessorNextStepButton } from './FileProcessorNextStepButton';
import { File } from '../types';

type Props = {
  handleFileStatusChange: (fileId: string, status: Props['fileStatus']) => void;
  handleRemoveAllPredictions: (file: File) => void;
  handleSkipPredictions: (file: File) => void;
  fileStatus: FileStatus;
  authorStatus: string;
  canCopyFile: boolean;
  setSourceDocument: (file: File | null) => void;
  row: File;
};

export function ProcessorFileActions({
  handleFileStatusChange,
  handleRemoveAllPredictions,
  handleSkipAutomatedProcessing,
  canCopyFile,
  setSourceDocument,
  row,
}: Props) {
  const fileID = row.id;
  const fileStatus = row.fileStatus;
  const onButtonClick = () => {
    try {
      handleFileStatusChange(fileID, fileStatus);
    } catch (e) {
      toast.error('Error updating file status.');
    }
  };
  return (
    <Stack direction="row" spacing={1}>
      {fileStatus === FileStatus.AUTOMATED_PROCESSING && (
        <Tooltip title="Skip Predictions">
          <Box
            position="relative"
            display="inline-flex"
            sx={{
              alignItems: 'center',
            }}
          >
            <Button
              sx={{ cursor: 'pointer', borderColor: 'warning.main' }}
              onClick={() => handleSkipAutomatedProcessing(row)}
              variant="outlined"
              size="small"
              color="primary"
            >
              Skip Processing
            </Button>
          </Box>
        </Tooltip>
      )}
      {(fileStatus === FileStatus.PENDING || fileStatus === FileStatus.GROUPING) && (
        <Tooltip title="Remove All Predictions">
          <Box
            position="relative"
            display="inline-flex"
            sx={{
              alignItems: 'center',
            }}
          >
            <Button
              sx={{ cursor: 'pointer', borderColor: 'warning.main' }}
              onClick={() => handleRemoveAllPredictions(row)}
              variant="outlined"
              size="small"
              color="primary"
            >
              Reset
            </Button>
          </Box>
        </Tooltip>
      )}
      {canCopyFile && (
        <Button
          variant="contained"
          onClick={() => setSourceDocument(row)}
          sx={{
            fontWeight: 700,
            fontSize: '0.8rem',
          }}
        >
          Copy
        </Button>
      )}
      <FileProcessorNextStepButton fileStatus={fileStatus} onClick={onButtonClick} />
    </Stack>
  );
}
