import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import { updateCaseDetails, caseDetails, updateCaseSubject, CaseSubject } from '../../../api';
import { GetCasesDocument } from '../../../__generated__/graphql';

const useUpdateCaseDetails = () => {
  const gqlClient = useApolloClient();
  const queryClient = useQueryClient();

  return useCallback(
    async (caseId: string, data: caseDetails) => {
      await updateCaseDetails(caseId, data);

      gqlClient.refetchQueries({
        include: [GetCasesDocument],
      });
      queryClient.invalidateQueries(['case', caseId]);
    },
    [gqlClient, updateCaseDetails],
  );
};

export const useUpdateCaseSubject = () => {
  const gqlClient = useApolloClient();
  const queryClient = useQueryClient();
  return useCallback(
    async (caseId: string, subjectId: bigint, subjectDetails: CaseSubject) => {
      const result = await updateCaseSubject(caseId, subjectId, subjectDetails);

      gqlClient.refetchQueries({
        include: [GetCasesDocument],
      });
      queryClient.invalidateQueries(['case', caseId]);
      return result.data ?? subjectId;
    },
    [gqlClient, updateCaseSubject],
  );
};

export default useUpdateCaseDetails;
