import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authenticatedRequest, Case } from '../../api';

async function fetchCase(caseID: string) {
  if (!caseID) {
    return;
  }
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/${caseID}`,
  });
  return normalizeCase(res.data as Case);
}

export default function useCase({ caseID }: { caseID: string }) {
  const queryClient = useQueryClient();
  return useQuery(['case', caseID], () => fetchCase(caseID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['timeline', caseID]);
    },
  });
}

const normalizeCase = (caseInstance: Case) => {
  return {
    caseID: caseInstance.id,
    caseName: caseInstance.case_name,
    userID: caseInstance.userID,
    caseStatus: caseInstance.case_status,
    claimReason: caseInstance.claimReason,
    createDate: caseInstance.createDate,
    dateOfBirth: caseInstance.dateOfBirth,
    dateOfInjury: caseInstance.dateOfInjury,
    dueDate: caseInstance.dueDate,
    isReady: caseInstance.isReady,
    fullName: caseInstance.fullName,
    lastOpened: caseInstance.lastOpened,
    pageCount: caseInstance.number_of_pages,
    pagesViewed: caseInstance.number_of_pages_viewed,
    similarityThreshold: caseInstance.similarity_threshold,
    archived_at: caseInstance.archived_at,
    archived_by: caseInstance.archived_by,
    ref_id: caseInstance.ref_id,
    duplicates_regenerate: caseInstance.duplicates_regenerate,
    caseUserLocation: {
      pageID: caseInstance.case_user_location?.page_id,
      view: caseInstance.case_user_location?.view,
      timelineEntryID: caseInstance.case_user_location?.timeline_entry_id,
      documentID: caseInstance.case_user_location?.document_id,
    },
    version: caseInstance.version,
    case_type_id: caseInstance.case_type_id,
    case_type_name: caseInstance.case_type_name,
    subjects: caseInstance.subjects,
    referralReceivedDate: caseInstance.referral_received,
  };
};
