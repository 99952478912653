import { useParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { PaginatedPageFragment } from '../../__generated__/graphql';
import useCaseFiles from '../Files/useCaseFiles';

export function useFileStatus() {
  const params = useParams();
  const caseID = params.caseID!;
  const documentID = params.documentID!;
  const [currentFileStatus, setCurrentFileStatus] = useState('');
  const { data: caseFiles, isLoading: caseFilesLoading } = useCaseFiles(caseID)


  useEffect(() => {
    if (!caseFilesLoading && caseFiles) {
      const document = caseFiles?.find((doc) => documentID === doc.documentID);
      if (document) {
        if (document.fileStatus === 'UPLOADING') {
          setCurrentFileStatus('UPLOADING');
        } else if (document.fileStatus === 'COMPLETE') {
          setCurrentFileStatus('PROCESSED');
        } else {
          setCurrentFileStatus('VIEWABLE');
        }
      }
    }
  }, [documentID, caseFiles]);

  return currentFileStatus;
}

export function useFileStatusForPage(page: PaginatedPageFragment) {
  const params = useParams();
  const caseID = params.caseID!;
  const { data: caseFiles, isLoading: caseFilesLoading } = useCaseFiles(caseID);

  return useMemo(() => {
    if (!caseFilesLoading && caseFiles) {
      const document = caseFiles?.find((doc) => doc.documentID === page.documentID);
      if (document) {
        if (document.fileStatus === 'UPLOADING') {
          return 'UPLOADING';
        }
        if (document.fileStatus === 'COMPLETE') {
          return 'PROCESSED';
        }
        return 'VIEWABLE';
      }
    }
    return '';
  }, [caseFiles, page]);
}
