import { useUser } from '../../library/contexts/AuthContext';

export function useIsSiftMedAdmin() {
  const { user } = useUser();
  return user?.email === 'support@siftmed.ca';
}

export function useIsSiftMedUser() {
  const { user } = useUser();
  return user?.email?.endsWith('@siftmed.ca');
}
