import { Page, Tag } from '../../types/timelineTypes';
import { toDisplayableTagSavingOriginalValues } from './pageTagUtils';
import { ApplicationPageFragmentFragment, TagType } from '../../../../__generated__/graphql';

export const normalizePage = (
  page: ApplicationPageFragmentFragment,
): Omit<Page> & { tags: Tag[] } => ({
  id: page.id,
  entryID: page.timeline_entry_id,
  pageID: page.file_id,
  documentFileName: page.documents?.document_file_name ?? '',
  documentID: page.document_id,
  hasDuplicates: Boolean(page.has_duplicates),
  isDuplicate: page.is_duplicate,
  isFavourite: Boolean(page.is_favourite),
  isHidden: Boolean(page.is_hidden),
  pageDate: page.corrected_page_date ?? page.page_date,
  pageNumber: page.page_number,
  viewedAt: page.pages_viewed[0]?.viewed_at ?? null,
  contentTypes:
    page.tags
      ?.filter((tag) => tag.type === TagType.ContentType)
      .map(toDisplayableTagSavingOriginalValues) ?? [],
  specialities:
    page.tags
      ?.filter((tag) => tag.type === TagType.Speciality)
      .map(toDisplayableTagSavingOriginalValues) ?? [],
  tags: page.tags?.map(toDisplayableTagSavingOriginalValues) ?? [],
  extracted_dates: page?.DataExtraction,
  confidenceScores: [page.source?.confidence ?? 0],
});
