/* eslint-disable camelcase */
import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, MenuList, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';

export default function TimelineViewCardMenu({ handleDownload, entryID, rotateDocument }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isFileProcessor = useIsFileProcessor();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Entry settings">
          <IconButton
            id={`review-tab-entry-settings-btn-entry-id-${entryID}`}
            onClick={handleClick}
            size="small"
            edge="end"
            sx={{ paddingTop: '2px' }}
          >
            <MoreVert fontSize="inherit" style={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuList sx={{ width: 195, maxWidth: '100%', paddingBottom: '0.5rem' }}>
          {!isFileProcessor && <MenuItem onClick={handleDownload}>Download</MenuItem>}
          <MenuItem onClick={() => rotateDocument('clockwise')}>Rotate Clockwise</MenuItem>
          <MenuItem onClick={() => rotateDocument('counterclockwise')}>
            Rotate Counterclockwise
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
