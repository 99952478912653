/* eslint-disable no-await-in-loop */
import { toast } from 'react-toastify';
import { getDocumentSummary, getTaskStatus, TaskStatus } from '../../api';

export async function fetchDocumentSummary(
  summaryTaskId: string,
  caseID: string,
  documentID: string,
): Promise<any> {
  try {
    if (summaryTaskId) {
      const taskStatus = await waitForTaskCompletion(summaryTaskId);
      if (taskStatus === TaskStatus.Failed || taskStatus === TaskStatus.Canceled) {
        toast.error('Failed to summarize document. Please refresh and try again.');
        return;
      }
    }
    return await waitForDocumentSummary(caseID, documentID);
  } catch (error) {
    toast.error('Failed to fetch summary.');
  }
}

async function waitForTaskCompletion(
  summaryTaskId: string,
  retryDelay = 10000,
): Promise<TaskStatus> {
  let attempts = 0;
  const maxAttempts = 6;

  while (attempts < maxAttempts) {
    try {
      const response = await getTaskStatus(summaryTaskId);
      const taskStatus = response.data.status;

      if (
        taskStatus === TaskStatus.Complete ||
        taskStatus === TaskStatus.Failed ||
        taskStatus === TaskStatus.Canceled
      ) {
        return taskStatus;
      }
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        throw error;
      }
    }
    attempts++;
    await delay(retryDelay);
  }
  throw new Error('Max attempts reached for task completion.');
}

export async function waitForDocumentSummary(
  caseID: string,
  documentID: string,
  retryDelay = 10000,
): Promise<any> {
  let attempts = 0;
  const maxAttempts = 3;

  while (attempts < maxAttempts) {
    try {
      const summaryData = await getDocumentSummary(caseID, documentID);
      if (summaryData?.data) {
        return summaryData.data;
      }
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        throw error;
      }
    }
    attempts++;
    await delay(retryDelay);
  }
  throw new Error('Max attempts reached for task completion.');
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
