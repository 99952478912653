import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import NoteEditableTemplate from './NoteEditableTemplate';
import useScreenCaptureStore from '../ScreenCapture/useScreenCaptureStore';
import { useGetPageByIdQuery } from '../../__generated__/graphql';
import { getAnnotations } from '../../api';
import useSources from '../Timeline/gql/useSources';

const useScreenCapStore = createSelectorFunctions(useScreenCaptureStore);

function NewNote(props) {
  const { open = false, onClose, onSuccess, saveNote, containerName = '' } = props;
  const params = useParams();
  const { caseID } = params;
  const [documentDate, setDocumentDate] = useState('');

  const screenCaptures = useScreenCapStore.use
    .screenCaptures()
    .filter((screenCap) => screenCap.type === 'note');
  const setScreenCaptures = useScreenCapStore.use.setScreenCaptures();

  const pageId = useMemo(() => {
    if (screenCaptures.length > 0) {
      return screenCaptures[0].pageID;
    }
    return params.pageID;
  }, [screenCaptures, params]);

  const {
    loading,
    data: pageObject,
    error: getPageError,
  } = useGetPageByIdQuery({
    variables: {
      id: pageId,
    },
    skip: !pageId,
  });

  useEffect(() => {
    const fetchAnnotations = async () => {
      if (pageObject?.page?.timelineEntryID && caseID) {
        try {
          const annotations = await getAnnotations({
            documentId: pageObject?.page?.timelineEntryID,
            type: 'date',
            caseID,
          });
          const startDate = annotations?.data?.filter(
            (annotation) =>
              annotation.field === 'start-date' &&
              (annotation.selection_status === 'Confirmed' ||
                annotation.selection_status === 'Proposed'),
          )[0]?.value;
          setDocumentDate(startDate);
        } catch (error) {
          console.error('Error fetching annotations:', error);
        }
      }
    };

    fetchAnnotations();
  }, [pageObject?.page?.documentID, caseID]);

  useEffect(() => {
    if (getPageError) {
      toast.error(
        'There was an issue fetching page details required to create a note. Please refresh your browser page and try again.',
      );
      Sentry.captureException(getPageError);
    }
  }, [getPageError]);

  const page = pageObject?.page ?? {};

  const sources = useSources();

  const pageSource = useMemo(
    () => sources?.find((source) => source.tag_id === pageObject?.page?.source_id)?.name,
    [pageObject, sources],
  );

  const notePage = { ...page, source: pageSource ?? '' };

  const [partialNote, setPartialNote] = useState(null);

  const onTakeScreenshotClick = (note) => setPartialNote(note);

  const deleteImage = (imageToBeDeleted) => {
    setScreenCaptures(screenCaptures.filter((capture) => capture !== imageToBeDeleted));
  };

  async function submitNewNote(title, body, physician, tags, date, privateNote) {
    const note = {
      documentID: page.documentID,
      pageID: page.pageID,
      title: title,
      body: body,
      physician: physician,
      tags: tags,
      privateNote: privateNote,
      date: date,
      images: screenCaptures ?? [],
    };
    onClose();
    try {
      await saveNote({ variables: { case_id: caseID, data: note } });
      if (onSuccess) {
        await onSuccess();
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
  }

  return (
    <NoteEditableTemplate
      open={open}
      loadingPage={loading}
      screenCaptures={screenCaptures}
      setScreenCaptures={setScreenCaptures}
      onTakeScreenshotClick={onTakeScreenshotClick}
      defaultNoteValues={
        partialNote
          ? { ...partialNote }
          : {
              date: documentDate,
              pageID: page?.pageID,
            }
      }
      onClose={onClose}
      onDeleteImage={(imageObject) => deleteImage(imageObject)}
      onSubmit={submitNewNote}
      dialogTitle="Add Note"
      page={notePage}
      containerName={containerName}
    />
  );
}

export default NewNote;
