import { Select, MenuItem } from '@mui/material';
import { gql, useApolloClient } from '@apollo/client';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useNavigationStore from '../useNavigationStore';

export default function TimelineDocumentViewSelector() {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const location = useLocation();
  const { caseID, pageID } = useParams();

  const setCurrentView = useNavigationStore((state) => state.setCurrentView);

  const changeListView = (view) => {
    setCurrentView(view);

    const pageDetails = fetchPageDetails(pageID);

    if (view === 'Document') {
      navigateToDocumentView(pageDetails);
    } else {
      navigateToTimelineView(pageDetails);
    }
  };

  // Function to fetch page details from Apollo cache
  function fetchPageDetails(pageID) {
    return apolloClient.cache.readFragment({
      id: apolloClient.cache.identify({ id: pageID, __typename: 'PageObject' }),
      fragment: gql`
        fragment SelectorPageDetails on PageObject {
          documentID: document_id
          entryID: timeline_entry_id
        }
      `,
    });
  }

  // Function to handle navigation to the Document view
  function navigateToDocumentView(pageDetails) {
    const documentURL = pageDetails?.documentID
      ? `/case/${caseID}/documents/${pageDetails.documentID}/${pageID}`
      : `/case/${caseID}/documents`;
    return navigate(documentURL);
  }

  // Function to handle navigation to the Timeline view
  function navigateToTimelineView(pageDetails) {
    const timelineURL = pageDetails?.entryID
      ? `/case/${caseID}/timeline/${pageDetails.entryID}/${pageID}`
      : `/case/${caseID}/timeline`;
    return navigate(timelineURL);
  }

  return (
    <Select
      variant="outlined"
      sx={{
        width: '100%',
        maxWidth: '130px',
        fontWeight: 600,
        fontSize: '0.8rem',
        backgroundColor: '#FFFFFF',
      }}
      value={location.pathname.indexOf('/documents') > -1 ? 'documents' : 'timeline'}
    >
      <MenuItem
        key="documents"
        value="documents"
        sx={{ height: 36 }}
        onClick={() => changeListView('Document')}
      >
        Documents
      </MenuItem>
      <MenuItem
        key="timeline"
        value="timeline"
        onClick={() => changeListView('Timeline')}
        sx={{ height: 36 }}
      >
        Timeline
      </MenuItem>
    </Select>
  );
}
