import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, TextField, Box, Button } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import CaseHeader from '../../Timeline/CaseHeader';
import CaseContext from '../CaseContext';
import './case-insights-container.css';
import useInsights, { CaseDetailsFields } from './useInsights';
import { CaseSubject } from '../../../api';
import Theme from '../../../theme';
import EditSubjectDialog, { CaseSubjectChip } from './CaseSubjects';
import GoToSource from '../../../components/icons/GoToSource';
import { useIsSiftMedAdmin } from '../../AccountSettings/useAdmin';
import PagesPieChart from './CaseInsightCharts/PagesPieChart';
import { isValidDate } from '../../../library/utilities/useDates';
import CaseRelatedFileManagement from './CaseRelatedFiles/caseRelatedFileManagement';
import { useUser } from '../../../library/contexts/AuthContext';

export default function CaseInsightsDashboard() {
  const { caseInstance } = useContext(CaseContext);
  const { caseID } = useParams();
  const { user } = useUser();
  const caseOwnerID = caseInstance.userID;
  const isSupportAccount = useIsSiftMedAdmin();

  const {
    handleUpdateCaseDetails,
    handleSaveCaseDetails,
    handleUpdateSubject,
    caseDetails,
    caseSubjects,
    caseHasSubjects,
    imeReportSpecialtyInsights,
    filterAndViewDocuments,
    errorMessage,
    setErrorMessage,
    validateCaseUpdate,
    detailsToUpdate,
    resetCaseDetails,
    authors,
    pageCounts,
  } = useInsights(caseID ?? '');

  const caseStatusText = caseStatusMap[caseDetails.caseStatus];

  const [subjectDialogOpen, setSubjectDialogOpen] = useState(false);
  const handleSubjectDialogClose = () => {
    setErrorMessage('');
    setSubjectDialogOpen(false);
  };

  const columns = [
    {
      field: 'author',
      headerName: 'Author',
      flex: 0.25,
    },
    {
      field: 'specialty',
      headerName: 'Type',
      flex: 0.25,
    },
    {
      field: 'date-range',
      headerName: 'Date(s)',
      flex: 0.25,
      sortable: false,
      renderCell: (params) => {
        const startDate = params.row.start_date;
        const endDate = params.row.end_date;
        if (startDate === endDate) {
          return startDate;
        }
        return startDate && endDate
          ? `${startDate} - ${endDate}`
          : `${startDate ?? ''} ${endDate ?? ''}`;
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      flex: 0.25,
      sortable: false,
      renderCell: (params) => {
        return (
          <span style={{ fontWeight: 400 }}>{`${params.row.documents?.length} Document${
            params.row.documents?.length > 1 ? 's' : ''
          }`}</span>
        );
      },
    },
  ];

  const pieChartData = pageCounts
    ? [
        { name: 'Important Pages', value: pageCounts.flaggedPages },
        { name: 'Duplicate Pages', value: pageCounts.duplicates },
        { name: 'Hidden Pages', value: pageCounts.hiddenPages },
        { name: 'Case Pages', value: pageCounts.totalPages },
      ]
    : [];
  const pieChartColors = ['#F79009', '#EB3D4F', '#6687D8', '#00B886'];

  return (
    <>
      <CaseHeader caseInstance={caseInstance} />
      <div id="case-insights" style={{ padding: '16px' }}>
        <div className="details-box" style={{ height: 'fit-content' }}>
          <div style={{ display: 'flex', height: 'fit-content' }}>
            <div className="details-card" style={{ width: '35%' }}>
              <div style={{ height: '28px' }}>
                <span className="details-header" style={{ fontWeight: 700 }}>
                  Case Details
                </span>
              </div>
              <div className="details-box-row" style={{ height: 'fit-content' }}>
                <span
                  className="details-label"
                  style={{ minWidth: '30px', width: '30%', alignSelf: 'center' }}
                >
                  Case Name
                </span>
                <div
                  style={{
                    border: '1px solid #D0D5DD',
                    borderRadius: '8px',
                    width: '80%',
                    padding: '5px',
                  }}
                  className="details-value"
                >
                  <TextField
                    size="small"
                    value={caseDetails.caseName}
                    variant="standard"
                    onChange={(event) => handleUpdateCaseDetails('caseName', event.target.value)}
                    sx={{
                      width: '100%',
                      height: '21px',
                      marginBottom: '2px',
                      '& .MuiInputBase-input': { WebkitTextFillColor: '#475467' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: { style: { fontSize: '14px' } },
                    }}
                  />
                </div>
              </div>
              {caseDetails.caseType && (
                <div className="details-box-row">
                  <span className="details-label" style={{ minWidth: '95px', width: '20%' }}>
                    Case Type
                  </span>
                  <span className="details-value">{caseDetails.caseType}</span>
                </div>
              )}
              <div className="details-box-row">
                <span className="details-label" style={{ minWidth: '95px', width: '20%' }}>
                  Case Status
                </span>
                <span className="details-value">{caseStatusText}</span>
              </div>
            </div>
            <div className="details-card" style={{ marginLeft: '16px', width: '40%' }}>
              <div style={{ height: '28px', display: 'flex', flexDirection: 'column' }}>
                <span className="details-header" style={{ fontWeight: 700 }}>
                  Case Dates
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="details-box-row" style={{ width: '25%' }}>
                  <span className="details-label" style={{ minWidth: '125px', width: '25%' }}>
                    Due Date
                  </span>
                  <CaseDateEdit
                    date={caseDetails.dueDate}
                    field="dueDate"
                    handleChangeDate={handleUpdateCaseDetails}
                  />
                </div>
                <div className="details-box-row" style={{ width: '25%' }}>
                  <span className="details-label" style={{ minWidth: '125px', width: '25%' }}>
                    Date of Incident
                  </span>
                  <CaseDateEdit
                    date={caseDetails.dateOfInjury}
                    field="dateOfInjury"
                    handleChangeDate={handleUpdateCaseDetails}
                  />
                </div>
              </div>
              <div className="details-box-row" style={{ width: '25%' }}>
                <span className="details-label" style={{ minWidth: '125px', width: '25%' }}>
                  Referral Received
                </span>
                <CaseDateEdit
                  date={caseDetails.referralReceivedDate}
                  field="referralReceivedDate"
                  handleChangeDate={handleUpdateCaseDetails}
                />
              </div>
              {errorMessage && !subjectDialogOpen && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '12px',
                    padding: '4px',
                    color: 'red',
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </div>

            <div className="details-box" style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className="details-card" style={{ width: 'fit-content' }}>
                <span className="details-header" style={{ paddingTop: '16px' }}>
                  Page Breakdown
                </span>
                <PagesPieChart data={pieChartData} colors={pieChartColors} />
              </div>
            </div>
          </div>
          <div className="details-card" style={{ display: 'flex', marginTop: '16px' }}>
            <div style={{ paddingLeft: '8px', width: '40%' }}>
              <div style={{ height: '28px', display: 'flex', flexDirection: 'column' }}>
                <span className="details-header">Subject</span>
                {!caseHasSubjects ? (
                  <span
                    onClick={() => setSubjectDialogOpen(true)}
                    style={{
                      color: Theme.palette.duplicatesByDocumentBlue.main,
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    + Add Subject
                  </span>
                ) : (
                  caseSubjects?.map((subject) => (
                    <CaseSubjectChip
                      subject={subject}
                      setSubjectDialogOpen={setSubjectDialogOpen}
                    />
                  ))
                )}
              </div>
            </div>
            <div id="case-description" style={{ paddingLeft: '8px', width: '60%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="details-header">Description</span>
                <div style={{ border: '1px solid #D0D5DD', borderRadius: '10px', width: '95%' }}>
                  <TextField
                    size="small"
                    onChange={(event) => handleUpdateCaseDetails('claimReason', event.target.value)}
                    multiline
                    variant="standard"
                    value={caseDetails.claimReason}
                    sx={{
                      width: '100%',
                      height: '150px',
                      overflowY: 'scroll',
                      '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        margin: '8px',
                        fontSize: '14px',
                        alignItems: 'flex-start',
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '95%',
                    display: 'flex',
                    marginRight: 'auto',
                    marginTop: '8px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    onClick={resetCaseDetails}
                    type="button"
                    size="small"
                    aria-label="save"
                    variant="contained"
                    style={{
                      backgroundColor: '#D0D5DD',
                      color: 'black',
                      opacity: detailsToUpdate ? 1 : 0.5,
                    }}
                    disabled={!detailsToUpdate}
                  >
                    <span style={{ fontSize: '12px', width: 'fit-content' }}> Cancel</span>
                  </Button>
                  <Button
                    id="save-description-button"
                    onClick={handleSaveCaseDetails}
                    type="button"
                    size="small"
                    aria-label="save"
                    variant="contained"
                    style={{ marginLeft: '8px' }}
                    disabled={!detailsToUpdate}
                  >
                    <span style={{ fontSize: '12px', width: 'fit-content' }}> Save</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {imeReportSpecialtyInsights?.length > 0 && isSupportAccount && (
          <div className="tag-counts-box">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span className="details-header">IME Reports</span>
              <Box
                sx={{ cursor: 'pointer', paddingLeft: '5px' }}
                onClick={() => filterAndViewDocuments(175)}
              >
                <GoToSource stroke="black" />
              </Box>
            </div>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {imeReportSpecialtyInsights?.map((data: { tag: string; count: number }) => (
                <div
                  key={data.tag}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    minWidth: '50px',
                  }}
                >
                  <span>
                    <Circle sx={{ fontSize: '0.75rem', marginRight: '0.25rem', color: 'green' }} />
                    <span className="details-value">{data.tag}</span>
                  </span>
                  <span style={{ marginLeft: '1rem' }} className="tag-count-number">
                    {data.count}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {authors.length > 0 && isSupportAccount && (
          <div
            style={{
              backgroundColor: 'white',
              width: '50%',
              borderRadius: '8px',
              marginTop: '16px',
            }}
          >
            <DataGrid
              rows={authors}
              columns={columns}
              autoHeight
              disableColumnMenu
              disableRowSelectionOnClick
              pageSizeOptions={[5, 10, 25]}
              initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
              sx={{
                width: '100%',
                border: '1px solid #00214733',
                borderRadius: '8px',
                fontSize: '0.8rem',
                boxShadow: 'none',
                '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
              }}
            />
          </div>
        )}
        <div className="details-card" style={{ display: 'flex', marginTop: '16px' }}>
          <div className="details-header" style={{ display: 'content', marginTop: '0px' }}>
            Case Related Files
            {user?.userId && caseID && (
              <CaseRelatedFileManagement
                userID={user.userId}
                caseID={caseID}
                caseOwnerID={caseOwnerID}
              />
            )}
          </div>
        </div>
      </div>
      <EditSubjectDialog
        subject={caseSubjects?.[0]}
        isOpen={subjectDialogOpen}
        onClose={handleSubjectDialogClose}
        handleSaveSubjectChanges={(updatedSubject: CaseSubject) => {
          handleUpdateSubject(updatedSubject);
          setSubjectDialogOpen(false);
        }}
        errorMessage={errorMessage}
        validateSubjectUpdate={validateCaseUpdate}
      />
    </>
  );
}

function CaseDateEdit({
  date,
  field,
  handleChangeDate,
}: {
  date: string;
  field: CaseDetailsFields;
  handleChangeDate: (field: CaseDetailsFields, date: string) => void;
}) {
  const [currentDate, setCurrentDate] = useState(date);

  useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  const editDate = (newDate: string) => {
    setCurrentDate(newDate);
    if (isValidDate(newDate)) {
      handleChangeDate(field, newDate);
    }
  };

  return (
    <span className="details-value">
      <TextField
        type="date"
        size="small"
        value={currentDate}
        variant="standard"
        onChange={(event) => editDate(event.target.value)}
        sx={{
          height: '21px',
          '& .MuiInputBase-input': { WebkitTextFillColor: '#475467' },
        }}
        InputProps={{
          disableUnderline: true,
          inputProps: {
            min: '1000-01-01',
            max: '2099-12-31',
            style: { fontSize: '14px', padding: '0 5px 0 5px' },
          },
        }}
      />
    </span>
  );
}

const caseStatusMap = {
  NEW: 'Files Required',
  PROCESSING: 'Processing',
  REQUIRES_PROCESSOR: 'Processing',
  READY: 'Open',
  CLOSED: 'Closed',
  ERROR: 'Error',
};
