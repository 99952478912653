import { create } from 'zustand';

const useBulkApplyStore = create<BulkApplyStoreTypes>((set, get) => ({
  bulkApplyInProgress: false,
  selectedDocuments: [],

  setBulkApplyInProgress: (bulkApplyInProgress: boolean) =>
    set(() => ({ bulkApplyInProgress: bulkApplyInProgress })),

  setSelectedDocuments: (selectedDocuments: string[]) =>
    set(() => ({ selectedDocuments: selectedDocuments })),

  isDocumentSelected: (documentID: string) => {
    return get().selectedDocuments.includes(documentID);
  },

  isBulkApplyDisabled: () => {
    const selectedDocumentCount = get().selectedDocuments.length;
    return selectedDocumentCount > 50 || selectedDocumentCount < 2;
  },

  disabledMessage: () => {
    const selectedDocumentCount = get().selectedDocuments.length;
    if (selectedDocumentCount > 50) {
      return 'Cannot bulk apply for over 50 documents';
    }
    if (selectedDocumentCount < 2) {
      return 'Select at least 2 documents for bulk apply';
    }
    return '';
  },
}));

export default useBulkApplyStore;

interface BulkApplyStoreTypes {
  bulkApplyInProgress: boolean;
  setBulkApplyInProgress: (selectionEnabled: boolean) => void;
  selectedDocuments: string[];
  setSelectedDocuments: (selectedPages: string[]) => void;
  isBulkApplyDisabled: () => boolean;
  isDocumentSelected: (documentID: string) => boolean;
  disabledMessage: () => string;
}
