import { Box, Stack } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import OverflowText from '../../../components/common/OverflowText';
import { FileStatus } from '../../../api';

type Props = {
  fileName: string;
  fileStatus: FileStatus;
  fileID: string;
  handleFileStatusChange: (fileID: string, fileStatus: FileStatus) => void;
};

export default function FileNameWithNavigationOptions({
  fileName,
  fileStatus,
  handleFileStatusChange,
  fileID,
}: Props) {
  const navigate = useNavigate();
  const componentRef = React.useRef(null);

  const handleNavigateToNextProcessingTab = async () => {
    if (fileStatus === 'PENDING') {
      await handleFileStatusChange(fileID, fileStatus);
      navigate(`${fileID}/grouping`);
    } else if (fileStatus === 'GROUPING') {
      navigate(`${fileID}/grouping`);
    } else {
      navigate(`../timeline?documentID=${fileID}`);
    }
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          '&:hover': {
            '& svg': {
              opacity: 1,
            },
          },
        }}
      >
        {!['UPLOADING', 'SKIP_MDOC', 'AUTOMATED_PROCESSING'].includes(fileStatus) ? (
          <span ref={componentRef} onClick={handleNavigateToNextProcessingTab}>
            <OverflowText
              sx={{
                color: 'var(--color-link-blue)',
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                }}
              >
                {fileName}
              </span>
            </OverflowText>
          </span>
        ) : (
          fileName
        )}
      </Box>
    </Stack>
  );
}
