import { useState, useMemo, useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { TextField, CircularProgress, Skeleton, InputAdornment, Button, Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import MultiSelectDropdown from '../../../../components/common/HTML_components/MultiSelectDropdown/MultiSelectDropdown';
import useContentTypesAndSpecialities from '../../../Timeline/gql/useContentTypesAndSpecialities';
import useBulkUpdatePageTags from '../../../Timeline/gql/useBulkUpdatePageTags';
import EntitySelect from '../../../Timeline/EntitySelect';
import useEntities from '../../../Timeline/gql/useEntities';
import { PreviewEntryDetails } from '../IndexReportTable';
import useTimelineStore, { UpdatedTimelineEntryObject } from '../../../Timeline/useTimelineStore';
import CustomSelectMenu from '../../../../components/common/HTML_components/DropdownSelect/DropdownSelect';
import {
  useDocumentTags,
  useUpdateDocumentTags,
} from '../../../../components/DocumentScrolling/useTimelineEntryTags';
import useSources from '../../../Timeline/gql/useSources';
import { TimelineDetailsProps } from './DocumentPreviewer';
import { useGetPageByIdQuery } from '../../../../__generated__/graphql';
import { DocumentTagsObject } from '../../../../api';
import DateRangeSelector from '../../../Timeline/Components/DateRangeSelector';
import { useGetDocumentNames } from '../../../Timeline/api-queries/useGetDocumentNames';
import { useIsFileProcessor } from '../../../AccountSettings/useFileProcessing';
import { useUserGroup } from '../../../MyCases/useCases';
import useEntryPagesWithTags from '../../../Timeline/useEntryPagesWithTags';
import { useAnnotationTextCaptureContext } from '../../../Timeline/hooks/useAnnotationTextCapture';
import Reticle from '../../../../components/icons/Reticle';

type Option = {
  value: string;
  label: string;
};

type EditDocumentDetailsProps = {
  entryID: bigint;
  pageID: string;
  caseID: string;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  isFetchingEntryDetails?: boolean;
  setSelectedDate: (dates: { startDate?: string; endDate?: string }) => void;
  refreshIndexReport?: () => void;
  updateIndexRowCache?: (rowId: string, updatedValues: any) => void;
  isEditable: boolean;
  isOnReviewTab?: boolean;
};

export function EditDocumentDetails({
  entryID,
  pageID,
  caseID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onUpdate,
  isFetchingEntryDetails = false,
  setSelectedDate,
  refreshIndexReport,
  updateIndexRowCache,
  isEditable = true,
  isOnReviewTab = false,
}: EditDocumentDetailsProps) {
  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';
  const { data: entryPages, refetch: refetchEntryPages } = useEntryPagesWithTags({
    entryID: entryID,
  });
  const { toggleIsScreenCapturing } = useAnnotationTextCaptureContext();
  const [hasPageTags, setHasPageTags] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (processorOrLabeller) {
      setHasPageTags(entryPages?.some((page) => page.tags?.length > 0));
    }
  }, [entryPages]);

  const [updatedEntryDetails, setUpdatedEntryDetails] =
    useState<PreviewEntryDetails>(currentEntryDetails);

  const [pageSelectedTags, setPageSelectedTags] = useState<Option[]>([]);

  const { data: documentTags } = useDocumentTags(entryID);

  const {
    loading,
    data: pageObject,
    refetch: refetchPageObject,
  } = useGetPageByIdQuery({
    variables: {
      id: pageID,
    },
    skip: !pageID,
  });

  const setPageTags = () => {
    const pageTags = pageObject?.page?.tags;
    if (pageTags && documentTags) {
      const currentDocumentTagIDs = [
        ...documentTags.documentTypes.map((tag) => tag.tag_id),
        ...documentTags.specialties.map((tag) => tag.tag_id),
      ];
      setPageSelectedTags(
        pageTags
          .filter((pageTag) => !currentDocumentTagIDs.includes(pageTag.id))
          .map((tag) => ({ value: tag.id.toString(), label: tag.label })) ?? [],
      );
    }
  };

  useEffect(() => {
    if (isEditable || !isOnReviewTab) {
      setPageTags();
    }
  }, [pageObject, isEditable, isOnReviewTab]);

  useEffect(() => {
    if (documentTags) {
      refetchPageObject();
    }
  }, [documentTags]);

  useEffect(() => {
    if (!isEditable && isOnReviewTab) {
      setPageSelectedTags([]);
    }
  }, [isEditable]);

  const handleClearAllPageTags = () => {
    bulkUpdatePageTags({
      where: { timeline_entry_id: entryID },
      data: {
        tagsIds: [],
      },
      newPagesState: entryPages?.map((page) => ({
        __typename: 'PageObject',
        id: page.id,
        tags: [],
      })),
      onCompleted: () => {
        toast.success(
          'Content labels cleared.',
          isFileProcessor
            ? { position: 'bottom-right', autoClose: 500 }
            : {
                autoClose: 500,
                hideProgressBar: true,
              },
        );
        setPageSelectedTags([]);
        refetchEntryPages();
      },
      onError: () => {
        toast.error('Error clearing content labels.');
      },
    });
  };

  const allContentTags = useContentTypesAndSpecialities();
  const contentTagOptions = useMemo(() => {
    const tags = allContentTags.pageTagList.filter((tag) => tag.origin !== 'deprecated');
    return tags.map((tag) => ({
      value: String(tag.id),
      label: tag.label,
    }));
  }, [allContentTags.pageTagList]);

  const [bulkUpdatePageTags] = useBulkUpdatePageTags();

  const handleChangeLabel = useCallback(
    async (currentTags: Option[]) => {
      const isAdding = currentTags.length > (pageSelectedTags?.length || 0);
      const mappedTagIDs = currentTags.map((option) => +option.value);

      const validTags = allContentTags.pageTagList
        .filter((tag) => mappedTagIDs.includes(tag.id))
        .filter(Boolean);

      setPageSelectedTags(currentTags.filter(Boolean));

      await bulkUpdatePageTags({
        where: { id: +pageID },
        data: {
          tagsIds: mappedTagIDs,
          shouldInvalidateUnspecifiedTags: !isAdding,
        },
        newPagesState: {
          __typename: 'PageObject',
          id: +pageID,
          tags: validTags,
        },
      });
      if (refreshIndexReport) {
        refreshIndexReport();
      }
      if (isOnReviewTab && processorOrLabeller) {
        refetchEntryPages();
      }
    },
    [allContentTags, pageID, bulkUpdatePageTags, pageSelectedTags, refreshIndexReport],
  );

  const { hiddenEntities, updatedTimelineEntry, setUpdatedTimelineEntry } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
      updatedTimelineEntry: state.updatedTimelineEntry,
      setUpdatedTimelineEntry: state.setUpdatedTimelineEntry,
    }),
    shallow,
  );

  const entities = useEntities(caseID, entryID);

  const filteredAuthors = useMemo(() => {
    return entities?.sortedAuthorsForCase.filter((author) => {
      return !hiddenEntities.includes(author.value) && !author.hidden;
    });
  }, [entities?.sortedAuthorsForCase, hiddenEntities]);

  const filteredOrgs = useMemo(() => {
    return entities?.sortedOrgsForCase.filter((org) => {
      return !hiddenEntities.includes(org.value) && !org.hidden;
    });
  }, [entities?.sortedOrgsForCase, hiddenEntities]);

  const handleBlurMonetaryTotal = async () => {
    if (updatedEntryDetails.monetary_total !== currentEntryDetails.monetary_total) {
      try {
        setCurrentEntryDetails(updatedEntryDetails);
        onUpdate(caseID, entryID, {
          monetary_total: updatedEntryDetails.monetary_total,
        });
        if (updateIndexRowCache) {
          updateIndexRowCache(String(entryID), {
            'monetary-total': updatedEntryDetails.monetary_total,
          });
        }
      } catch (error) {
        toast.error(`Failed to update monetary total: ${error.message}`);
      }
    }
  };

  const addEntityToQueryCache = (
    entityType,
    response,
    newDetails,
    queryClient,
    caseID,
    entryID,
    pageID,
    setUpdatedEntryDetails,
    setCurrentEntryDetails,
  ) => {
    const entityKeyMap = {
      author: {
        idKey: 'author_id',
        nameKey: 'author_name',
        queryPeopleKey: 'peopleForCase',
        queryTimelineKey: 'peopleForTimelineEntry',
        suggestedEntity: 'AUTHOR',
      },
      organization: {
        idKey: 'organization_id',
        nameKey: 'organization_name',
        queryPeopleKey: 'organizationsForCase',
        queryTimelineKey: 'organizationsForTimelineEntry',
        suggestedEntity: 'ORGANIZATION',
      },
    };

    const { idKey, nameKey, suggestedEntity } = entityKeyMap[entityType];

    if (!newDetails[entityType].id) {
      const newEntity = {
        id: response !== undefined ? response[idKey] : null,
        name: response !== undefined ? response[nameKey] : newDetails[entityType].name,
        label: response !== undefined ? response[nameKey] : newDetails[entityType].label,
        timeline_entry_id: entryID,
        page_id: pageID,
        dismissed: 0,
        ml_suggested_entity: suggestedEntity,
      };

      queryClient.cancelQueries(['entities', caseID, entryID]);
      const previousEntities = queryClient.getQueryData(['entities', caseID, entryID]);
      const newEntities = {
        peopleForCase:
          entityType === 'author'
            ? [...previousEntities.peopleForCase, newEntity]
            : previousEntities.peopleForCase,
        peopleForTimelineEntry:
          entityType === 'author'
            ? [...previousEntities.peopleForTimelineEntry, newEntity]
            : previousEntities.peopleForTimelineEntry,
        organizationsForCase:
          entityType === 'org'
            ? [...previousEntities.organizationsForCase, newEntity]
            : previousEntities.organizationsForCase,
        organizationsForTimelineEntry:
          entityType === 'org'
            ? [...previousEntities.organizationsForTimelineEntry, newEntity]
            : previousEntities.organizationsForTimelineEntry,
      };
      queryClient.setQueryData(['entities', caseID, entryID], newEntities);
      newDetails[entityType] = newEntity;
      setUpdatedEntryDetails(newDetails);
      setCurrentEntryDetails(newDetails);
      queryClient.invalidateQueries(['entities', caseID, entryID]);
    }
  };

  const handleUpdateAuthor = useCallback(
    async (author) => {
      if (!author) {
        return;
      }
      const previousDetails = { ...currentEntryDetails };
      const newDetails = { ...updatedEntryDetails, author };

      const prevName = previousDetails.author.name ?? previousDetails.author.label ?? '';
      const newName = newDetails.author.name ?? newDetails.author.label ?? '';
      if (prevName !== newName) {
        setUpdatedEntryDetails(newDetails);
        setCurrentEntryDetails(newDetails);

        try {
          const response = await onUpdate(caseID, entryID, {
            author: newDetails.author,
          });
          addEntityToQueryCache(
            'author',
            response,
            newDetails,
            queryClient,
            caseID,
            entryID,
            pageID,
            setUpdatedEntryDetails,
            setCurrentEntryDetails,
          );
          if (isOnReviewTab) {
            setUpdatedTimelineEntry({
              ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
              id: Number(entryID),
              authorID: newDetails.author.id ?? response?.author_id,
            });
          }
        } catch (error) {
          setCurrentEntryDetails(previousDetails);
          setUpdatedEntryDetails(previousDetails);
        }
      }
    },
    [caseID, entryID, updatedEntryDetails, currentEntryDetails, onUpdate],
  );

  const handleUpdateOrganization = useCallback(
    async (organization) => {
      if (!organization) {
        return;
      }
      const previousDetails = { ...currentEntryDetails };
      const newDetails = { ...updatedEntryDetails, organization };

      const prevName =
        previousDetails.organization.name ?? previousDetails.organization.label ?? '';
      const newName = newDetails.organization.name ?? newDetails.organization.label ?? '';
      if (prevName !== newName) {
        setUpdatedEntryDetails(newDetails);
        setCurrentEntryDetails(newDetails);

        try {
          const response = await onUpdate(caseID, entryID, {
            organization: newDetails.organization,
          });
          addEntityToQueryCache(
            'organization',
            response,
            newDetails,
            queryClient,
            caseID,
            entryID,
            pageID,
            setUpdatedEntryDetails,
            setCurrentEntryDetails,
          );
          if (isOnReviewTab) {
            setUpdatedTimelineEntry({
              ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
              id: Number(entryID),
              orgID: newDetails.organization.id ?? response?.org_id,
            });
          }
        } catch (error) {
          setCurrentEntryDetails(previousDetails);
          setUpdatedEntryDetails(previousDetails);
        }
      }
    },
    [caseID, entryID, updatedEntryDetails, currentEntryDetails, onUpdate],
  );

  useEffect(() => {
    if (!isEqual(currentEntryDetails, updatedEntryDetails)) {
      setUpdatedEntryDetails(currentEntryDetails);
    }
  }, [currentEntryDetails]);

  return (
    <div
      style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: processorOrLabeller && isOnReviewTab ? '2.5rem' : '5rem',
      }}
      id="details-box"
    >
      <h2 style={{ textAlign: 'left', fontSize: '0.95rem', fontWeight: 500 }}>Document Details</h2>
      <div style={{ width: '85%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          {isFileProcessor && isOnReviewTab && (
            <span
              onClick={() => toggleIsScreenCapturing('author')}
              style={{
                marginRight: '0.25rem',
                cursor: 'pointer',
              }}
            >
              <Reticle />
            </span>
          )}
          <h3
            style={{
              textAlign: 'left',
              fontSize: '0.8rem',
              fontWeight: 400,
              marginTop: '2.5px',
            }}
          >
            Author
          </h3>
        </div>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            className={!isEditable ? 'sm-disabled' : ''}
            entityName="author"
            value={updatedEntryDetails?.author.name ?? updatedEntryDetails?.author.label ?? ''}
            optionsForCase={filteredAuthors}
            optionsForEntry={entities?.sortedAuthorsForEntry}
            allOptions={entities?.allAuthors}
            setValues={(e: { id: number | null; name: string | null; label: string | null }) => {
              handleUpdateAuthor(e);
            }}
            inputProps={undefined}
          />
        )}
      </div>
      <div style={{ width: '85%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          {isFileProcessor && isOnReviewTab && (
            <span
              onClick={() => toggleIsScreenCapturing('organization')}
              style={{
                marginRight: '0.25rem',
                cursor: 'pointer',
              }}
            >
              <Reticle />
            </span>
          )}
          <h3
            style={{
              textAlign: 'left',
              fontSize: '0.8rem',
              fontWeight: 400,
              marginTop: '-10px',
            }}
          >
            Organization
          </h3>
        </div>

        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            className={!isEditable ? 'sm-disabled' : ''}
            entityName="organization"
            value={
              updatedEntryDetails?.organization.name ??
              updatedEntryDetails?.organization.label ??
              ''
            }
            optionsForCase={filteredOrgs}
            optionsForEntry={entities?.sortedOrgsForEntry}
            allOptions={entities?.allOrgs}
            setValues={(e: { id: number | null; name: string | null; label: string | null }) => {
              handleUpdateOrganization(e);
            }}
            inputProps={undefined}
          />
        )}
      </div>
      <div style={{ width: '85%' }}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '-10px',
          }}
        >
          <h3
            style={{
              textAlign: 'left',
              fontSize: '0.8rem',
              fontWeight: 400,
              marginBottom: 0,
            }}
          >
            Date
          </h3>
        </span>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <span className={!isEditable ? 'sm-disabled' : ''}>
            <DateRangeSelector
              entryStartDate={currentEntryDetails.startDate}
              entryEndDate={currentEntryDetails.endDate}
              entryID={entryID}
              orientation="horizontal"
              isPopUp={true}
              shouldOpenByDefault={false}
              onChange={setSelectedDate}
            />
          </span>
        )}
      </div>
      <div style={{ width: '85%' }}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
            fontWeight: 400,
            marginBottom: '2.5px',
            marginTop: '8px',
          }}
        >
          Amount
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <TextField
            className={!isEditable ? 'sm-disabled' : ''}
            value={updatedEntryDetails.monetary_total}
            onChange={(e) =>
              setUpdatedEntryDetails({ ...updatedEntryDetails, monetary_total: e.target.value })
            }
            onBlur={handleBlurMonetaryTotal}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& .MuiTypography-root': { fontSize: 'small' } }}
                >
                  $
                </InputAdornment>
              ),
              style: { fontSize: '0.75rem' },
            }}
            sx={{
              width: '100%',
              minHeight: 'fit-content',
              overflowY: 'visible',
              alignContent: 'center',
              border: '0.5px solid rgb(208, 213, 221)',
              borderRadius: '10px',
              '& .MuiAutocomplete-inputRoot': { border: 'none', paddingY: 0, paddingX: 0 },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { border: 'none' },
                '&:hover fieldset': { border: 'none' },
                '&.Mui-focused fieldset': { border: 'none' },
              },
            }}
          />
        )}
      </div>
      <SplitTagsDetails
        caseID={caseID}
        entryID={entryID}
        currentEntryDetails={currentEntryDetails}
        setCurrentEntryDetails={setCurrentEntryDetails}
        onUpdate={onUpdate}
        documentTags={documentTags}
        isFetchingEntryDetails={isFetchingEntryDetails}
        refreshIndexReport={refreshIndexReport}
        isEditable={isEditable}
        isOnReviewTab={isOnReviewTab}
        updatedTimelineEntry={updatedTimelineEntry}
        setUpdatedTimelineEntry={setUpdatedTimelineEntry}
      />

      <h2 style={{ textAlign: 'left', fontSize: '0.95rem', fontWeight: 500, paddingTop: '25px' }}>
        Page Details
      </h2>
      <div style={{ width: '85%' }}>
        {loading ? (
          <CircularProgress size="1.4rem" sx={{ marginBottom: '0.7rem' }} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <MultiSelectDropdown
              className={!isEditable ? 'sm-disabled' : ''}
              options={contentTagOptions}
              selectedValues={pageSelectedTags ?? []}
              onChange={handleChangeLabel}
              shouldOpenByDefault={false}
              style={{
                width: '70%',
                fontSize: 'small',
              }}
              searchBar={false}
              dropdownHeader="Tags"
            />
            {processorOrLabeller && isOnReviewTab && (
              <Button
                onClick={() => handleClearAllPageTags()}
                disabled={!hasPageTags}
                sx={{
                  cursor: 'pointer',
                  color: 'warning.main',
                  fontSize: '0.85em',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  margin: '0.5rem auto',
                }}
              >
                Clear All Page Tags for Document
              </Button>
            )}
          </Box>
        )}
      </div>
    </div>
  );
}

type SplitTagsDetailsProps = {
  caseID: string;
  entryID: bigint;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  documentTags?: DocumentTagsObject;
  isFetchingEntryDetails: boolean;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  refreshIndexReport?: () => void;
  isEditable: boolean;
  isOnReviewTab?: boolean;
  updatedTimelineEntry: UpdatedTimelineEntryObject | null;
  setUpdatedTimelineEntry: (entry: UpdatedTimelineEntryObject | null) => void;
};

function SplitTagsDetails({
  caseID,
  entryID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onUpdate,
  documentTags,
  isFetchingEntryDetails,
  refreshIndexReport,
  isEditable = true,
  isOnReviewTab = false,
  updatedTimelineEntry,
  setUpdatedTimelineEntry,
}: SplitTagsDetailsProps) {
  const { refetch: refetchDocumentNames } = useGetDocumentNames(
    caseID ?? '',
    currentEntryDetails.file_id ?? '',
  );

  const allContentTags = useContentTypesAndSpecialities();
  const contentTags = allContentTags?.content.map((tag) => {
    return { value: tag.tag_id, label: tag.name, deprecated: tag.deprecated };
  });
  const specialityTags = allContentTags?.specialities.map((tag) => {
    return { value: tag.tag_id, label: tag.name, deprecated: tag.deprecated };
  });

  const contentTagsForCase = useMemo(() => {
    if (!contentTags) {
      return [];
    }
    return contentTags
      .filter(
        (tag) =>
          !documentTags?.documentTypes
            .map((tag) => String(tag.tag_id))
            .includes(String(tag.value)) && !tag.deprecated,
      )
      .map((tag) => {
        return { value: String(tag.value), label: tag.label };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [documentTags, contentTags]);

  const specialityTagsForCase = useMemo(() => {
    if (!specialityTags) {
      return [];
    }
    return specialityTags
      .filter(
        (tag) =>
          !documentTags?.specialties.map((tag) => String(tag.tag_id)).includes(String(tag.value)) &&
          !tag.deprecated,
      )
      .map((tag) => {
        return { value: String(tag.value), label: tag.label };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [documentTags, specialityTags]);

  const [documentType, setDocumentType] = useState<any[]>(
    documentTags?.documentTypes.map((tag) => {
      return { value: tag.tag_id, label: tag.name };
    }) ?? [],
  );
  const [speciality, setSpeciality] = useState<any[]>(
    documentTags?.specialties.map((tag) => {
      return { value: tag.tag_id, label: tag.name };
    }) ?? [],
  );

  const setDocumentTypeAndSpeciality = () => {
    if (documentTags) {
      setDocumentType(
        documentTags?.documentTypes.map((tag) => {
          return { value: tag.tag_id, label: tag.name };
        }) ?? [],
      );
      setSpeciality(
        documentTags?.specialties.map((tag) => {
          return { value: tag.tag_id, label: tag.name };
        }) ?? [],
      );
    }
  };

  useEffect(() => {
    setDocumentTypeAndSpeciality();
  }, [documentTags]);

  const sources = useSources();

  const sourceOptions = sources
    ?.filter((source) => !source.deprecated)
    .map((sourceName) => ({
      value: sourceName.tag_id,
      label: sourceName.name,
    }));
  const handleUpdateSource = (newSource) => {
    if (newSource.value !== currentEntryDetails.sourceID) {
      onUpdate(caseID, entryID, {
        source: newSource.value,
      });
      setCurrentEntryDetails({ ...currentEntryDetails, sourceID: newSource.value });
      if (refreshIndexReport) {
        refreshIndexReport();
      }
      if (isOnReviewTab) {
        setUpdatedTimelineEntry({
          ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
          id: entryID,
          sourceID: newSource.value,
        });
      }
    }
  };

  const { mutateAsync: updateDocumentTagsWithType } = useUpdateDocumentTags();

  const handleUpdateDocumentType = async (tags: Option[]) => {
    setDocumentType(tags);
    if (isEqual(documentTags?.documentTypes, tags)) {
      return;
    }

    const tagType = 'Content';
    await updateDocumentTagsWithType({
      entryID: entryID,
      tags: tags.map((tag) => {
        return { tag_id: tag.value, name: tag.label, category: 'Content' };
      }),
      tagType,
    });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
    if (isOnReviewTab) {
      setTimeout(() => {
        refetchDocumentNames();
      }, 500);
    }
  };

  const handleUpdateSpeciality = async (tags: Option[]) => {
    setSpeciality(tags);
    if (isEqual(documentTags?.specialties, tags)) {
      return;
    }

    const tagType = 'Specialist';
    await updateDocumentTagsWithType({
      entryID: entryID,
      tags: tags.map((tag) => {
        return { tag_id: tag.value, name: tag.label, category: 'Specialist' };
      }),
      tagType,
    });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
    if (isOnReviewTab) {
      setTimeout(() => {
        refetchDocumentNames();
      }, 500);
    }
  };

  return (
    <>
      <div id="source">
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
            fontWeight: 400,
            marginBottom: '2.5px',
            marginTop: '5px',
          }}
        >
          Source Type
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <CustomSelectMenu
            className={!isEditable ? 'sm-disabled' : ''}
            options={sourceOptions}
            dropUp={true}
            onChange={(e) => {
              handleUpdateSource(e);
            }}
            currentOption={{
              value: currentEntryDetails.sourceID,
              label:
                sourceOptions?.find(
                  (option) => option.value === Number(currentEntryDetails.sourceID),
                )?.label ?? null,
            }}
            onCommit={() => {}}
          />
        )}
      </div>
      <div style={{ width: '85%' }}>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <MultiSelectDropdown
            className={!isEditable ? 'sm-disabled' : ''}
            options={contentTagsForCase}
            selectedValues={documentType}
            onChange={handleUpdateDocumentType}
            shouldOpenByDefault={false}
            style={{
              width: '70%',
              fontSize: 'small',
            }}
            dropdownHeader="Type"
            autoFocus
          />
        )}
      </div>
      <div style={{ width: '85%' }}>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <MultiSelectDropdown
            className={!isEditable ? 'sm-disabled' : ''}
            options={specialityTagsForCase}
            selectedValues={speciality}
            onChange={handleUpdateSpeciality}
            shouldOpenByDefault={false}
            style={{
              width: '70%',
              fontSize: 'small',
            }}
            dropdownHeader="Specialty"
            autoFocus
          />
        )}
      </div>
    </>
  );
}
