import { useState, useEffect } from 'react';
import { TextField, Button, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import './case-insights-container.css';
import { CaseSubjectFields, CaseDetails } from './useInsights';
import { CaseSubject } from '../../../api';
import Theme from '../../../theme';
import Modal from '../../../components/common/HTML_components/Modal/Modal';
import CloseIcon from '../../../components/icons/Close';

export default function EditSubjectDialog({
  subject,
  isOpen,
  onClose,
  handleSaveSubjectChanges,
  errorMessage,
  validateSubjectUpdate,
}: {
  subject: CaseSubject;
  isOpen: boolean;
  onClose: () => void;
  handleSaveSubjectChanges: (s: CaseSubject) => void;
  errorMessage: string;
  validateSubjectUpdate: (s: CaseSubject & CaseDetails) => string;
}) {
  const [updatedSubject, setUpdatedSubject] = useState<CaseSubject>(
    subject ?? { name: '', date_of_birth: '', address: '', phone_number: '', email: '' },
  );
  const handleUpdateSubject = (field: CaseSubjectFields, value: string) => {
    setUpdatedSubject({ ...updatedSubject, [field]: value });
  };

  useEffect(() => {
    if (subject && isOpen) {
      setUpdatedSubject(subject);
      validateSubjectUpdate({ ...subject });
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span className="details-header" style={{ padding: '16px' }}>
            Edit Subject
          </span>
          <button
            className="sm-icon-button"
            type="button"
            onClick={onClose}
            style={{ marginRight: '2px' }}
          >
            <CloseIcon />
          </button>
        </div>
        <div style={{ display: 'grid', width: '600px', padding: '16px', paddingTop: 0 }}>
          <div className="details-box-row">
            <span className="dialog-text">Name</span>
            <TextField
              sx={{ width: '70%' }}
              onChange={(e) => handleUpdateSubject('name', e.target.value)}
              value={updatedSubject.name}
            />
          </div>
          <div className="details-box-row">
            <span className="dialog-text">Date of Birth</span>
            <TextField
              sx={{ width: '70%' }}
              onChange={(e) => handleUpdateSubject('date_of_birth', e.target.value)}
              onBlur={() => validateSubjectUpdate({ ...updatedSubject })}
              InputProps={{
                inputProps: { min: '1000-01-01', max: '2099-12-31' },
              }}
              type="date"
              value={updatedSubject.date_of_birth}
            />
          </div>
          <div className="details-box-row">
            <span className="dialog-text">Address</span>
            <TextField
              sx={{ width: '70%' }}
              onChange={(e) => handleUpdateSubject('address', e.target.value)}
              value={updatedSubject.address}
            />
          </div>
          <div className="details-box-row">
            <span className="dialog-text">Phone</span>
            <TextField
              sx={{ width: '70%' }}
              onChange={(e) => handleUpdateSubject('phone_number', e.target.value)}
              value={updatedSubject.phone_number}
            />
          </div>
          <div className="details-box-row">
            <span className="dialog-text">Email</span>
            <TextField
              sx={{ width: '70%' }}
              onChange={(e) => handleUpdateSubject('email', e.target.value)}
              value={updatedSubject.email}
            />
          </div>
          {errorMessage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '12px',
                padding: '4px',
                color: 'red',
              }}
            >
              {errorMessage}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              id="save-subject-button"
              onClick={() => handleSaveSubjectChanges(updatedSubject)}
              disabled={Boolean(errorMessage)}
              size="small"
              aria-label="save"
              variant="contained"
            >
              <span style={{ fontSize: '12px' }}> Save</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function CaseSubjectChip({
  subject,
  setSubjectDialogOpen,
}: {
  subject: CaseSubject;
  setSubjectDialogOpen: (open: boolean) => void;
}) {
  return (
    <div
      style={{
        backgroundColor: Theme.palette.duplicatesByDocumentBlue.light,
        borderRadius: '10px',
        minWidth: '25%',
        width: 'fit-content',
        padding: '8px',
        marginTop: '2px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ width: '95%' }}>
          <div className="dialog-box-row">
            <span className="dialog-label">Name:</span>
            <span className="details-value">{subject.name}</span>
          </div>
          <div className="dialog-box-row">
            <span className="dialog-label">Date of Birth:</span>
            <span className="details-value">{subject.date_of_birth}</span>
          </div>
          <div className="dialog-box-row">
            <span className="dialog-label">Address:</span>
            <span className="details-value">{subject.address}</span>
          </div>
          <div className="dialog-box-row">
            <span className="dialog-label">Phone Number:</span>
            <span className="details-value">{subject.phone_number}</span>
          </div>
          <div className="dialog-box-row">
            <span className="dialog-label">Email:</span>
            <span className="details-value">{subject.email}</span>
          </div>
        </div>
        <div style={{ width: '15px' }}>
          <Tooltip title="Edit Subject">
            <Edit
              id="case-subject-edit"
              style={{
                flex: '0 0 auto',
                marginTop: '-5px',
                position: 'relative',
                cursor: 'pointer',
                fontSize: '1rem',
              }}
              onClick={() => setSubjectDialogOpen(true)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
