import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import './document-grouping-container.css';
import DocumentScroller from '../Case/DocumentScroller';
import Loading from '../../components/common/Loading';
import { PageObject } from '../../api';
import UpArrowWithTail from '../../components/icons/UpArrowWithTail';
import Theme from '../../theme';
import Attachment from '../../components/icons/Attachment';
import CheckMark from '../../components/icons/CheckMark';
import GroupingPageRange from './GroupingPageRange';
import {
  DocumentScrollerCompatiblePage,
  GroupingActionOnClicks,
  GroupingActionStatuses,
} from './utils/documentState';
import NavBar from '../../components/common/PdfNavigation/NavBar';

export default function DocumentGroupingContainer({
  pageID,
  pages,
  groupingActionOnClicks,
  getButtonEnabledStatuses,
  depthAt,
  pageIndexById,
  groupIDByPageID,
  attachmentIDByPageID,
  groupedDocumentTree,
  startPage,
  endPage,
  onChangePageRange,
  documentScrollerCompatiblePages,
  onChangePage,
  groupingType,
}: {
  pageID: string;
  pages: PageObject[];
  groupingActionOnClicks: GroupingActionOnClicks;
  getButtonEnabledStatuses: GroupingActionStatuses;
  depthAt: (page: PageObject['id']) => number;
  pageIndexById: Map<string, number>;
  groupIDByPageID: Record<string, number>;
  attachmentIDByPageID: Record<string, number>;
  groupedDocumentTree: Array<PageObject | PageObject[]>;
  startPage: number;
  endPage: number;
  onChangePageRange: (start: number, end: number) => void;
  onChangePage: (nextIndex: number) => void;
  documentScrollerCompatiblePages: DocumentScrollerCompatiblePage[];
  groupingType: 'grouping' | 'preview';
}) {
  const navigate = useNavigate();

  const { currentPageIndex, currentPage } = useMemo(() => {
    if (!pageIndexById || !pageID) {
      return { currentPageIndex: 0, currentPage: undefined };
    }

    const currentPageIndex = pageIndexById.get(pageID) ?? 0;
    const currentPage = pages?.[currentPageIndex];
    return { currentPageIndex, currentPage };
  }, [pageIndexById, pageID, pages]);

  if (!pageID || pages == null || pages.length === 0) {
    return <Loading />;
  }

  const handleChangePage = (id: string) => {
    const pageIndex = pageIndexById.get(id) ?? 0;
    onChangePage(pageIndex + 1);
  };

  return (
    <>
      <NavBar
        currentPage={currentPageIndex + 1}
        page={currentPage}
        numberOfPages={pages ? pages.length : 0}
        onChangePage={onChangePage}
        onPreviousButtonClick={() => {}}
        onNextButtonClick={() => {}}
        nextButtonDisabled={true}
        previousButtonDisabled={true}
        showZoomRotateToolbar={true}
        containerName="grouping"
      />
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: '20%',
            maxWidth: 270,
            backgroundColor: Theme.palette.selectedGrey.main,
          }}
        >
          <span
            className="sm-back-button"
            onClick={() => {
              navigate('../files');
            }}
            style={{ width: 'fit-content', padding: '0.5rem' }}
          >
            <UpArrowWithTail
              style={{
                color: '#1E407D',
                marginRight: '0.5rem',
              }}
              transform="rotate(270)"
            />
            <span className="sm-back-button-text sm-button-text">Back to files</span>
          </span>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.5rem' }}>
            <GroupingPageRange
              currentStartPage={startPage ?? 1}
              currentEndPage={endPage ?? pages.length}
              totalNumberOfPages={pages.length}
              onChangePage={onChangePageRange}
            />
          </div>
          <div
            style={{
              height: window.innerHeight - 250,
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {Object.values(groupedDocumentTree).map((group) => (
              <DocumentChip
                documentPages={group}
                currentPage={pageID}
                startPage={startPage}
                endPage={endPage}
                handleChangePage={handleChangePage}
              />
            ))}
          </div>
        </div>
        <div style={{ width: '80%' }}>
          <DocumentScroller
            pages={documentScrollerCompatiblePages ?? []}
            currentPageID={Number(pageID)}
            searchResults={[]}
            currentMode={groupingType}
            dismissDocumentEdge={() => {}}
            pageDepthByPageID={depthAt}
            groupingActionOnClicks={groupingActionOnClicks}
            isGrouping
            getGroupingButtonDisabledStatuses={getButtonEnabledStatuses}
            groupIDByPageID={groupIDByPageID}
            attachmentIDByPageID={attachmentIDByPageID}
          />
        </div>
      </div>
    </>
  );
}

function AttachmentChip({
  documentPages,
  currentPage,
  isInRange,
  onChangePage,
}: {
  documentPages: PageObject[];
  currentPage: string | undefined;
  isInRange: boolean;
  onChangePage: (id: string) => void;
}) {
  const firstPageOfAttachment = documentPages[0].id;
  return (
    <div
      style={{
        margin: '5px',
      }}
    >
      <div className={`attachment-chip${isInRange ? '-page-range' : ''}`}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {documentPages.map((page: PageObject) => {
            const isCurrentPage = currentPage === page.id;
            return (
              <span
                style={{
                  paddingBottom: '2px',
                  paddingTop: '2px',
                }}
              >
                <div
                  id={`chip-${page.id}`}
                  className="page-chip"
                  data-status={`${isCurrentPage}`}
                  style={{
                    justifyContent: 'flex-start',
                  }}
                  onClick={() => {
                    onChangePage(page.id);
                  }}
                >
                  <span
                    style={{
                      marginLeft: firstPageOfAttachment === page.id ? '20px' : '40px',
                      width: '90%',
                    }}
                  >
                    {firstPageOfAttachment === page.id && (
                      <span style={{ padding: '4px' }}>
                        <Attachment />
                      </span>
                    )}
                    {`Page ${page.page_number}`}
                  </span>
                  {page.document_status !== 'GROUPING' && (
                    <div style={{ alignItems: 'right', marginRight: '0.4rem' }}>
                      <CheckMark />
                    </div>
                  )}
                </div>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function DocumentChip({
  documentPages,
  currentPage,
  startPage,
  endPage,
  handleChangePage,
}: {
  documentPages: Array<PageObject | PageObject[]>;
  currentPage: string | undefined;
  startPage: number | null;
  endPage: number | null;
  handleChangePage: (id: string) => void;
}) {
  const inPageRange = documentPages.some((item) => {
    if (Array.isArray(item)) {
      return item.some(
        (page) => page.page_number >= (startPage ?? 1) && page.page_number <= (endPage ?? 0),
      );
    }
    return item.page_number >= (startPage ?? 1) && item.page_number <= (endPage ?? 0);
  });

  return (
    <div
      style={{
        margin: '6px',
      }}
    >
      <div
        className={`document-chip${inPageRange ? '-page-range' : ''}`}
        style={{
          marginTop: '0.5rem',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {documentPages.map((page) => {
            const isCurrentPage = currentPage === page.id;
            return !Array.isArray(page) ? (
              <div
                id={`chip-${page.id}`}
                className="page-chip"
                data-status={`${isCurrentPage}`}
                style={{ margin: '2px' }}
                onClick={() => {
                  handleChangePage(page.id);
                }}
              >
                <div style={{ marginLeft: '1rem', width: '95%' }}>{`Page ${page.page_number}`}</div>
                {page.document_status !== 'GROUPING' && page.document_status !== 'PENDING' && (
                  <div style={{ alignItems: 'right', marginRight: '1rem' }}>
                    <CheckMark />
                  </div>
                )}
              </div>
            ) : (
              <AttachmentChip
                documentPages={page}
                currentPage={currentPage}
                isInRange={inPageRange}
                onChangePage={handleChangePage}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
