import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiUrl from '../../library/utilities/apiUrl';
import { createNormalizedSelectorWithFallback } from '../../library/utilities/reactQueryUtils';
import { tiemlineListSchema, timelineSchema } from './stateSchemas';

const queryDefaults = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export function useIsTimelineLoading() {
  return Boolean(
    useIsFetching({
      predicate: (query) => query.state === 'loading',
      queryKey: ['timelineList'],
    }) +
      useIsFetching({
        predicate: (query) => query.state === 'loading',
        queryKey: ['timeline'],
      }),
  );
}

//TIMELINE LIST

async function fetchTimelineList(caseID) {
  return (await axios.post(`${apiUrl}getOneCase`, { caseID })).data?.timelines;
}

export function useTimelineList(caseID) {
  return useQuery(['timelineList', caseID], () => fetchTimelineList(caseID), {
    ...queryDefaults,
    schema: tiemlineListSchema,
  });
}

export function createTimeline(caseID, name) {
  return axios.post(`${apiUrl}case/${caseID}/timeline`, { name });
}

export function useCreateTimeline() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(({ caseID, name }) => createTimeline(caseID, name), {
    mutationKey: 'timelineList__createTimeline',
    onSuccess: (data, { caseID }) => {
      queryClient.invalidateQueries(['timelineList', caseID]);
      navigate(`/case/${caseID}/timeline/${data.data[0].id}`);
    },
    onError: (error) => {
      toast.error('Error creating new timeline.', {
        toastId: 'timeline-create',
      });
      throw error;
    },
  });
}

//TIMELINE ENTRIES

export function deleteCase({ userID, caseID }) {
  return axios.post(`${apiUrl}deleteCase`, { caseID: caseID, userID: userID });
}
