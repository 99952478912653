import { Box } from '@mui/material';
import { DocumentSimilarityRecord } from '../../api';
import Theme from '../../theme';

type DuplicatesListChipProps = {
  selectedSimilarity: DocumentSimilarityRecord | null;
  similarity: DocumentSimilarityRecord;
  navigateTo: (id: string) => void;
};

export function DuplicatesListChip({
  selectedSimilarity,
  similarity,
  navigateTo,
}: DuplicatesListChipProps) {
  const duplicateStatusLabel = {
    Duplicate: 'Duplicate',
    NotDuplicate: 'Not Duplicate',
    Unset: '',
  };

  const isChipSelected =
    (String(selectedSimilarity?.duplicate_of) === similarity.duplicate_of &&
      String(selectedSimilarity?.document_id) === similarity.document_id) ||
    (String(selectedSimilarity?.document_id) === similarity.duplicate_of &&
      String(selectedSimilarity?.duplicate_of) === similarity.document_id);

  return (
    <div style={{ marginLeft: '5px', width: '95%', marginTop: '4px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
          width: '95%',
        }}
      >
        <span
          style={{
            color:
              similarity.status === 'NotDuplicate'
                ? Theme.palette.duplicatesByDocumentOrange.main
                : Theme.palette.darkgrey.main,
          }}
        >
          {duplicateStatusLabel[similarity.status]}
        </span>
      </div>
      <Box
        style={{
          width: '100%',
          height: 'fit-content',
          border: '1.5px solid',
          borderColor: isChipSelected
            ? Theme.palette.duplicatesByDocumentBlue.main
            : Theme.palette.borderGrey.main,
          backgroundColor: isChipSelected ? Theme.palette.duplicatesByDocumentBlue.light : 'white',
          borderRadius: '10px',
          padding: '5px 5px 8px 8px',
          fontSize: '12px',
          cursor: 'pointer',
          marginTop: '0px',
          color: isChipSelected ? Theme.palette.duplicatesByDocumentBlue.main : '',
          fontWeight: isChipSelected ? 'bold' : '',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() => navigateTo(similarity.id)}
      >
        <Box>
          Document {similarity.duplicate_of}
          <br />
          Document {similarity.document_id}
        </Box>
        <Box>
          {Number.isInteger(similarity.similarity * 100)
            ? similarity.similarity * 100
            : (Math.floor(similarity.similarity * 1000) / 10).toFixed(1)}
          %
        </Box>
      </Box>
    </div>
  );
}
