import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../../api';

async function getEntryPagesWithTags({ entryID }: { entryID: string }) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/pages/getEntryPagesWithTags?entryID=${entryID}`,
  });
  return res.data;
}

export default function useEntryPagesWithTags({ entryID }: { entryID: string }) {
  const { data, isLoading, isError, refetch } = useQuery(
    ['pages', entryID],
    () => getEntryPagesWithTags({ entryID }),
    {
      enabled: !!entryID,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
    },
  );

  return { data, isLoading, isError, refetch };
}
