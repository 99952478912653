import { create } from 'zustand';

interface ScreenCaptureStore {
  screenCaptures: ScreenCaptureType[];
  addScreenCapture: (screenCapture: ScreenCaptureType) => void;
  setScreenCaptures: (screenCaptures: ScreenCaptureType[]) => void;
  capturingFor?: ScreenCaptureType['type'];
  setCapturingFor: (capturingFor: ScreenCaptureStore['capturingFor']) => void;
}

const useScreenCaptureStore = create<ScreenCaptureStore>((set) => ({
  screenCaptures: [],
  capturingFor: undefined,
  setCapturingFor: (capturingFor: ScreenCaptureStore['capturingFor']) =>
    set(() => ({ capturingFor })),
  addScreenCapture: (screenCapture: ScreenCaptureType) =>
    set(({ screenCaptures, capturingFor }: ScreenCaptureStore) => ({
      screenCaptures: [...screenCaptures, { ...screenCapture, type: capturingFor }],
    })),
  setScreenCaptures: (screenCaptures: ScreenCaptureType[]) =>
    set(({ capturingFor }) => ({
      screenCaptures: [
        ...screenCaptures.map((screenCap) => ({
          ...screenCap,
          type: capturingFor,
        })),
      ],
    })),
}));

export type ScreenCaptureType = {
  pageID: string;
  documentID: string;
  type?: 'note' | 'document-name' | 'author' | 'organization';
  capturedText: string;
  coordinates: {
    Left: number;
    Top: number;
    Width: number;
    Height: number;
  };
};
export default useScreenCaptureStore;
