import { create } from 'zustand';

interface DuplicateByDocumentStore {
  pageID?: string;
  compareToPageID?: string;
  setPageID: (id: string) => void;
  setCompareToPageID: (id: string) => void;
}

const useDuplicateByDocumentStore = create<DuplicateByDocumentStore>((set) => ({
  pageID: undefined,
  compareToPageID: undefined,
  setPageID: (id: string) => set({ pageID: id }),
  setCompareToPageID: (id: string) => set({ compareToPageID: id }),
}));

export default useDuplicateByDocumentStore;
