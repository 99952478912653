import { useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreCaseRelatedFile } from './useModifyCaseRelatedFile';

import { authenticatedRequest } from '../../../../api';

export const useUploadCaseRelatedFile = (userID: string, caseID: string, caseOwnerID: string) => {
  const [isUploading, setIsUploading] = useState(false);
  const { mutate: storeFiles } = useStoreCaseRelatedFile();

  const uploadCaseRelatedFiles = async (files: FileList) => {
    setIsUploading(true);
    const newFiles = Array.from(files).map((file) => {
      const fileID = crypto.randomUUID();
      const fileExtension = file.name.split('.').pop() || '';
      const filePath = `${caseOwnerID}/${caseID}/Case_Related_Files/${file.name}`;


      return {
        id: fileID,
        case_id: caseID,
        file_name: file.name.split('.').shift() || '',
        file_type: fileExtension,
        file_path: filePath,
        uploaded_by: userID,
        uploaded_by_name: '',
        uploaded_date: new Date(),
        latest_download_date: null,
        downloaded_count: 0,
        fileContent: file,
      };
    });

    try {
      // Step 1: Get Signed URLs in Parallel
      const signedUrlRequests = newFiles.map((fileObj) =>
        authenticatedRequest({
          method: 'GET',
          url: `/api/v1/case/${caseID}/case-related-files/upload-url`,
          params: {
            caseOwnerID,
            caseID,
            fileName: fileObj.file_name,
            fileType: fileObj.file_type,
          },
        }),
      );

      const signedUrlsResponses = await Promise.all(signedUrlRequests);
      const signedUrls = signedUrlsResponses.map((response) => response.data);

      // Step 2: Upload Files to S3 in Parallel
      const uploadRequests = newFiles.map((fileObj, index) => {
        const signedUrl = signedUrls[index];

        if (!signedUrl) {
          throw new Error(`Signed URL is undefined for file: ${fileObj.file_name}`);
        }

        return fetch(signedUrl as string, {
          method: 'PUT',
          body: fileObj.fileContent,

          headers: {
            'Content-Type': fileObj.fileContent.type,
          },
        }).then((response) => {
          if (response.status !== 200) {
            throw new Error(
              `Failed to upload ${fileObj.file_name} to S3. Status: ${response.status}`,
            );
          }
          return response;
        });
      });

      await Promise.all(uploadRequests);

      // Step 3: Store file details in the database
      storeFiles({ caseID, caseFiles: newFiles });
      toast.success('Files uploaded successfully!');
    } catch (error) {
      toast.error('Failed to upload case related files');
    } finally {
      setIsUploading(false);
    }
  };

  return { uploadCaseRelatedFiles, isUploading };
};
