import { useState, KeyboardEvent, useMemo, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import FilledInput from '@mui/material/FilledInput';
import Stack from '@mui/material/Stack';
import useCaseFiles from '../../Files/useCaseFiles';

export default function TimelineHeader({
  documentID,
  startPage,
  setStartPage,
  endPage,
  setEndPage,
}: {
  documentID: string;
  startPage: number;
  setStartPage: (page: number) => void;
  endPage: number;
  setEndPage: (page: number) => void;
}) {
  let [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();
  const caseID = params.caseID!;

  const { data: caseFiles } = useCaseFiles(caseID);

  const numberOfPages = useMemo(() => {
    if (!caseFiles) {
      return;
    }
    return caseFiles.find((doc) => documentID.includes(doc.documentID))?.numberOfPages;
  }, [caseFiles, documentID]);

  useEffect(() => {
    if (searchParams.get('startPage')) {
      setStartPage(Number(searchParams.get('startPage')));
    }
    if (searchParams.get('endPage')) {
      setEndPage(Number(searchParams.get('endPage')));
    } else if (numberOfPages && endPage === 1) {
      setEndPage(numberOfPages);
    }
  }, [caseFiles, searchParams]);

  const onChangePage = () => {
    if (endPage < startPage) {
      return setEndPage(startPage);
    }
    if (endPage > numberOfPages) {
      return setEndPage(numberOfPages);
    }
    setSearchParams(
      new URLSearchParams({
        documentID: documentID,
        startPage: startPage.toString(),
        endPage: endPage.toString(),
      }),
    );
  };

  return (
    <PageRange
      startPage={startPage}
      endPage={endPage}
      setStartPage={setStartPage}
      setEndPage={setEndPage}
      onChangePage={onChangePage}
    />
  );
}

export function PageRange({
  startPage,
  setStartPage,
  endPage,
  setEndPage,
  onChangePage,
}: {
  startPage: number;
  setStartPage: (page: number) => void;
  endPage: number;
  setEndPage: (page: number) => void;
  onChangePage: () => void;
}) {
  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onChangePage();
    }
  };

  const onBlur = () => {
    onChangePage();
  };
  return (
    <Stack mt={1} display="flex" flexDirection="row" justifyContent="left">
      <Typography variant="subtitle">Page Range:</Typography>
      <FilledInput
        id="start_page_number"
        disableUnderline={true}
        sx={{
          width: '42px',
          backgroundColor: 'white',
          mx: 0.5,
          padding: '0 5px',
          fontWeight: 600,
          marginRight: '4px',
          borderRadius: 2,
          border: '1px solid #D0D5DD',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        value={startPage}
        inputProps={{
          style: {
            padding: '0',
            fontSize: '0.75rem',
            lineHeight: '8px',
            textAlign: 'center',
            background: 'none',
          },
          pattern: '[0-9]*',
          type: 'text',
        }}
        onChange={(e) => setStartPage(e.target.validity.valid ? Number(e.target.value) : startPage)}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
      />
      <Typography variant="subtitle" mx={0.5}>
        -
      </Typography>
      <FilledInput
        id="end_page_number"
        disableUnderline={true}
        sx={{
          width: '42px',
          mx: 0.5,
          backgroundColor: 'white',
          padding: '0 5px',
          fontWeight: 600,
          marginRight: '4px',
          borderRadius: 2,
          border: '1px solid #D0D5DD',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        value={endPage}
        inputProps={{
          style: {
            padding: '0',
            fontSize: '0.75rem',
            lineHeight: '8px',
            textAlign: 'center',
            background: 'none',
          },
          pattern: '[0-9]*',
          type: 'text',
        }}
        onChange={(e) => setEndPage(e.target.validity.valid ? Number(e.target.value) : endPage)}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
      />
    </Stack>
  );
}
