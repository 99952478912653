import { MenuItem, Select, Typography } from '@mui/material';
import FileNameWithNavigationOptions from './tableComponents/FileNameWithNavigation';
import { FileStatus } from '../../api';
import { File } from './types';
import { ProcessorFileActions } from './tableComponents/ProcessorFileActions';
import EditableFileName from './tableComponents/EditableFileName';
import FileEditingActions from './tableComponents/FileEditingActions';
import FileStatusItem from '../FileUpload/FileStatusItem';

type User = {
  email: string;
  email_verified: 'true' | 'false';
  family_name: string;
  given_name: string;
  sub: string;
  username: string;
};

export const getMDOCProcessingColumns = ({
  handleFileStatusChange,
  calcCanCopyFile,
  setSourceDocument,
  processorList,
  handleUpdateFileAssignee,
  handleRemoveAllPredictions,
  handleSkipAutomatedProcessing,
}: {
  handleFileStatusChange: (fileID: string, fileStatus: FileStatus) => void;
  calcCanCopyFile: (row: any) => boolean;
  setSourceDocument: (file: File | null) => void;
  processorList: User[];
  handleUpdateFileAssignee: (fileID: string, userID: string) => void;
  handleRemoveAllPredictions: (file: File) => void;
  handleSkipPredictions: (file: File) => void;
}) => [
  {
    field: 'sequence',
    headerName: '#',
    flex: 0.2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => (
      <Typography sx={{ fontSize: '12.8px' }}>{params.value}</Typography>
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => {
      return (
        <FileNameWithNavigationOptions
          fileName={params.row.name}
          fileStatus={params.row.fileStatus}
          fileID={params.row.id}
          handleFileStatusChange={handleFileStatusChange}
        />
      );
    },
  },
  {
    field: 'processorAssigned',
    headerName: 'Assignee',
    flex: 1.2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => {
      return (
        <Select
          displayEmpty
          value={params.row.processorAssigned ?? ''}
          size="small"
          sx={{
            width: '9rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          onChange={(event) => handleUpdateFileAssignee(params.row.id, event.target.value)}
        >
          {processorList?.map((user) => (
            <MenuItem key={user.username} value={user.username}>
              {`${user?.given_name} ${user?.family_name}`}
            </MenuItem>
          ))}
          <MenuItem key="unassigned" value="">
            Unassigned
          </MenuItem>
        </Select>
      );
    },
  },
  {
    field: 'type',
    headerName: 'File Type',
    flex: 0.75,
    sortable: false,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'uploadDate',
    headerName: 'Time to SLA',
    flex: 0.75,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'numOfPages',
    headerName: 'Pages',
    flex: 0.75,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'fileStatus',
    hide: true,
    headerName: 'Status',
    flex: 1.2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => {
      return (
        <FileStatusItem
          fileStatus={params.row.fileStatus}
          errorType={params.row.errorType ?? null}
          isFileProcessor={true}
          uploadProgress={params.row.uploadProgress}
        />
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'right',
    sortable: false,
    flex: 2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => (
      <ProcessorFileActions
        fileStatus={params.row.fileStatus}
        handleFileStatusChange={handleFileStatusChange}
        handleRemoveAllPredictions={handleRemoveAllPredictions}
        handleSkipAutomatedProcessing={handleSkipAutomatedProcessing}
        authorStatus={params.row.authorStatus}
        canCopyFile={calcCanCopyFile(params.row)}
        row={params.row}
        setSourceDocument={setSourceDocument}
      />
    ),
  },
];

export const getUserProcessingColumns = ({
  editMode,
  editedFileName,
  setEditedFileName,
  selectedFile,
  setSelectedFile,
  handleRenameFile,
  toggleEditFileName,
  setDeleteModalOpen,
}: {
  editMode: boolean;
  editedFileName: string;
  setEditedFileName: (name: string) => void;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  handleRenameFile: (file: File, newName: string) => void;
  toggleEditFileName: (row: File) => void;
  setDeleteModalOpen: (isOpen: boolean) => void;
}) => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => {
      const isEditing = editMode && selectedFile?.id === params.row.id;
      return (
        <EditableFileName
          isEditing={isEditing}
          editedFileName={editedFileName}
          setEditedFileName={setEditedFileName}
          handleRenameFile={handleRenameFile}
          fileStatus={params.row.fileStatus}
          fileName={params.row.name}
          fileID={params.row.id}
          selectedFile={selectedFile}
        />
      );
    },
  },
  {
    field: 'type',
    headerName: 'File Type',
    flex: 0.75,
    sortable: false,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'uploadDate',
    headerName: 'Upload Date',
    flex: 0.75,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'numOfPages',
    headerName: 'Pages',
    flex: 0.75,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'fileStatus',
    hide: true,
    headerName: 'Status',
    flex: 1.2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => {
      return (
        <FileStatusItem
          fileStatus={params.row.fileStatus}
          errorType={params.row.errorType ?? null}
          isFileProcessor={false}
          uploadProgress={params.row.uploadProgress}
        />
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'right',
    align: 'right',
    sortable: false,
    flex: 0.8,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => (
      <FileEditingActions
        fileStatus={params.row.fileStatus}
        file={params.row}
        setSelectedFile={setSelectedFile}
        toggleEditFileName={toggleEditFileName}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    ),
  },
];

export const getUserReadyColumns = ({
  editMode,
  editedFileName,
  setEditedFileName,
  selectedFile,
  setSelectedFile,
  handleRenameFile,
  toggleEditFileName,
  setDeleteModalOpen,
}: {
  editMode: boolean;
  editedFileName: string;
  setEditedFileName: (name: string) => void;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  handleRenameFile: (file: File, newName: string) => void;
  toggleEditFileName: (row: File) => void;
  setDeleteModalOpen: (isOpen: boolean) => void;
}) => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: any) => {
      const isEditing = editMode && selectedFile?.id === params.row.id;
      return (
        <EditableFileName
          isEditing={isEditing}
          editedFileName={editedFileName}
          setEditedFileName={setEditedFileName}
          handleRenameFile={handleRenameFile}
          fileStatus={params.row.fileStatus}
          fileName={params.row.name}
          fileID={params.row.id}
          selectedFile={selectedFile}
        />
      );
    },
  },
  {
    field: 'type',
    headerName: 'File Type',
    flex: 0.75,
    sortable: false,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'uploadDate',
    headerName: 'Upload Date',
    flex: 0.75,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'numOfPages',
    headerName: 'Pages',
    flex: 0.75,
    headerClassName: 'files-table-header-row',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'right',
    align: 'right',
    sortable: false,
    flex: 2,
    headerClassName: 'files-table-header-row',
    renderCell: (params: { row: File }) => (
      <FileEditingActions
        fileStatus={params.row.fileStatus}
        file={params.row}
        setSelectedFile={setSelectedFile}
        toggleEditFileName={toggleEditFileName}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    ),
  },
];
