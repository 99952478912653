import { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Loading from '../common/Loading';
import usePDFViewerStore from '../../containers/Case/usePDFViewerStore';
import PdfViewerPage from './components/PdfViewerPage';
import NoteImageHighlights from '../../containers/Case/NoteImageHighlights';
import PageScreenCapture from '../../containers/Timeline/Components/PageScreenCapture';
import useNotes from '../../containers/Notes/gql/useNotes';
import useDisplayStore from '../../containers/Timeline/useDisplayStore';
import DocumentGroupingControlsToolbar from '../DocumentGrouping/DocumentGroupingControlsToolbar';
import DateHighlight from './DateHighlight';
import { useFetchTimelineEntryDocumentDetails } from '../../containers/ReportEditor/api-queries/useGetTimelineEntriesForReportSection';

export default function Page({
  index,
  style,
  data: {
    fetchFile,
    pages,
    documentRotation,
    documentZoom,
    caseID,
    pageSearchResultsMap,
    currentSearchMatch,
    pageDepthByPageID,
    isGrouping,
    groupingActionOnClicks,
    isUpdateRequestInProgress,
    isFileProcessor,
    getGroupingButtonDisabledStatuses,
  },
}) {
  const [searchOverlay, setSearchOverlay] = useState([]);
  const isScreenCapturing = usePDFViewerStore((state) => state.isScreenCapturing);
  const { documentWidth, documentHeight, groupingPreviewPageID } = useDisplayStore((state) => ({
    documentWidth: state.PDFDimensions.documentWidth,
    documentHeight: state.PDFDimensions.documentHeight,
    groupingPreviewPageID: state.groupingPreviewPageID,
  }));
  const page = pages[index] || { documentID: '', pageNumber: 0 };
  const { documentID, pageNumber, document_edges } = page;
  const { isLoading, data: pageUrls } = fetchFile({
    caseID,
    documentID,
    pageNumber,
  });
  const offsetPageNumber = pageNumber % 50 ? pageNumber % 50 : 50;
  const { data: notes } = useNotes({
    case_id: caseID,
  });
  const { pageID, entryID } = useParams();
  const { data: documentDetails } = useFetchTimelineEntryDocumentDetails(caseID, entryID, false);

  const currentPageID =
    isGrouping && groupingPreviewPageID ? Number(groupingPreviewPageID) : +pageID;

  const rotation = Number(documentRotation?.[page.id] || 0);

  const GROUPING_BUTTONS_HEIGHT = isGrouping ? 45 : 0;

  const constantCalculatedContainerWidth = documentWidth * documentZoom;
  const constantCalculatedContainerHeight = documentHeight * documentZoom + GROUPING_BUTTONS_HEIGHT;

  const adjustedContainerHeightForRotationInPx =
    rotation === 90 || rotation === 270
      ? (613 * documentZoom).toString().concat('px')
      : (documentHeight * documentZoom).toString().concat('px');

  const adjustedContainerWidthForRotationInPx =
    rotation === 90 || rotation === 270
      ? (794 * documentZoom).toString().concat('px')
      : (documentWidth * documentZoom).toString().concat('px');

  const pageWidth = rotation === 90 || rotation === 270 ? 613 : documentWidth; //613px and 794px are the computed constants used by react-pdf for page size when rotated, regardless of screen size or window size. This causes no change.
  const pageHeight = rotation === 90 || rotation === 270 ? 794 : documentHeight;

  const highlightSearchResults = useMemo(() => {
    if (!pageSearchResultsMap) {
      return;
    }
    let searchTextOverlay = [];
    const pageResults = pageSearchResultsMap?.[page.id];
    if (pageResults === undefined) {
      return [];
    }
    for (const res of pageResults) {
      const { Width, Height, Left, Top } = res;
      const overlayText = {
        isActive: res.word_id === currentSearchMatch?.word_id,
        top:
          rotation === 0
            ? `${Top * 100}%`
            : rotation === 90
            ? `${Left * 100}%`
            : rotation === 180
            ? `${(1 - Top - Height) * 100}%`
            : `${(1 - Left - Width) * 100}%`,
        left:
          rotation === 0
            ? `${Left * 100}%`
            : rotation === 90
            ? `${(1 - Top - Height) * 100}%`
            : rotation === 180
            ? `${(1 - Left - Width) * 100}%`
            : `${Top * 100}%`,
        width:
          rotation === 0
            ? `${Width * 100}%`
            : rotation === 90
            ? `${Height * 100}%`
            : rotation === 180
            ? `${Width * 100}%`
            : `${Height * 100}%`,
        height:
          rotation === 0
            ? `${Height * 100}%`
            : rotation === 90
            ? `${Width * 100}%`
            : rotation === 180
            ? `${Height * 100}%`
            : `${Width * 100}%`,
      };
      searchTextOverlay = [...searchTextOverlay, overlayText];
    }

    return searchTextOverlay;
  }, [pageSearchResultsMap, rotation, currentSearchMatch]);

  useEffect(() => {
    setSearchOverlay(pageSearchResultsMap ? highlightSearchResults : []);
  }, [pageSearchResultsMap, rotation, highlightSearchResults]);

  const topEdges = document_edges?.filter((edge) => edge.type === 'start');
  const bottomEdges = document_edges?.filter((edge) => edge.type === 'end');

  const pageDepth = pageDepthByPageID != null ? pageDepthByPageID(String(page.id)) - 2 : 0;

  const topOfGroupStyles = {
    borderRadius: '8px 8px 0 0',
    marginTop: '45px',
    height: constantCalculatedContainerHeight - 44,
  };

  const bottomOfGroupStyles = {
    height: constantCalculatedContainerHeight,
    borderRadius: '0 0 8px 8px',
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          height: documentHeight * documentZoom,
        }}
        id={`pdf-page-${page.id}`}
      >
        <Box
          sx={{
            textAlign: 'center',
            width: documentWidth * documentZoom,
            height: documentHeight * documentZoom,
            marginBottom: '16px',
            backgroundColor: 'white',
            position: 'absolute',
          }}
        >
          <Loading top text="Loading Document..." customStyles={{ marginTop: '1rem' }} />
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        height: documentHeight * documentZoom + 80,
      }}
      id={`document-box-${index}`}
    >
      {/* document edges display */}

      {/* coloured grouping backgrounds for documents and attachments */}

      {isGrouping && !page.inPageRange && (
        <div
          style={{
            width: '100%',
            height: constantCalculatedContainerHeight + 1 * documentZoom,
            position: 'absolute',
            zIndex: 5,
            backgroundColor: 'rgba(192,192,192,0.5)',
          }}
        />
      )}
      {pageDepth > 1 && (
        <div
          style={{
            position: 'absolute',
            flex: 1,
            width: constantCalculatedContainerWidth + 100,
            height: constantCalculatedContainerHeight + 90,
            backgroundColor: 'green',
            zIndex: 2,
            borderRight: '1px solid white',
            borderLeft: '1px solid white',
            ...(bottomEdges.length > 0 && bottomOfGroupStyles),
            ...(topEdges.length > 0 && topOfGroupStyles),
            ...(topEdges.length > 0 &&
              bottomEdges.length > 0 && {
                borderRadius: '8px',
              }),
          }}
        />
      )}

      {pageDepth > 0 && (
        <div
          style={{
            position: 'absolute',
            flex: 1,
            width: constantCalculatedContainerWidth + 200,
            height: constantCalculatedContainerHeight + 90,
            backgroundColor: 'pink',
            borderRight: '1px solid white',
            borderLeft: '1px solid white',
            zIndex: 1,
            ...(bottomEdges.length >= pageDepth && bottomOfGroupStyles),
            ...(topEdges.length >= pageDepth && topOfGroupStyles),

            ...(topEdges.length >= pageDepth &&
              bottomEdges.length >= pageDepth && {
                borderRadius: '8px',
              }),
          }}
        />
      )}

      {/* page */}
      <Box
        sx={{
          textAlign: 'center',
          flex: 1,
          width: adjustedContainerWidthForRotationInPx,
          height: adjustedContainerHeightForRotationInPx,
          position: 'absolute',
          zIndex: 100,
        }}
      >
        {isScreenCapturing && (
          <PageScreenCapture page={page} documentZoom={documentZoom} documentRotation={rotation} />
        )}
        {isGrouping && (
          <DocumentGroupingControlsToolbar
            page={page}
            groupingActionOnClicks={groupingActionOnClicks}
            currentPageDepth={pageDepth}
            isUpdateRequestInProgress={isUpdateRequestInProgress}
            getGroupingButtonDisabledStatuses={getGroupingButtonDisabledStatuses}
            pageDepthByPageID={pageDepthByPageID}
          />
        )}
        {isGrouping && !page.inPageRange && (
          <div
            style={{
              width: adjustedContainerWidthForRotationInPx,
              height: adjustedContainerHeightForRotationInPx,
              position: 'absolute',
              zIndex: 101,
              backgroundColor: 'rgba(192,192,192,0.5)',
            }}
          />
        )}
        <Box
          id={`pdf-page-${page.id}`}
          sx={{
            position: 'relative',
            height: `${adjustedContainerHeightForRotationInPx} !important`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PdfViewerPage
            url={pageUrls?.url[pageUrls.pageNumber.indexOf(offsetPageNumber)]}
            width={pageWidth}
            height={pageHeight}
            scale={documentZoom}
            rotate={rotation}
            currentPage={currentPageID === page.id}
          />
          {!isScreenCapturing && (
            <>
              <NoteImageHighlights
                notes={notes}
                pageID={page.id}
                documentID={documentID}
                pageNumber={pageNumber}
                width={adjustedContainerWidthForRotationInPx}
                height={adjustedContainerHeightForRotationInPx}
                documentRotation={rotation}
              />

              {isFileProcessor && !isGrouping && page.extracted_dates && (
                <DateHighlight
                  entryID={page.entryID}
                  dates={page.extracted_dates.filter((date) => date?.Left)}
                  rotation={rotation}
                  selectedDate={page.pageDate}
                  setSelectedDate={() => {}}
                  startDate={documentDetails?.start_date}
                  endDate={documentDetails?.end_date}
                />
              )}
            </>
          )}
          {searchOverlay.map((overlay) => (
            <p
              key={`${overlay.height}${overlay.width}${overlay.left}${overlay.top}`}
              style={{
                backgroundColor: overlay.isActive ? '#00DFA2' : 'orange',
                margin: 0,
                padding: 0,
                opacity: '0.5',
                position: 'absolute',

                ...overlay,
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
