import useNotesStore from '../Notes/useNotesStore';
import useScreenCaptureStore from '../ScreenCapture/useScreenCaptureStore';
import useDisplayStore from '../Timeline/useDisplayStore';
import PageHighlight from '../../components/DocumentScrolling/components/PageHighlight';

function NoteImageHighlights(props) {
  const { pageID, notes, width, height, documentRotation } = props;
  const noteImages = notes
    ?.map((note) => note.images.map((image) => ({ ...image, noteID: note.noteID })))
    .flat()
    .filter((image) => image.pageID === pageID);

  const noteBeingEdited = useNotesStore((state) => state.noteBeingEdited);
  const screenCaptures = useScreenCaptureStore((state) => state.screenCaptures).filter(
    (capture) => capture.pageID === String(pageID) && capture.type === 'note',
  );
  const setNoteBeingEdited = useNotesStore((state) => state.setNoteBeingEdited);

  const activeTab = useDisplayStore((state) => state.activeTab);
  const setActiveTab = useDisplayStore((state) => state.setActiveTab);

  return (
    <>
      {noteImages?.length > 0 &&
        noteImages?.map((image, index) => (
          <PageHighlight
            key={index}
            boundingBox={image.coordinates}
            onClick={() => {
              if (activeTab !== 'Notes') {
                setActiveTab('Notes');
              }
              const noteToEdit = notes.find((note) => note.noteID === image.noteID);
              setNoteBeingEdited(noteToEdit);
            }}
            width={width}
            height={height}
            rotation={documentRotation}
            selected={noteBeingEdited?.noteID === image.noteID}
            sx={{
              borderRadius: 2,
              border: noteBeingEdited?.noteID === image.noteID ? 'inherit' : '2px solid',
              borderColor: 'secondary.main',
              backgroundColor: 'rgba(0,0,0,0.1)',
              '&:hover': {
                background: !noteBeingEdited?.noteID === image.noteID && 'rgba(0, 0, 0, 0.25)',
              },
            }}
          />
        ))}
      {screenCaptures &&
        screenCaptures.map((capture, index) => (
          <PageHighlight
            key={index}
            boundingBox={capture.coordinates}
            onClick={null}
            rotation={documentRotation}
            selected={true}
            sx={{
              borderRadius: 2,
              border: 'inherit',
              borderColor: 'secondary.main',
              backgroundColor: 'rgba(0,0,0,0.1)',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.25)',
              },
            }}
          />
        ))}
    </>
  );
}
export default NoteImageHighlights;
