import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FileStatus, getNextFileIDToProcess } from '../api';
import { useUpdateFileStatus } from '../containers/Files/useCaseFiles';

export function useProcessorFileNavigation(fileStatus: FileStatus) {
  const { caseID } = useParams();
  const navigate = useNavigate();

  const { mutateAsync: updateFileStatus } = useUpdateFileStatus();

  const navigateToGroupingTab = useCallback(
    async (nextFile: { id: string; file_status: FileStatus }) => {
      if (caseID == null) {
        toast.error('There was an issue re-directing to the next grouping file.');
        return;
      }
      if (nextFile.file_status === FileStatus.PENDING) {
        await updateFileStatus({
          caseID,
          fileID: nextFile.id,
          status: FileStatus.GROUPING,
        });
      }
      navigate(`/case/${caseID}/files/${nextFile.id}/grouping`);
    },
    [caseID],
  );

  const navigateToFilesTab = useCallback(async () => {
    if (caseID == null) {
      toast.error('There was an issue re-directing to the files tab.');
      return;
    }
    navigate(`/case/${caseID}/files`);
  }, [caseID]);

  const navigateToReviewTab = useCallback(
    async (fileID: string) => {
      if (caseID == null || fileID == null || fileStatus == null) {
        toast.error('There was an issue re-directing to the next tagging file.');
        return;
      }
      navigate(`/case/${caseID}/timeline?documentID=${fileID}`);
    },
    [caseID, fileStatus],
  );

  const navigateToNextFile = useCallback(async () => {
    if (caseID == null) {
      toast.error('There was an issue re-directing to the next file. ');
      return;
    }
    const nextFile: NextFile | '' = (await getNextFileIDToProcess(caseID, fileStatus))?.data;
    if (nextFile === '') {
      await navigateToFilesTab();
      return;
    }

    if (fileStatus === 'GROUPING') {
      await navigateToGroupingTab(nextFile);
      return nextFile.number_of_pages;
    }

    navigateToReviewTab(nextFile.id);
  }, [navigateToFilesTab, navigateToGroupingTab, navigateToReviewTab, fileStatus]);

  return {
    navigateToNextFile,
  };
}

type NextFile = {
  id: string;
  file_status: FileStatus;
  number_of_pages: number;
};
