import {
  format,
  formatDistanceToNow,
  getYear,
  differenceInMilliseconds,
  parse,
  parseISO,
  isValid,
} from 'date-fns';

/**
 * Formats the date in the DD/MMM/YYYY format
 *
 * @param {String} d
 */
export function formatFormalDate(d) {
  const date = parseISO(d);

  if (isValid(date)) {
    return format(date, 'dd/MMM/yyyy');
  }
  if (isValid(d)) {
    return format(d, 'dd/MMM/yyyy');
  }
  return '-';
}
/**
 * Formats the date in the MMMM DD, YYYY format
 *
 * @param {String} d
 */
export function formatDisplayDate(d) {
  if (d == null) {
    return null;
  }
  const date = new Date(parseISO(d));
  if (isValid(date)) {
    return format(date, 'MMM dd, yyyy');
  }
  if (isValid(d)) {
    return format(d, 'MMM dd, yyyy');
  }
  return 'Invalid date';
}

export function formatPageStringDate(d) {
  const date = parse(d, 'MM/dd/yyyy', new Date());
  return isValid(date) && d !== '1900-01-01' ? format(date, 'MMMM dd, yyyy') : 'No Date';
}

export function formatSegmentDate(dateIsoString) {
  const date = parseISO(dateIsoString);
  return isValid(date) && dateIsoString !== '1900-01-01' ? format(date, 'MMM dd, yyyy') : 'No Date';
}

export function getYearfromDate(d) {
  const date = parseISO(d);
  return isValid(date) ? getYear(parseISO(d)) : 0;
}
/**
 * Returns the estimated elapsed time from date d to now
 * @param {String} d
 */
export function getTimeToNow(d) {
  const t = new Date(parseISO(d));

  const dtDateOnly = new Date(t.valueOf() - t.getTimezoneOffset() * 60 * 1000);
  return formatDistanceToNow(dtDateOnly, { addSuffix: true });
}

export function getLastEditToNow(d) {
  const date = new Date(parseISO(d));
  return formatDistanceToNow(date, { addSuffix: true });
}

export function getDifferenceInMilliSeconds(d1, d2) {
  return differenceInMilliseconds(new Date(d1), new Date(d2));
}

//https://stackoverflow.com/questions/3075577/convert-mysql-datetime-stamp-into-javascripts-date-format
export function formatTimestampToDate(date) {
  const t = date.split(/[- :]/);
  const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3] ?? 0, t[4] ?? 0, t[5] ?? 0));
  return d;
}

export function formatDateToDateNoTimezone(date) {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * -60000);
}

export function isValidDate(dateStr) {
  if (dateStr === null) {
    return true;
  }
  const date = new Date(dateStr);
  const startDate = new Date('1899-12-31');
  const endDate = new Date('2030-01-01');

  // Check if the date is valid and within the specified range
  return !isNaN(date.getTime()) && date >= startDate && date <= endDate;
}

export function convertDateStringToUTCDate(dateString) {
  const dateParts = dateString.split('-').map((part) => parseInt(part, 10));
  const year = dateParts[0];
  const month = dateParts[1] - 1; // Adjust month because JavaScript's Date month is 0-based.
  const day = dateParts[2];

  // Create a local date object for noon of the given date
  // This helps ensure that the date is the same regardless of the time zone when displayed
  const localDateMidday = new Date(year, month, day, 12, 0, 0);

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return localDateMidday.toLocaleDateString('en-US', options);
}

export function formatUTCDateToDateString(date) {
  if (!(date instanceof Date)) {
    throw new Error('Input must be a Date object');
  }

  const year = date.getUTCFullYear();
  // getUTCMonth() returns a zero-based month index (0 for January, 1 for February, etc.),
  // so we need to add 1 to get the correct month number and ensure it's in 'MM' format.
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  // getUTCDate() returns the day of the month, ensure it's in 'DD' format.
  const day = date.getUTCDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}
