import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getCaseFiles, FileRawObject, updateFileStatus } from '../../api';
import { FileObject } from './dto/file.object';

async function fetchFiles(caseID: string) {
  const files = (await getCaseFiles(caseID)).data as FileRawObject[];
  return normalizeFiles(files);
}

export default function useCaseFiles(caseID: string) {
  return useQuery(['files', caseID], () => fetchFiles(caseID), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    enabled: !!caseID,
  });
}

export function useUpdateFileStatus() {
  const queryClient = useQueryClient();
  return useMutation(updateFileStatus, {
    onMutate: async ({ caseID, fileID, status }) => {
      await queryClient.cancelQueries(['files', caseID]);
      queryClient.setQueryData(
        ['files', caseID],
        (old: FileObject[] | undefined): FileObject[] | undefined => {
          return old?.map((file) => {
            if (file.documentID === fileID) {
              return {
                ...file,
                fileStatus: status,
              };
            }
            return file;
          });
        },
      );
    },
    onSuccess: (_, variables) => {
      console.log(variables);
      if (variables.status !== 'GROUPING') {
        toast.success('File status updated successfully.');
      }
      queryClient.invalidateQueries(['filesStatus']);
      queryClient.invalidateQueries(['files', variables.caseID]);
    },
    onError: (error: any, variables) => {
      queryClient.invalidateQueries(['files', variables.caseID]);
      if (error?.response?.data?.message === 'missing pages') {
        toast.error(
          'File cannot be marked as complete as pages were found missing. Please contact engineering for help with the case.',
        );
      } else {
        toast.error('Error updating file status');
      }
    },
  });
}

const normalizeFiles = (files: FileRawObject[]) => {
  return files.map((file: FileRawObject) => {
    return {
      documentID: file.id,
      docFileName: file.document_file_name,
      caseID: file.case_id,
      isReady: file.is_ready,
      uploadDate: file.upload_date,
      numberOfPages: file.number_of_pages,
      fileStatus: file.file_status,
      authorStatus: file.author_status,
      duplicateStatus: file.duplicate_status,
      file_type: file.file_type,
      source_file_path: file.source_file_path,
      bucket_name: file.bucket_name,
      processor_assigned: file.processor_assigned,
    };
  });
};
