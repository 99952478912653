import { useEffect, useState } from 'react';

export function useSyncDocumentsScroll(areScrollersVisible = false) {
  const [areDocumentScrollsSynced, setAreDocumentScrollsSynced] = useState(false);
  const [firstDocumentPageCount, setFirstDocumentPageCount] = useState(0);
  const [secondDocumentPageCount, setSecondDocumentPageCount] = useState(0);
  const [documentIDs, setDocumentIDs] = useState<{
    firstDocumentID?: number;
    secondDocumentID?: number;
  }>({});

  useEffect(() => {
    if (firstDocumentPageCount === secondDocumentPageCount) {
      setAreDocumentScrollsSynced(true);
    } else {
      setAreDocumentScrollsSynced(false);
    }
  }, [firstDocumentPageCount, secondDocumentPageCount]);

  useEffect(() => {
    if (!areScrollersVisible) {
      return;
    }

    const documentScrollers = Array.from(document.getElementsByClassName('scrollable-viewer'));
    let isSyncing = false;

    const syncScrollPositions = (e) => {
      if (isSyncing) {
        return;
      }

      isSyncing = true;
      const verticalMovement = e.target.scrollTop - e.target.dataset.previousScrollTop;
      const horizontalMovement = e.target.scrollLeft - e.target.dataset.previousScrollLeft;

      documentScrollers.forEach((allScrollers) => {
        if (allScrollers !== e.target) {
          allScrollers.removeEventListener('scroll', syncScrollPositions); // Disable sync temporarily
          allScrollers.scrollTop += verticalMovement;
          allScrollers.scrollLeft += horizontalMovement;
          allScrollers.dataset.previousScrollTop = allScrollers.scrollTop;
          allScrollers.dataset.previousScrollLeft = allScrollers.scrollLeft;
          allScrollers.addEventListener('scroll', syncScrollPositions); // Re-enable sync
        }
      });

      e.target.dataset.previousScrollTop = e.target.scrollTop;
      e.target.dataset.previousScrollLeft = e.target.scrollLeft;
      isSyncing = false;
    };
    if (areDocumentScrollsSynced) {
      documentScrollers.forEach((scroller) => {
        scroller.dataset.previousScrollTop = scroller.scrollTop;
        scroller.dataset.previousScrollLeft = scroller.scrollLeft;
        scroller.addEventListener('scroll', syncScrollPositions);
      });
    }

    return () => {
      documentScrollers.forEach((scroller) => {
        scroller.removeEventListener('scroll', syncScrollPositions);
      });
    };
  }, [areDocumentScrollsSynced, areScrollersVisible, documentIDs]);

  return {
    areDocumentScrollsSynced,
    setAreDocumentScrollsSynced,
    setFirstDocumentPageCount,
    setSecondDocumentPageCount,
    setDocumentIDs,
  };
}
