/* eslint no-underscore-dangle: 0 */
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ChangeEvent, useCallback, useState } from 'react';
// @ts-ignore-next-line
import debounce from 'lodash.debounce';
import APIURL from './apiUrl';
import {
  BoundingBox,
  ElasticsearchHit,
  PageListValue,
  SearchMatch,
  searchResults,
} from './types/search';

type SearchQueryParams = {
  caseID: string;
  searchTerm: string;
  onSuccess?: (data: unknown, searchTerm: string) => void | null;
};

export function useSearchQuery({ caseID, searchTerm, onSuccess }: SearchQueryParams) {
  return useQuery(
    ['searchResults', caseID, searchTerm],
    async () => findSearchOccurances(searchTerm, caseID),
    {
      enabled: Boolean(caseID),
      onSuccess: (data) => {
        if (typeof onSuccess === 'function') {
          onSuccess(data, searchTerm);
        }
      },
      staleTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
}

/**
 * 
 * @param {String} keyword The text sting being searched
 * @param {String} caseID The caseID being queried
 * @returns {Promise} Returns object with search results in the following format:
 * 
 *{ documentID: { pageNumber: # numOfMatchesInFile: # }, ...other documents, totalOccurancesFound: # }
        
 * 
 */
export function findSearchOccurances(keyword: string, caseID: string): Promise<searchResults> {
  return new Promise((resolve, reject) => {
    if (!keyword || keyword.length < 3) {
      return resolve({ files: {}, totalOccurancesFound: 0 });
    }

    const terms: any[] = [];
    keyword.split(' ').forEach((term) => {
      if (term.length > 2) {
        terms.push({
          wildcard: {
            text: {
              value: `*${term}*`,
              case_insensitive: true,
            },
          },
        });
      }
    });
    axios
      .post(`${APIURL}searchDocuments`, {
        size: 5000,
        query: {
          bool: {
            minimum_should_match: 1,
            should: terms,
            filter: {
              match: {
                caseID: {
                  query: caseID,
                  operator: 'and',
                },
              },
            },
          },
        },
      })
      .then((response) => {
        const hits = groupResults(response.data.hits.hits, keyword);
        const searchResults: searchResults = {
          files: {},
          totalOccurancesFound: 0,
        };

        hits.forEach((res) => {
          if (!searchResults.files[res._source.documentID]) {
            searchResults.files[res._source.documentID] = {
              pages: {},
              numOfMatchesInFile: 0,
            };
          }

          const pageNumber = (res._source.pageNumber + 1).toString();
          if (!searchResults.files[res._source.documentID].pages[pageNumber]) {
            searchResults.files[res._source.documentID].pages[pageNumber] = [];
          }

          searchResults.files[res._source.documentID].pages[pageNumber].push(res._source);
        });

        // Calculate totals
        Object.values(searchResults.files).forEach((document) => {
          let count = 0;
          Object.values(document.pages).forEach((matches) => {
            count += matches.length;
          });
          document.numOfMatchesInFile = count;
          searchResults.totalOccurancesFound += count;
        });

        return resolve(searchResults);
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.message === 'Network Error') {
          return reject(
            Error('Network Error. Please check your internet connection and try again.'),
          );
        }

        return reject(error.message);
      });
  });
}

export function createPageSearchResultsMap(searchMatchesList: SearchMatch[]) {
  const results: Record<string, SearchMatch[]> = {};

  searchMatchesList.forEach((match) => {
    const pageID = match.pageID;

    if (!results[pageID]) {
      results[pageID] = [];
    }
    results[pageID].push(match);
  });

  return results;
}

function groupResults(hits: ElasticsearchHit[], keyword: string) {
  const numberOfWords = keyword.trim().split(' ').length;
  if (numberOfWords === 1) {
    return hits;
  }
  hits = hits.sort(positionSortWithPageNumberAndDocumentID);
  const hitsGrouped = [];
  for (let i = 0; i < hits.length; i += 1) {
    const subArray = hits.slice(i, i + numberOfWords);
    if (!subArrayValid(subArray)) {
      continue;
    }
    if (subArrayValidMatch(subArray, keyword)) {
      hitsGrouped.push(combineResults(subArray));
    }
  }
  return hitsGrouped;
}

function combineResults(arrayOfHits: ElasticsearchHit[]) {
  return {
    _index: arrayOfHits[0]._index,
    _source: {
      ...arrayOfHits[0]._source,
      text: arrayOfHits.map((hit) => hit._source.text).join(' '),
      Width:
        arrayOfHits.map((hit) => hit._source.Width).reduce((a, b) => a + b) +
        0.001 * arrayOfHits.length,
    },
  };
}

export function flattenSearchResults(searchResults: searchResults): SearchMatch[] {
  return Object.values(searchResults.files).reduce<SearchMatch[]>((acc, file) => {
    Object.values(file.pages).forEach((page) => {
      acc.push(...page);
    });
    return acc;
  }, []);
}

export function sortAndEnrichSearchResults(
  searchMatches: SearchMatch[],
  orderedPageList: PageListValue[],
) {
  // Create a map for quick lookup of page details
  const pageDetailsMap = new Map(
    orderedPageList.map((page) => [`${page.documentID}_${page.pageNumber}`, page]),
  );

  // Filter out matches without corresponding pages and enrich the remaining ones
  const enrichedMatches = searchMatches
    .filter((match) => {
      const key = `${match.documentID}_${match.pageNumber + 1}`;
      return pageDetailsMap.has(key);
    })
    .map((match) => {
      const key = `${match.documentID}_${match.pageNumber + 1}`;
      const pageDetails = pageDetailsMap.get(key)!;
      return {
        ...match,
        pageID: pageDetails.id,
        entryID: pageDetails.entryID,
      };
    });

  // Sort the filtered and enriched matches
  return enrichedMatches.sort((a, b) => {
    const aKey = `${a.documentID}_${a.pageNumber + 1}`;
    const bKey = `${b.documentID}_${b.pageNumber + 1}`;
    const aIndex = orderedPageList.findIndex((p) => p.id === pageDetailsMap.get(aKey)?.id);
    const bIndex = orderedPageList.findIndex((p) => p.id === pageDetailsMap.get(bKey)?.id);
    // First sort by the page order
    const pageOrderResult = aIndex - bIndex;
    if (pageOrderResult !== 0) {
      return pageOrderResult;
    }

    // If on the same page, sort by word_position
    return a.word_position - b.word_position;
  });
}

const positionSortWithPageNumberAndDocumentID = (f1: ElasticsearchHit, f2: ElasticsearchHit) =>
  f1._source.documentID.localeCompare(f2._source.documentID) ||
  f1._source.pageNumber - f2._source.pageNumber ||
  f1._source.word_position - f2._source.word_position;

const subArrayValid = (subArray: ElasticsearchHit[]) =>
  subArray.filter(
    (hit) =>
      hit._source.pageNumber === subArray[0]._source.pageNumber &&
      hit._source.documentID === subArray[0]._source.documentID,
  ).length === subArray.length &&
  subArray[subArray.length - 1]._source.word_position - subArray[0]._source.word_position ===
    subArray.length - 1;

const subArrayValidMatch = (subArray: ElasticsearchHit[], keyword: string) => {
  const wordArr = strippedString(subArray.map((hit) => hit._source.text).join(' ')).split(' ');

  const keywordArr = strippedString(keyword).split(' ');
  for (let i = 0; i < wordArr.length; i++) {
    if (!wordArr[i].includes(keywordArr[i])) {
      return false;
    }
  }
  return true;
};

function strippedString(str: string) {
  return str.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
}

export async function getTextFromNoteScreenshot(
  caseID: string,
  documentID: string,
  pageNumber: string | number,
  boundingBox: BoundingBox,
) {
  const { Left, Top, Width, Height } = boundingBox;
  return axios
    .get(
      `${APIURL}note/textDetection/${caseID}/${documentID}/${pageNumber}?sx=${Left}&sy=${Top}&ex=${Width}&ey=${Height}`,
    )
    .then((text) => text.data);
}

export const useDebounceInput = ({
  initialValue,
  onChange,
  delay,
}: {
  initialValue: string;
  onChange: (value: string) => void;
  delay: number;
}) => {
  const [internalValue, setInternalValue] = useState(initialValue);

  const propagateValue = useCallback(
    debounce((value: string) => {
      onChange(value);
    }, delay),
    [onChange, delay],
  );
  const handleChange = useCallback(
    (inputEvent: ChangeEvent<HTMLInputElement>) => {
      const { value } = inputEvent.target;

      setInternalValue(value);
      propagateValue(value);
    },
    [propagateValue],
  );

  const reset = useCallback((resetValue = '') => {
    setInternalValue(resetValue);
    propagateValue(resetValue);
  }, []);

  return {
    value: internalValue,
    onChange: handleChange,
    reset,
  };
};
