import { useApolloClient } from '@apollo/client';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { authenticatedRequest } from '../../../api';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import usePDFViewerStore from '../../Case/usePDFViewerStore';
import { GetTimelineEntriesDocument } from '../../../__generated__/graphql';

function updateDocumentRotation(
  caseID: string,
  documentId: bigint,
  rotation: 'clockwise' | 'counterclockwise',
): Promise<AxiosResponse> {
  const res = authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateDocumentRotation/${caseID}/${documentId}`,
    data: { rotation: rotation },
  });

  return res;
}

export function useUpdateDocumentRotation(
  caseID: string,
): (
  caseID: string,
  documentId: bigint,
  pageIDs: number[],
  rotation: 'clockwise' | 'counterclockwise',
) => Promise<void> {
  const documentRotation = usePDFViewerStore((state) => state.documentRotation);
  const setDocumentRotation = usePDFViewerStore((state) => state.setDocumentRotation);
  const isFileProcessor = useIsFileProcessor();
  const apolloClient = useApolloClient();

  return async (caseID, documentId, pageIDs, rotation) => {
    try {
      const newRotations = { ...documentRotation };

      pageIDs.forEach((pageID) => {
        const currentRotation = newRotations[pageID] || 0;
        if (rotation === 'clockwise') {
          newRotations[pageID] = (currentRotation + 90) % 360;
        } else {
          newRotations[pageID] = (currentRotation - 90 + 360) % 360;
        }
      });

      setDocumentRotation(newRotations);

      await updateDocumentRotation(caseID, documentId, rotation);

      apolloClient.refetchQueries({
        include: [GetTimelineEntriesDocument],
      });

      if (!isFileProcessor) {
        toast.success('Document rotation saved');
      }
    } catch (error) {
      toast.error('Failed to save document rotation');
    }
  };
}
