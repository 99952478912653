import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  CaseRelatedFileStoreRawObject,
  storeCaseRelatedFile,
  CaseRelatedFileDelete,
  deleteCaseRelatedFile,
  CaseRelatedFileNameUpdate,
  updateCaseRelatedFileName,
} from '../../../../api';

async function storeFile({ caseID, caseFiles }: CaseRelatedFileStoreRawObject) {
  const filesArray = Array.isArray(caseFiles) ? caseFiles : [caseFiles];
  const response = await storeCaseRelatedFile({ caseID, caseFiles: filesArray });
  return response.data;
}

export function useStoreCaseRelatedFile() {
  const queryClient = useQueryClient();

  return useMutation(storeFile, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['case-files', variables.caseID]);
    },
    onError: (error) => {
      toast.error('File upload failed');
      console.error('File upload failed:', error);
    },
  });
}

export function useDeleteCaseRelatedFile() {
  const queryClient = useQueryClient();

  return useMutation(deleteFile, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['case-files', variables.caseID]);
    },
    onError: (error) => {
      toast.error('File deletion failed');
      console.error('File deletion failed:', error);
    },
  });
}

async function deleteFile({ caseID, fileId, filePath }: CaseRelatedFileDelete): Promise<Boolean> {
  const response = await deleteCaseRelatedFile({ caseID, fileId, filePath: filePath });
  if (response.status === 200) {
    return true;
  }
  return false;
}

export async function editFileName({
  caseID,
  fileId,
  fileName,
  oldFilePath,
  newFilePath,
}: CaseRelatedFileNameUpdate): Promise<boolean> {
  try {
    const response = await updateCaseRelatedFileName({
      caseID,
      fileId,
      fileName,
      oldFilePath: oldFilePath,
      newFilePath: newFilePath,
    });

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    toast.error('Failed to update file name');
  }
  return false;
}
