import { useQuery } from '@tanstack/react-query';
import { getCaseRelatedFiles, CaseRelatedFileRawObject } from '../../../../api';
import { normalizeCaseRelatedFiles } from './useFileNormalize';

async function fetchFiles(caseID: string) {
  const files = (await getCaseRelatedFiles(caseID)).data as CaseRelatedFileRawObject[];
  return normalizeCaseRelatedFiles(files);
}

export function useCaseRelatedFiles(caseID: string) {
  return useQuery(['case-files', caseID], () => fetchFiles(caseID), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    enabled: !!caseID,
  });
}
