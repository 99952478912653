import { useState } from 'react';
import { authenticatedRequest } from '../../../../api';

export const useDownloadCaseRelatedFile = (caseID: string) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadCaseRelatedFile = async (filePath: string) => {
    try {
      setIsDownloading(true);

      const response = await authenticatedRequest<string>({
        method: 'GET',
        url: `/api/v1/case/${caseID}/case-related-files/download-url`,
        params: { filePath },
      });

      const signedUrl = response.data;
      if (!signedUrl) {
        throw new Error('Failed to get signed URL');
      }

      const fileResponse = await fetch(signedUrl);
      if (!fileResponse.ok) {
        throw new Error(`Failed to download file. Status: ${fileResponse.status}`);
      }

      const blob = await fileResponse.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filePath.split('/').pop() || 'downloaded_file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return { downloadCaseRelatedFile, isDownloading };
};
