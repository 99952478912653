import React from 'react';
import { Stack } from '@mui/system';
import { Tooltip } from '@mui/material';
import Pencil from '../../../components/icons/Pencil';
import Theme from '../../../theme';
import Delete from '../../../components/icons/Delete';
import { File } from '../types';

type Props = {
  fileStatus: string;
  toggleEditFileName: (file: File) => void;
  file: File;
  setSelectedFile: (file: File) => void;
  setDeleteModalOpen: (isOpen: boolean) => void;
};

export default function FileEditingActions({
  fileStatus,
  toggleEditFileName,
  file,
  setSelectedFile,
  setDeleteModalOpen,
}: Props) {
  const canFileBeDeleted = fileStatus === 'ERROR' || fileStatus === 'COMPLETE';

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip
        title={fileStatus === 'COMPLETE' ? 'Rename File' : 'File renaming will be available soon'}
      >
        <span>
          <Pencil
            id={`files-tab-rename-file-btn-row-${file.id}`}
            cursor="pointer"
            width={19}
            height={19}
            className={fileStatus !== 'COMPLETE' ? 'sm-disabled' : ''}
            color={Theme.palette.selectedGrey.contrastText}
            onClick={() => toggleEditFileName(file)}
            sx={{ opacity: 0, transition: 'opacity 0.2s ease-in-out' }}
          />
        </span>
      </Tooltip>

      <Tooltip title={canFileBeDeleted ? 'Delete File' : 'File deletion will be available soon'}>
        <span>
          <Delete
            id={`files-tab-delete-file-btn-row-${file.id}`}
            cursor="pointer"
            width={19}
            height={19}
            color={fileStatus !== 'ERROR' ? Theme.palette.selectedGrey.contrastText : null}
            className={!canFileBeDeleted ? 'sm-disabled' : ''}
            onClick={() => {
              if (canFileBeDeleted) {
                setSelectedFile(file);
                setDeleteModalOpen(true);
              }
            }}
          />
        </span>
      </Tooltip>
    </Stack>
  );
}
