import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FetchUserAttributesOutput, FetchMFAPreferenceOutput } from '@aws-amplify/auth';
import { apiBaseUrl } from './config/apiConfig';
import APIURL from './library/utilities/apiUrl';
import { queueLimit } from './utils/asyncUtils';
import { TimelineDetailsProps } from './containers/ReportEditor/IndexReports/DocumetPreviewer/DocumentPreviewer';

export type CognitoUser = FetchUserAttributesOutput & {
  groups: string[];
  userId: string;
  username: string; //multiple keys used around the app to get user ID
  mfa: FetchMFAPreferenceOutput;
};

type httpRequest<T> = AxiosRequestConfig<T> & {
  method: NonNullable<AxiosRequestConfig<T>['method']>;
  url: NonNullable<AxiosRequestConfig<T>['url']>;
  keepalive?: boolean;
};

const baseLocalstorageKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}`;

export const authenticatedRequest = <T, R = T>(config: httpRequest<T>) => {
  const userId = localStorage.getItem(`${baseLocalstorageKey}.LastAuthUser`);
  const accessToken = localStorage.getItem(`${baseLocalstorageKey}.${userId}.idToken`);
  const axiosConfig: AxiosRequestConfig<T> = {
    baseURL: apiBaseUrl,
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...config?.headers,
    },
  };

  if (config.keepalive) {
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Connection: 'keep-alive',
    };
  }

  return axios.request<T, AxiosResponse<R>>(axiosConfig);
};

type PreviewProps = {
  caseId: string;
  documentIds: string[];
  hideBlanks: boolean;
};

export type PagePreview = {
  id: string;
  url: string;
  pageDetails: {
    id: string;
    file_id: string;
    file_name: string;
    page_number: number;
    document_id: string;
    rotation_angle: number;
    is_hidden: boolean;
  };
};

type checkFileStatusProps = {
  caseId: string;
  fileId: string;
};

export const getBulkDocumentPreview = ({ caseId, documentIds, hideBlanks }: PreviewProps) =>
  authenticatedRequest<PagePreview[]>({
    method: 'GET',
    url: `/api/v1/case/${caseId}/document/preview`,
    params: { hideBlanks, documentIds: documentIds.toString() },
  });

enum caseStatusEnum {
  NEW = 'NEW',
  READY = 'READY',
  CLOSED = 'CLOSED',
  PROCESSING = 'PROCESSING',
  REQUIRES_PROCESSOR = 'REQUIRES_PROCESSOR',
  ERROR = 'ERROR',
  TAG_IDENTIFICATION = 'TAG_IDENTIFICATION',
}

type CaseUserLocationObject = {
  case_id: string;
  user_id: string;
  page_id: number;
  view: 'document' | 'timeline';
  document_id: string;
  timeline_entry_id: string;
};

export type CaseSubject = {
  id: bigint;
  name: string;
  date_of_birth: string;
  email: string;
  phone_number: string;
  address: string;
};

export type Case = {
  id: string;
  userID: string;
  processor_assigned: string | null;
  similarity_threshold: number;
  case_status: caseStatusEnum;
  case_name: string;
  claimReason: string | null;
  fullName: string | null;
  isReady: number;
  createDate: Date;
  lastOpened: Date;
  dueDate: Date | null;
  marked_ready_date: Date | null;
  dateOfBirth: Date | null;
  dateOfInjury: Date | null;
  number_of_pages?: string;
  number_of_pages_viewed?: string;
  ref_id: bigint;
  case_user_location?: CaseUserLocationObject;
  archived_at: Date | null;
  archived_by: string | null;
  version: number;
  duplicates_regenerate: boolean;
  case_type_id: bigint | null;
  case_type_name: string | null;
  subjects: CaseSubject[];
  referral_received: Date | null;
};

export const getCase = (caseId: string) =>
  authenticatedRequest<Case>({
    method: 'GET',
    url: `/api/v1/case/${caseId}`,
  });

export enum NewOrReady {
  NEW = 'NEW',
  READY = 'READY',
}

export enum FileStatus {
  UPLOADING = 'UPLOADING',
  PENDING = 'PENDING',
  GROUPING = 'GROUPING',
  TAGGING = 'TAGGING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  CLOSED = 'CLOSED',
  QA_REQUIRED = 'QA_REQUIRED',
  APPROVED = 'APPROVED',
  COPYING = 'COPYING',
  SKIP_MDOC = 'SKIP_MDOC',
  AUTOMATED_PROCESSING = 'AUTOMATED_PROCESSING',
}

// File = document (trying to switch to use file instead!)
export type FileRawObject = {
  id: string;
  case_id: string;
  document_file_name: string;
  document_file_path: string;
  is_ready: number;
  upload_date: Date;
  classified_chunks: number;
  number_of_chunks: number;
  retry: number;
  number_of_pages: number;
  file_status: FileStatus;
  author_chunks: number;
  author_status: NewOrReady;
  duplicate_status: NewOrReady;
  completed_duplicate_chunks: number;
  num_duplicate_chunks: number;
  file_type: string;
  source_file_path: string;
  bucket_name: string;
  processor_assigned?: string;
};

export const getCaseFiles = async (caseId: string) =>
  await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/${caseId}/files`,
  });

export type CaseRelatedFileRawObject = {
  id: string;
  case_id: string;
  file_name: string;
  file_path: string;
  file_type: string;
  uploaded_by: string;
  uploaded_by_name: string;
  uploaded_date: Date;
  latest_download_date: Date | null;
  downloaded_count: number | null;
};

export const getCaseRelatedFiles = async (caseID: string) =>
  // eslint-disable-next-line no-return-await
  await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/${caseID}/case-related-files`,
  });

// 2. Store Case Related Files
export type CaseRelatedFileStoreRawObject = {
  caseID: string;
  caseFiles: CaseRelatedFileRawObject | CaseRelatedFileRawObject[];
};
export const storeCaseRelatedFile = async ({ caseID, caseFiles }: CaseRelatedFileStoreRawObject) =>
  // eslint-disable-next-line no-return-await
  await authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseID}/case-related-files`,
    data: caseFiles,
  });

// 3. Update Case Related File Name

export type CaseRelatedFileNameUpdate = {
  caseID: string;
  fileId: string;
  fileName: string;
  oldFilePath: string;
  newFilePath: string;
};

export const updateCaseRelatedFileName = async ({
  caseID,
  fileId,
  fileName,
  oldFilePath,
  newFilePath,
}: CaseRelatedFileNameUpdate) =>
  // eslint-disable-next-line no-return-await
  await authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/case/${caseID}/case-related-files/${fileId}`,
    data: { fileName },
    params: { oldFilePath: oldFilePath, newFilePath: newFilePath },
  });

// 4. Delete Case Related File

export type CaseRelatedFileDelete = { caseID: string; fileId: string; filePath: string };
export const deleteCaseRelatedFile = async ({ caseID, fileId, filePath }: CaseRelatedFileDelete) =>
  // eslint-disable-next-line no-return-await
  await authenticatedRequest({
    method: 'DELETE',
    url: `/api/v1/case/${caseID}/case-related-files/${fileId}`,
    params: { filePath: filePath },
  });

type FileStatusUpdate = {
  caseID: string;
  fileID: string;
  status: FileStatus;
};

export const updateFileStatus = async ({ caseID, fileID, status }: FileStatusUpdate) =>
  await authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/case/${caseID}/file/${fileID}/status`,
    data: { status },
  });

export const getCasesForAdmin = async () =>
  await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/admin/cases`,
  });

export const reprocessCase = (caseId: string) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseId}/reprocess`,
  });

export const updateSupportAccess = (caseId: string, action: 'ADD' | 'REMOVE') =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseId}/updateSupportAccess/${action}`,
  });

export const getImagePages = (caseID: string) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/${caseID}/images`,
  });

export type EntryTagsObject = {
  documentTypeId: number | null;
  specialityId: number | null;
  suggestedDocumentTypeIds: number[];
  suggestedSpecialityIds: number[];
};
export const getEntryTags = (entryId: number) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/timeline-entry/${entryId}/tags`,
  });

export type DocumentTag = {
  tag_id: bigint;
  name: string;
  category: 'Content' | 'Source' | 'Specialist';
  category_id?: number;
  deprecated?: boolean;
};

export type DocumentTagsObject = {
  documentTypes: DocumentTag[];
  specialties: DocumentTag[];
  source: DocumentTag;
};

export const getAllDocumentTags = () =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/timeline-entry/allDocumentTags`,
  });

export const getDocumentTags = (documentID: number) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/timeline-entry/${documentID}/documentTags`,
  });

export type GetAnnotationsInput = {
  documentId: number;
  type: 'date' | 'monetary-amount' | 'organization-name' | 'person-name' | 'body-part';
  caseID: string;
};

export const getAnnotations = (params: GetAnnotationsInput) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/annotations/${params.caseID}/${params.documentId}/${params.type}`,
  });

export type CreateAnnotationsInput = {
  caseID: string;
  documentId: number;
  annotations: Annotation[];
};

export type Annotation = {
  type: string;
  field: string;
  value: string;
  kind: 'selection' | 'extraction' | 'annotation';
  extractionId?: number;
  pageId?: number;
};

export type AnnotationForDismiss = {
  selectionId?: number;
  kind: 'selection' | 'extraction' | 'annotation';
  extractionId?: number;
};

export const createAnnotations = (body: CreateAnnotationsInput) =>
  authenticatedRequest({
    method: 'POST',
    url: '/api/v1/annotations',
    data: body,
  });

export type DismissAnnotationsInput = {
  caseID: string;
  documentId: number;
  annotations: AnnotationForDismiss[];
};

export const dismissAnnotations = (body: DismissAnnotationsInput) =>
  authenticatedRequest({
    method: 'POST',
    url: '/api/v1/annotations/dismiss',
    data: body,
  });

export const updateDocumentTags = ({
  entryID,
  tags,
  tagType,
}: UpdateDocumentTagsInput): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/${entryID}/updateDocumentTag`,
    data: {
      entryID,
      tags: tags.map((tag) => tag.tag_id),
      tagType,
    },
  });

export type UpdateDocumentTagsInput = {
  entryID: number;
  tags: DocumentTag[];
  tagType: string | string[];
};

export const getBodyPartTagsForDocument = (documentID: bigint) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/timeline-entry/${documentID}/documentBodyPartTags`,
  });

export const getBodyPartTagsForCase = (caseID: string) =>
  authenticatedRequest({ method: 'GET', url: `/api/v1/timeline-entry/${caseID}/caseBodyPartTags` });

export const updateBodyPartTags = ({
  caseID,
  documentID,
  tags,
}: {
  caseID: string;
  documentID: bigint;
  tags: {
    extraction_id: bigint;
    value: string;
  }[];
}): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/${documentID}/updateBodyPartTags`,
    data: {
      caseID: caseID,
      tags: tags.map((tag) => tag.value),
    },
  });

export const removeBodyPartTag = (
  caseID: string,
  documentID: bigint,
  extractionID: string,
): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/${documentID}/removeBodyPartTag`,
    data: {
      caseID: caseID,
      extractionID: extractionID,
    },
  });

export type UpdateEntryTagInput = {
  entryId: number;
  tagId: number | null;
  tagType: string;
};

export const updateEntryTag = ({ entryId, tagId, tagType }: UpdateEntryTagInput): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/updateEntryTag`,
    data: {
      entryId,
      tagId,
      tagType,
    },
  });

export const getPagesByCaseFile = (
  caseID: string,
  caseFileID: string,
): Promise<AxiosResponse<PageObject[]>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/case-files/${caseFileID}/pages`,
  });

export const fetchSequenceUpdates = async (
  caseID: string,
  fileID: string,
  sequence: bigint | null,
) => {
  return authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/case-files/${fileID}/document-structure/sequence`,
    params: { sequence: sequence },
  });
};

export type DocumentNameEntry = {
  timelineEntryId: string;
  documentName: string;
};

export const getCaseFileDocumentNames = (
  fileID: string,
  caseID: string,
): Promise<AxiosResponse<DocumentNameEntry[]>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/case-files/${fileID}/document_names`,
  });

export const createDocumentEdge = async (
  caseId: string,
  caseFileId: string,
  pageId: string,
  body: { type: 'start' | 'end'; confidence: number; created: 'Processor' | 'QA' },
): Promise<AxiosResponse<DocumentEdge>> =>
  authenticatedRequest<
    { type: 'start' | 'end'; confidence: number; created: 'Processor' | 'QA' },
    DocumentEdge
  >({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/document-edges/${pageId}`,
    data: body,
  });

export const updateDocumentEdgeStatus = async (
  edgeId: string,
  caseId: string,
  caseFileId: string,
  body: { status: 'Confirmed' | 'Dismissed' },
): Promise<AxiosResponse<DocumentEdge>> =>
  authenticatedRequest<{ status: 'Confirmed' | 'Dismissed' }, DocumentEdge>({
    method: 'PATCH',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/document-edges/${edgeId}`,
    data: body,
  });

export const validateAndUpdateDocumentStructure = async (
  caseID: string,
  fileID: string,
  command: string,
  pageID: bigint,
  sequence: bigint,
): Promise<AxiosResponse> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/case-files/${fileID}/document-structure/${command}`,
    data: { pageID: pageID, currentSequence: sequence },
  });

export const buildGroupingsForCaseFile = async (
  caseId: string,
  caseFileId: string,
  startPage: number,
  endPage: number,
): Promise<AxiosResponse> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/buildDocumentsFromEdges`,
    data: { start_page: startPage, end_page: endPage },
  });

export const resetDocumentEdgesForCaseFile = async (
  caseId: string,
  caseFileId: string,
  body: {
    created: 'mdoc' | 'qa';
    start_page: number;
    end_page: number;
  },
): Promise<AxiosResponse> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/resetDocumentEdges`,
    data: body,
  });

export const getCaseFile = async (
  caseId: string,
  caseFileId: string,
): Promise<AxiosResponse<CaseFile>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}`,
  });

export const updateMarkedImportantTimelineEntry = async (
  entryID: number,
  markedImportant: boolean,
) => {
  return authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateMarkedImportant/${entryID}`,
    data: { marked_important: markedImportant },
  });
};

export const updateDuplicateDocument = ({
  entryId,
  isDuplicate,
}: UpdateDuplicateDocumentInput): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/updateDuplicateDocument`,
    data: {
      entryId,
      isDuplicate,
    },
  });

export const checkFileStatus = ({ caseId, fileId }: checkFileStatusProps) =>
  authenticatedRequest<FileStatus>({
    method: 'GET',
    url: `/api/v1/case/${caseId}/document/${fileId}/checkFileStatus`,
  });

export const getDocumentSimilarities = async (
  caseID: string,
  similarityThreshold?: number,
  hideResolved?: boolean,
  hideUnresolved?: boolean,
  page?: number,
  pageSize?: number,
): Promise<
  AxiosResponse<{
    count: number;
    documentSimilarities: DocumentSimilarityRecord[];
  }>
> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/document-similarities`,
    params: {
      similarity_threshold: similarityThreshold,
      hide_resolved: hideResolved,
      hide_unresolved: hideUnresolved,
      page,
      page_size: pageSize,
    },
  });

export type WordBlock = {
  Text: string;
  BoundingBox: {
    Left: number;
    Top: number;
    Width: number;
    Height: number;
  };
  Page: number;
};

export type DocumentComparisonResponse = {
  document_similarity: DocumentSimilarityRecord;
  document: { id: number; word_diff: WordBlock[] };
  compare_with: { id: number; word_diff: WordBlock[] };
};

export const getDocumentComparison = async (
  caseID: string,
  documentID: bigint | string,
  compareWith: bigint | string,
): Promise<AxiosResponse<DocumentComparisonResponse>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/document-similarities/document-compare`,
    params: {
      document_id: documentID,
      compare_with: compareWith,
    },
  });

export const markDocumentAsDuplicateOf = async (
  caseID: string,
  documentID: bigint | string,
  duplicateOf: bigint | string,
) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/document-similarities/set-duplicate`,
    data: {
      document_id: documentID,
      duplicate_of: duplicateOf,
    },
  });

export const markDocumentAsNotDuplicateOf = async (
  caseID: string,
  documentID: bigint | string,
  duplicateOf: bigint | string,
) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/document-similarities/set-not-duplicate`,
    data: {
      document_id: documentID,
      duplicate_of: duplicateOf,
    },
  });

export const createAllDocumentSimilaritiesForCase = async (
  caseID: string,
): Promise<AxiosResponse<{ task_id: string }>> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/document-similarities/create-similarities`,
  });

export const getIndexReport = async (
  caseID: string,
  indexReportID: string,
  shouldHideDuplicates: boolean,
  shouldShowHiddenPages: boolean,
  areSectionsGrouped: boolean,
  uploadDates?: string[],
) => {
  const paramObject: {
    shouldHideDuplicates: string;
    shouldShowHiddenPages: string;
    areSectionsGrouped: string;
    uploadDates?: string;
  } = {
    shouldHideDuplicates: shouldHideDuplicates.toString(),
    shouldShowHiddenPages: shouldShowHiddenPages.toString(),
    areSectionsGrouped: areSectionsGrouped.toString(),
  };
  if (uploadDates && uploadDates.length > 0) {
    paramObject.uploadDates = uploadDates.toString();
  }
  const params = new URLSearchParams(paramObject);
  const url = `/api/v1/cases/${caseID}/reports/index/${indexReportID}?${params.toString()}`;
  return authenticatedRequest({
    method: 'GET',
    url,
  });
};

export const getIndexListForCase = async (
  caseID: string,
): Promise<AxiosResponse<{ id: string; name: string; created_at: Date }[]>> => {
  const url = `/api/v1/cases/${caseID}/reports/index/`;
  return authenticatedRequest({
    method: 'GET',
    url,
  });
};

export const renameIndexReport = async (caseID: string, reportID: string, newName: string) =>
  authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/cases/${caseID}/reports/index/${reportID}/name`,
    data: { name: newName },
  });

export const renameIndexReportSection = async (
  caseID: string,
  reportID: string,
  sectionID: string,
  newName: string,
) =>
  authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/cases/${caseID}/reports/index/${reportID}/sections/${sectionID}/name`,
    data: { name: newName },
  });

export const updateIndexSectionSortOrder = async (
  newSortOrder: { field: string; sort: string | null | undefined },
  caseID: string,
  reportID: string,
  sectionID: string,
) => {
  return authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/cases/${caseID}/reports/index/${reportID}/sections/${sectionID}/sort-order`,
    data: newSortOrder,
  });
};

export const updateDocumentBookmark = async (
  caseID: string,
  reportID: string,
  documentID: string,
  isBookmarked: boolean,
) => {
  return authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/cases/${caseID}/reports/index/${reportID}/bookmarks`,
    data: { document_id: documentID, bookmarked: isBookmarked },
  });
};

export async function updateTimelineEntriesForReportSection({
  caseID,
  entryID,
  pageID,
  valuesToUpdate,
}: UpdateTimelineEntriesProps) {
  return authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateTimelineEntriesForReportSection/${caseID}`,
    data: { entryID, pageID, valuesToUpdate },
  });
}

type UpdateTimelineEntriesProps = {
  caseID: string | undefined;
  entryID: bigint;
  pageID?: bigint;
  valuesToUpdate: TimelineDetailsProps;
};

export type PageObject = {
  id: string;
  document_id: string;
  file_id: string;
  page_number: number;
  rotation_angle: number;
  document_edges: DocumentEdge[];
  document_status: string;
};

export type DocumentEdge = {
  id: string; // converted bigint
  file_id: string;
  page_id: string; // converted bigint
  type: 'start' | 'end';
};

export type UpdateDuplicateDocumentInput = {
  entryId: string;
  isDuplicate: boolean;
};

export type CaseFile = {
  id: string;
  case_id: string;
  document_file_name: string;
  document_file_path: string;
  is_ready: number;
  upload_date: Date;
  classified_chunks: number;
  number_of_chunks: number;
  retry: number;
  number_of_pages: number;
  file_status: FileStatus;
  author_chunks: number;
  author_status: NewOrReady;
  completed_duplicate_chunks: number;
  num_duplicate_chunks: number;
  duplicate_status: NewOrReady;
};

export type PaginationDetails = {
  count: number;
  page: number;
  page_size: number;
  next: string | null;
  previous: string | null;
  first: string;
  last: string;
};

export type DocumentSimilarityRecord = {
  id: string;
  case_id: CaseFile['id'];
  document_id: string;
  duplicate_of: string;
  similarity: number;

  status: 'Unset' | 'Duplicate' | 'NotDuplicate';
  status_changed: 'ML' | 'Processor' | 'QA' | 'User' | null;
  status_changed_by: string | null;

  created_at: Date;
  updated_at: Date;
};

// Activity Logging
type UserActivities =
  | { activity: 'report-templates' }
  | { activity: 'report-template'; report_template_id: string }
  | { activity: 'cases' }
  | { activity: 'case:image'; case_id: string }
  | { activity: 'case:tagging'; case_id: string; file_id: string }
  | {
      activity: 'case:review';
      case_id: string;
      file_id?: string;
      document_id: string;
      page_id: string;
    }
  | { activity: 'case:reports'; case_id: string }
  | { activity: 'case:notes'; case_id: string }
  | { activity: 'case:report'; case_id: string; report_id: string }
  | { activity: 'case:index'; case_id: string; index_report_id: string }
  | { activity: 'case:grouping'; case_id: string; file_id: string; page_id: string }
  | { activity: 'case:files'; case_id: string }
  | { activity: 'case:files:uploading'; case_id: string } // todo: add logging for this
  | { activity: 'case:exports'; case_id: string }
  | { activity: 'case:entities'; case_id: string }
  | {
      activity: 'case:duplicates';
      case_id: string;
      document_id: string;
      compare_document_id: string;
      page_id: string;
      compare_page_id: string;
    }
  | {
      activity: 'case:document-compare';
      case_id: string;
      document_id: string;
      compare_document_id: string;
    }; // todo: add logging for this in future (new duplicate by document);

export type UserActivity = {
  inactive?: boolean;
} & UserActivities;
export type TimeStampedUserActivity = UserActivity & { timestamp: string };

export const logUserActivity = queueLimit({ limit: 1 }, (activity: TimeStampedUserActivity[]) =>
  authenticatedRequest({
    method: 'POST',
    url: '/api/v1/me/activity',
    data: activity,
  }),
);

export const updateUserDetails = () =>
  authenticatedRequest({
    method: 'PUT',
    url: '/api/v1/me',
  });

export const removeMFA = () =>
  authenticatedRequest({
    method: 'DELETE',
    url: '/api/v1/me/mfa',
  });

export const updatePhoneNumber = async (phoneNumber: string) =>
  await authenticatedRequest({
    method: 'PUT',
    url: '/api/v1/me/phone_number',
    data: { phone_number: phoneNumber },
  });

export const exportIndex = async (
  caseId: string,
  reportId: string,
  data: any,
  useOrchestrator = false,
) => {
  return await axios.post(`${APIURL}case/${caseId}/index-report/${reportId}/download-async`, data);
  // return useOrchestrator
  //   ? authenticatedRequest({
  //       method: 'POST',
  //       url: `/api/v1/cases/${caseId}/reports/index/${reportId}/download`,
  //       data: data,
  //     })
};

type UpdateTimelineEntryStatusProps = {
  caseId: string;
  caseFileId: string;
  entryId: string;
  currentStatus: string;
};

export const updateTimelineEntryStatus = async ({
  caseId,
  caseFileId,
  entryId,
  currentStatus,
}: UpdateTimelineEntryStatusProps): Promise<AxiosResponse> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/timeline-entries/${entryId}/update-status`,
    data: { currentStatus },
  });

export const assignCase = ({ caseID, userID }: { caseID: string; userID: string }): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseID}/assignCase`,
    data: { userID: userID },
  });

export type DocumentTagFilter = DocumentTag & {
  tag_id: number;
  name: string;
  category_id: number;
  value?: number;
  label?: string;
  subItems?: DocumentTagFilter[];
  parent_tag_id?: number | null;
};

export const DocumentTagCategoriesMap: { [key: number]: string } = {
  1: 'Source',
  2: 'Content',
  3: 'Specialist',
  4: 'Diagnostic Imaging',
  5: 'Report',
  6: 'OCF Form',
  7: 'Insurance Form',
  8: 'ML Purpose',
};

export const getCaseSourceFilters = async (caseID: string) => {
  return authenticatedRequest({
    method: 'GET',
    url: `/api/v1/document-tags/case/${caseID}/source-tags`,
  });
};

export const getCaseContentFilters = async (caseID: string) => {
  return authenticatedRequest({
    method: 'GET',
    url: `/api/v1/document-tags/case/${caseID}/content-tags`,
  });
};

export const updatePageHiddenStatus = async ({
  pageID,
  hidden,
}: {
  pageID: bigint;
  hidden: boolean;
}) => {
  return authenticatedRequest({
    method: 'POST',
    url: `/api/v1/pages/${pageID}/updateHiddenStatus`,
    data: { is_hidden: hidden },
  });
};

export type User = {
  user_id: string;
  email: string;
  family_name: string;
  given_name: string;
  last_login: string;
  user_groups: string[];
};

export const getUsersForAdmin = (): Promise<AxiosResponse<User[]>> =>
  authenticatedRequest({
    method: 'GET',
    url: '/user/admin/list-users',
  });

export const resetPasswordForUser = (userId: string, email: string) =>
  authenticatedRequest({
    method: 'POST',
    url: '/user/admin/reset-password',
    data: { userId, email },
  });

export const resetMFAForUser = (userId: string) =>
  authenticatedRequest({
    method: 'POST',
    url: '/user/admin/reset-mfa',
    data: { userId },
  });

export type createNewUserProps = {
  email: string;
  first_name: string;
  last_name: string;
  group_name: string;
};

export const createNewUser = ({ email, first_name, last_name, group_name }: createNewUserProps) => {
  return authenticatedRequest({
    method: 'POST',
    url: '/user/admin/create-new-user',
    data: { first_name, last_name, email, group_name },
  });
};

export const deleteUser = (userId: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: '/user/admin/delete-user',
    data: { userId },
  });
};

export const updateUser = ({ userId, ...fields }: { userId: string; [key: string]: any }) => {
  return authenticatedRequest({
    method: 'POST',
    url: '/user/admin/update-user',
    data: { userId, ...fields },
  });
};

export const getUserGroups = (): Promise<AxiosResponse<string[]>> => {
  return authenticatedRequest({
    method: 'GET',
    url: '/user/admin/user-groups',
  });
};

export type caseDetails = {
  dueDate: Date | null;
  caseName: string | null;
  claimReason: string | null;
  patientName: string | null;
  fullName: string | null;
  dateOfBirth: Date | null;
  dateOfInjury: Date | null;
  case_type_id: bigint | null;
};

export const updateCaseDetails = (caseId: string, caseDetails: caseDetails) => {
  return authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseId}/updateDetails`,
    data: caseDetails,
  });
};

export const updateCaseSubject = (
  caseID: string,
  subjectID: bigint,
  subjectDetails: CaseSubject,
) => {
  return authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseID}/${subjectID}/updateSubject`,
    data: subjectDetails,
  });
};

export const updateIndexShowEmptySections = async ({
  caseID,
  indexID,
  shouldShowEmptySections,
}: {
  caseID: string;
  indexID: string;
  shouldShowEmptySections: boolean;
}) => {
  return authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/cases/${caseID}/reports/index/${indexID}/showEmptySections`,
    data: { should_show_empty_sections: shouldShowEmptySections },
  });
};

export enum TaskStatus {
  Created = 'created',
  Running = 'running',
  Queued = 'queued',
  Complete = 'completed',
  Failed = 'failed',
  TimedOut = 'timed_out',
  Canceled = 'canceled',
}

export const getTaskStatus = (taskId: string): Promise<AxiosResponse<{ status: TaskStatus }>> => {
  return authenticatedRequest({
    method: 'GET',
    url: `/api/v1/orchestrator/task/${taskId}/status`,
  });
};

export const getTagInsights = (caseID: string, tagID: number, resultCategoryID?: number) => {
  return authenticatedRequest({
    method: 'GET',
    url: `api/v1/cases/${caseID}/case-details/tag-insights`,
    params: { tagID, resultCategoryID },
  });
};

export const getDocumentSummary = (caseID: string, documentID: string) => {
  return authenticatedRequest({
    method: 'GET',
    url: `api/v1/summaries/document-summary/${caseID}/${documentID}`,
  });
};

export const updateDocumentSummary = (caseID: string, documentID: string, newValue: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/summaries/document-summary/${caseID}/${documentID}`,
    data: { summary: newValue },
  });
};

export const canGenerateSummary = (caseID: string, documentID: string) => {
  return authenticatedRequest({
    method: 'GET',
    url: `api/v1/summaries/document-summary/${caseID}/${documentID}/can-generate-summary`,
  });
};

export const generateDocumentSummary = (
  caseID: string,
  documentID: string,
  generateNew?: boolean,
) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/summaries/${caseID}/${documentID}/generate-document-summary/`,
    params: generateNew ? { generateNew: true } : {},
  });
};

export const startDocumentNameGeneration = (caseID: string, documentID: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/timeline-entry/${caseID}/${documentID}/generate-document-name`,
  });
};

export const getCaseAuthors = (caseID: string) => {
  return authenticatedRequest({
    method: 'GET',
    url: `api/v1/cases/${caseID}/case-details/authors`,
  });
};

export const getPageCountInsights = (caseID: string) => {
  return authenticatedRequest({
    method: 'GET',
    url: `api/v1/cases/${caseID}/case-details/page-count-insights`,
  });
};

export const updateFileAssignee = (
  caseID: string,
  fileID: string,
  userID: string | null,
  updateOnlyIfUnassigned: boolean,
) =>
  authenticatedRequest({
    method: 'PATCH',
    url: `/api/v1/case/${caseID}/file/${fileID}/assignee`,
    data: { userID, updateOnlyIfUnassigned },
  });

export const getNextFileIDToProcess = (caseID: string, processingStage: FileStatus) =>
  authenticatedRequest<{ id: string; file_status: FileStatus; number_of_pages: number } | ''>({
    method: 'GET',
    url: `/api/v1/case/${caseID}/file/next/${processingStage}`,
  });

export enum DuplicateGenerationStatus {
  NOT_STARTED = 'NotStarted',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}
export type duplicateProcessingStatus = {
  status: DuplicateGenerationStatus;
  version?: 'compare_documents' | 'generate_duplicates';
  updated_at?: string;
  elapsedMinutes?: number;
  totalEstimatedMinutes?: number;
};

export const getDuplicateProcessingStatus = (caseID: string) => {
  return authenticatedRequest<duplicateProcessingStatus>({
    method: 'GET',
    url: `api/v1/cases/${caseID}/document-similarities/status`,
  });
};

export const triggerAutoGenerateSummaries = (caseID: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/case/${caseID}/auto-generate-summaries`,
  });
};

export const removeAllPredictionsForFile = (caseID: string, fileID: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/case/${caseID}/file/${fileID}/remove-all-predictions`,
  });
};

export const renameFile = (caseID: string, fileID: string, name: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/cases/${caseID}/case-files/${fileID}/rename`,
    data: { name },
  });
};

export const skipAutomatedProcessingForFile = (caseID: string, fileID: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: `api/v1/case/${caseID}/file/${fileID}/skip-automated-processing`,
  });
};

export const createUserGroup = (groupName: string) => {
  return authenticatedRequest({
    method: 'POST',
    url: '/user/admin/create-group',
    data: { groupName },
  });
};
