import { useRef, useEffect } from 'react';

export function useBatchedRequest<T>({
  flushInterval,
  maxBatchSize,
  request,
}: {
  flushInterval: number;
  maxBatchSize: number;
  request: (data: T[]) => Promise<any>;
}) {
  const batchedDataRef = useRef<T[]>([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      flushBatchedData();
    }, flushInterval);

    const handleUnload = () => flushBatchedData();
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const addDataToBatch = (data: T) => {
    batchedDataRef.current.push(data);

    if (batchedDataRef.current.length >= maxBatchSize) {
      flushBatchedData();
    }
  };

  const flushBatchedData = () => {
    if (batchedDataRef.current.length === 0) {
      return;
    }

    const batchedDataToSend = [...batchedDataRef.current];
    batchedDataRef.current = [];

    request(batchedDataToSend);
  };

  return { addDataToBatch, flushBatchedData };
}
