/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import {
  getCurrentUser,
  confirmSignIn,
  deleteUserAttributes,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  signIn,
  signInWithRedirect,
  signOut,
} from '@aws-amplify/auth';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useUpdateCaseUserLocationMutation } from '../../__generated__/graphql';
import { removeMFA } from '../../api';

export const useUser = () => {
  const { user } = useContext(AuthContext);
  return { user, userId: user?.userId };
};

export const AuthContext = React.createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const [updateCaseUserLocation] = useUpdateCaseUserLocationMutation();
  const queryClient = useQueryClient();
  const setUserState = useCallback(async () => {
    try {
      const session = await fetchAuthSession();
      const user = await fetchUserAttributes();
      const mfaPreference = await fetchMFAPreference();
      const groups = session.tokens.accessToken.payload['cognito:groups'] ?? [];
      setUser({
        ...user,
        groups,
        username: user.sub,
        userId: user.sub,
        mfa: mfaPreference,
      });
    } catch (err) {
      console.log('error', err);
      setUser(null);
    }
  }, []);

  const checkSessionTimeout = (timeoutMinutes) => {
    const lastActiveTime = localStorage.getItem('lastActiveTime');
    if (lastActiveTime) {
      const currentTime = Date.now();
      const timeDiff = currentTime - parseInt(lastActiveTime, 10);
      return timeDiff > timeoutMinutes * 60 * 1000;
    }
    return true; // if no lastActiveTime, assume session is expired
  };

  const getSession = useCallback(async () => {
    if (checkSessionTimeout(Number(process.env.REACT_APP_SESSION_TIMEOUT) ?? 60)) {
      await signOut();
      localStorage.removeItem('lastActiveTime');
      window.location.href = '/login';
      return null;
    }

    try {
      const currentUser = await getCurrentUser().catch(() => null);
      if (!currentUser) {
        console.log('error', 'No current user');
        throw new Error('No current user');
      }

      const mfaPreference = await fetchMFAPreference();
      if (
        (process.env.REACT_APP_MFA_REQUIRED === 'true' ||
          (process.env.REACT_APP_IS_PRODUCTION === 'true' &&
            user?.email?.split('@')[1] === 'siftmed.ca')) &&
        !mfaPreference.enabled
      ) {
        console.log('error', 'MFA is required but not enabled');
        const attributes = await fetchUserAttributes();
        if (!attributes?.identities?.length > 0) {
          signOut();
          window.location.href = '/login';
          return null;
        }
      }

      await authorizeSession();
      await setUserState();
      return true;
    } catch (error) {
      console.error('Error getting session:', error);
      throw error;
    }
  }, []);

  const authorizeSession = useCallback(
    async ({ refreshUser = false } = {}) => {
      const session = await fetchAuthSession({ forceRefresh: true });
      const token = session.tokens.idToken.toString();
      axios.defaults.headers.common.Authorization = `${token}`;
      localStorage.setItem('ReactAmplify.TokenKey', token);

      if (!user || refreshUser) {
        await setUserState();
      }

      return session;
    },
    [setUserState],
  );

  const authenticate = useCallback(
    async (username, password) => {
      try {
        // if already signed in, sign out first
        const currentUser = await getCurrentUser().catch(() => null);
        if (currentUser) {
          await signOut();
        }

        const signInResponse = await signIn({
          username,
          password,
        });

        if (signInResponse.isSignedIn) {
          authorizeSession();
          localStorage.setItem('lastActiveTime', Date.now().toString());
        }

        return signInResponse;
      } catch (error) {
        console.error('Error during authentication:', error);
        throw error;
      }
    },
    [authorizeSession],
  );

  const confirmMFASignIn = useCallback(
    async (code) => {
      const response = await confirmSignIn({ challengeResponse: code });
      if (response.isSignedIn) {
        localStorage.setItem('lastActiveTime', Date.now().toString());
        await authorizeSession();
      }
    },
    [authorizeSession],
  );

  const saveUserLocation = useCallback(
    async (location, caseID) => {
      const pageIDRegex = /\/(\d+)\/?$/;
      const pageIDMatch = location.pathname?.match(pageIDRegex);
      const pageID = pageIDMatch ? pageIDMatch[1] : null;

      if (pageID && caseID && user) {
        await updateCaseUserLocation({
          variables: {
            data: {
              caseId: caseID,
              userId: user.sub,
              pageId: +pageID,
              view: location.pathname.indexOf('/documents') > -1 ? 'document' : 'timeline',
            },
          },
        });
        queryClient.invalidateQueries(['case', caseID]);
      }
    },
    [updateCaseUserLocation, queryClient],
  );

  const logout = useCallback(
    async (location, caseID) => {
      if (location && caseID) {
        try {
          await saveUserLocation(location, caseID);
        } catch (error) {
          console.error('Error saving user location:', error);
        }
      }
      setUser(null);
      await signOut();
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.href = '/login';
    },
    [saveUserLocation, queryClient],
  );

  const removeMFAFromAccount = useCallback(async () => {
    await removeMFA();
    await logout();
    window.location.href = '/login';
  }, [removeMFA, logout]);

  const forceChangePassword = useCallback(
    async (password) => {
      try {
        const response = await confirmSignIn({
          challengeResponse: password,
          options: {
            userAttributes: {},
          },
        });

        if (response.isSignedIn) {
          await authorizeSession();
        }
        return response;
      } catch (error) {
        console.error('Error changing password:', error);
        throw error;
      }
    },
    [authorizeSession],
  );

  const SSOLogin = useCallback(async (provider) => {
    await signInWithRedirect({ provider: { custom: provider } });
    localStorage.setItem('lastActiveTime', Date.now().toString());
  }, []);

  const removePhoneNumber = useCallback(async () => {
    await deleteUserAttributes({ userAttributeKeys: ['phone_number'] });
    await setUserState();
  });

  useEffect(() => {
    axios.interceptors.response.use(undefined, async (error) => {
      if (error?.response?.status === 401) {
        console.log('401, retrying');
        if (error.config._retry) {
          console.log('401, retrying, logging out');
          return logout();
        }
        console.log('401, retrying, authorizing session');
        return authorizeSession().then(() => {
          const originalRequestConfig = error.config;
          console.log('401, retrying, deleting authorization header');
          delete originalRequestConfig.headers.Authorization;

          const token = localStorage.getItem(
            `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}.${user.sub}.idToken`,
          );
          originalRequestConfig.headers.Authorization = token;
          axios.defaults.headers.common.Authorization = token;
          originalRequestConfig._retry = true;
          return axios.request(originalRequestConfig);
        });
      }
      return Promise.reject(error);
    });
  });

  const authContextValue = useMemo(
    () => ({
      user,
      authenticate,
      confirmSignIn: confirmMFASignIn,
      getSession,
      logout,
      removeMFAFromAccount,
      forceChangePassword,
      SSOLogin,
      updateUserState: setUserState,
      removePhoneNumber,
    }),
    [
      user,
      authenticate,
      confirmSignIn,
      getSession,
      logout,
      removeMFAFromAccount,
      forceChangePassword,
      SSOLogin,
      setUserState,
      removePhoneNumber,
    ],
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
