import { Chip, SxProps, Theme } from '@mui/material';

interface BetaChipProps {
  sx?: SxProps<Theme>;
}

export default function BetaChip({ sx = {} }: BetaChipProps) {
  return (
    <Chip
      variant="filled"
      label="Beta"
      size="small"
      sx={{
        backgroundColor: 'beta.main',
        fontSize: '0.5rem',
        height: '0.7rem',
        width: '1.8rem',
        mr: '0.2rem',
        '& .MuiChip-label': {
          overflow: 'visible',
        },
        ...sx,
      }}
    />
  );
}

BetaChip.defaultProps = {
  sx: {},
};
