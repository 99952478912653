import { useCallback, useEffect, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import {
  GetTimelineEntriesQueryVariables,
  useGetTimelineEntriesQuery,
} from '../../../__generated__/graphql';
import { UserIdHost } from '../types/commonTypes';
import { ListPagesSortByEnum, Page, TimelineEntry, TimelineFilters } from '../types/timelineTypes';
import config from '../../../config';
import { normalizePage } from './utils/pageUtils';
import {
  createAllowedTagIdsFromFilters,
  createOrderByFromFilters,
  createPageWhereInputFromFilters,
  isTagFilterApplied,
} from './utils/timelineGqlUtils';
import { useUser } from '../../../library/contexts/AuthContext';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import usePDFViewerStore from '../../Case/usePDFViewerStore';

export type UseTimelineEntriesParams = {
  caseID: string;
  filters?: TimelineFilters;
  shouldSkip?: boolean;
  documentID?: string[];
  startPageNumber?: string;
  endPageNumber?: string;
};

const useTimelineEntries = ({
  caseID,
  filters,
  shouldSkip = undefined,
  documentID,
  startPageNumber,
  endPageNumber,
}: UseTimelineEntriesParams) => {
  const { userId } = useUser();
  const isFileProcessor = useIsFileProcessor();
  const setDocumentRotation = usePDFViewerStore((state) => state.setDocumentRotation);

  const prevDocumentIDRef = useRef('');
  const prevFiltersRef = useRef({});
  const whereInput = useMemo(() => {
    const where = createGetTimelineEntriesWhereInputFromFilters(
      {
        caseID,
        userId,
        filters,
        documentID,
        startPageNumber,
        endPageNumber,
      },
      isFileProcessor,
    );

    return {
      ...where,
      skip: config.timeline.gql.defaultSkip,
      take: config.timeline.gql.defaultTake,
    };
  }, [userId, filters, isFileProcessor, documentID, startPageNumber, endPageNumber, caseID]);

  const fetchPolicy = !isEqual(documentID, prevDocumentIDRef.current)
    ? 'network-only'
    : 'cache-first';

  const query = useGetTimelineEntriesQuery({
    variables: whereInput,
    skip: shouldSkip || isFileProcessor == null,
    returnPartialData: true,
    fetchPolicy,
  });

  useEffect(() => {
    if (documentID !== prevDocumentIDRef.current) {
      prevDocumentIDRef.current = documentID;
    }
  }, [documentID]);

  useEffect(() => {
    if (filters && !isEqual(filters, prevFiltersRef.current)) {
      prevFiltersRef.current = filters;
    }
  }, [filters]);

  const refetch = useCallback(
    (
      refetchParams: UseTimelineEntriesParams & {
        skip?: number;
        take?: number;
      },
    ) => {
      const where = createGetTimelineEntriesWhereInputFromFilters(
        {
          ...refetchParams,
          userId,
        },
        isFileProcessor,
      );
      query.refetch({
        ...where,
        skip: refetchParams.skip ?? config.timeline.gql.defaultSkip,
        take: refetchParams.take ?? config.timeline.gql.defaultTake,
      });
    },
    [query],
  );

  const fetchMore = useCallback(
    (
      params: UseTimelineEntriesParams & {
        skip?: number;
        take?: number;
      },
    ) => {
      const where = createGetTimelineEntriesWhereInputFromFilters(
        {
          ...params,
          userId,
        },
        isFileProcessor,
      );
      query.fetchMore({
        variables: {
          ...where,
          skip: params.skip ?? config.timeline.gql.defaultSkip,
          take: params.take ?? config.timeline.gql.defaultTake,
        },
      });
    },
    [query],
  );

  return useMemo(() => {
    const rotationMap: Record<number, number> = {};

    if (!query.data) {
      return {
        ...query,
        refetch,
        fetchMore,
      };
    }

    const mappedEntries = query.data?.entries.map((entry): TimelineEntry => {
      const { pages } = entry || {};
      if (!pages) {
        return {} as TimelineEntry;
      }
      const firstPage = pages[0];
      const entryPages = pages?.map((pageHost): Page => {
        if (pageHost.rotation_angle !== 0) {
          rotationMap[pageHost.id] = pageHost.rotation_angle;
        }
        return {
          ...normalizePage(pageHost),
          sourceID: entry.source_id,
        };
      });

      return {
        id: entry.id,
        createdDate: entry.created_date,
        entryDate: entry.entry_date,
        startDate: entry.start_date,
        endDate: entry.end_date,
        author_id: entry.author_id ?? null,
        subject_id: entry.subject_id ?? null,
        org_id: entry.org_id ?? null,
        lastModifiedDate: entry.last_modified_date,
        marked_important: entry.marked_important,
        documentID: firstPage?.document_id ?? '',
        sourceID: entry.source_id,
        documentTags: entry.document_tags ?? [],
        pages: entryPages,
        order: entry.order,
      };
    });
    setDocumentRotation(rotationMap);

    const orderedEntries = mappedEntries?.reduce((result: TimelineEntry[], item) => {
      result[item.order] = item;
      return result;
    }, []);

    return {
      ...query,
      refetch,
      fetchMore,
      data: {
        entries: mappedEntries,
        entriesByOrderInDataset: orderedEntries,
      },
    };
  }, [query, query.data, refetch, fetchMore]);
};

const createGetTimelineEntriesWhereInputFromFilters = (
  params: UseTimelineEntriesParams & UserIdHost,
  isFileProcessor: boolean,
): GetTimelineEntriesQueryVariables => {
  const pageWhereInput = createPageWhereInputFromFilters(params.filters, params.userId);

  const allowedSources = params.filters?.sources?.map((item) => item.tag_id) ?? null;
  const allowedTagIds = isTagFilterApplied(params.filters)
    ? createAllowedTagIdsFromFilters(params.filters)
    : null;
  if (isFileProcessor) {
    return {
      timelineEntriesWhere: {
        document_id: params.documentID ?? null,
        startPageNumber: Number(params.startPageNumber) ?? null,
        endPageNumber: Number(params.endPageNumber) ?? null,
        case_id: params.caseID,
      },
      pagesWhere: {
        ...{
          AND: [],
        },
      },
      pagesViewedWhere: {
        user_id: {
          equals: params.userId,
        },
      },

      // @TODO: ordering pages inside the timeline entry is missed - fix it after aligning the shape of GQL query between timeline entries and pages
      timelineEntriesOrderBy: createOrderByFromFilters({
        documentOrder: ListPagesSortByEnum.Document,
      }),
    };
  }
  return {
    timelineEntriesWhere: {
      showFavourited: params.filters?.showFavourited,
      hideViewed: params.filters?.hideViewed,
      hideDuplicates: params.filters?.hideDuplicates,
      allowedSourceTags: allowedSources,
      allowedDocumentTags: allowedTagIds,
      beforeDate: params.filters?.beforeDate ?? null,
      afterDate: params.filters?.afterDate ?? null,
      document_id: params.filters?.documentID ?? null,
      case_id: params.caseID,
    },
    pagesWhere: {
      ...pageWhereInput,
      ...{
        AND: [],
      },
    },
    pagesViewedWhere: {
      user_id: {
        equals: params.userId,
      },
    },
    // @TODO: ordering pages inside the timeline entry is missed - fix it after aligning the shape of GQL query between timeline entries and pages
    timelineEntriesOrderBy: createOrderByFromFilters(params.filters),
  };
};

export default useTimelineEntries;
