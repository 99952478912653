import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { Typography, Button } from '@mui/material';
import { PageDate } from '../../__generated__/graphql';
import PageHighlight from './components/PageHighlight';
import { formatSegmentDate } from '../../library/utilities/useDates';
import useUpdateEntryDates from '../../containers/Timeline/gql/useUpdateEntryDates';
import useTimelineStore from '../../containers/Timeline/useTimelineStore';

export default function DateHighlight({
  entryID,
  dates,
  rotation = 0,
  setSelectedDate,
  startDate = '',
  endDate = '',
}: {
  entryID: string;
  dates: PageDate[];
  rotation: number;
  setSelectedDate: (date: string, dateType: 'start' | 'end') => void;
  startDate: string;
  endDate: string;
}) {
  const [selected, setSelected] = useState<PageDate | null>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [entryStartDate, setEntryStartDate] = useState('');
  const [entryEndDate, setEntryEndDate] = useState('');
  const [disabledButtons, setDisabledButtons] = useState<{ start: boolean; end: boolean }>({
    start: false,
    end: false,
  });

  useEffect(() => {
    setEntryStartDate(startDate);
    setEntryEndDate(endDate);
  }, [startDate, endDate]);

  const { updateEntryDates } = useUpdateEntryDates();

  const { updatedTimelineEntry, setUpdatedTimelineEntry } = useTimelineStore(
    (state) => ({
      updatedTimelineEntry: state.updatedTimelineEntry,
      setUpdatedTimelineEntry: state.setUpdatedTimelineEntry,
    }),
    shallow,
  );

  useEffect(() => {
    if (updatedTimelineEntry?.id === Number(entryID)) {
      if ('startDate' in updatedTimelineEntry) {
        setEntryStartDate(updatedTimelineEntry.startDate ?? '');
      }
      if ('endDate' in updatedTimelineEntry) {
        setEntryEndDate(updatedTimelineEntry.endDate ?? '');
      }
    }
  }, [updatedTimelineEntry, entryID]);

  if (!dates) {
    return null;
  }

  const minBoundingBoxDimension = 0.0001;
  const filteredDates = dates.filter(
    (date) =>
      Math.abs((date.Width ?? 0) - (date.Left ?? 0)) > minBoundingBoxDimension &&
      Math.abs((date.Height ?? 0) - (date.Top ?? 0)) > minBoundingBoxDimension,
  );

  const clickDate = (event: React.SyntheticEvent, date: PageDate) => {
    setSelected(date);
    setAnchorEl(event.currentTarget);
    setDisabledButtons({ start: false, end: false });
  };

  const onClose = () => {
    setSelected(null);
    setAnchorEl(null);
  };

  // eslint-disable-next-line no-shadow
  const updateDate = async (dateType: 'start' | 'end') => {
    if (!selected) {
      return;
    }

    if (dateType === 'end' && selected.value < entryStartDate) {
      toast.error('End date cannot be before the start date');
      onClose();
      return;
    }

    setDisabledButtons((prev) => ({ ...prev, [dateType]: true }));

    setSelectedDate(selected.value, dateType);

    const updateEntryDatesInput = {
      entryID: Number(entryID),
      dates: [],
      newStartDate: dateType === 'start' ? selected.value : entryStartDate,
      newEndDate: dateType === 'end' ? selected.value : entryEndDate,
      entryStartDate,
      entryEndDate,
    };
    await updateEntryDates(updateEntryDatesInput);

    if (dateType === 'start') {
      setEntryStartDate(selected.value);
      setUpdatedTimelineEntry({
        ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
        id: Number(entryID),
        startDate: selected.value,
        endDate: entryEndDate,
      });
    } else if (dateType === 'end') {
      setEntryEndDate(selected.value);
      setUpdatedTimelineEntry({
        ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
        id: Number(entryID),
        startDate: entryStartDate,
        endDate: selected.value,
      });
    }

    onClose();
  };

  return (
    <>
      {filteredDates.map((date, index) => (
        <PageHighlight
          key={index}
          boundingBox={{
            Left: date.Left ?? 0,
            Top: date.Top ?? 0,
            Width: date.Width ?? 0,
            Height: date.Height ?? 0,
          }}
          rotation={rotation}
          sx={{
            backgroundColor:
              // eslint-disable-next-line no-nested-ternary
              entryStartDate === date.value
                ? 'rgba(99, 237, 71, 0.3)'
                : entryEndDate === date.value
                ? 'rgba(134, 71, 142, 0.3)'
                : 'rgba(253, 255, 50, 0.2)',
            border: `1px solid ${
              // eslint-disable-next-line no-nested-ternary
              entryStartDate === date.value
                ? 'green'
                : entryEndDate === date.value
                ? 'purple'
                : 'gold'
            }`,
            padding: '4px',
          }}
          onClick={
            entryStartDate === date.value || entryEndDate === date.value
              ? null
              : (e) => clickDate(e, date)
          }
        />
      ))}

      {selected && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          role={undefined}
          sx={{ zIndex: 2000 }}
          onClose={onClose}
        >
          <Paper variant="outlined" sx={{ p: '12px' }}>
            <Typography variant="caption">
              Set date to {formatSegmentDate(selected.value)}?
            </Typography>
            <Button
              onClick={() => updateDate('start')}
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: '1rem' }}
              disabled={disabledButtons.start}
            >
              Start-Date
            </Button>
            <Button
              onClick={() => updateDate('end')}
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: '1rem' }}
              disabled={disabledButtons.end}
            >
              End-Date
            </Button>
          </Paper>
        </Popover>
      )}
    </>
  );
}
