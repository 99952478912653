import { CaseRelatedFileRawObject } from '../../../../api';

//  **single** file
export function normalizeCaseRelatedFile(file: CaseRelatedFileRawObject) {
  return {
    fileID: file.id,
    caseID: file.case_id,
    fileName: file.file_name,
    fileType: file.file_type,
    uploadedBy: file.uploaded_by,
    uploadedByName: file.uploaded_by_name,
    uploadedDate: file.uploaded_date,
    latestDownloadDate: file.latest_download_date,
    downloadedCount: file.downloaded_count,
  };
}
export type NormalizedCaseRelatedFile = ReturnType<typeof normalizeCaseRelatedFile>;

//  **multiple** files
export function normalizeCaseRelatedFiles(files: CaseRelatedFileRawObject[]) {
  return files.map(normalizeCaseRelatedFile);
}
