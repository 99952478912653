import { useQuery } from '@tanstack/react-query';
import { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getTagInsights, CaseSubject, getCaseAuthors, getPageCountInsights } from '../../../api';
import { useTimelineList } from '../../Timeline/useTimeline';
import CaseContext from '../CaseContext';
import './case-insights-container.css';
import useUpdateCaseDetails, { useUpdateCaseSubject } from '../gql/updateCaseDetails';
import { validateCaseDetails } from '../../MyCases/utils/caseDetailsValidation';
import { formatUTCDateToDateString } from '../../../library/utilities/useDates';
import useDocumentSearchStore, {
  initialDocumentSearchStoreState,
} from '../../Timeline/useDocumentSearchStore';
import useCaseDocumentTagFilters from '../../Timeline/api-queries/useCaseDocumentTagFilters';
import useCaseFiles from '../../Files/useCaseFiles';

export type CaseDetails = {
  caseName: string;
  dueDate: string;
  caseType: string;
  caseStatus: string;
  dateOfInjury: string;
  claimReason: string;
  referralReceivedDate: string;
};

export type CaseDetailsFields =
  | 'caseName'
  | 'dueDate'
  | 'caseType'
  | 'dateOfInjury'
  | 'claimReason'
  | 'referralReceivedDate';

export type CaseSubjectFields = 'name' | 'date_of_birth' | 'address' | 'phone_number' | 'email';

export default function useInsights(caseID: string) {
  const { caseInstance } = useContext(CaseContext);
  const navigate = useNavigate();
  const {
    caseName,
    dueDate,
    caseStatus,
    case_type_name: caseType,
    dateOfInjury,
    claimReason,
    subjects,
    referralReceivedDate,
  } = caseInstance;

  const [filters, setFilters] = useDocumentSearchStore(
    (state) => [state.filters, state.setFilters],
    shallow,
  );
  const { data: caseTags } = useCaseDocumentTagFilters({ caseID: caseID! });
  const sourceList = caseTags?.sourceTags!;
  const contentList = caseTags?.contentTags!;

  const { data: caseFiles } = useCaseFiles(caseID ?? '');
  const fileList =
    caseFiles?.map((file) => ({ value: file.documentID, label: file.docFileName })) || [];

  const [caseDetails, setCaseDetails] = useState<CaseDetails>({
    caseName,
    dueDate: dueDate ? formatUTCDateToDateString(new Date(dueDate)) : '',
    caseStatus,
    caseType,
    dateOfInjury: dateOfInjury ? formatUTCDateToDateString(new Date(dateOfInjury)) : '',
    claimReason: claimReason ?? '',
    referralReceivedDate: referralReceivedDate
      ? formatUTCDateToDateString(new Date(referralReceivedDate))
      : '',
  });
  const [caseSubjects, setCaseSubjects] = useState<CaseSubject[]>(
    subjects?.map((s: CaseSubject) => ({
      ...s,
      date_of_birth: s.date_of_birth ? formatUTCDateToDateString(new Date(s.date_of_birth)) : '',
    })) ?? [],
  );

  const [errorMessage, setErrorMessage] = useState('');

  const validateCaseUpdate = (details: CaseDetails & CaseSubject) => {
    const [isValid, error] = validateCaseDetails(
      false,
      details.caseName ?? '',
      details.dueDate,
      details.date_of_birth,
      details.dateOfInjury,
    );
    setErrorMessage(isValid ? '' : String(error));
  };

  useEffect(() => {
    resetCaseDetails();
  }, [caseInstance]);

  const caseHasSubjects =
    caseSubjects.filter(
      (subject) =>
        subject.name ||
        subject.address ||
        subject.date_of_birth ||
        subject.email ||
        subject.phone_number,
    ).length > 0;

  const detailsToUpdate = !_.isEqual(
    {
      caseName,
      dueDate: dueDate ? formatUTCDateToDateString(new Date(dueDate)) : '',
      caseStatus,
      caseType,
      dateOfInjury: dateOfInjury ? formatUTCDateToDateString(new Date(dateOfInjury)) : '',
      claimReason: claimReason ?? '',
      referralReceivedDate: referralReceivedDate
        ? formatUTCDateToDateString(new Date(referralReceivedDate))
        : '',
    },
    caseDetails,
  );

  const handleUpdateCaseDetails = async (field: CaseDetailsFields, value: string) => {
    const updatedDetails = {
      ...caseDetails,
      [field]: value,
    };
    validateCaseUpdate(updatedDetails);
    setCaseDetails(updatedDetails);
  };

  const resetCaseDetails = () => {
    setCaseDetails({
      caseName,
      dueDate: dueDate ? formatUTCDateToDateString(new Date(dueDate)) : '',
      caseStatus,
      caseType,
      dateOfInjury: dateOfInjury ? formatUTCDateToDateString(new Date(dateOfInjury)) : '',
      claimReason: claimReason ?? '',
      referralReceivedDate: referralReceivedDate
        ? formatUTCDateToDateString(new Date(referralReceivedDate))
        : '',
    });
    setCaseSubjects(
      subjects?.map((s: CaseSubject) => ({
        ...s,
        date_of_birth: s.date_of_birth ? formatUTCDateToDateString(new Date(s.date_of_birth)) : '',
      })) ?? [],
    );
  };

  const updateCase = useUpdateCaseDetails();
  const updateSubject = useUpdateCaseSubject();

  const handleSaveCaseDetails = async () => {
    const updatedDetails = {
      ...caseDetails,
      caseName: caseDetails.caseName ? caseDetails.caseName : caseName,
      ...(caseHasSubjects
        ? { patientName: caseSubjects[0].name, dateOfBirth: caseSubjects[0].date_of_birth }
        : {}),
    };
    try {
      await updateCase(caseID, updatedDetails);
      toast.success('Case details updated successfully');
    } catch (e) {
      console.error(e);
      toast.error('Error updating case details');
    }
  };

  const handleUpdateSubject = async (updatedSubject: CaseSubject) => {
    const currentSubject = caseSubjects.find((s) => s.id === updatedSubject.id);
    if (_.isEqual(currentSubject, updatedSubject)) {
      return;
    }
    try {
      const subjectID = await updateSubject(caseID, updatedSubject.id, updatedSubject);
      const newSubjects = [{ ...updatedSubject, id: subjectID }];
      setCaseSubjects(newSubjects);
      toast.success('Subject details updated successfully');
    } catch (e) {
      console.error(e);
      toast.error('Error updating subject details');
    }
  };

  const { tagCounts: imeReportSpecialtyInsights, documents: imeReportDocuments } =
    useTagInsightsByCategory(caseID, 175, 3);
  const { authors } = useDoctorSpecialtyInsights(caseID);
  const { pageCounts } = usePageCountInsights(caseID);

  const filterAndViewDocuments = (tagID: number) => {
    setFilters({
      ...filters,
      ...initialDocumentSearchStoreState.filters,
      contentTypes: contentList.filter((content) => content.value === tagID),
      subContentTypes: [],
      sources: sourceList,
      documentID: fileList.map((file) => file.value),
    });
    navigate(
      `/case/${caseID}/timeline/${imeReportDocuments[0].documentID}/${imeReportDocuments[0].firstPage}`,
    );
  };

  return {
    handleUpdateCaseDetails,
    handleSaveCaseDetails,
    handleUpdateSubject,
    caseDetails,
    caseSubjects,
    caseHasSubjects,
    imeReportSpecialtyInsights,
    filterAndViewDocuments,
    errorMessage,
    setErrorMessage,
    validateCaseUpdate,
    detailsToUpdate,
    resetCaseDetails,
    authors,
    pageCounts,
  };
}

function useTagInsightsByCategory(
  caseID: string,
  tagID: number,
  resultCategoryID: number,
): {
  tagCounts: any;
  documents: any;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    ['tagInsights', caseID, tagID],
    () => getTagInsights(caseID, tagID, resultCategoryID ?? null),
    {
      enabled: !!caseID && !!tagID,
    },
  );
  const tagCounts = data?.data?.tagCounts;
  const documents = data?.data?.documentIDs;
  if (tagCounts) {
    const sortedTags = Object.keys(tagCounts)
      .sort((a, b) => {
        return b === 'Other' ? -1 : tagCounts[b] - tagCounts[a];
      })
      .map((tag) => ({ tag, count: tagCounts[tag] }));
    return { tagCounts: sortedTags, documents, isLoading, isError };
  }

  return { tagCounts: [], documents: [], isLoading, isError };
}

function useDoctorSpecialtyInsights(caseID: string): {
  authors: any[];
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    ['doctorInsights', caseID],
    () => getCaseAuthors(caseID),
    {
      enabled: !!caseID,
    },
  );
  return { authors: data?.data ?? [], isLoading, isError };
}

function usePageCountInsights(caseID: string): {
  pageCounts: { totalPages: number; duplicates: number; hiddenPages: number; flaggedPages: number };
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    ['pageCountInsights', caseID],
    () => getPageCountInsights(caseID),
    {
      enabled: !!caseID,
    },
  );
  const pageCounts = data?.data?.map(
    (pageCount: {
      total_pages: number;
      duplicate_pages: number;
      hidden_pages: number;
      favourite_pages: number;
    }) => {
      return {
        totalPages:
          pageCount.total_pages -
          pageCount.hidden_pages -
          pageCount.favourite_pages -
          pageCount.duplicate_pages,
        duplicates: pageCount.duplicate_pages,
        hiddenPages: pageCount.hidden_pages,
        flaggedPages: pageCount.favourite_pages,
      };
    },
  );
  if (pageCounts?.length > 0) {
    return { pageCounts: pageCounts[0], isLoading, isError };
  }
  return { pageCounts, isLoading, isError };
}
