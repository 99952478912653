import { useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import apiUrl from '../../library/utilities/apiUrl';
import { useUser } from '../../library/contexts/AuthContext';
import useCaseFiles from '../Files/useCaseFiles';

function useDocumentUpload(caseID) {
  const { data: caseFiles, refetch: refetchCaseDocuments } = useCaseFiles(caseID);

  const previousDocuments = caseFiles;
  const [uploadProgress, setUploadProgress] = useState({});
  const [roundedUploadProgress, setRoundedUploadProgress] = useState({});
  const [files, setFiles] = useState([]);
  const [failedFiles, setFailedFiles] = useState([]);
  const currentUser = useUser();

  const clearFiles = () => {
    setFiles([]);
    setFailedFiles([]);
  };

  async function handleFileUpload(uploadedFiles) {
    const newFiles = [...uploadedFiles];
    newFiles.sort((a, b) => {
      // for removing duplicates
      if (a.name === b.name) {
        return 0;
      }
      if (a.name > b.name) {
        return 1;
      }
      return -1;
    });

    await Promise.all(
      newFiles.map(async (file, index) => {
        // remove duplicates from new files
        if (index > 0 && file.name === newFiles[index - 1].name) {
          newFiles.splice(index, 1);
        }

        const { name: fileName } = file;
        const ext = fileName.split('.').pop();

        const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'docx', 'png', 'tiff', 'tif', 'txt'];
        if (!allowedExtensions.includes(ext.toLowerCase())) {

          setFailedFiles([
            ...failedFiles,
            {
              imageID: Math.round(10000000000 + Math.random() * (99999999999 - 10000000000)),
              name: fileName,
              errorType: 'Incompatible file type',
            },
          ]);
          return;
        }

        const pdfFile = await convertPDF(file, ext);

        const alreadyUploaded = Boolean(
          previousDocuments?.find((d) => d.docFileName === fileName) ||
            files.find((f) => f.name === fileName),
        );

        if (alreadyUploaded) {
          setFailedFiles([
            ...failedFiles,
            {
              imageID: Math.round(10000000000 + Math.random() * (99999999999 - 10000000000)),
              name: fileName,
              errorType: 'File is already in this case',
            },
          ]);
          return;
        }
        const UID = Math.round(10000000000 + Math.random() * (99999999999 - 10000000000));
        pdfFile.imageID = UID;
        const fileInfo = {
          caseID,
          fileName,
          userID: currentUser.username,
        };
        const pdfFileName = file.name.replace(/\.[^/.]+$/, '.pdf');
        fileInfo.fileName = ['pdf', 'jpg', 'jpeg'].includes(ext.toLowerCase()) ? pdfFileName : fileName;

        if (ext === 'pdf' || ext === 'jpeg' || ext === 'jpg') {
          setFiles((current) => [...current, pdfFile]);
        } else {
          setFiles((current) => [...current, file]);
        }
        try {
          if (ext === 'pdf' || ext === 'jpeg' || ext === 'jpg') {
            await processPDF(pdfFile, fileInfo, ext);
          } else {
            await processPDF(file, fileInfo, ext);
          }
        } catch (e) {
          Sentry.captureException(e);
          if (!failedFiles.map((file) => file.name).includes(fileInfo.fileName)) {
            setFailedFiles([
              ...failedFiles,
              {
                imageID: UID,
                name: fileName,
                errorType: 'Error processing file',
              },
            ]);
          }
          setFiles((current) => current.filter((f) => f.name !== fileName));
        }
      }),
    );
    refetchCaseDocuments({ caseID });
  }

  async function processPDF(fileData, fileInfo, fileExt) {
    // Generate presigned URL
    const presignedResponseJson = await axios
      .post(`${apiUrl}getPdfPresignedUrl`, fileInfo)
      .catch((error) => {
        Sentry.captureException(error);
        if (!failedFiles.map((file) => file.name).includes(fileInfo.fileName)) {
          setFailedFiles([
            ...failedFiles,
            {
              imageID: fileData.imageID,
              name: fileInfo.fileName,
              errorType: 'Error processing file',
            },
          ]);
          setFiles((current) => current.filter((f) => f.name !== fileInfo.fileName));
        }
      });

    // Upload file with form data object
    fileInfo.documentID = presignedResponseJson.data.documentID;
    fileInfo.fileFormat = fileInfo.fileName.split('.').at(-1);
    const form = new FormData();
    Object.keys(presignedResponseJson.data.url.fields).forEach((key) =>
      form.append(key, presignedResponseJson.data.url.fields[key]),
    );
    form.append('file', fileData);

    await axios
      .post(presignedResponseJson.data.url.url, form, {
        transformRequest: (data, headers) => {
          delete headers.common.Authorization;
          return data;
        },

        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ??
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress((progress) => ({
              ...progress,
              [fileData.name]: Math.round((progressEvent.loaded / totalLength) * 90),
            }));
            setRoundedUploadProgress((progress) => ({
              ...progress,
              [fileData.name]: Math.floor((progressEvent.loaded / totalLength) * 9),
            }));
          }
        },
      })
      .then(async () => {
        const convertedFileInfo = {
          ...fileInfo,
          fileName: fileInfo.fileName.replace(/\.[^/.]+$/, `.${fileExt}`),
        };
        await axios.post(`${apiUrl}createNewDocument`, convertedFileInfo).catch((error) => {
          Sentry.captureException(error);
          if (!failedFiles.map((file) => file.name).includes(fileInfo.fileName)) {
            setFailedFiles([
              ...failedFiles,
              {
                imageID: fileData.imageID,
                name: fileInfo.fileName,
                errorType: 'Error processing file',
              },
            ]);
            setFiles((current) => current.filter((f) => f.name !== fileInfo.fileName));
          }
        });

        setUploadProgress((progress) => ({
          ...progress,
          [fileData.name]: 100,
        }));
        setRoundedUploadProgress((progress) => ({
          ...progress,
          [fileData.name]: 10,
        }));
      });
  }
  //Convert JPG file  to PDF file
  async function convertPDF(file, ext) {
    return new Promise((resolve, reject) => {
       if (ext.toLowerCase() === 'jpeg' || ext.toLowerCase() === 'jpg') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            // eslint-disable-next-line new-cap
            const newFile = new jsPDF({ format: 'a4', unit: 'px' });
            const width = newFile.internal.pageSize.getWidth();
            const height = newFile.internal.pageSize.getHeight();
            const aspectRatio = img.width / img.height;
            if (img.width > img.height) {
              const resizedImageHeight = width / aspectRatio;
              newFile.addImage(
                e.target.result,
                'JPEG',
                0,
                (height - resizedImageHeight) / 2,
                width,
                resizedImageHeight,
              );
            } else {
              const resizedImageWidth = aspectRatio * height;
              newFile.addImage(
                e.target.result,
                'JPEG',
                (width - resizedImageWidth) / 2,
                0,
                resizedImageWidth,
                height,
              );
            }
            const pdfFile = new File([newFile.output('blob')], file.name, {
              type: 'application/pdf',
            });
            resolve(pdfFile);
          };
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      } else {
        resolve(file);
      }
    });
  }

  function handleRemoveFromFailedFiles(fileName, error) {
    if (uploadProgress[fileName] && error !== 'File already in this case') {
      setUploadProgress((current) => {
        const filesInProgress = { ...current };
        delete filesInProgress[fileName];
        return filesInProgress;
      });
      setRoundedUploadProgress((current) => {
        const filesInProgress = { ...current };
        delete filesInProgress[fileName];
        return filesInProgress;
      });
    }
    setFailedFiles(failedFiles.filter((file) => file.name !== fileName));
  }

  async function deleteFile(doc) {
    try {
      await axios.post(`${apiUrl}deleteDocument`, {
        documentID: doc.documentID,
        caseID,
      });
    } catch (e) {
      console.log(e);
      toast.error('Failed to delete document...', {
        toastId: 'document-delete',
      });
    }
    setFiles((current) => [...current.filter((file) => doc.docFileName !== file.name)]);
    refetchCaseDocuments({ caseID });
  }

  async function deleteUnprocessedFiles() {
    refetchCaseDocuments({ caseID });

    if (previousDocuments) {
      previousDocuments.forEach(async (doc) => {
        if (doc.isReady === 0) {
          await deleteFile(doc);
        }
      });
    }
    refetchCaseDocuments({ caseID });
  }

  return {
    handleFileUpload,
    handleRemoveFromFailedFiles,
    uploadProgress,
    roundedUploadProgress,
    files,
    failedFiles,
    clearFiles,
    deleteUnprocessedFiles,
    deleteFile,
  };
}

export default useDocumentUpload;
