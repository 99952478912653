import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { updateFileAssignee } from '../api';
import { useIsFileProcessor } from '../containers/AccountSettings/useFileProcessing';
import { useUser } from '../library/contexts/AuthContext';

export function useAutomaticFileAssignment(fileID?: string) {
  const isFileProcessor = useIsFileProcessor();
  const { user } = useUser();
  const { caseID } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    async function assignFileToProcessor() {
      if (!isFileProcessor || caseID == null || fileID == null || user?.userId == null) {
        return;
      }

      try {
        await updateFileAssignee(caseID, fileID, user.userId, true);
        queryClient.invalidateQueries(['files', caseID]);
      } catch (err: any) {
        console.error(err);
      }
    }

    assignFileToProcessor();
  }, [caseID, user.userId, fileID, isFileProcessor]);
}
