import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useApolloClient, gql, ApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import {
  getDocumentTags,
  DocumentTagsObject,
  updateDocumentTags,
  DocumentTag,
  DocumentTagFilter,
  DocumentTagCategoriesMap,
} from '../../api';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';

async function fetchDocumentTags(entryId: number): Promise<DocumentTagsObject> {
  const res = await getDocumentTags(entryId);
  return res.data as DocumentTagsObject;
}

export function useDocumentTags(entryId: number) {
  return useQuery<DocumentTagsObject>(['documentTags', entryId], () => fetchDocumentTags(entryId));
}

export function useUpdateDocumentTags() {
  const queryClient = useQueryClient();
  const isFileProcessor = useIsFileProcessor();
  const apolloClient = useApolloClient();
  const { caseID } = useParams();

  return useMutation(updateDocumentTags, {
    onMutate: async ({
      entryID,
      tags,
      tagType,
    }: {
      entryID: number;
      tags: DocumentTag[];
      tagType: string | string[];
    }) => {
      await queryClient.cancelQueries(['documentTags', entryID]);
      const previousTags: DocumentTagsObject | undefined = await queryClient.getQueryData([
        'documentTags',
        entryID,
      ]);
      if (!previousTags) {
        return;
      }
      const newDocumentTags = { ...previousTags };
      if (Array.isArray(tagType)) {
        newDocumentTags.documentTypes = tags.filter((tag) => tag.category === 'Content');
        newDocumentTags.specialties = tags.filter((tag) => tag.category === 'Specialist');
      }
      if (tagType === 'Content') {
        newDocumentTags.documentTypes = tags;
      }
      if (tagType === 'Specialist') {
        newDocumentTags.specialties = tags;
      }
      updateApolloCacheAfterMutation(apolloClient, Number(entryID), [
        ...newDocumentTags.documentTypes,
        ...newDocumentTags.specialties,
        newDocumentTags.source,
      ]);

      queryClient.setQueryData(['documentTags', entryID], newDocumentTags);

      await queryClient.cancelQueries(['case-document-tags', caseID]);
      const caseTags: { contentTags: DocumentTagFilter[] } | undefined =
        await queryClient.getQueryData(['case-document-tags', caseID]);
      let newCaseTags = caseTags?.contentTags ?? [];
      const newTags = tags.filter((newTag) => {
        return !newCaseTags.some((tag) => {
          if (tag.subItems && tag.subItems.length > 0) {
            return tag.subItems.some((subItem) => String(subItem.tag_id) === String(newTag.tag_id));
          }
          return String(tag.tag_id) === String(newTag.tag_id);
        });
      });
      if (newTags.length > 0) {
        newTags.forEach((newTag) => {
          if (newTag.category_id === 2) {
            newCaseTags.push({
              tag_id: Number(newTag.tag_id),
              name: newTag.name,
              category_id: 2,
              value: Number(newTag.tag_id),
              label: newTag.name,
              subItems: [],
              parent_tag_id: null,
            });
          } else {
            const existingCategory = newCaseTags.find(
              (tag) => tag.category_id === newTag.category_id,
            );

            if (existingCategory) {
              existingCategory.subItems?.push({
                tag_id: Number(newTag.tag_id),
                name: newTag.name,
                category_id: newTag.category_id,
                value: Number(newTag.tag_id),
                label: newTag.name,
                subItems: [],
                parent_tag_id: Number(newTag.category_id) + 1000,
              });
            } else {
              newCaseTags.push({
                tag_id: Number(newTag.category_id) + 1000,
                name: DocumentTagCategoriesMap[newTag.category_id ?? 0],
                category_id: newTag.category_id,
                label: DocumentTagCategoriesMap[newTag.category_id ?? 0],
                subItems: [
                  {
                    tag_id: Number(newTag.tag_id),
                    name: newTag.name,
                    category_id: newTag.category_id,
                    value: Number(newTag.tag_id),
                    label: newTag.name,
                    subItems: [],
                    parent_tag_id: Number(newTag.category_id) + 1000,
                  },
                ],
                parent_tag_id: null,
              });
            }
          }
        });
        queryClient.setQueryData(['case-document-tags', caseID], {
          ...caseTags,
          contentTags: [...newCaseTags],
        });
      }
    },
    onSuccess: (_, variables) => {
      if (!isFileProcessor) {
        toast.success(
          `${
            variables.tagType === 'Specialist' ? 'Specialty' : 'Document Type'
          } updated successfully`,
          {
            autoClose: 500,
            hideProgressBar: true,
          },
        );
      }
    },
    onError: async (error, { entryID }) => {
      toast.error('A problem occurred when assigning a new document tag');
      Sentry.captureException(error);
      queryClient.invalidateQueries(['documentTags', entryID]);
      queryClient.invalidateQueries(['case-document-tags']);
    },
  });
}

const updateApolloCacheAfterMutation = (
  apolloClient: ApolloClient<any>,
  timelineEntryId: number,
  tags: DocumentTag[],
) => {
  const cacheId = apolloClient.cache.identify({
    __typename: 'TimelineEntryObject',
    id: timelineEntryId,
  });
  const entry = apolloClient.readFragment({
    id: cacheId,
    fragment: GET_ENTRY_OBJECT,
  });

  const newDocumentTags = tags.map((tag) => {
    return {
      __typename: 'DocumentTag',
      ...tag,
    };
  });

  apolloClient.writeFragment({
    id: cacheId,
    fragment: GET_ENTRY_OBJECT,
    data: { ...entry, document_tags: newDocumentTags },
  });
};

const GET_ENTRY_OBJECT = gql`
  fragment EntryUpdateFragment on TimelineEntryObject {
    document_tags {
      tag_id
      name
      category
    }
  }
`;
