import { Box, Typography } from '@mui/material';
import Thumbnail from '../../../components/Thumbnail';
import OverflowText from '../../../components/common/OverflowText';
import useDisplayStore from '../useDisplayStore';
import PageDuplicateControl from '../../Page/components/PageDuplicateControl';
import PageFlagControl from '../../Page/components/PageFlagControl';
import PageViewedControl from '../../Page/components/PageViewedControl';

export default function ThumbnailPage({
  caseID,
  page,
  pageSourceObj,
  isCurrentPage,
  isInTimelineView,
  pageControls: { handleToggleViewed, handleToggleFavourite },
  showFlagAsImportant,
  searchMatches,
}) {
  const { pageNumber } = page;
  const showThumbnailSource = useDisplayStore((state) => state.showThumbnailSource);
  const thumbnailSize = useDisplayStore((state) => state.thumbnailSize);
  return (
    <Box sx={{ maxWidth: 3 * thumbnailSize }}>
      <Box sx={{ display: 'flex', ml: '.25rem' }}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', mt: '3px' }}>
          {isInTimelineView && (
            <PageViewedControl page={page} handleToggleViewed={handleToggleViewed} />
          )}

          <Typography variant="body2">{pageNumber}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Box>
            <PageDuplicateControl page={page} caseID={caseID} />
          </Box>
          {showFlagAsImportant && (
            <PageFlagControl
              pageID={page.id}
              isFavourite={page.isFavourite}
              onClick={handleToggleFavourite}
            />
          )}
        </Box>
      </Box>
      {showThumbnailSource && (
        <OverflowText
          sx={{
            maxWidth: 3 * thumbnailSize,
            fontSize: '0.75rem',
          }}
          placement="right"
        >
          {isInTimelineView ? page?.documentFileName : pageSourceObj?.name}
        </OverflowText>
      )}
      <Thumbnail
        page={page}
        id={page.id}
        caseID={caseID}
        isCurrentPage={isCurrentPage}
        searchMatches={searchMatches}
      />
    </Box>
  );
}
