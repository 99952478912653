import { Box, Typography, TextField, Tooltip } from '@mui/material';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useGetDocumentNames } from '../api-queries/useGetDocumentNames';
import { useUpdateDocumentName } from '../../ReportEditor/api-queries/useUpdateDocumentName';
import { TimelineEntry } from '../types/timelineTypes';
import { useAnnotationTextCaptureContext } from '../hooks/useAnnotationTextCapture';
import Reticle from '../../../components/icons/Reticle';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';

const DocumentNameDisplay = React.memo(function DocumentNameDisplay({
  caseID,
  fileID,
  entry,
  disableLink,
  setDisableLink,
}: Props) {
  const {
    data: documentIDs,
    isLoading: areDocumentIDsLoading,
    refetch: refetchDocumentNames,
  } = useGetDocumentNames(caseID ?? '', fileID ?? '');
  const isFileProcessor: boolean = useIsFileProcessor();

  const { toggleIsScreenCapturing, capturedDocumentNameByDocumentID } =
    useAnnotationTextCaptureContext();

  const textCapturedDocumentName: string | null = useMemo(() => {
    return capturedDocumentNameByDocumentID[String(entry.id)] ?? null;
  }, [capturedDocumentNameByDocumentID[String(entry.id)], entry.id]);

  const getDocumentName = useMemo(() => {
    if (areDocumentIDsLoading) {
      return 'Loading...';
    }

    const documentNameFromIDs = documentIDs?.find(
      (doc) => doc.timelineEntryId === String(entry?.id),
    )?.documentName;
    return documentNameFromIDs ?? 'No Document Name';
  }, [documentIDs]);

  const [documentName, setDocumentName] = useState(getDocumentName);

  const editDocumentName = useUpdateDocumentName();

  useEffect(() => {
    const name = getDocumentName;
    if (documentName !== name) {
      setDocumentName(name);
    }
  }, [documentIDs]);

  useEffect(() => {
    const updateDocumentName = async () => {
      if (textCapturedDocumentName == null) {
        return;
      }
      const prevDocumentName = getDocumentName;

      try {
        if (textCapturedDocumentName !== prevDocumentName) {
          await editDocumentName(BigInt(entry?.id), textCapturedDocumentName);
          await refetchDocumentNames();
          if (!isFileProcessor) {
            toast.success('Successfully updated document name.');
          }
          toggleIsScreenCapturing('document-name');
        }
      } catch (error) {
        setDocumentName(prevDocumentName);
        toast.error('There was an error updating the document name.');
      }
      setTimeout(() => setDisableLink(false), 500);
    };

    updateDocumentName();
  }, [textCapturedDocumentName]);

  const handleNameClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDisableLink(true);
  }, []);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentName(event.target.value);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleBlur();
    }
  };

  const handleBlur = useCallback(async () => {
    const prevDocumentName = getDocumentName;
    try {
      if (documentName !== prevDocumentName) {
        await editDocumentName(BigInt(entry?.id), documentName);
        if (!isFileProcessor) {
          toast.success('Successfully updated document name.');
        }
        await refetchDocumentNames();
      }
    } catch (error) {
      setDocumentName(prevDocumentName);
      toast.error('There was an error updating the document name.');
    }
    // use timeout to prevent a flicker when updating the state
    setTimeout(() => setDisableLink(false), 500);
  }, [editDocumentName]);

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      {isFileProcessor && (
        <span
          onClick={() => toggleIsScreenCapturing('document-name')}
          style={{
            marginRight: '0.25rem',
            cursor: 'pointer',
          }}
        >
          <Reticle />
        </span>
      )}
      <Box
        sx={{
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          verticalAlign: 'top',
        }}
      >
        {disableLink ? (
          <TextField
            value={documentName}
            onChange={handleNameChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            sx={{
              maxHeight: '20px',
              overflowY: 'visible',
              marginTop: '0.5rem',
              border: 'none',
              alignContent: 'center',
              mx: 'auto',
              width: '100%',
              '& .MuiAutocomplete-inputRoot': {
                border: 'none',
                borderRadius: 0,
                padding: 0,
                margin: 0,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '.MuiInputBase-input': {
                  fontSize: '0.85rem',
                  fontWeight: 600,
                  padding: 0,
                  margin: 0,
                },
              },
            }}
          />
        ) : (
          <Tooltip title={documentName} placement="bottom-start">
            <Typography
              sx={{
                fontSize: '0.85rem',
                fontWeight: 600,
                color: '#002147',
                display: 'block',
                marginTop: '0.4rem',
                marginBottom: '-0.28rem',
                paddingTop: '0.1rem',
                paddingBottom: '0.25rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                minHeight: '26px',
              }}
              onClick={handleNameClick}
            >
              {documentName}
            </Typography>
          </Tooltip>
        )}
      </Box>
    </div>
  );
});

export default DocumentNameDisplay;

type Props = {
  caseID: string;
  fileID: string;
  entry: TimelineEntry;
  disableLink: boolean;
  setDisableLink: (disable: boolean) => void;
};
