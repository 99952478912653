import { DescriptionOutlined, FindInPageOutlined, Image } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useUserGroup } from '../MyCases/useCases';
import Folder from '../../components/icons/Folder';
import DuplicateHeaderIcon from '../../components/icons/DuplicateHeader';
import Export from '../../components/icons/Export';
import useNavigationStore from './useNavigationStore';
import useReportsStore from '../ReportEditor/useReportsStore';
import PagesRead from './PagesRead';
import Theme from '../../theme';
import ProcessingFiles from '../../components/icons/ProcessingFiles';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import NavigationTab from '../../components/common/NavigationTab.tsx';
import FileViewer from './FileViewer';
import useCaseFiles from '../Files/useCaseFiles';
import { useImagePages } from '../DocumentGrouping/api-hooks/useGetImagePages';

export default function CaseHeader({ caseInstance }) {
  const { data: userGroup } = useUserGroup();
  const location = useLocation();
  const params = useParams();
  const { caseID, fileID: caseFileID } = params;
  const [searchParams] = useSearchParams();
  const currentDocumentID = searchParams.get('documentID') ?? null;

  const isFileProcessor = useIsFileProcessor();
  const navigate = useNavigate();

  const { data: imagePages } = useImagePages(caseID);

  const { setCurrentReport, currentReport, isNotesTableOpen, setIsNotesTableOpen } =
    useReportsStore(
      (state) => ({
        setCurrentReport: state.setCurrentReport,
        currentReport: state.currentReport,
        isNotesTableOpen: state.isNotesTableOpen,
        setIsNotesTableOpen: state.setIsNotesTableOpen,
      }),
      shallow,
    );

  const { data: caseFiles } = useCaseFiles(caseID);
  const currentView = useNavigationStore((state) => state.currentView);
  const setCurrentView = useNavigationStore((state) => state.setCurrentView);

  useEffect(() => {
    if (location.pathname.indexOf('/documents') > -1) {
      setCurrentView('Document');
    } else if (location.pathname.indexOf('/timeline') > -1) {
      setCurrentView('Timeline');
    }
  }, []);

  const sourceUrl = useMemo(
    () => `/case/${caseID}/${currentView === 'Document' ? 'documents' : `timeline`}`,
    [currentView, caseID],
  );

  const openAllTabs =
    caseInstance?.caseStatus === 'READY' ||
    caseInstance?.caseStatus === 'CLOSED' ||
    (caseInstance?.caseStatus === 'REQUIRES_PROCESSOR' && userGroup === 'Processors');
  const noFilesUploaded = caseFiles ? caseFiles.length === 0 : true;
  const viewableFiles = useMemo(() => {
    if (!caseFiles) {
      return false;
    }
    if (noFilesUploaded) {
      return false;
    } // ready for processing
    if (isFileProcessor && caseFiles.filter((doc) => doc.fileStatus === 'UPLOADING').length === 0) {
      return true;
    } // viewable to users
    if (caseFiles.filter((doc) => doc.fileStatus === 'COMPLETE').length > 0) {
      return true;
    }
    return false;
  }, [caseFiles]);

  const processingFiles =
    caseInstance?.caseStatus !== 'READY' &&
    caseInstance?.caseStatus !== 'CLOSED' &&
    !isFileProcessor &&
    !noFilesUploaded;

  const currentFile = caseFiles?.find(
    (file) => file.documentID === caseFileID || file.documentID === currentDocumentID,
  );

  return (
    <Box
      width="100%"
      height="4rem"
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        borderBottom: '0.5px solid #00214733',
      }}
    >
      <Box
        sx={{
          marginLeft: '2rem',
        }}
      >
        <Typography
          className="fs-exclude"
          sx={{
            width: '18rem',
            fontSize: '1rem',
            fontWeight: 500,
            overflow: 'auto',
            color: 'primary',
            whiteSpace: 'nowrap',
          }}
        >
          {isFileProcessor
            ? `#${caseInstance?.ref_id} - ${caseInstance?.caseName}${
                location.pathname.includes('/grouping') || location.pathname.includes('/timeline')
                  ? currentFile?.docFileName
                    ? ` - ${currentFile?.docFileName}`
                    : ''
                  : ['/image', '/tagging'].some((path) => location.pathname.includes(path)) &&
                    currentFile?.docFileName
                  ? ` - ${currentFile.docFileName}`
                  : ''
              }`
            : caseInstance?.caseName}
        </Typography>
        <Box sx={{ marginTop: '-0.5rem' }}>
          <PagesRead caseID={caseID} />
        </Box>
      </Box>
      {!isFileProcessor && (
        <NavigationTab
          id="case-header-insights-nav-btn"
          name="Details"
          icon={
            <AssessmentOutlinedIcon
              sx={{ ...navStyles.navIcons, color: navStyles.navIcons.color, fontSize: '22px' }}
            />
          }
          isActive={location.pathname.indexOf('/details') > -1}
          handleClick={() => {
            navigate(`/case/${caseID}/details`);
          }}
        />
      )}
      <NavigationTab
        id="case-header-files-tab-nav-btn"
        name="Files"
        icon={
          <Folder
            sx={{ ...navStyles.navIcons, paddingRight: 0.8, color: navStyles.navIcons.color }}
            color={navStyles.navIcons.color}
          />
        }
        isActive={location.pathname.indexOf('/files') > -1}
        handleClick={() => {
          navigate(`/case/${caseID}/files`);
        }}
      />
      <NavigationTab
        id="case-header-duplicates-tab-nav-btn"
        name="Duplicates"
        useDisabledTextColor={!openAllTabs}
        icon={
          <DuplicateHeaderIcon
            sx={{ ...navStyles.navIcons }}
            color={openAllTabs ? Theme.typography.color : Theme.palette.mediumGrey.light}
          />
        }
        isActive={location.pathname.indexOf('/duplicates') > -1}
        handleClick={() => {
          if (openAllTabs) {
            navigate(`/case/${caseID}/duplicates`);
          }
        }}
        tooltipText="Access to this tab is disabled until the file upload process is complete."
        disableTooltip={userGroup === 'Processors' || openAllTabs || noFilesUploaded}
      />
      <NavigationTab
        id="case-header-review-tab-nav-btn"
        name="Review"
        useDisabledTextColor={!viewableFiles}
        icon={
          <FindInPageOutlined
            sx={{
              ...navStyles.navIcons,
              color: viewableFiles ? Theme.typography.color : Theme.palette.mediumGrey.light,
            }}
          />
        }
        isActive={
          location.pathname.indexOf('/timeline') > -1 ||
          location.pathname.indexOf('/documents') > -1
        }
        handleClick={() => {
          if (viewableFiles) {
            navigate(sourceUrl);
          }
        }}
        tooltipText="Access to this tab is disabled until the file upload process is complete."
        disableTooltip={userGroup === 'Processors' || viewableFiles || noFilesUploaded}
      />
      <NavigationTab
        id="case-header-reports-tab-nav-btn"
        name="Reports"
        useDisabledTextColor={noFilesUploaded}
        icon={
          <DescriptionOutlined
            sx={{
              ...navStyles.navIcons,
              color: !noFilesUploaded ? Theme.typography.color : Theme.palette.mediumGrey.light,
            }}
          />
        }
        isActive={location.pathname.indexOf('/reports') > -1}
        handleClick={() => {
          if (location.pathname.indexOf('/reports') > -1) {
            if (currentReport) {
              setCurrentReport(null);
            } else if (isNotesTableOpen) {
              setIsNotesTableOpen(false);
            }
          }
          if (!noFilesUploaded) {
            navigate(`/case/${caseID}/reports`);
          }
        }}
      />
      {isFileProcessor && (
        <NavigationTab
          id="case-header-images-tab-nav-btn"
          name="Images/Blank Pages"
          useDisabledTextColor={imagePages?.length === 0}
          icon={
            <Image
              sx={{
                ...navStyles.navIcons,
                paddingRight: 0.8,
                color:
                  imagePages?.length > 0
                    ? navStyles.navIcons.color
                    : 'Theme.palette.mediumGrey.light',
              }}
            />
          }
          isActive={location.pathname.indexOf('/images') > -1}
          handleClick={() => {
            if (imagePages?.length > 0) {
              navigate(`/case/${caseID}/images`);
            }
          }}
        />
      )}
      {!isFileProcessor && (
        <NavigationTab
          id="case-header-exports-tab-nav-btn"
          name="Exports"
          icon={
            <Export
              sx={{
                ...navStyles.navIcons,
              }}
              style={{ marginRight: '0.4rem' }}
              color={!noFilesUploaded ? Theme.typography.color : Theme.palette.mediumGrey.light}
            />
          }
          isActive={location.pathname.indexOf('/exports') > -1}
          handleClick={() => {
            if (!noFilesUploaded) {
              navigate(`/case/${caseID}/exports`);
            }
          }}
        />
      )}
      {isFileProcessor &&
        caseFiles &&
        location.pathname.indexOf('/files') === -1 &&
        location.pathname.indexOf('/reports') === -1 &&
        location.pathname.indexOf('/images') === -1 &&
        location.pathname.indexOf('/duplicates') === -1 &&
        location.pathname.indexOf('/details') === -1 && (
          <FileViewer
            caseDocuments={caseFiles.filter((file) => file.fileStatus !== 'COMPLETE')}
            caseID={caseID}
          />
        )}
      {processingFiles && (
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: '2rem',
            textAlign: 'right',
          }}
        >
          <Typography
            sx={{
              width: '18rem',
              fontSize: '1rem',
              fontWeight: 500,
              overflow: 'auto',
              color: Theme.palette.themeOrange.main,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <ProcessingFiles style={{ marginRight: '0.25rem' }} />
            Processing Files
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const navStyles = {
  navIcons: {
    fontSize: '1.3rem',
    marginRight: '0.3rem',
    color: '#002147',
    verticalAlign: 'top',
  },
};
