import React, { useEffect } from 'react';
import {
  Divider,
  Box,
  Tooltip,
  CircularProgress,
  SxProps,
  Typography,
  IconButton,
} from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { DocumentSimilarityRecord, DuplicateGenerationStatus } from '../../api';
import Theme from '../../theme';
import NavigationButton from '../common/PdfNavigation/Components/NavigationButton';
import { useDuplicatesByDocument } from '../../containers/DuplicatesByDocument/DuplicatesByDocument';
import { DuplicatesListChip } from './DuplicatesListChip';
import Loading from '../common/Loading';
import useDuplicateGenerationStatus from '../../containers/DuplicatesByDocument/useDuplicateGenerationStatus';
import IconRefresh from '../icons/IconRefresh';

// Constants
const REFETCH_INTERVAL = 10000;
const LOADING_SPINNER_SIZE = 14;
const DEFAULT_ESTIMATED_MINUTES = 1;

// Styles
const styles = {
  container: {
    backgroundColor: Theme.palette.selectedGrey.main,
    height: '100%',
  },
  header: {
    display: 'inline-block',
    fontWeight: 600,
    fontSize: '20px',
    padding: '5px',
    paddingLeft: '10px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    height: '5%',
  },
  scrollableContent: {
    padding: '10px',
    paddingRight: '5px',
    paddingLeft: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '75%',
    overflowY: 'auto',
  },
  navigationContainer: {
    height: '20%',
    backgroundColor: Theme.palette.selectedGrey.main,
    width: '100%',
    display: 'inline-block',
    textAlign: 'center',
  },
} as const;

// Types
interface TabProps {
  isActive: boolean;
  width: string;
  onClick: () => void;
  label: string;
  count?: number;
}

interface StatusComponentProps {
  status?: DuplicateGenerationStatus;
  version: 'compare_documents' | 'generate_duplicates';
  sx?: SxProps;
  size?: number;
  elapsedMinutes?: number;
  totalEstimatedMinutes?: number;
  isRequestInProgress?: boolean;
  createAllDocumentSimilarities?: () => void;
}

function Tab({ isActive, width, onClick, label, count }: TabProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        width,
        borderBottom: '3px solid',
        borderColor: isActive
          ? Theme.palette.duplicatesByDocumentBlue.main
          : Theme.palette.selectedGrey.main,
        fontSize: isActive ? '14px' : '13px',
        fontWeight: isActive ? 'bold' : 'normal',
        color: isActive ? Theme.palette.duplicatesByDocumentBlue.main : 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        marginLeft: '2px',
      }}
    >
      <span
        style={{
          flex: 1,
          minWidth: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {label} {count !== undefined && `(${count})`}
      </span>
    </Box>
  );
}

function StatusComponent({
  status,
  version = 'compare_documents',
  sx,
  size = LOADING_SPINNER_SIZE,
  isRequestInProgress,
  createAllDocumentSimilarities,
}: StatusComponentProps) {
  
  if (version === 'generate_duplicates') {
    return (
      <span>
        <IconButton
          sx={{
            ml: '3rem',
          }}
          disabled={isRequestInProgress}
          onClick={createAllDocumentSimilarities}
        >
          <IconRefresh />
        </IconButton>
      </span>
    );
  }

  const statusComponents = {
    [DuplicateGenerationStatus.PENDING]: (
      <Tooltip title="Generating document similarities...">
        <CircularProgress size={size} sx={{ mx: 1, ...sx }} />
      </Tooltip>
    ),
    [DuplicateGenerationStatus.COMPLETED]: (
      <Tooltip title="Document similarities generated.">
        <CheckIcon sx={{ mx: 1, ...sx }} />
      </Tooltip>
    ),
    [DuplicateGenerationStatus.FAILED]: (
      <Tooltip title="Failed to generate document similarities.">
        <ErrorIcon sx={{ mx: 1, color: Theme.palette.error.main, ...sx }} />
      </Tooltip>
    ),
    [DuplicateGenerationStatus.NOT_STARTED]: (
      <Tooltip title="Please complete grouping before generating document similarities.">
        <DoNotDisturbOnIcon sx={{ mx: 1, color: Theme.palette.error.main, ...sx }} />
      </Tooltip>
    ),
  };

  return (
    statusComponents[status as keyof typeof statusComponents] ?? (
      <Tooltip title="Fetching status...">
        <CircularProgress size={size} sx={{ mx: 1, ...sx }} />
      </Tooltip>
    )
  );
}

interface DuplicatesListProps {
  caseID: string;
  documentSimilaritiesList: DocumentSimilarityRecord[];
  navigateTo: (id: string) => void;
  currentSimilarity: DocumentSimilarityRecord | null;
  showResolved: () => void;
  createAllDocumentSimilarities: () => void;
  isRequestInProgress: boolean;
  showUnresolved: () => void;
  count: number | null;
  currentPage: number;
  nextPage: () => void;
  previousPage: () => void;
  hideResolved: boolean;
  isFileProcessor: boolean;
}

function DuplicatesList({
  caseID,
  documentSimilaritiesList,
  navigateTo,
  currentSimilarity,
  count,
  nextPage,
  previousPage,
  currentPage,
  showResolved,
  createAllDocumentSimilarities,
  isRequestInProgress,
  showUnresolved,
  hideResolved,
  isFileProcessor,
}: DuplicatesListProps) {
  const { pageSize } = useDuplicatesByDocument();
  const { data: duplicateGenerationStatus, refetch } = useDuplicateGenerationStatus({
    caseID,
  });


  useEffect(() => {
    if (duplicateGenerationStatus?.data?.status === DuplicateGenerationStatus.PENDING) {
      const interval = setInterval(refetch, REFETCH_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [duplicateGenerationStatus?.data?.status, refetch]);

  const renderContent = () => {
    if (count === null) {
      return (
        <div style={{ height: '100%' }}>
          <Loading customStyles={{ marginTop: '90%' }} />
        </div>
      );
    }

    return (
      <div style={styles.scrollableContent}>
        {documentSimilaritiesList
          .filter((similarity) => isFileProcessor || similarity.status !== 'NotDuplicate')
          .map((similarity, index) => (
            <DuplicatesListChip
              key={`similarity-${index}`}
              selectedSimilarity={currentSimilarity}
              similarity={similarity}
              navigateTo={navigateTo}
            />
          ))}
      </div>
    );
  };

  const renderNavigation = () => {
    if (count === null) {
      return null;
    }

    const totalPages = count === 0 ? 1 : Math.ceil(count / pageSize);
    const isLastPage = currentPage - 1 === Math.floor(count / pageSize);

    return (
      <div
        style={{
          ...styles.navigationContainer,
          paddingTop: isFileProcessor ? '6px' : '10%',
        }}
      >
        <Tooltip title="Previous page">
          <span>
            <NavigationButton
              // @ts-ignore 
              onClick={previousPage}
              icon={<KeyboardArrowLeft sx={{ fontSize: '1rem' }} />}
              disabled={currentPage === 1}
              sx={{ marginLeft: '.5rem', marginRight: '.5rem' }}
            />
          </span>
        </Tooltip>
        {`${currentPage} / ${totalPages}`}
        <Tooltip title="Next page">
          <span>
            <NavigationButton
              // @ts-ignore
              onClick={nextPage}
              icon={<KeyboardArrowRight sx={{ fontSize: '1rem' }} />}
              disabled={isLastPage}
              sx={{ marginLeft: '.5rem', marginRight: '1rem' }}
            />
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        Duplicates
      </div>

      {isFileProcessor && (
        <StatusComponent
          version={duplicateGenerationStatus?.data.version ?? 'compare_documents'}
          status={duplicateGenerationStatus?.data.status}
          isRequestInProgress={isRequestInProgress}
          createAllDocumentSimilarities={createAllDocumentSimilarities}
        />
      )}

      <Divider />

      <div style={styles.contentContainer}>
        {isFileProcessor && (
          <div style={styles.tabContainer}>
            <Tab
              isActive={hideResolved}
              width={hideResolved ? '65%' : '35%'}
              onClick={showUnresolved}
              label="Unresolved"
              count={hideResolved && count !== null ? count : undefined}
            />
            <Tab
              isActive={!hideResolved}
              width={!hideResolved ? '65%' : '35%'}
              onClick={showResolved}
              label="Resolved"
              count={!hideResolved && count !== null ? count : undefined}
            />
          </div>
        )}

        {renderContent()}
        {renderNavigation()}
      </div>
    </div>
  );
}

export default DuplicatesList;
