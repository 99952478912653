import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/system';
import { TextField } from '@mui/material';
import { File } from '../types';
import useDocumentSearchStore from '../../Timeline/useDocumentSearchStore';
import LinkWithQuery from '../../Timeline/LinkWithQuery';
import OverflowText from '../../../components/common/OverflowText';

type Props = {
  isEditing: boolean;
  editedFileName: string;
  setEditedFileName: (value: string) => void;
  handleRenameFile: (selectedFile: File, name: string) => void;
  fileStatus: string;
  fileName: string;
  fileID: string;
  selectedFile: File | null;
};

export default function EditableFileName({
  isEditing,
  editedFileName,
  setEditedFileName,
  handleRenameFile,
  fileStatus,
  fileName,
  fileID,
  selectedFile,
}: Props) {
  const navigate = useNavigate();

  const [filters, setFilters] = useDocumentSearchStore((state) => [
    state.filters,
    state.setFilters,
  ]);
  const navigateToReviewTab = () => {
    setFilters({
      ...filters,
      documentID: [fileID],
    });
    navigate(`../timeline`);
  };
  return (
    <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
      {isEditing ? (
        <TextField
          defaultValue={editedFileName}
          onChange={(e) => setEditedFileName(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter' && selectedFile != null) {
              handleRenameFile(selectedFile, e.target.value);
            }
          }}
          autoFocus
          sx={{ mt: 0.8 }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            '&:hover': {
              '& svg': {
                opacity: 1,
              },
            },
          }}
        >
          {fileStatus === 'COMPLETE' ? (
            <LinkWithQuery to="../timeline" documentID={null} onClick={navigateToReviewTab}>
              <OverflowText>{fileName}</OverflowText>
            </LinkWithQuery>
          ) : (
            fileName
          )}
        </Box>
      )}
    </Stack>
  );
}
