import { useCallback, useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { debounce } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentPreviewer } from '../../../containers/ReportEditor/IndexReports/DocumetPreviewer/DocumentPreviewer';
import { useUpdatePageRotationMutation } from '../../../__generated__/graphql';
import useEntryPages from '../../../containers/Timeline/useEntryPages';
import { usePreviewPagesNew } from '../../../containers/ReportEditor/IndexReports/DocumetPreviewer/usePreviewPagesNew';
import { WordBlock } from '../../../api';

type Props = {
  documentId: number;
  containerId: string;
  style: any;
  pageHeader: any;
  sharedZoom: number;
  isFileProcessor?: boolean;
  isDocumentDuplicate: boolean;
  setDocumentPageCount?: (count: number) => void;
  markAsDuplicate?: () => void;
  isUpdating: boolean;
  wordHighlights?: WordBlock[];
  onPageChange?: (id: string) => void;
};

type PageRotation = { id: string; rotation_angle: number };

export default function PageScroller({
  documentId,
  isUpdating,
  containerId,
  isDocumentDuplicate,
  style,
  pageHeader,
  sharedZoom,
  markAsDuplicate,
  setDocumentPageCount = () => {},
  isFileProcessor,
  wordHighlights,
  onPageChange,
}: Props) {
  const queryClient = useQueryClient();
  const params = useParams();
  const caseID = params.caseID!;

  const { isLoading: isLoadingRotations, data: entryPages } = useEntryPages({
    entryID: documentId,
  });
  const [pageRotationMutation] = useUpdatePageRotationMutation();
  const { updatePagePreviewCache, previewPages } = usePreviewPagesNew();

  const [dynamicWidth, setDynamicWidth] = useState(() => calculateWidth());
  // Function to calculate width based on window width
  function calculateWidth(): number {
    if (window.innerWidth > 2700) {
      return 1100;
    }
    if (window.innerWidth > 2100) {
      return 850;
    }
    if (window.innerWidth > 1700) {
      return 700;
    }
    if (window.innerWidth > 1440) {
      return 545;
    }
    return 545;
  }

  useEffect(() => {
    const handleResize = debounce(() => {
      setDynamicWidth(calculateWidth());
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (previewPages?.length) {
      setDocumentPageCount(previewPages.length);
    }
  }, [previewPages]);

  const saveRotation = async (pageID: string, newRotation: number) => {
    try {
      pageRotationMutation({
        variables: {
          data: { pageID, rotation_angle: newRotation },
        },
      });
      queryClient.cancelQueries(['pages', documentId]);
      const previousPages = queryClient.getQueryData(['pages', documentId]);
      const newPagesArr = previousPages as Array<PageRotation>;
      const pageIndex = newPagesArr.findIndex((p) => +p.id === +pageID);
      newPagesArr[pageIndex] = {
        ...newPagesArr[pageIndex],
        rotation_angle: newRotation,
      };
      queryClient.setQueryData(['pages', documentId], newPagesArr);
      updatePagePreviewCache(String(pageID), {
        rotation_angle: newRotation,
      });
      if (!isFileProcessor) {
        toast.success('Page rotation saved');
      }
    } catch (error) {
      toast.error('Failed to save page rotation');
      Sentry.captureException(error);
    }
  };

  const getVisiblePageId = useCallback(() => {
    const container = document.getElementById(containerId);
    if (!container) {
      console.error(`Container with id ${containerId} not found`);
      return null;
    }

    const images = Array.from(container.querySelectorAll('.image')) as HTMLImageElement[];
    const containerRect = container.getBoundingClientRect();
    const containerArea = containerRect.width * containerRect.height;
    let maxVisibleArea = 0;
    let mostVisiblePage: HTMLImageElement | null = null;

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const rect = image.getBoundingClientRect();
      const visibleArea =
        ((Math.min(rect.right, containerRect.right) - Math.max(rect.left, containerRect.left)) *
          (Math.min(rect.bottom, containerRect.bottom) - Math.max(rect.top, containerRect.top))) /
        containerArea;

      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        mostVisiblePage = image;
      }
    }

    return mostVisiblePage?.id;
  }, []);

  const rotateClockwise = (pageID: string) => {
    const currentRotation =
      previewPages?.find((p) => p.id === pageID)?.pageDetails.rotation_angle ?? 0;
    if (pageID) {
      saveRotation(pageID, currentRotation === 270 ? 0 : currentRotation + 90);
    }
  };

  const rotateCounterClockwise = (pageID: string) => {
    const currentRotation =
      previewPages?.find((p) => p.id === pageID)?.pageDetails.rotation_angle ?? 0;
    if (pageID) {
      saveRotation(pageID, currentRotation === 0 ? 270 : currentRotation - 90);
    }
  };

  const [currentPageID, setCurrentPageID] = useState<string | null>(
    entryPages?.length > 0 ? entryPages[0].id : null,
  );

  const scrollRef = useRef<HTMLDivElement>(null);

  const getCurrentPageID = useCallback(
    () => setCurrentPageID(getVisiblePageId() ?? null),
    [getVisiblePageId],
  );

  useEffect(() => {
    if (entryPages?.length > 0) {
      setCurrentPageID(String(entryPages[0].id));
    }
  }, [entryPages]);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.addEventListener('scrollend', getCurrentPageID);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scrollend', getCurrentPageID);
      }
    };
  }, [scrollRef, entryPages]);

  useEffect(() => {
    if (onPageChange && currentPageID != null) {
      onPageChange(currentPageID);
    }
  }, [currentPageID]);

  return (
    <div style={{ display: 'flex' }} id={containerId}>
      <div
        id="scrollable-viewer"
        className="scrollable-viewer"
        ref={scrollRef}
        style={{
          ...style,
          overflowX: 'scroll',
          width: dynamicWidth + 35,
        }}
      >
        {documentId && (
          <DocumentPreviewer
            isFileProcessor={isFileProcessor}
            isUpdating={isUpdating}
            markAsDuplicate={markAsDuplicate}
            isDocumentDuplicate={isDocumentDuplicate}
            zoom={sharedZoom}
            entryPages={entryPages}
            documentIds={[String(documentId)]}
            currentPageID={currentPageID}
            isLoadingRotations={isLoadingRotations}
            hideBlanks={true}
            pageHeader={pageHeader}
            rotateCounterClockwise={rotateCounterClockwise}
            rotateClockwise={rotateClockwise}
            extractedDates={[]}
            selectedDate={null}
            setSelectedDate={() => {}}
            caseId={caseID}
            wordHighlights={wordHighlights}
          />
        )}
      </div>
    </div>
  );
}
