import { useQuery } from '@tanstack/react-query';
import { getDuplicateProcessingStatus } from '../../api';

export default function useDuplicateGenerationStatus({ caseID }: { caseID: string }) {
  return useQuery(
    ['duplicateGenerationStatus', caseID],
    () => getDuplicateProcessingStatus(caseID),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  );
}
