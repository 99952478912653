/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import _ from 'lodash';
import Loading from '../../../components/common/Loading';
import useReportsStore from '../useReportsStore';
import TimelineEntriesTableSection from './IndexReportTableSection';
import EditHoverCellEffect from '../../../components/common/Table/EditHoverCellEffect';
import { CustomDataGridProProps } from '../../../components/common/Table/CustomDataGridTypes';
import ExportTimelineReportButton from './ExportTimelineReportButton';
import useContentTypesAndSpecialities from '../../Timeline/gql/useContentTypesAndSpecialities';
import theme from '../../../theme';
import UploadDateFilterModal from './UploadDateFilterModal';
import FilterButton from '../../../components/common/FilterButton';
import { DocumentPreviewerModal } from './DocumetPreviewer/DocumentPreviewer';
import { EditableTimelineEntriesReportTitle } from './EditableIndexReportTitle';
import { EntityEditCell } from './TableCellComponents/EntityEditCell';
import { DocumentTypeEditCell } from './TableCellComponents/DocumentTypeEditCell';
import { DocumentTypeCell } from './TableCellComponents/DocumentTypeCell';
import { DocumentNameEdit } from './TableCellComponents/DocumentNameEdit';
import './timeline-entries-table.css';
import IndexTableActions from './TableCellComponents/IndexTableActions';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import { isRowEditable, processorFileStatusLabels } from './utils/tableUtils';
import DocumentComparisonModal from './DocumentComparisonModal/DocumentComparisonModal';
import { MonetaryTotalEdit } from './TableCellComponents/MonetaryTotalEdit';
import { useIndexReport } from './useIndexReport';
import { useActivityLog } from '../../../components/ActivityTracker/ActivityTracker';
import useCaseFiles from '../../Files/useCaseFiles';
import FeedbackPopup from '../../../components/common/FeedbackPopup';
import { PageDate } from '../../../__generated__/graphql';
import { ConfigureIcon } from '../../../components/icons/Configure';
import SwitchDropdownMenu from '../../../components/common/HTML_components/AnchoredDropdownMenu/SwitchDropdownMenu';
import DateRangeSelector from '../../Timeline/Components/DateRangeSelector';
import { PreviewPagesWrapper } from './DocumetPreviewer/usePreviewPagesNew';
import DocumentNameCell from './TableCellComponents/DocumentNameCell';
import ExpandRowsCell from './TableCellComponents/ExpandRowsCell';
import { formatSegmentDate } from '../../../library/utilities/useDates';

const SHOULD_EXCLUDE_REPEATED_ITEMS = true;

type Props = {
  currentReport: { id: string };
  returnToReports: () => void;
};

type Option = {
  label: string;
  value: string;
};

export type PreviewEntryDetails = {
  sourceID: number;
  contentTags: Option[];
  sectionId: string;
  entryDate: string;
  startDate: string | null;
  endDate: string | null;
  documentName: string;
  isHidden: boolean;
  author: { id: number | null; name: string | null; label: string | null };
  organization: { id: number | null; name: string | null; label: string | null };
  monetary_total?: string;
  markedImportant?: boolean;
  file_id: string;
  extracted_dates?: PageDate[];
  documents?: IndexRow[];
  'group-type'?: 'collapsed' | 'attachments';
};

export default function IndexReportTable({ currentReport, returnToReports }: Props) {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const logUserActivity = useActivityLog();
  const configureRef = React.useRef(null);
  const [previewDocumentId, setPreviewDocumentId] = useState<string[] | null>(null);
  const [previewEntryDetails, setPreviewEntryDetails] = useState<PreviewEntryDetails>({
    sourceID: 0,
    contentTags: [],
    sectionId: '',
    entryDate: '',
    documentName: '',
    author: { id: null, name: null, label: null },
    organization: { id: null, name: null, label: null },
    isHidden: false,
    monetary_total: '',
    markedImportant: false,
    extracted_dates: [],
    file_id: '',
  });
  const isFileProcessor = useIsFileProcessor();

  const {
    data: reportData,
    isLoading,
    shouldShowDuplicates,
    shouldShowHiddenPages,
    shouldShowEmptySections,
    configureOptions,
    uploadDates,
    setUploadDates,
    updateIndexReportName,
    updateIndexReportSectionName,
    areIndexSectionsEmpty,
    hasCollapsedDocuments,
    sortModel,
    handleSortChange,
    updateIndexRow,
    updateIndexRowCache,
    handleUpdateDuplicateDocumentV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    clearDocumentComparisonIDs,
    closeDocumentComparerModal,
    handleSetFirstDocumentComparisonID,
    handleSetSecondDocumentComparisonIDAndOpenModal,
    isDocumentComparerModalOpen,
    documentComparerDocumentIds,
    setDocumentComparerDocumentIds,
    handleUpdateTimelineEntryFromDocumentPreview,
    refreshIndexReport,
    handleChangeImportanceMark,
    getNextDocument,
    lastDocumentOfReport,
    firstDocumentOfReport,
    areSectionsGrouped,
    handleUpdateDocumentBookmark,
    closedRowIds,
    setClosedRowIds,
    bulkHideSelectedDocuments,
    totalPagesInIndex,
    includesSummaries,
  } = useIndexReport(currentReport.id);

  const allContentTags = useContentTypesAndSpecialities();
  const { data: caseFiles } = useCaseFiles(reportData.case_id);

  useEffect(() => {
    if (reportData?.case_id) {
      logUserActivity({
        activity: 'case:index',
        case_id: reportData?.case_id,
        index_report_id: reportData?.id,
      });
    }
  }, [reportData?.case_id, reportData?.id]);

  const { isDocumentComparisonSelectionInProgress, shouldSecondComparisonButtonBeHidden } =
    useMemo(() => {
      const isDocumentComparisonSelectionInProgress =
        documentComparerDocumentIds.firstDocumentID !== undefined &&
        documentComparerDocumentIds.secondDocumentID === undefined;
      const shouldSecondComparisonButtonBeHidden =
        documentComparerDocumentIds.firstDocumentID === reportData?.id;
      return { isDocumentComparisonSelectionInProgress, shouldSecondComparisonButtonBeHidden };
    }, [documentComparerDocumentIds, reportData?.id]);

  const [comparisonIsInProgress, setComparisonIsInProgress] = useState(false);

  useEffect(() => {
    if (
      isDocumentComparisonSelectionInProgress ||
      documentComparerDocumentIds.secondDocumentID !== undefined
    ) {
      setComparisonIsInProgress(!comparisonIsInProgress);
    } else {
      setComparisonIsInProgress(comparisonIsInProgress);
    }
  }, [isDocumentComparisonSelectionInProgress, documentComparerDocumentIds]);

  const handleCancelComparison = () => {
    clearDocumentComparisonIDs();
    setComparisonIsInProgress(false);
  };

  const [contentTagOptions, deprecatedTagOptions] = useMemo(() => {
    const contentTags = [];
    const deprecatedTags = [];

    allContentTags.documentTagList.forEach((tag) => {
      const mappedTag = {
        value: String(tag.tag_id),
        label: tag.name,
        deprecated: tag.deprecated,
      };
      if (tag.deprecated) {
        deprecatedTags.push(mappedTag);
      } else {
        contentTags.push(mappedTag);
      }
    });
    return [contentTags, deprecatedTags];
  }, [allContentTags.documentTagList]);

  const { setCurrentReport } = useReportsStore((state) => ({
    setCurrentReport: state.setCurrentReport,
  }));

  // can we just get rid of this part?
  const shouldDisableIndexExport = useMemo(() => {
    if (areIndexSectionsEmpty == null) {
      return true;
    }
    return areIndexSectionsEmpty;
  }, [areIndexSectionsEmpty]);

  const uploadDatesArray = useMemo(() => {
    if (!caseFiles) {
      return [];
    }

    const uniqueDates = new Set<string>();

    caseFiles.forEach((file) => {
      const date = file.uploadDate ? new Date(file.uploadDate).toISOString().split('T')[0] : null;
      if (date) {
        uniqueDates.add(date);
      }
    });

    const allUploadDates = Array.from(uniqueDates).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
    if (uploadDates.length === 0) {
      setUploadDates(allUploadDates);
    }
    return allUploadDates;
  }, [caseFiles]);

  const timelineEntriesTableConfig: CustomDataGridProProps = {
    columnOptions: {
      id: {
        headerName: 'ID',
        field: 'id',
        flex: 0.25,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            params.row['group-type'] !== 'collapsed' && (
              <div style={{ alignContent: 'center', height: '100%' }}>
                <Tooltip title={params.row.id} arrow>
                  <span>{params.row.id}</span>
                </Tooltip>
              </div>
            )
          );
        },
      },
      'document-name': {
        headerName: 'Document Name',
        field: 'document-name',
        flex: 0.7,
        editable: true,
        renderCell: (params) => (
          <DocumentNameCell {...params} onBookmarkClick={handleUpdateDocumentBookmark} />
        ),
        renderEditCell: (params) => <DocumentNameEdit {...params} defaultValue={params.value} />,
        disableColumnMenu: true,
      },
      summary: {
        headerName: 'Summary',
        field: 'summary',
        flex: 3,
        editable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Typography
            sx={{
              whiteSpace: 'wrap',
              fontSize: '0.8rem',
              marginY: '0.5rem',
            }}
          >
            {params.row.summary}
          </Typography>
        ),
      },
      'document-tags': {
        headerName: 'Tags',
        field: 'document-tags',
        flex: 0.9,
        editable: true,
        renderCell: (params) => <DocumentTypeCell {...params} />,
        renderEditCell: (params) => (
          <DocumentTypeEditCell
            {...params}
            contentTagOptions={contentTagOptions}
            deprecatedTagOptions={deprecatedTagOptions}
            isEditable={isRowEditable(params.row)}
          />
        ),
        disableColumnMenu: true,
      },
      organization: {
        headerName: 'Organization',
        field: 'organization',
        flex: 0.57,
        editable: true,
        disableColumnMenu: true,
        valueGetter: (value) => value ?? '',
        renderEditCell: (params) => (
          <EntityEditCell entityType="organization" {...params} caseID={reportData?.case_id} />
        ),
        renderCell: (params) => (
          <EditHoverCellEffect
            params={params}
            cellValue={params.value}
            isEditable={isRowEditable(params.row)}
          />
        ),
      },
      author: {
        headerName: 'Author',
        field: 'author',
        flex: 0.57,
        editable: true,
        disableColumnMenu: true,
        valueGetter: (value) => value ?? '',
        renderEditCell: (params) => {
          return <EntityEditCell entityType="author" {...params} caseID={reportData?.case_id} />;
        },
        renderCell: (params) => (
          <EditHoverCellEffect
            params={params}
            cellValue={params.value}
            isEditable={isRowEditable(params.row)}
          />
        ),
      },
      'document-date': {
        headerName: 'Date',
        field: 'document-date',
        flex: 0.48,
        editable: true,
        type: 'string',
        valueGetter: (value, row) => {
          const startDate = row?.['start-date'];
          const endDate = row?.['end-date'];
          let displayDate =
            startDate && startDate !== '1900-01-01' ? formatSegmentDate(startDate) : 'No Date';
          if (endDate) {
            displayDate += ` - ${formatSegmentDate(endDate)}`;
          }
          return displayDate;
        },
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <EditHoverCellEffect
              params={params}
              cellValue={params.value}
              multiLine={true}
              isEditable={isRowEditable(params.row)}
            />
          );
        },
        renderEditCell: (params) => (
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <DateRangeSelector
              {...params}
              entryStartDate={params.row['start-date']}
              entryEndDate={params.row['end-date']}
              entryID={params.row.id}
              orientation="horizontal"
              isPopUp={true}
              shouldOpenByDefault={true}
            />
          </Box>
        ),
      },
      'number-pages': {
        headerName: 'Pages',
        field: 'number-pages',
        flex: 0.25,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <div style={{ alignContent: 'center', height: '100%' }}>
              {params.row['number-pages']}
            </div>
          );
        },
      },
      'file-status': {
        headerName: 'File Status',
        field: 'file-status',
        flex: 0.4,
        disableColumnMenu: true,
        renderCell: (params) => {
          const value = params.row['file-status'];
          const displayValue =
            value in processorFileStatusLabels
              ? processorFileStatusLabels[value as keyof typeof processorFileStatusLabels]
              : value;

          return (
            <div
              style={{
                alignContent: 'center',
                height: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {displayValue}
            </div>
          );
        },
      },
      goToSource: {
        headerName: 'Actions',
        headerAlign: 'center',
        field: 'goToSource',
        flex: 0.54,
        sortable: false,
        renderCell: (params) => {
          return (
            <IndexTableActions
              isEditable={isRowEditable(params.row)}
              file_id={params?.row?.file_id}
              timelineEntryID={params.row.id}
              firstPageID={params.row.first_page_id}
              setPreviewDocumentID={setPreviewDocumentId}
              handleSetFirstDocumentComparisonID={handleSetFirstDocumentComparisonID}
              handleSetSecondDocumentComparisonIDAndOpenModal={
                handleSetSecondDocumentComparisonIDAndOpenModal
              }
              clearDocumentComparisonIDs={clearDocumentComparisonIDs}
              documentComparerDocumentIds={documentComparerDocumentIds}
              sourceID={params.row.source_id}
              contentTags={params.row['document-tags']}
              setPreviewEntryDetails={setPreviewEntryDetails}
              sectionId={params.row.section_id}
              startDate={params.row['start-date']}
              endDate={params.row['end-date']}
              documentName={params.row['document-name']}
              monetary_total={params.row['monetary-total']}
              author={{ id: Number(params.row.author_id), name: params.row.author }}
              organization={{ id: Number(params.row.org_id), name: params.row.organization }}
              isHidden={params.row.is_hidden === 1}
              markedImportant={params.row.marked_important}
              isFileProcessor={isFileProcessor}
              allPagesMarkedAsDuplicate={params.row.all_pages_marked_duplicate}
              handleChangeImportanceMark={handleChangeImportanceMark}
              updateIndexRowCache={updateIndexRowCache}
              row={params.row}
            />
          );
        },
        disableColumnMenu: true,
      },
      'monetary-total': {
        headerName: 'Amount',
        field: 'monetary-total',
        flex: 0.4,
        editable: true,
        sortable: false,
        renderCell: (params) => {
          const value = params.value ? `$${params.value}` : '';
          return (
            <EditHoverCellEffect
              params={params}
              cellValue={value}
              isEditable={isRowEditable(params.row)}
            />
          );
        },
        renderEditCell: (params) => <MonetaryTotalEdit {...params} defaultValue={params.value} />,
        disableColumnMenu: true,
      },
    },
    loading: isLoading,
    slots: {
      loadingOverlay: TableLoadingIcon,
    },
    pagination: true,
    pageSizeOptions: [5, 20, 50, 100],
    initialState: {
      pagination: { paginationModel: { pageSize: 20 } },
    },
    disableColumnResize: true,
    // set to server so we can manually control sorting
    sortingMode: 'server',
    getRowId: (row) => row.id,
    localeText: {
      noRowsLabel: "Sorry, we couldn't find any documents that match this section.",
    },
    isCellEditable: (params) => isRowEditable(params.row),
    processRowUpdate: updateIndexRow,
    getRowClassName: (params) => {
      const classNames: string[] = [];

      if (
        params.row.is_hidden === 1 ||
        (isFileProcessor && params.row.all_pages_marked_duplicate)
      ) {
        classNames.push('hidden-row');
      }
      if (isFileProcessor && params.row['file-status'] === 'COMPLETE') {
        classNames.push('complete-row');
      }
      return classNames.join(' ');
    },
    checkboxSelection: true,
    checkboxSelectionVisibleOnly: true,
    isRowSelectable: (params) => params.row['group-type'] !== 'collapsed',
    disableRowSelectionOnClick: true,
    treeData: hasCollapsedDocuments,
    getTreeDataPath: (row) => row.hierarchy,
    groupingColDef: {
      headerName: '',
      flex: 0.1,
      renderCell: (params) => {
        return (
          <ExpandRowsCell
            {...params}
            closedRowIds={closedRowIds}
            setClosedRowIds={setClosedRowIds}
          />
        );
      },
    },
    disableChildrenSorting: true,
    defaultGroupingExpansionDepth: -1,
    isGroupExpandedByDefault: (group) => {
      return !closedRowIds.includes(group.id);
    },
    sx: {
      '& .MuiDataGrid-cell': {
        overflow: 'visible',
      },
      '& .MuiDataGrid-virtualScroller': {
        minHeight: '6rem',
      },
      '& .MuiDataGrid-cell--withBorder': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
        cursor: 'default',
      },
      '& .MuiDataGrid-cell:focus-within': {
        // Tried various ways to avoid - Important required, styling is overridden by MUI otherwise
        outline: `1px solid ${theme.palette.mediumGrey.dark} !important`,
      },
    },
  };

  const onCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleCloseDocumentPreview = () => setPreviewDocumentId(null);

  const navigateDocument = useCallback(
    (direction: 'next' | 'previous') => {
      const nextDocument = getNextDocument(
        direction,
        previewDocumentId?.length ? previewDocumentId[0] : '',
        previewEntryDetails.sectionId,
      );

      if (nextDocument) {
        const nextId = nextDocument.documents?.length
          ? [
              nextDocument.id,
              ...nextDocument.documents.flatMap((doc: IndexRow) => [
                doc.id,
                ...(doc.documents?.map((d) => d.id) ?? []),
              ]),
            ]
          : [nextDocument.id];
        setPreviewDocumentId(nextId);
        setPreviewEntryDetails({
          sourceID: nextDocument.source_id,
          contentTags:
            nextDocument['document-tags']?.map((tag) => ({
              value: tag.id,
              label: tag.value,
            })) ?? [],
          sectionId: nextDocument.section_id,
          startDate: nextDocument['start-date'],
          endDate: nextDocument['end-date'],
          documentName: nextDocument['document-name'],
          monetary_total: nextDocument['monetary-total'],
          author: {
            id: nextDocument.author_id ? +nextDocument.author_id : null,
            name: nextDocument.author,
          },
          organization: {
            id: nextDocument.org_id ? +nextDocument.org_id : null,
            name: nextDocument.organization,
          },
          isHidden: nextDocument.is_hidden,
          markedImportant: nextDocument.marked_important,
          file_id: nextDocument.file_id,
          extracted_dates: nextDocument.extracted_dates,
          documents: nextDocument?.documents,
          'group-type': nextDocument['group-type'],
        });
      }
    },
    [previewDocumentId, previewEntryDetails],
  );

  if (isLoading) {
    return (
      <Loading
        text="Loading Index Sections..."
        typographyProps={{
          sx: {
            fontWeight: 600,
            color: 'primary.main',
          },
        }}
      />
    );
  }

  const handleSelectedDateChange = (updatedDates: {
    startDate?: string | null;
    endDate?: string | null;
  }) => {
    setPreviewEntryDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDates,
    }));
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <UploadDateFilterModal
        isOpen={isFilterModalOpen}
        onClose={onCloseFilterModal}
        currentlySelectedDates={uploadDates.length > 0 ? uploadDates : uploadDatesArray}
        handleChangeSelectedDates={setUploadDates}
        allDates={uploadDatesArray}
      />
      {previewDocumentId && (
        <PreviewPagesWrapper
          documentIds={previewDocumentId}
          caseId={reportData?.case_id}
          hideBlanks={true}
        >
          <DocumentPreviewerModal
            file_id={previewEntryDetails.file_id}
            caseId={reportData?.case_id}
            documentIds={previewDocumentId}
            navigateDocument={navigateDocument}
            currentEntryDetails={previewEntryDetails}
            setCurrentEntryDetails={setPreviewEntryDetails}
            onClose={handleCloseDocumentPreview}
            onUpdate={handleUpdateTimelineEntryFromDocumentPreview}
            setSelectedDate={handleSelectedDateChange}
            sectionName={
              reportData?.groups[0]?.sections?.find(
                (section) => section.id === previewEntryDetails.sectionId,
              )?.name
            }
            handleChangeImportanceMark={handleChangeImportanceMark}
            refreshIndexReport={refreshIndexReport}
            updateIndexRowCache={updateIndexRowCache}
            lastDocumentOfReport={lastDocumentOfReport?.id === previewDocumentId[0]}
            firstDocumentOfReport={firstDocumentOfReport?.id === previewDocumentId[0]}
          />
        </PreviewPagesWrapper>
      )}
      <DocumentComparisonModal
        isDocumentComparerModalOpen={isDocumentComparerModalOpen}
        closeDocumentComparerModal={closeDocumentComparerModal}
        documentComparerDocumentIds={documentComparerDocumentIds}
        setDocumentComparerDocumentIds={setDocumentComparerDocumentIds}
        isFirstComparisonDocumentDuplicate={isFirstComparisonDocumentDuplicate}
        isSecondComparisonDocumentDuplicate={isSecondComparisonDocumentDuplicate}
        handleUpdateDocumentDuplicateStatusV1Cases={handleUpdateDuplicateDocumentV1Cases}
        updateIndexRowCache={updateIndexRowCache}
        getNextDocument={getNextDocument}
        handleSetSecondDocumentComparisonIDAndOpenModal={
          handleSetSecondDocumentComparisonIDAndOpenModal
        }
      />
      <FeedbackPopup
        text={
          <div
            style={{
              textAlign: 'center',
              whiteSpace: 'normal',
              fontSize: '12px',
            }}
          >
            <div>Select Another Document to Compare or</div>
            <div
              style={{
                fontSize: '12px',
                cursor: 'pointer',
                color: 'white',
                fontWeight: 'bold',
                textDecoration: 'none',
                textAlign: 'center',
              }}
              onClick={handleCancelComparison}
            >
              Click Here to Cancel
            </div>
          </div>
        }
        severity="info"
        verticalLocation="bottom"
        horizontalLocation="center"
        open={isDocumentComparisonSelectionInProgress}
      />
      <Box
        sx={{
          mx: '2rem',
          mt: '1rem',
          pb: '4rem',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            mb: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              onClick={returnToReports}
              sx={{
                fontSize: '0.9rem',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              Reports
            </Box>
            <ArrowForwardIos
              sx={{
                fontSize: '0.9rem',
                opacity: '40%',
                marginTop: '0.2rem',
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            />
            <EditableTimelineEntriesReportTitle
              value={reportData?.name ?? 'Loading...'}
              updateIndexReportName={updateIndexReportName}
              timelineReportID={currentReport.id}
              setCurrentReport={setCurrentReport}
            />
            {!isFileProcessor && (
              <ExportTimelineReportButton
                timelineReportId={currentReport.id}
                caseId={reportData?.case_id}
                shouldExcludePreviousEntries={SHOULD_EXCLUDE_REPEATED_ITEMS}
                shouldExcludeConfirmedDuplicates={!shouldShowDuplicates}
                shouldIncludeHiddenPages={shouldShowHiddenPages}
                groupByDate={areSectionsGrouped}
                uploadDates={uploadDates}
                disabled={shouldDisableIndexExport}
                disabledMessage="No documents available to export. Please adjust your filters and try again."
                reportName={reportData?.name}
                areSectionsGrouped={areSectionsGrouped}
                includesSummaries={includesSummaries}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'right',
            }}
          >
            <span
              style={{ fontSize: '12px', fontWeight: 500, color: '#667085', marginBottom: '5px' }}
            >
              {totalPagesInIndex ? `Showing ${totalPagesInIndex} Pages` : ''}
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <span
                ref={configureRef}
                style={{
                  padding: '0.5rem 0 0.5rem 0',
                  cursor: 'pointer',
                  marginRight: '0.5rem',
                }}
              >
                <ConfigureIcon id="reports-tab-index-configure-btn" />
                <SwitchDropdownMenu
                  anchorComponentRef={configureRef}
                  options={configureOptions}
                  header="Configure"
                />
              </span>
              <FilterButton
                onClick={() => setIsFilterModalOpen(true)}
                dot={true}
                filterCount={uploadDates?.length < uploadDatesArray?.length ? 1 : 0}
                containerName="reports-tab-index-report-table"
              />
            </div>
          </Box>
        </Box>
        {reportData?.groups?.map((group) => {
          return (
            <div
              style={{
                marginBottom: group.title != null ? '4rem' : '0rem',
              }}
            >
              {group.title != null && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '1.25rem',
                  }}
                >
                  {group.title}
                </span>
              )}
              {group?.sections?.map((section) => {
                if (!shouldShowEmptySections && section.rows.length === 0) {
                  return null;
                }

                // Create a copy of the base columns
                const columnOptions = { ...timelineEntriesTableConfig.columnOptions };
                let columns = isFileProcessor ? [columnOptions.id] : [];

                section.columns.forEach((column: string) => {
                  const columnDetails = columnOptions[column];
                  if (columnDetails) {
                    columns.push(columnDetails);
                  }
                });

                if (isFileProcessor) {
                  columns.push(columnOptions['file-status']);
                }
                columns.push(columnOptions.goToSource);

                return (
                  <TimelineEntriesTableSection
                    key={section.id}
                    {...timelineEntriesTableConfig}
                    columns={columns}
                    caseID={reportData?.case_id}
                    rows={section.rows}
                    section={section}
                    // accessing the first sort model as the free MUI table only supports one sort model
                    sortModel={sortModel[section.id]}
                    handleSortChange={handleSortChange}
                    updateIndexReportSectionName={updateIndexReportSectionName}
                    bulkHideDocuments={bulkHideSelectedDocuments}
                  />
                );
              })}
            </div>
          );
        })}
      </Box>
    </div>
  );
}

function TableLoadingIcon() {
  return <Loading text="Loading Timeline Entries..." />;
}

export type IndexRow = {
  id: string;
  'start-date': string | null;
  'end-date': string | null;

  organization: string;
  organization_origin: string;
  org_id: string;

  author: string;
  author_origin: string;
  author_id: string;

  'document-tags': { id: string; value: string }[];
  tag_names?: string;

  first_page_id: string;
  source_id: string;

  'number-pages': number;

  source_name: string;
  'document-name': string;
  is_hidden: number;
  isCollapsed?: boolean;
  marked_important: number;
  all_pages_marked_duplicate: boolean;
  'file-status': string;
  file_id: string;
  upload_date: string;
  'monetary-total': string;
  summary: string;

  group_by?: string;
  section_name?: string;
  section_id?: string;
  documents?: IndexRow[];
  'group-type'?: 'collapsed' | 'attachments';
};
