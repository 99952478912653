import { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Stack,
  Box,
  Button,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import CaseContext from '../Case/CaseContext';
import CaseHeader from '../Timeline/CaseHeader';
import '../../resources/styles/App.css';
import { deleteDocument } from '../Timeline/api';
import Loading from '../../components/common/Loading';
import ReportsIcon from '../../components/icons/ReportsIcon';
import DeleteDialog from '../../components/common/DeleteDialog';
import { useFileProcessorUserList, useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import {
  FileStatus,
  checkFileStatus,
  removeAllPredictionsForFile,
  updateFileAssignee,
  renameFile,
  skipAutomatedProcessingForFile,
} from '../../api';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import Theme from '../../theme';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useSetCaseStatus } from '../MyCases/useCases';
import useCaseFiles, { useUpdateFileStatus } from './useCaseFiles';
import CopyFilesDialog from './CopyFilesDialog';
import FeedbackPopup from '../../components/common/FeedbackPopup';
import FileUploadBox from '../../components/FileUploadBox';
import useDocumentUpload from '../FileUpload/useDocumentUpload';
import { useUser } from '../../library/contexts/AuthContext';
import { File } from './types';
import {
  getMDOCProcessingColumns,
  getUserProcessingColumns,
  getUserReadyColumns,
} from './filesTableConfig';
import { validateFileOrReportName } from '../../utils/reportUtils';

function FilesTab() {
  const { caseInstance } = useContext(CaseContext);
  const logUserActivity = useActivityLog();
  const isFileProcessor = useIsFileProcessor();
  const [copyingFile, setCopyingFile] = useState({ sourceFile: '', targetFile: '' });
  const [filesLoading, setFilesLoading] = useState(true);
  const [filesInProgress, setFilesInProgress] = useState<File[]>([]);
  const [processedFiles, setProcessedFiles] = useState([]);
  const { caseID = '' } = useParams();
  const {
    isLoading: caseFilesLoading,
    data: caseFiles,
    refetch: refetchDocuments,
  } = useCaseFiles(caseID);

  const queryClient = useQueryClient();
  const userID = useUser().userId;

  const { mutateAsync: updateFileStatus } = useUpdateFileStatus();
  const {
    handleFileUpload,
    files: filesUploading,
    failedFiles,
    roundedUploadProgress: uploadProgress,
    handleRemoveFromFailedFiles,
  } = useDocumentUpload(caseID);

  const [isFileUploading, setIsFileUploading] = useState(false);
  useEffect(() => {
    const uploadProgressLess10 = Object.fromEntries(
      Object.entries(uploadProgress).filter(([key, value]) => value < 10),
    );

    if (Object.keys(uploadProgressLess10).length > 0) {
      setIsFileUploading(true);
    } else {
      setIsFileUploading(false);
    }
  }, [uploadProgress]);

  const windowSize = useDisplayStore((state) => state.windowSize);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [removeAllPredictionsModalOpen, setRemoveAllPredictionsModalOpen] = useState(false);
  const [skipAutomatedProcessingModalOpen, setSkipAutomatedProcessingModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [editedFileName, setEditedFileName] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const { data: processorList = [] } = useFileProcessorUserList({
    enabled: isFileProcessor ?? false,
  });

  const toggleEditFileName = (row) => {
    if (editMode && selectedFile?.id === row.id) {
      setSelectedFile(null);
      setEditMode(false);
      setEditedFileName('');
    } else {
      setSelectedFile(row);
      setEditMode(true);
      setEditedFileName(row.name);
    }
  };

  const checkCopyingFileStatus = useCallback(
    async (fileId) => {
      const fileStatusResponse = await checkFileStatus({ caseID, fileId });
      return fileStatusResponse?.data[0]?.file_status;
    },
    [filesInProgress, updateFileStatus],
  );

  const setNewFileStatus = (fileID, newStatus) => {
    const updatedFiles = caseFiles.map((file) => {
      if (file.documentID === fileID) {
        return {
          ...file,
          fileStatus: newStatus,
        };
      }
      return file;
    });
    queryClient.setQueryData(['files', caseID], updatedFiles);
  };

  useEffect(() => {
    let timeoutId;
    let retryCount = 0;

    const checkStatusAndScheduleNextCheck = async () => {
      const fileStatus = await checkCopyingFileStatus(copyingFile.sourceFile);
      let delay;
      switch (fileStatus) {
        case 'QA_REQUIRED':
          toast.success('Successfully copied file');
          setCopyingFile({ sourceFile: '', targetFile: '' });
          setNewFileStatus(copyingFile.targetFile, fileStatus);
          break;
        case 'COPYING':
          retryCount++;
          delay = 2 ** retryCount * 1000;
          timeoutId = setTimeout(checkStatusAndScheduleNextCheck, delay);
          break;
        default:
          break;
      }
    };

    if (copyingFile.sourceFile) {
      checkStatusAndScheduleNextCheck();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [copyingFile]);

  useEffect(() => {
    if (!isFileProcessor || !caseFiles) {
      return;
    }
    const documents = caseFiles;
    const authorNotReady = documents.some((doc) => doc.authorStatus !== 'READY');

    // set interval to refetch documents every 1 minute if author is not ready
    if (authorNotReady) {
      const interval = setInterval(() => {
        refetchDocuments({
          variables: {
            caseID: caseID,
          },
        });
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [isFileProcessor, caseFiles]);

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:files',
        case_id: caseID,
      });
    }
  }, [caseID]);

  const calcCanCopyFile = useCallback(
    (fileRow) => {
      return (
        filesInProgress.filter((file) => file.numOfPages === fileRow.numOfPages).length > 1 &&
        (fileRow.fileStatus === 'QA_REQUIRED' || fileRow.fileStatus === 'APPROVED')
      );
    },
    [filesInProgress],
  );

  useEffect(() => {
    if (filesUploading && failedFiles && !caseFilesLoading && caseFiles) {
      setFilesLoading(true);
      let extractedFiles = caseFiles.map((file) => {
        const lastDotIndex = file.docFileName.lastIndexOf('.');

        const timeRemaining = (() => {
          const timeDifference =
            new Date(new Date(file.uploadDate).getTime() + 24 * 60 * 60 * 1000) - new Date();
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          return `${hours}h ${minutes}m`;
        })();

        let fileStatus;
        if (isFileProcessor) {
          fileStatus = file.isReady === 0 ? 'UPLOADING' : file.fileStatus;
        } else {
          fileStatus = file.fileStatus === 'UPLOADING' ? 'PENDING' : file.fileStatus;
        }
        return {
          id: file.documentID,
          name: file.docFileName.substring(0, lastDotIndex),
          type: file.docFileName.substring(lastDotIndex + 1).toUpperCase(),
          uploadDate: isFileProcessor
            ? timeRemaining
            : new Date(file.uploadDate).toLocaleDateString('en-GB'),
          numOfPages: file.numberOfPages,
          fileStatus: fileStatus,
          authorStatus: file.authorStatus,
          refID: file.refID,
          uploadTime: new Date(file.uploadDate).getTime(),
          uploadProgress: null,
          processorAssigned: file.processor_assigned ?? null,
        };
      });
      extractedFiles.sort((a, b) => a.uploadTime - b.uploadTime || a.id - b.id);
      extractedFiles = extractedFiles.map((file, index) => ({ ...file, sequence: index + 1 }));

      let filesInUpload = isFileProcessor
        ? extractedFiles
        : filesUploading
            .map((file) => {
              const lastDotIndex = file.name.lastIndexOf('.');
              return {
                id: file.imageID,
                name: file.name.substring(0, lastDotIndex),
                type: file.name.substring(lastDotIndex + 1).toUpperCase(),
                uploadDate: new Date().toLocaleDateString('en-GB'),
                numOfPages: 0,
                fileStatus: 'UPLOADING',
                authorStatus: true,
                refID: 0,
                uploadProgress: uploadProgress[file.name] ?? null,
              };
            })
            .concat(extractedFiles);

      filesInUpload = filesInUpload.filter((file) => file.fileStatus !== FileStatus.COMPLETE);
      filesInUpload = filesInUpload.filter((file) => {
        if (
          file.uploadProgress &&
          extractedFiles.map((processingFile) => processingFile.name).includes(file.name)
        ) {
          return false;
        }
        return true;
      });

      const allFiles = failedFiles
        .map((file) => {
          const lastDotIndex = file.name.lastIndexOf('.');
          return {
            id: file.imageID,
            name: file.name.substring(0, lastDotIndex),
            type: file.name.substring(lastDotIndex + 1).toUpperCase(),
            uploadDate: new Date().toLocaleDateString('en-GB'),
            numOfPages: 0,
            fileStatus: 'ERROR',
            authorStatus: true,
            refID: 0,
            uploadProgress: uploadProgress[file.name] ?? null,
            errorType: file.errorType,
          };
        })
        .concat(filesInUpload);
      extractedFiles = extractedFiles.filter((file) => file.fileStatus === FileStatus.COMPLETE);
      setProcessedFiles(extractedFiles);
      setFilesInProgress(allFiles);
      setFilesLoading(false);
    }
  }, [caseFiles, caseFilesLoading, filesUploading, failedFiles, uploadProgress]);

  const handleDeleteFile = () => {
    if (selectedFile) {
      if (selectedFile.fileStatus === FileStatus.ERROR) {
        handleRemoveFromFailedFiles(
          `${selectedFile.name}.${selectedFile.type.toLowerCase()}`,
          selectedFile.errorType,
        );
        setDeleteModalOpen(false);
        setSelectedFile(null);
        return;
      }
      deleteDocument(caseID, selectedFile.id)
        .then(() => {
          refetchDocuments({ caseID });
          toast.success('File deleted');
          if (selectedFile.fileStatus === FileStatus.COMPLETE) {
            setProcessedFiles(processedFiles.filter((file) => file.id !== selectedFile.id));
          }
          if (selectedFile.fileStatus === FileStatus.UPLOADING) {
            setFilesInProgress(filesInProgress.filter((file) => file.id !== selectedFile.id));
          }
        })
        .catch((err) => {
          toast.error('Error deleting file');
          console.log(err);
        });

      setDeleteModalOpen(false);
      setSelectedFile(null);
    }
  };

  const handleRenameFile = (file, newFileNameInput) => {
    const newFileName = `${newFileNameInput}.${file.type.toLowerCase()}`;
    if (!validateFileOrReportName(newFileName)) {
      return;
    }

    const prevFiles = [...processedFiles];
    setProcessedFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((f) =>
        f.id === file.id ? { ...f, name: newFileNameInput } : f,
      );
      return updatedFiles;
    });
    renameFile(caseID, file.id, newFileName)
      .then(() => {
        refetchDocuments({ caseID }).then(() => {
          toast.success('File renamed successfully');
          setEditMode(false);
          setSelectedFile(null);
        });
      })
      .catch((err) => {
        console.log(err);
        // Revert the state if the API call fails
        setProcessedFiles(prevFiles);
      });
  };

  const [sourceDocument, setSourceDocument] = useState<File | null>(null);

  const handleFileStatusChange = async (id, currentStatus) => {
    const nextStatusByCurrentStatus = {
      [FileStatus.PENDING]: FileStatus.GROUPING,
      [FileStatus.GROUPING]: FileStatus.TAGGING,
      [FileStatus.TAGGING]: FileStatus.QA_REQUIRED,
      [FileStatus.QA_REQUIRED]: FileStatus.APPROVED,
    };

    if (!Object.keys(nextStatusByCurrentStatus).includes(currentStatus)) {
      return;
    }

    await updateFileStatus({
      caseID,
      fileID: id,
      status: nextStatusByCurrentStatus[currentStatus],
    });
  };

  const areAllFilesMarkedAsComplete = useMemo(() => {
    if (!caseFiles) {
      return false;
    }
    return caseFiles.every((document) => document.fileStatus === FileStatus.APPROVED);
  }, [caseFiles]);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedFile(null);
  };

  const getNoRowsOverlay = () => {
    return <NoRowsOverlay isFileProcessor={isFileProcessor} />;
  };

  const isProcessing = caseInstance?.caseStatus === 'REQUIRES_PROCESSOR';
  const newFilesInPipeline = caseInstance?.caseStatus === 'PROCESSING';

  const { mutateAsync: setCaseStatus } = useSetCaseStatus();

  const navigate = useNavigate();

  const [checked, setChecked] = useState({
    dates: false,
    sources: false,
    content: false,
    grouping: false,
    duplicates: false,
  });

  const [disabled, setDisabled] = useState(false);
  const [readyDialogOpen, setReadyDialogOpen] = useState(false);

  const handleChange = (key) => {
    setChecked({
      ...checked,
      [key.id]: key.checked,
    });
  };

  const markAsReadyOnClick = () => {
    setReadyDialogOpen(true);
  };
  const handleMarkAsReady = async () => {
    try {
      await setCaseStatus({
        caseID,
        caseStatus: 'READY',
        sendReadyEmail: true,
      });
      toast.success('Case successfully marked as ready.', {
        toastId: 'caseStatusSuccess',
      });
      setReadyDialogOpen(false);
      navigate('/cases');
    } catch (e) {
      // error handling is done in the setCaseStatus function
    }
  };

  const handleUpdateFileAssignee = useCallback(
    async (fileID: string, userID: string) => {
      try {
        await updateFileAssignee(caseID, fileID, userID || null);
        await refetchDocuments();
      } catch (err) {
        toast.error('There was a problem updating the file assignee.');
      }
    },
    [caseID],
  );

  const removeAllPredictions = async () => {
    if (selectedFile) {
      await removeAllPredictionsForFile(caseID, selectedFile.id);
      window.location.reload();
    }
  };
  const handleRemoveAllPredictions = (file: File) => {
    setSelectedFile(file);
    setRemoveAllPredictionsModalOpen(true);
  };

  const skipAutomatedProcessing = async () => {
    if (selectedFile) {
      await skipAutomatedProcessingForFile(caseID, selectedFile.id);
      window.location.reload();
    }
  };
  const handleSkipAutomatedProcessing = (file: File) => {
    setSelectedFile(file);
    setSkipAutomatedProcessingModalOpen(true);
  };
  useEffect(() => {
    if (
      Object.values(checked).filter((check) => check !== true).length === 0 &&
      areAllFilesMarkedAsComplete
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [checked]);

  useEffect(() => {
    if (!areAllFilesMarkedAsComplete) {
      setDisabled(true);
    }
  }, [areAllFilesMarkedAsComplete]);

  let processorFileButtonText;
  if (isProcessing) {
    processorFileButtonText = 'Mark Case As Ready';
  } else if (newFilesInPipeline) {
    processorFileButtonText = 'New Files in Pipeline';
  } else {
    processorFileButtonText = 'Processing Complete';
  }

  const processingColumns: any[] = useMemo(() => {
    if (isFileProcessor) {
      return getMDOCProcessingColumns({
        handleFileStatusChange,
        calcCanCopyFile,
        setSourceDocument,
        processorList,
        handleUpdateFileAssignee,
        handleRemoveAllPredictions,
        handleSkipAutomatedProcessing,
      });
    }

    return getUserProcessingColumns({
      editMode,
      editedFileName,
      setEditedFileName,
      selectedFile,
      setSelectedFile,
      handleRenameFile,
      toggleEditFileName,
      setDeleteModalOpen,
    });
  }, [
    isFileProcessor,
    calcCanCopyFile,
    editMode,
    editedFileName,
    selectedFile,
    handleRenameFile,
    toggleEditFileName,
  ]);

  const readyColumns: any[] = useMemo(() => {
    if (isFileProcessor) {
      return [];
    }
    return getUserReadyColumns({
      editMode,
      editedFileName,
      setEditedFileName,
      selectedFile,
      setSelectedFile,
      handleRenameFile,
      toggleEditFileName,
      setDeleteModalOpen,
    });
  }, [
    isFileProcessor,
    calcCanCopyFile,
    editMode,
    editedFileName,
    selectedFile,
    handleRenameFile,
    toggleEditFileName,
  ]);

  return (
    <div style={{ backgroundColor: 'white' }}>
      <CaseHeader caseInstance={caseInstance} />
      <Box
        sx={{
          width: '100%',
          minHeight: 'calc(100vh - 64px)',
          borderRadius: 0,
          justifyContent: 'center',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          backgroundColor: 'white',
          paddingBottom: '2rem',
        }}
      >
        {isFileProcessor ? (
          <Stack
            direction="row"
            spacing={4}
            sx={{
              paddingTop: '1.2rem',
              width: '100%',
            }}
          >
            <Typography fontSize="1.2rem" fontWeight={600} sx={{ display: 'inline' }}>
              Files
            </Typography>
            <CopyFilesDialog
              userID={userID}
              caseID={caseID}
              files={filesInProgress || []}
              setCopyingFile={setCopyingFile}
              sourceDocument={sourceDocument}
              onClose={() => setSourceDocument(null)}
            />
            <DeleteDialog
              open={removeAllPredictionsModalOpen}
              title="Remove All Predictions and Changes for this file?"
              contentText={`
                File: ${selectedFile?.name}

                The following data will be removed for this file, including any changes made by MDOC:
                  - Grouping
                  - Dates
                  - Document Name
                  - All Tags
                  - Author / Org`}
              onClose={() => setRemoveAllPredictionsModalOpen(false)}
              onConfirm={removeAllPredictions}
              confirmText="Remove All Predictions"
              cancelText="Cancel"
            />
            <DeleteDialog
              open={skipAutomatedProcessingModalOpen}
              title="Skip Processing?"
              contentText={`
                File: ${selectedFile?.name}

                This is only intended to be used if automated processing has been stuck for over 30 minutes.

                This will skip all remaining predictions and allow you to continue working on the file.`}
              onClose={() => setSkipAutomatedProcessingModalOpen(false)}
              onConfirm={skipAutomatedProcessing}
              confirmText="Skip Processing"
              cancelText="Cancel"
            />
            <Button
              color="newSuccess"
              variant="contained"
              disabled={!isProcessing || newFilesInPipeline}
              onClick={markAsReadyOnClick}
              sx={{
                marginLeft: '1.7rem',
                fontWeight: 700,
                fontSize: '0.8rem',
              }}
            >
              {processorFileButtonText}
            </Button>
          </Stack>
        ) : (
          <>
            <div
              style={{
                fontSize: '30px',
                fontWeight: 600,
                width: '100%',
                paddingTop: '1.2rem',
              }}
            >
              Files
            </div>
            <div
              style={{
                width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '1.2rem',
                paddingBottom: '1rem',
              }}
            >
              <FileUploadBox uploadFiles={handleFileUpload} />
            </div>
          </>
        )}

        {caseFilesLoading || filesLoading ? (
          <Loading />
        ) : (
          <div
            style={{
              width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingTop: '1.2rem',
              paddingBottom: '1rem',
            }}
          >
            {filesInProgress.length > 0 && (
              <Box
                sx={{
                  width: '100%',
                  maxHeight: 'calc(100% - 200px)',
                  '&::-webkit-scrollbar': {
                    width: '1em',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    padding: '0 4px',
                    border: '4px solid transparent',
                    backgroundClip: 'padding-box',
                    borderRadius: '100px',
                  },
                }}
              >
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    marginLeft: '4px',
                    color: Theme.palette.subHeading.main,
                  }}
                >
                  {`Processing (${filesInProgress.length})`}
                </div>
                <DataGrid
                  columns={processingColumns}
                  rows={filesInProgress || []}
                  autoHeight
                  disableColumnMenu
                  disableRowSelectionOnClick
                  pageSize={pageSize}
                  pagination
                  paginationMode="client"
                  rowCount={filesInProgress?.length || 0}
                  page={page}
                  onPageChange={(newPage: number) => setPage(newPage)}
                  onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                  pageSizeOptions={[5, 20, 50, 100]}
                  sx={{
                    width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
                    border: '1px solid #00214733',
                    borderRadius: '8px',
                    fontSize: '0.8rem',
                    boxShadow: 'none',
                    minHeight: filesInProgress?.length > 0 ? '0px' : '400px',
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: ' none',
                    },
                  }}
                  components={{
                    NoRowsOverlay: getNoRowsOverlay,
                  }}
                />
                <DeleteDialog
                  open={deleteModalOpen}
                  title="Delete File"
                  contentText="Are you sure you want to delete this file?"
                  onClose={handleCloseDeleteModal}
                  onConfirm={handleDeleteFile}
                />
              </Box>
            )}
            <div
              style={{
                width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '1.2rem',
                paddingBottom: '1rem',
              }}
            >
              {processedFiles.length > 0 && !isFileProcessor && (
                <Box
                  sx={{
                    width: '100%',
                    maxHeight: 'calc(100% - 200px)',
                    '&::-webkit-scrollbar': {
                      width: '1em',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      padding: '0 4px',
                      border: '4px solid transparent',
                      backgroundClip: 'padding-box',
                      borderRadius: '100px',
                    },
                  }}
                >
                  <div
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      marginLeft: '4px',
                      color: Theme.palette.subHeading.main,
                    }}
                  >
                    {`Ready (${processedFiles.length})`}
                  </div>
                  <DataGrid
                    columns={readyColumns}
                    rows={processedFiles || []}
                    autoHeight
                    disableColumnMenu
                    disableRowSelectionOnClick
                    pageSize={pageSize}
                    pagination
                    paginationMode="client"
                    rowCount={processedFiles?.length || 0}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pageSizeOptions={[5, 20, 50, 100]}
                    sx={{
                      width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
                      border: '1px solid #00214733',
                      borderRadius: '8px',
                      fontSize: '0.8rem',
                      boxShadow: 'none',
                      minHeight: processedFiles?.length > 0 ? '0px' : '400px',
                      '& .MuiDataGrid-cell:focus-within': {
                        outline: ' none',
                      },
                    }}
                    components={{
                      NoRowsOverlay: getNoRowsOverlay,
                    }}
                  />
                  <DeleteDialog
                    open={deleteModalOpen}
                    title="Delete File"
                    contentText="Are you sure you want to delete this file?"
                    onClose={handleCloseDeleteModal}
                    onConfirm={handleDeleteFile}
                  />
                </Box>
              )}
            </div>
          </div>
        )}
      </Box>
      <DeleteDialog
        open={readyDialogOpen}
        onClose={() => {
          setChecked({
            dates: false,
            sources: false,
            content: false,
            grouping: false,
            duplicates: false,
          });
          setReadyDialogOpen(false);
        }}
        onConfirm={handleMarkAsReady}
        title="Mark Case As Ready"
        disabledTooltip={
          !areAllFilesMarkedAsComplete
            ? 'Please mark all files as complete in the Files tab before marking the case as ready.'
            : 'Please confirm all QA steps as complete before marking the case as ready.'
        }
        cancelText="Cancel"
        confirmText="Confirm as Ready"
        confirmDisabled={disabled}
        sx={{
          backgroundColor: Theme.palette.secondary.main,
          color: '#000000',
          borderColor: Theme.palette.secondary.main,
        }}
        contentText="After marking the case as ready, you will no longer be able to edit the case and it will be sent to the client for review."
        footerComponent={
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="confirm"
                  onChange={(e) => {
                    setDisabled(!e.target.checked);
                  }}
                  sx={{ color: 'red' }}
                />
              }
              label="Manually override file status lock."
            />
          </FormGroup>
        }
        content={
          <FormGroup>
            <FormLabel>Please confirm that all of the following are complete:</FormLabel>
            <FormControlLabel
              required
              control={<Checkbox id="dates" onChange={(e) => handleChange(e.target)} />}
              label="All dates have been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="sources" onChange={(e) => handleChange(e.target)} />}
              label="All sources have been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="content" onChange={(e) => handleChange(e.target)} />}
              label="All content labels have been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="grouping" onChange={(e) => handleChange(e.target)} />}
              label="All grouping has been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="duplicates" onChange={(e) => handleChange(e.target)} />}
              label="All duplicates have been resolved"
            />
          </FormGroup>
        }
      />
      <FeedbackPopup
        text={
          <div>
            <div>Copying File...</div>
            <div style={{ fontSize: '12px' }}>Please do not leave this page</div>
          </div>
        }
        severity="info"
        icon={<CircularProgress size={24} sx={{ color: 'white', margin: 'auto 15px auto 0px' }} />}
        verticalLocation="bottom"
        horizontalLocation="center"
        open={Boolean(copyingFile.sourceFile) ?? false}
      />
      <FeedbackPopup
        text={
          <div>
            <div>Uploading Files...</div>
            <div style={{ fontSize: '12px' }}>Please do not leave this page</div>
          </div>
        }
        severity="info"
        icon={<CircularProgress size={24} sx={{ color: 'white', margin: 'auto 15px auto 0px' }} />}
        verticalLocation="bottom"
        horizontalLocation="center"
        open={Boolean(isFileUploading) ?? false}
      />
    </div>
  );
}

export default FilesTab;

function NoRowsOverlay({ isFileProcessor }) {
  const noRowText = isFileProcessor
    ? 'There are no files that require processing.'
    : 'To upload a new file, click the "Add Files" button above.';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5rem',
        marginBottom: '5rem',
        width: '100%',
      }}
    >
      <ReportsIcon />
      <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>No Files Found</Typography>
      <Typography
        sx={{
          fontWeight: 600,
          opacity: '50%',
          fontSize: '0.8rem',
        }}
      >
        {noRowText}
      </Typography>
    </Box>
  );
}
