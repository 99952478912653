import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useState, useMemo, useEffect } from 'react';
import useUpdateCaseDetails from '../Case/gql/updateCaseDetails';
import Cases from '../../components/icons/Cases';
import { validateCaseDetails } from './utils/caseDetailsValidation';
import { useCaseTypes } from './hooks/useGetAllCaseTypes';

/**
 * Component for handling Update functionalies of the case
 */
function CaseUpdate({
  caseID,
  caseName,
  dueDate,
  patientName,
  dateOfBirth,
  claimReason,
  dateOfInjury,
  menuOpen,
  handleUpdateMenuClose,
  setEventAlert,
  caseTypeId,
}) {
  const updateCase = useUpdateCaseDetails();

  const [updatedCaseName, setUpdatedCaseName] = useState(caseName);
  const [updatedPatientName, setUpdatedPatientName] = useState(patientName);
  const [updatedClaimReason, setUpdatedClaimReason] = useState(claimReason);
  const [updatedDueDate, setUpdatedDueDate] = useState(dueDate);
  const [updatedDOI, setUpdatedDOI] = useState(dateOfInjury);
  const [updatedDOB, setUpdatedDOB] = useState(dateOfBirth);
  const [selectedCaseTypeId, setSelectedCaseTypeId] = useState(caseTypeId);

  const { data: caseTypesList = [] } = useCaseTypes();

  useEffect(() => {
    // Ensure selected case type ID reflects prop value
    setSelectedCaseTypeId(caseTypeId);
  }, [caseTypeId]);

  const handleCaseUpdate = async () => {
    if (hasDiff()) {
      const updatedCaseDetails = {
        caseName: updatedCaseName,
        patientName: updatedPatientName,
        dateOfInjury: updatedDOI || null,
        dateOfBirth: updatedDOB || null,
        claimReason: updatedClaimReason,
        dueDate: updatedDueDate || null,
        case_type_id: selectedCaseTypeId,
      };
      // Call API to the backend.
      await updateCase(caseID, updatedCaseDetails);
      handleUpdateMenuClose();
      setEventAlert('Your case has been successfully updated.');
    }
  };

  const hasDiff = () => {
    if (
      updatedCaseName !== caseName ||
      updatedPatientName !== patientName ||
      updatedClaimReason !== claimReason ||
      updatedDueDate !== dueDate ||
      updatedDOI !== dateOfInjury ||
      updatedDOB !== dateOfBirth ||
      selectedCaseTypeId !== caseTypeId
    ) {
      return true;
    }
    return false;
  };

  // if no Error, staff is not empty, and hasDifference, then good to go.
  const nameError = validateCaseDetails(
    false,
    updatedCaseName,
    updatedDueDate,
    updatedDOB,
    updatedDOI,
  );
  const readyToUpdate = nameError[0] && hasDiff();

  function handleUpdate() {
    if (readyToUpdate) {
      handleCaseUpdate();
    } else if (
      !validateCaseDetails(false, updatedCaseName, updatedDueDate, updatedDOB, updatedDOI)[0]
    ) {
      // eslint-disable-next-line no-alert
      alert(validateCaseDetails(false, updatedCaseName, updatedDueDate, updatedDOB, updatedDOI)[1]);
    }
  }

  return (
    <Dialog
      open={menuOpen}
      aria-labelledby="dialogTitle"
      maxWidth="sm"
      fullWidth={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleUpdateMenuClose();
        }
      }}
      sx={{ justifyContent: 'center' }}
      PaperProps={{
        sx: { height: '90%', maxHeight: '40rem', width: '60%' },
      }}
    >
      <Cases
        sx={{
          alignSelf: 'center',
          color: 'primary.light',
          marginTop: '3rem',
          fontSize: '3rem',
        }}
      />
      <DialogTitle
        id="dialogTitle"
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          fontSize: '1rem',
          paddingTop: 0,
          fontWeight: 700,
          color: 'primary.light',
        }}
        onClose={handleUpdateMenuClose}
      >
        Update Case
        <Typography sx={{ fontSize: '0.7rem', textAlign: 'center', color: '#002147' }}>
          Please update your case details below
        </Typography>
        <IconButton
          aria-label="close"
          sx={caseUpdateStyles.iconBtn}
          onClick={handleUpdateMenuClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '0px 16px 24px ' }}>
        <Box sx={caseUpdateStyles.contentContainer}>
          <Box sx={{ width: '100%', margin: 'auto' }}>
            <Box sx={caseUpdateStyles.formRow}>
              <Typography variant="caseDialogHeader">Case Details</Typography>
              <Box sx={caseUpdateStyles.formFlexControl}>
                <Box sx={caseUpdateStyles.labelBox}>
                  <InputLabel classes="case-dialog">
                    Case Name{' '}
                    <Typography
                      sx={{
                        display: 'inline',
                        color: 'grey',
                        opacity: '80%',
                        fontWeight: 700,
                        fontSize: '0.6rem',
                      }}
                    >
                      *Required
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="caseName"
                    onChange={(event) => setUpdatedCaseName(event.target.value)}
                    name="caseName"
                    sx={caseUpdateStyles.textField}
                    defaultValue={updatedCaseName}
                    className="fs-exclude"
                  />
                </Box>
                <Box sx={{ ...caseUpdateStyles.labelBox, flex: '39%' }}>
                  <InputLabel classes="case-dialog">Due Date</InputLabel>
                  <TextField
                    id="updateDueDate"
                    type="date"
                    name="dueDate"
                    defaultValue={dueDate === '0000-00-00' ? '' : dueDate}
                    onChange={(e) => setUpdatedDueDate(e.target.value)}
                    InputProps={{
                      inputProps: { min: '1000-01-01', max: '2099-12-31' },
                      role: 'textbox',
                    }}
                  />
                </Box>
              </Box>
              {caseTypesList?.length !== 0 && (
                <FormControl sx={{ width: '17.3rem', marginTop: '1rem' }}>
                  <Typography sx={{ fontWeight: 700, fontSize: '0.6rem', color: '#385D9F' }}>
                    Type
                  </Typography>

                  <Select
                    value={selectedCaseTypeId ?? ''}
                    onChange={(e) =>
                      setSelectedCaseTypeId(e.target.value === '' ? null : e.target.value)
                    }
                    width="12rem"
                    labelId="case_type_id"
                    displayEmpty
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    {caseTypesList.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box sx={caseUpdateStyles.formRow}>
              <Typography variant="caseDialogHeader">Claimant Details</Typography>
              <Box sx={caseUpdateStyles.formFlexControl}>
                <Box sx={caseUpdateStyles.labelBox}>
                  <InputLabel classes="case-dialog">Claimant's Name</InputLabel>
                  <TextField
                    id="patientName"
                    name="patientName"
                    sx={caseUpdateStyles.textField}
                    defaultValue={patientName}
                    onChange={(e) => setUpdatedPatientName(e.target.value)}
                    className="fs-exclude"
                  />
                </Box>
                <Box sx={{ ...caseUpdateStyles.labelBox, flex: '39%' }}>
                  <InputLabel classes="case-dialog">Date of Birth</InputLabel>
                  <TextField
                    id="updateBirthDate"
                    type="date"
                    name="birthDate"
                    InputProps={{
                      inputProps: { min: '1000-01-01', max: '2099-12-31' },
                      role: 'textbox',
                    }}
                    defaultValue={dateOfBirth === '0000-00-00' ? '' : dateOfBirth}
                    onChange={(e) => setUpdatedDOB(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={caseUpdateStyles.formRow}>
              <Typography variant="caseDialogHeader">Incident Details</Typography>
              <Box sx={caseUpdateStyles.formFlexControl}>
                <Box sx={caseUpdateStyles.labelBox}>
                  <InputLabel classes="case-dialog">Reason For Claim</InputLabel>
                  <TextField
                    id="claimReason"
                    name="claimReason"
                    defaultValue={claimReason}
                    sx={caseUpdateStyles.textField}
                    onChange={(e) => {
                      setUpdatedClaimReason(e.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ ...caseUpdateStyles.labelBox, flex: '39%' }}>
                  <InputLabel classes="case-dialog">Date of Incident</InputLabel>
                  <TextField
                    id="updateInjuryDate"
                    type="date"
                    name="injuryDate"
                    InputProps={{
                      inputProps: { min: '1000-01-01', max: '2099-12-31' },
                      role: 'textbox',
                    }}
                    defaultValue={dateOfInjury === '0000-00-00' ? '' : dateOfInjury}
                    onChange={(e) => setUpdatedDOI(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {!nameError[0] ? <Box sx={caseUpdateStyles.errorDisplay}>{nameError[1]}</Box> : ''}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '3.5rem' }}>
        <Button
          onClick={handleUpdate}
          disabled={readyToUpdate === false || updatedCaseName === ''}
          color="primary"
          size="small"
          variant="contained"
          aria-label="confirm-update-case"
        >
          Update Case
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const caseUpdateStyles = {
  textField: {
    flex: '59%',
    marginRight: '1rem',
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    flex: '59%',
  },
  iconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  uploadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  margin0: {
    margin: '0 auto',
  },
  alignCenter: {
    textAlign: 'center',
  },
  errorDisplay: {
    color: 'red',
    fontSize: 12,
    marginTop: '1rem',
    textAlign: 'center',
  },
  formFlexControl: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  formRow: {
    marginTop: '0.5rem',
    width: '100%',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
};

export default CaseUpdate;
