import { Box, Tooltip } from '@mui/material';
import { JSX } from 'react';
import Theme from '../../theme';

type NavigationTabProps = {
  id: string;
  name: string;
  useDisabledTextColor?: boolean;
  icon?: JSX.Element | null;
  isActive: boolean;
  handleClick: () => void;
  tooltipText?: string;
  disableTooltip?: boolean;
};

NavigationTab.defaultProps = {
  useDisabledTextColor: false,
  icon: null,
  tooltipText: '',
  disableTooltip: true,
};

export default function NavigationTab({
  id,
  name,
  useDisabledTextColor,
  icon = null as JSX.Element | null,
  isActive,
  handleClick,
  tooltipText = '',
  disableTooltip = false,
}: NavigationTabProps) {
  return (
    <Tooltip title={tooltipText} disableHoverListener={disableTooltip}>
      <Box
        id={id ?? `review-tab-${name}-nav-button`}
        onClick={() => {
          handleClick();
        }}
        sx={{
          ...navStyles.navButtons,
          color: useDisabledTextColor ? Theme.palette.mediumGrey.light : Theme.typography.color,
          ...(isActive && {
            position: navStyles.styledGreenUnderline.position,
            '::after': {
              ...navStyles.styledGreenUnderline['::after'],
            },
          }),
        }}
      >
        {icon}
        {name}
      </Box>
    </Tooltip>
  );
}

const navStyles = {
  navButtons: {
    marginLeft: '1.2rem',
    color: '#002147',
    marginRight: '0.7rem',
    verticalAlign: 'bottom',
    height: '2.5rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    paddingRight: '0.5rem',
    display: 'inline-block !important',
    '&:hover': {
      position: 'relative',
      cursor: 'pointer',
      display: 'inline-block !important',
      '::after': {
        content: '""',
        marginTop: '6px',
        height: '4px',
        background: '#A8FCDC',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'block',
      },
    },
  },
  styledGreenUnderline: {
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-block !important',
    '::after': {
      content: '""',
      marginTop: '6px',
      height: '4px',
      background: '#6CE9A6',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      display: 'block',
    },
  },
};
