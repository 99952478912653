/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useApolloClient } from '@apollo/client';

import {
  buildGroupingsForCaseFile,
  validateAndUpdateDocumentStructure,
  DocumentEdge,
  getPagesByCaseFile,
  PageObject,
  resetDocumentEdgesForCaseFile,
} from '../../../api';
import {
  GetTimelineEntriesDocument,
  GetTimelineEntriesDescriptorsDocument,
} from '../../../__generated__/graphql';
import { useAsync } from '../../../hooks/useAsync';
import { compareEdges } from './documentParser';
import { DocumentNode, parsePages, EdgeError } from './DocumentTree';
import { retryRequest } from '../../../utils/asyncUtils';
import { useDocumentTreeSequence } from '../api-hooks/useDocumentTreeSequence';

export function useGroupings(caseId: string, fileId: string) {
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'saving' | 'saved' | 'errored' | 'staged'
  >('idle');
  const [pagesResult, refreshPages] = useAsync(() => fetchPages(caseId, fileId), [caseId, fileId]);
  const [pages, setPages] = useState<PageObject[]>([]);
  const [filteredPages, setFilteredPages] = useState<PageObject[]>([]);
  const [groupIDByPageID, setGroupIDByPageID] = useState<Record<string, number>>({});
  const [attachmentIDByPageID, setAttachmentIDByPageID] = useState<Record<string, number>>({});
  const [pageIndexById, setPageIndexById] = useState(new Map<PageObject['id'], number>());
  const [documentTree, setDocumentTree] = useState<DocumentNode | null>(null);
  const [parseError, setParseError] = useState<EdgeError | null>(null);
  const [sequence, setSequence] = useState<bigint | null>(null);
  const [documentScrollerCompatiblePages, setDocumentScrollerCompatiblePages] = useState<
    DocumentScrollerCompatiblePage[]
  >([]);
  const [startPage, setStartPage] = useState<number | null>(1);
  const [endPage, setEndPage] = useState<number | null>(null);

  const queryClient = useQueryClient();
  const apolloClient = useApolloClient();
  const sequenceQuery = useDocumentTreeSequence(sequence, caseId, fileId);

  useEffect(() => {
    setSequence(null);
  }, [fileId]);

  useEffect(() => {
    if (pagesResult.status === 'pending') {
      setStatus('loading');
    } else if (pagesResult.status === 'rejected') {
      setStatus('errored');
    } else if (pagesResult.status === 'resolved') {
      const newPageIndexById = new Map<PageObject['id'], number>();
      const allPages = pagesResult.data;

      setPages(allPages);
      if (!endPage) {
        setEndPage(allPages.length);
      }

      for (const [index, page] of allPages.entries()) {
        newPageIndexById.set(page.id, index);
        page.document_edges.sort(compareEdges);
      }

      setPageIndexById(newPageIndexById);
      const parsed = parsePages(allPages);
      if (parsed.type === 'error') {
        setDocumentTree(null);
        setParseError(parsed.error);
        setStatus('errored');
      } else {
        setDocumentTree(parsed.value);
        setParseError(null);
        setStatus('idle');
      }
    }
  }, [pagesResult]);

  const onChangePageRange = (newStartPage: number, newEndPage: number) => {
    setStartPage(newStartPage);
    setEndPage(newEndPage);
    const validStartParent = findParentDocument(newStartPage);
    const validEndParent = findParentDocument(newEndPage);
    const validStart =
      validStartParent?.firstChild?.page ?? validStartParent?.firstChild?.firstChild?.page;
    const validEnd = validEndParent?.lastChild?.page ?? validEndParent?.lastChild?.lastChild?.page;

    const formattedPages = pages?.map((page) => {
      return {
        ...page,
        id: Number(page.id),
        documentID: page.file_id,
        pageNumber: page.page_number,
        inPageRange:
          page.page_number >= validStart?.page_number && page.page_number <= validEnd?.page_number,
      };
    });
    setDocumentScrollerCompatiblePages(formattedPages ?? []);
    if (validStart?.id) {
      const currentPageDiv = document.getElementById(`chip-${validStart?.id}`);
      if (currentPageDiv) {
        currentPageDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }

    const startPageID = pages.find((p) => p.page_number === newStartPage)?.id;
    return startPageID;
  };

  useEffect(() => {
    // todo - we can use the document tree to do this much more eloquently
    function getGroupAndAttachmentIdsForPages(pages: PageObject[]) {
      // document scroller breaks without number ids for pages, so we need to keep this one

      const topEdgeCountByPageID: Record<string, number> = {};
      const groupIDByPageID: Record<string, number> = {};
      const attachmentIDByPageID: Record<string, number> = {};

      let currentDepth = 0;
      let currentGroupID = 1;
      let currentAttachmentID = 1;

      pages.forEach((page, index) => {
        page.document_edges = page.document_edges.sort(compareEdges);

        let topEdgesForPage = 0;

        page.document_edges.forEach((edge) => {
          if (edge.type === 'start') {
            currentDepth += 1;
            topEdgesForPage += 1;
          }
        });
        topEdgeCountByPageID[page.id] = topEdgesForPage;
        // current depth should be taken before the end edges of page are processed
        if (index !== 0 && currentDepth > 0 && currentDepth === topEdgeCountByPageID[page.id]) {
          currentGroupID += 1;
        }

        groupIDByPageID[page.id] = currentGroupID;
        if (currentDepth === 2 && topEdgeCountByPageID[page.id] >= 1) {
          currentAttachmentID += 1;
        }
        attachmentIDByPageID[page.id] = currentAttachmentID;

        page.document_edges.forEach((edge) => {
          if (edge.type === 'end') {
            if (currentDepth > 0) {
              currentDepth -= 1;
            }
          }
        });
      });
      return {
        groupIDByPageID,
        attachmentIDByPageID,
      };
    }

    const pagesRemovedDismissedEdges = [];
    for (const page of pages) {
      const edges = page.document_edges.sort(compareEdges);

      pagesRemovedDismissedEdges.push({ ...page, document_edges: edges });
    }
    setFilteredPages(pagesRemovedDismissedEdges);
    const { groupIDByPageID: newGroupIDByPageID, attachmentIDByPageID: newAttachmentIDByPageID } =
      getGroupAndAttachmentIdsForPages(pagesRemovedDismissedEdges);
    setGroupIDByPageID(newGroupIDByPageID);
    setAttachmentIDByPageID(newAttachmentIDByPageID);
  }, [pages]);

  const handleUpdateSequenceWithDiff = (
    newSequence: bigint,
    sequenceDiff: { page_id: bigint; edges: DocumentEdge[] }[],
  ) => {
    setSequence(newSequence);
    const updatedPages = [...pages];
    for (const diff of sequenceDiff) {
      const pageIndex = pageIndexById.get(String(diff.page_id));
      const currentPage = updatedPages[pageIndex!];
      if (currentPage) {
        currentPage.document_edges = [...diff.edges];
      }
    }
    setPages(updatedPages);
    const parsed = parsePages(updatedPages);

    if (parsed.type === 'error') {
      setDocumentTree(null);
      setParseError(parsed.error);
      setStatus('errored');
    } else {
      setDocumentTree(parsed.value);
      setParseError(null);
      setStatus('idle');
    }
  };

  useEffect(() => {
    if (sequenceQuery.isLoading) {
      return;
    }
    if (sequenceQuery.data?.sequence > (sequence ?? -1)) {
      handleUpdateSequenceWithDiff(
        sequenceQuery.data.sequence ?? sequence,
        sequenceQuery.data.diff ?? [],
      );
    }
  }, [sequenceQuery, fileId]);

  const groupedDocumentTree = useMemo(() => {
    if (!documentTree) {
      return [];
    }
    const treeList = [];
    let currentNode = documentTree.firstChild;
    while (currentNode) {
      const document = [];
      let currentPage = currentNode.firstChild;
      while (currentPage) {
        if (currentPage.page) {
          // PageNode
          document.push(currentPage.page);
        } else {
          // DocumentNode
          const attachment = [];
          let currentAttachmentPage = currentPage.firstChild;
          while (currentAttachmentPage) {
            attachment.push(currentAttachmentPage.page);
            currentAttachmentPage = currentAttachmentPage.nextSibling;
          }
          document.push(attachment);
        }
        currentPage = currentPage.nextSibling;
      }
      treeList.push(document);
      currentNode = currentNode.nextSibling;
    }
    return treeList;
  }, [documentTree]);

  const commitEdges = useCallback(
    async (command: string, pageId: any) => {
      setStatus('staged');
      try {
        const response = await retryRequest(
          () => validateAndUpdateDocumentStructure(caseId, fileId, command, pageId, sequence),
          1,
        );

        if (response.data.status !== 'resolved') {
          toast.error('There was an error while updating the document edges. Please try again.');
          setStatus('idle');
          refreshPages();
          return;
        }

        const updatedEdges = response.data.data;
        if (updatedEdges == null) {
          toast.error('There was an error while updating the document edges. Please try again.');
          setStatus('idle');
          refreshPages();
          return;
        }

        if (response.data.sequence) {
          handleUpdateSequenceWithDiff(response.data.sequence, updatedEdges);
        } else {
          refreshPages();
        }
        setStatus('saved');
      } catch (error) {
        toast.error('There was an error while updating the document edges. Please try again.');
        setStatus('idle');
      }
    },
    [documentTree, pages, pageIndexById, sequence],
  );

  const canSplitAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canSplitAt(pageId);
    },
    [documentTree, status],
  );

  const splitAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canSplitAt(pageId)) {
        return;
      }

      commitEdges('split', pageId);
    },
    [documentTree, canSplitAt, commitEdges],
  );

  const canMergeAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canMergeAt(pageId);
    },
    [documentTree, status],
  );

  const mergeAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canMergeAt(pageId)) {
        return;
      }

      commitEdges('merge', pageId);
    },
    [documentTree, canMergeAt, commitEdges],
  );

  const canAttachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canAttachAt(pageId);
    },
    [documentTree, status],
  );

  const attachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canAttachAt(pageId)) {
        return;
      }

      commitEdges('attach', pageId);
    },
    [documentTree, canAttachAt, commitEdges],
  );

  const canDetachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canDetachAt(pageId);
    },
    [documentTree, status],
  );

  const detachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canDetachAt(pageId)) {
        return;
      }

      commitEdges('detach', pageId);
    },
    [documentTree, canDetachAt, commitEdges],
  );

  const depthAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!documentTree) {
        return 0;
      }
      // this isn't working right. Returns 3 when depth should be 1
      const calcDepth = documentTree.depthAt(pageId);
      return calcDepth;
    },

    [documentTree],
  );

  const canBuildGroupings = useCallback(
    (pageId?: string) => {
      if (pageId == null || groupIDByPageID == null || pages?.length === 0) {
        return false;
      }
      const startPageID = pages.find((p) => p.page_number === startPage)?.id ?? 0;
      const groupID = groupIDByPageID[pageId];
      const startGroupID = groupIDByPageID[startPageID];

      if (groupID > startGroupID) {
        return true;
      }
      return pageId === pages[pages.length - 1].id;
    },
    [groupIDByPageID, pages, startPage],
  );

  const findParentDocument = (pageNumber: number) => {
    const pageID = pages.find((p) => p.page_number === pageNumber)?.id;
    if (!pageID) {
      return null;
    }
    const depth = depthAt(pageID) - 2;
    if (depth === 1) {
      const current = documentTree?.findPage(pageID);
      if (current?.parent) {
        return current.parent;
      }
    }
    if (depth === 2) {
      const current = documentTree?.findPage(pageID);
      if (current?.parent?.parent) {
        return current.parent.parent;
      }
    }
  };

  const buildGroupings = useCallback(
    async (startPage: number, endPage: number, keepEndPage: boolean) => {
      if (caseId == null || fileId == null) {
        return { status: 'rejected' };
      }
      const validStartParent = findParentDocument(startPage);
      const validStart =
        validStartParent?.firstChild?.page ?? validStartParent?.firstChild?.firstChild?.page;
      let validEndPage = endPage;
      if (!keepEndPage) {
        const validEndParent = findParentDocument(endPage);
        validEndPage =
          validEndParent?.lastChild?.page?.page_number ??
          validEndParent?.lastChild?.lastChild?.page?.page_number;
      }

      if (!validStart || !validEndPage) {
        return { status: 'rejected' };
      }
      setStatus('saving');
      try {
        const response = await retryRequest(
          () => buildGroupingsForCaseFile(caseId, fileId, validStart.page_number, validEndPage),
          1,
        );
        if (response.status === 'resolved') {
          queryClient.invalidateQueries(['files', caseId]);
          apolloClient.refetchQueries({
            include: [GetTimelineEntriesDocument, GetTimelineEntriesDescriptorsDocument],
          });

          toast.success('Groupings built successfully');
          setStatus('saved');
          refreshPages();
          return { status: 'resolved', groupingComplete: response.data?.groupingCompleted };
        }

        toast.error('Failed to build groupings');
        setStatus('idle');
        return { status: 'rejected' };
      } catch (error) {
        toast.error('Failed to build groupings');
        setStatus('idle');
        return { status: 'rejected' };
      }
    },
    [caseId, fileId, documentTree],
  );

  const resetEdges = useCallback(
    async (created: 'qa' | 'mdoc', start_page: number, end_page: number) => {
      if (caseId == null || fileId == null || !start_page || !end_page) {
        return;
      }
      setStatus('saving');
      const response = await resetEdgesCall(caseId, fileId, created, start_page, end_page);
      if (response.status === 'rejected') {
        toast.error('Failed to reset edges');
        setStatus('idle');
        return;
      }
      await refreshPages();
      setStatus('saved');
      toast.success(`All edges were reset between page ${startPage} and page ${endPage}.`);
    },
    [caseId, fileId, refreshPages],
  );

  useEffect(() => {
    const validStartParent = findParentDocument(startPage ?? 1);
    const validEndParent = findParentDocument(endPage ?? 0);
    const validStart =
      validStartParent?.firstChild?.page ?? validStartParent?.firstChild?.firstChild?.page;
    const validEnd = validEndParent?.lastChild?.page ?? validEndParent?.lastChild?.lastChild?.page;

    const formattedPages = pages?.map((page) => {
      return {
        ...page,
        id: Number(page.id),
        documentID: page.file_id,
        pageNumber: page.page_number,
        inPageRange:
          page.page_number >= validStart?.page_number && page.page_number <= validEnd?.page_number,
      };
    });
    setDocumentScrollerCompatiblePages(formattedPages ?? []);
  }, [documentTree]);

  const groupingActionOnClicks: GroupingActionOnClicks = {
    splitAt,
    mergeAt,
    attachAt,
    detachAt,
  };

  const getButtonEnabledStatuses: GroupingActionStatuses = {
    canSplitAt,
    canMergeAt,
    canAttachAt,
    canDetachAt,
  };

  return {
    status,
    pages,
    filteredPages,
    refreshPages,
    parseError,
    groupingActionOnClicks,
    getButtonEnabledStatuses,
    depthAt,
    buildGroupings,
    resetEdges,
    pageIndexById,
    groupIDByPageID,
    attachmentIDByPageID,
    canBuildGroupings,
    groupedDocumentTree,
    startPage,
    endPage,
    onChangePageRange,
    documentScrollerCompatiblePages,
  };
}

const fetchPages = async (caseId: string, fileId: string): Promise<PageObject[]> => {
  if (!caseId || !fileId) {
    return [];
  }
  const response = await retryRequest<PageObject[]>(getPagesByCaseFile, 1, caseId, fileId);
  if (response.status === 'resolved') {
    return response.data!;
  }
  throw new Error('Failed to fetch pages');
};

const resetEdgesCall = async (
  caseId: string,
  fileId: string,
  created: 'qa' | 'mdoc',
  start_page: number,
  end_page: number,
): Promise<{ status: 'resolved' | 'rejected'; data?: DocumentEdge[] }> => {
  return retryRequest<DocumentEdge[]>(resetDocumentEdgesForCaseFile, 1, caseId, fileId, {
    created,
    start_page,
    end_page,
  });
};

export type DocumentScrollerCompatiblePage = Omit<PageObject, 'id' | 'file_id' | 'page_number'> & {
  id: number;
  documentID: string;
  pageNumber: number;
  inPageRange: boolean;
};

export type GroupingActionOnClicks = {
  splitAt: (pageID: string) => void;
  mergeAt: (pageID: string) => void;
  attachAt: (pageID: string) => void;
  detachAt: (pageID: string) => void;
};

export type GroupingActionStatuses = {
  canSplitAt: (pageID: string) => boolean;
  canMergeAt: (pageID: string) => boolean;
  canAttachAt: (pageID: string) => boolean;
  canDetachAt: (pageID: string) => boolean;
};
