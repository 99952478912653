import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { apiBaseUrl } from '../../../config/apiConfig';
import { authenticatedRequest } from '../../../api';

async function fetchEntities({ caseID, entryID }: { caseID: string; entryID?: any }) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `${apiBaseUrl}/api/v1/cases/${caseID}/entities${entryID ? `?entry_id=${entryID}` : ''}`,
  });
  return res.data;
}

const useEntities = (caseID: string, entryID?: any) => {
  const queryKey = useMemo(
    () => (entryID ? ['entities', caseID, entryID] : ['entities', caseID]),
    [caseID, entryID],
  );
  const { data, isLoading } = useQuery(queryKey, () => fetchEntities({ caseID, entryID }), {
    enabled: !!caseID,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const {
    filteredEntryEntities: filteredPeopleForTimelineEntry,
    filteredCaseEntities: filteredPeopleForCase,
  } = filterUniqueEntities(data?.peopleForTimelineEntry, data?.peopleForCase);

  const sortedPeopleForEntry = sortEntities(filteredPeopleForTimelineEntry);
  const sortedPeopleForCase = sortEntities(filteredPeopleForCase);

  const {
    filteredEntryEntities: filteredOrgsForTimelineEntry,
    filteredCaseEntities: filteredOrgsForCase,
  } = filterUniqueEntities(data?.organizationsForTimelineEntry, data?.organizationsForCase);

  const sortedOrgsForEntry = sortEntities(filteredOrgsForTimelineEntry);
  const sortedOrgsForCase = sortEntities(filteredOrgsForCase);

  const result = useMemo(
    () => ({
      sortedAuthorsForCase: sortedPeopleForCase ?? [],
      sortedAuthorsForEntry: sortedPeopleForEntry ?? [],
      allAuthors: data?.peopleForCase ?? [],
      sortedOrgsForEntry: sortedOrgsForEntry ?? [],
      sortedOrgsForCase: sortedOrgsForCase ?? [],
      allOrgs: data?.organizationsForCase ?? [],
      isLoading,
    }),
    [data, isLoading],
  );

  return result;
};

export default useEntities;

const filterUniqueEntities = (entryEntities: Entity[], caseEntities: Entity[]) => {
  const uniqueEntities = new Set<string>();
  const filterEntities = (entities: Entity[]) => {
    return entities.filter((entity) => {
      if (!uniqueEntities.has(entity.value)) {
        uniqueEntities.add(entity.value);
        return true;
      }
      return false;
    });
  };
  const filteredEntryEntities = entryEntities ? filterEntities(entryEntities) : [];
  const filteredCaseEntities = caseEntities ? filterEntities(caseEntities) : [];
  return { filteredEntryEntities, filteredCaseEntities };
};

const sortEntities = (entities: Entity[]) => {
  return entities
    ? entities.sort((a, b) => (a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1))
    : [];
};

type Entity = {
  case_id: string;
  confidence: number | null;
  dismissed: boolean;
  hidden: boolean;
  id: number;
  ml_predicted: number;
  origin: string;
  page_id: number;
  start_position: number;
  value: string;
};
