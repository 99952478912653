import { Typography, LinearProgress, linearProgressClasses } from '@mui/material';
import { FileStatus } from '../../api';
import HelpIcon from '../../components/NewTemplateModal/HelpIcon';

export default function FileStatusItem({
  fileStatus,
  isFileProcessor,
  uploadProgress,
  errorType,
}: {
  fileStatus: FileStatus;
  isFileProcessor: boolean;
  uploadProgress: number | null;
  errorType: string;
}) {
  const fileStatusLabelsByValue = {
    GROUPING: isFileProcessor ? 'Grouping' : 'Processing',
    TAGGING: isFileProcessor ? 'Tagging' : 'Processing',
    QA_REQUIRED: isFileProcessor ? 'QA Required' : 'Processing',
    APPROVED: isFileProcessor ? 'Approved' : 'Processing',
    COMPLETE: isFileProcessor ? 'Complete' : 'Ready',
    PENDING: isFileProcessor ? 'Not Started' : 'Processing',
    ERROR: 'Error',
    CLOSED: 'Closed',
    UPLOADING: 'Uploading',
    COPYING: '',
    SKIP_MDOC: isFileProcessor ? 'Skipped' : 'Processing',
    AUTOMATED_PROCESSING: isFileProcessor ? 'Automated Processing' : 'Processing',
  };
  const colourByStatus = {
    GROUPING: isFileProcessor ? 'status.warning' : 'themeOrange.light',
    TAGGING: isFileProcessor ? 'status.warning' : 'themeOrange.light',
    QA_REQUIRED: isFileProcessor ? 'status.success' : 'themeOrange.light',
    APPROVED: isFileProcessor ? 'status.success' : 'themeOrange.light',
    COMPLETE: 'status.success',
    PENDING: isFileProcessor ? 'status.error' : 'themeOrange.light',
    ERROR: 'status.error',
    CLOSED: 'lightgrey',
    UPLOADING: isFileProcessor ? 'themeOrange.main' : 'primary.dark',
    COPYING: '',
    SKIP_MDOC: isFileProcessor ? 'status.success' : 'themeOrange.light',
    AUTOMATED_PROCESSING: 'themeOrange.light',
  };

  const tooltipByError: { [key: string]: string } = {
    'File is already in this case':
      'This file has already been uploaded. Please check your files or upload a different one.',
    'Incompatible file type':
      'This file type is not supported. Please upload PDF, JPEG, DOCX, TXT, TIFF or PNG files.',
    'Error processing file':
      'An error occurred while processing the file. Please try uploading the file again or contact support if the problem persists.',
    '': '',
  };

  return (
    <>
      {fileStatus === 'UPLOADING' &&
        (isFileProcessor ? (
          <Typography
            sx={{
              color: colourByStatus[fileStatus],
              fontSize: '0.8rem',
              fontWeight: 600,
              padding: '5px',
            }}
          >
            {fileStatusLabelsByValue[fileStatus]}
          </Typography>
        ) : (
          <div>
            <Typography
              sx={{
                color: colourByStatus[fileStatus],
                fontSize: '0.8rem',
                fontWeight: 600,
                padding: '5px',
              }}
            >
              {`${fileStatusLabelsByValue[fileStatus]} ${!uploadProgress ? '' : uploadProgress}0%`}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={uploadProgress ? uploadProgress * 10 : 0}
              sx={{
                height: 5,
                borderRadius: 8,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: 'lightgrey',
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor: 'primary.dark',
                },
              }}
            />
          </div>
        ))}
      {fileStatus === 'ERROR' && errorType && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                color: colourByStatus[fileStatus],
                fontSize: '0.8rem',
                fontWeight: 600,
                padding: '5px',
              }}
            >
              {`Error: ${errorType}`}
            </Typography>
            <HelpIcon
              helpText={tooltipByError[errorType]}
              sx={{ color: 'status.error', marginLeft: '2px' }}
            />
          </span>
        </div>
      )}
      {fileStatus !== 'UPLOADING' && fileStatus !== 'ERROR' && (
        <Typography
          sx={{
            color: colourByStatus[fileStatus],
            fontSize: '0.8rem',
            fontWeight: 600,
            padding: '5px',
          }}
        >
          {fileStatusLabelsByValue[fileStatus]}
        </Typography>
      )}
    </>
  );
}
