import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getUsersForAdmin,
  resetPasswordForUser,
  createNewUser,
  deleteUser,
  updateUser,
  getUserGroups,
  createNewUserProps,
  User,
  resetMFAForUser,
  createUserGroup,
} from '../../../api';

export function useAdminUsers() {
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(['getUsersForAdmin'], getUsersForAdmin, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const { data: userGroups } = useQuery(['getUserGroups'], getUserGroups, {
    staleTime: 1000 * 60 * 10,
  });

  const resetPassword = async (userId: string, email: string) => {
    try {
      await resetPasswordForUser(userId, email);
      toast.success('Temporary password set and emailed successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    } catch (error) {
      toast.error('Failed to reset password. Please try again.');
      throw error;
    }
  };

  const resetMFA = async (userId: string) => {
    try {
      await resetMFAForUser(userId);
      toast.success('MFA reset successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    } catch (error) {
      toast.error('Failed to reset MFA. Please try again.');
      throw error;
    }
  };

  const createNewUserFunc = async (newUser: createNewUserProps) => {
    try {
      await createNewUser(newUser);
      toast.success('User created successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    } catch (error) {
      toast.error('Failed to create user. Please try again.');
      throw error;
    }
  };

  const deleteUserFunc = async (userId: string) => {
    try {
      await deleteUser(userId);
      toast.success('User deleted successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    } catch (error) {
      toast.error('Failed to delete user. Please try again.');
      throw error;
    }
  };

  const updateUserFunc = async (userId: string, updatedFields: Partial<User>) => {
    if (
      ('given_name' in updatedFields && !updatedFields.given_name?.trim()) ||
      ('family_name' in updatedFields && !updatedFields.family_name?.trim())
    ) {
      toast.error('First Name and Last Name cannot be empty.');
      return;
    }

    const existingUser = data?.data?.find((user) => user.user_id === userId);

    if (!existingUser) {
      console.error('User not found for update.');
      return;
    }

    const fieldsToUpdate = Object.entries(updatedFields).reduce(
      (changedFields, [key, newValue]) => {
        if (existingUser[key as keyof User] !== newValue) {
          // @ts-ignore
          changedFields[key as keyof User] = newValue;
        }
        return changedFields;
      },
      {},
    );

    if (Object.keys(fieldsToUpdate).length === 0) {
      return;
    }

    try {
      await updateUser({ userId, ...fieldsToUpdate });
      toast.success('User updated successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user. Please try again.');
    }
  };

  const createNewUserGroup = async (groupName: string) => {
    try {
      await createUserGroup(groupName);
      toast.success('Group created successfully');
      queryClient.invalidateQueries(['getUserGroups']);
    } catch (error) {
      toast.error('Failed to create group.');
      throw error;
    }
  };
  return {
    userList: data?.data ?? [],
    isLoading,
    isError,
    resetPassword,
    resetMFA,
    createNewUserFunc,
    deleteUserFunc,
    updateUserFunc,
    userGroups,
    createNewUserGroup,
  };
}
