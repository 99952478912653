import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { Container, Box, Typography, Button } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import PageHeader from '../../components/common/PageHeader';
import { useUser } from '../../library/contexts/AuthContext';
import AdminHeader from './AdminHeader';
import useDisplayStore from '../Timeline/useDisplayStore';
import { reprocessCase, updateSupportAccess } from '../../api';
import { useAdminCases } from '../DocumentGrouping/api-hooks/useAdminCases';
import { useIsSiftMedAdmin } from '../AccountSettings/useAdmin';

function CasesManagement() {
  const { user } = useUser();
  const windowSize = useDisplayStore((state) => state.windowSize);
  const queryClient = useQueryClient();
  const { data: caseList } = useAdminCases();
  const isSiftMedAdmin = useIsSiftMedAdmin();

  const updateSupport = async (caseId: string, action: 'ADD' | 'REMOVE') => {
    try {
      await updateSupportAccess(caseId, action);
      queryClient.invalidateQueries(['getCasesForAdmin']);
      toast.success('Support access updated successfully');
    } catch (error) {
      toast.error('Failed to update support access');
    }
  };

  const formatStatus = (status: string): string => {
    return status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const rows = useMemo(() => {
    return caseList?.map((caseItem) => ({
      id: caseItem.id,
      name: caseItem.name,
      status: caseItem.status,
      referenceNumber: caseItem.referenceNumber,
      hasSupport: caseItem.hasSupport,
    }));
  }, [caseList]);

  const columns = [
    {
      field: 'ref',
      headerName: '#',
      type: 'number',
      sortable: true,
      valueGetter: (params) => params.row.referenceNumber,
      flex: 0.3,
      renderCell: (params) => {
        return params.row.referenceNumber;
      },
      sortComparator: (a, b) => a - b,
    },
    {
      field: 'caseName',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      valueGetter: (params) => params.row.name,
      flex: 1.5,
      renderCell: (params) => {
        return params.row.name;
      },
      sortComparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return formatStatus(params.row.status);
      },
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              classes="primary"
              sx={{ fontSize: '0.8rem', mr: '0.5rem' }}
              onClick={async () => {
                try {
                  await reprocessCase(params.row.id as string);
                  toast.success('Case status updated successfully');
                  queryClient.invalidateQueries(['getCasesForAdmin']);
                } catch (error) {
                  console.log(error);
                  toast.error('Case status update failed');
                }
              }}
              disabled={params.row.status !== 'READY'}
            >
              Reprocess
            </Button>
            {params.row.hasSupport ? (
              <Button
                variant="contained"
                classes="primary"
                onClick={() => updateSupport(params.row.id as string, 'REMOVE')}
              >
                <Remove sx={{ fontSize: '0.9rem', mr: '0.2rem' }} /> Support
              </Button>
            ) : (
              <Button
                variant="contained"
                classes="primary"
                onClick={() => updateSupport(params.row.id as string, 'ADD')}
              >
                <Add sx={{ fontSize: '0.9rem', mr: '0.2rem' }} /> Support
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return !isSiftMedAdmin ? (
    <div>Unauthorized. Please contact SiftMed support</div>
  ) : (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <AdminHeader />

      <PageHeader title="Case Management Dashboard" />
      <Box sx={myCasesStyles.displayContainer}>
        {caseList?.length > 0 ? (
          <DataGrid
            columns={columns ?? []}
            rows={rows ?? []}
            pageSizeOptions={[5, 10]}
            sx={{
              width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
              border: '1px solid #00214733',
              borderRadius: '8px',
              fontSize: '0.8rem',
              boxShadow: 'none',
              backgroundColor: 'white',
              '& .MuiDataGrid-cell:focus-within': {
                outline: ' none',
              },
            }}
          />
        ) : (
          <Box sx={{ margin: 'auto' }}>
            <Typography variant="h5">No Cases</Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default CasesManagement;

const myCasesStyles = {
  title: {
    fontSize: '1.875rem',
    display: 'inline-block',
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '1rem',
  },
  container: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  casesContainer: {
    marginTop: '2rem',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  displayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    width: '100%',
    marginTop: '2rem',
  },
};
