/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material';
import React from 'react';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import TimelineView from './TimelineView';

/*
Layout - View selection, search, and document/page view.
Breaks down pages into segments
*/

export default function Timeline() {
  const params = useParams();
  const { caseID, entryID, pageID, timelineID } = params;
  const containerRef = React.useRef(null);

  const { handleIsSegmentDownloading } = useOutletContext();

  // Handle legacy timeline paths
  if (timelineID) {
    return <Navigate to={`/case/${caseID}/timeline/${entryID}/${pageID}?pageID=${pageID}`} />;
  }

  return (
    <Box
      id="timeline-outer-container"
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        maxWidth: '100%',
      }}
    >
      <Box
        ref={containerRef}
        id="timeline-list-container"
        sx={{
          padding: 0,
          flex: 1,
          minHeight: 0,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <TimelineView caseID={caseID} handleIsSegmentDownloading={handleIsSegmentDownloading} />
      </Box>
    </Box>
  );
}
