import { Box, Button, Typography, Chip, Divider } from '@mui/material';
import { Close, AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState, useMemo, Fragment } from 'react';
import { isAfter, isBefore, isWithinInterval, parseISO } from 'date-fns';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import FeedbackPopup from '../../components/common/FeedbackPopup';
import Loading from '../../components/common/Loading';
import Searchbar from '../../components/Searchbar';
import { formatDisplayDate } from '../../library/utilities/useDates';
import EditNote from '../Notes/EditNote';
import NoteCard from '../Notes/NoteCard';
import {
  orderNotesByDocument,
  orderNotesByFirstTag,
  orderNotesByDate,
  orderNotesByPhysician,
  getNotesDateWithoutDuplicates,
  filterNotesByDocumentPage,
} from '../Notes/useNotes';
import { NotesListOrderEnum } from '../Notes/types/noteTypes';
import { useUserGroup } from '../MyCases/useCases';
import FilterPopover from './Components/FilterPopover';
import NewNote from '../Notes/NewNote';
import NotesStyled from '../../components/icons/NotesStyled';
import NoteFilters from './Components/NoteFilters/NoteFilters';
import useNotesStore from '../Notes/useNotesStore';
import usePDFViewerStore from '../Case/usePDFViewerStore';
import InsertNotesButton from '../ReportEditor/InsertNotesButton';
import useDocumentNames from '../Case/gql/useDocumentNames';
import useScreenCaptureStore from '../ScreenCapture/useScreenCaptureStore';
import useNotes from '../Notes/gql/useNotes';
import useCreateNote from '../Notes/gql/useCreateNote';
import { useCustomTagsQuery } from '../../__generated__/graphql';
import useDeleteNote from '../Notes/gql/useDeleteNote';
import { useUser } from '../../library/contexts/AuthContext';

const useScreenCapStore = createSelectorFunctions(useScreenCaptureStore);

export default function NotesView({ containerName, showInsertIntoReportIcon = false }) {
  const { caseID, pageID } = useParams();
  const documentNames = useDocumentNames(caseID);

  const location = useLocation();
  const reportEditorOpen = location.pathname.indexOf('/reports') > -1;

  const { userId } = useUser();
  const { loading: tagsLoading, data } = useCustomTagsQuery({
    variables: {
      where: {
        userID: { equals: userId },
      },
    },
  });
  const noteTags = data?.custom_tags ?? [];
  const { loading: notesLoading, data: notes } = useNotes({
    case_id: caseID,
  });

  const [deleteNote] = useDeleteNote();
  const [saveNote, { loading: saveNoteLoading }] = useCreateNote();
  const setScreenCaptures = useScreenCapStore.use.setScreenCaptures();
  const setCapturingFor = useScreenCapStore.use.setCapturingFor();
  const showOnlyMyNotes = useNotesStore((state) => state.showOnlyMyNotes);
  const tagsBeingFiltered = useNotesStore((state) => state.tagsBeingFiltered);
  const {
    afterDate,
    beforeDate,
    clearFilters,
    notesOrder,
    physician,
    setAfterDate,
    setBeforeDate,
    setIsDateRangeValid,
    setSingleNoteToInsert,
    setPhysician,
    setShowOnlyMyNotes,
    setTagsBeingFiltered,
    noteBeingEdited,
    setNoteBeingEdited,
  } = useNotesStore(
    (state) => ({
      afterDate: state.afterDate,
      beforeDate: state.beforeDate,
      clearFilters: state.clearFilters,
      notesOrder: state.notesOrder,
      physician: state.physician,
      setAfterDate: state.setAfterDate,
      setBeforeDate: state.setBeforeDate,
      setIsDateRangeValid: state.setIsDateRangeValid,
      setSingleNoteToInsert: state.setSingleNoteToInsert,
      setPhysician: state.setPhysician,
      setShowOnlyMyNotes: state.setShowOnlyMyNotes,
      setTagsBeingFiltered: state.setTagsBeingFiltered,
      noteBeingEdited: state.noteBeingEdited,
      setNoteBeingEdited: state.setNoteBeingEdited,
    }),
    shallow,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const setIsScreenCapturing = usePDFViewerStore((state) => state.setIsScreenCapturing);
  const docBeingFiltered = searchParams.get('notes__docId');
  const pageBeingFiltered = parseInt(searchParams.get('notes__pageId'), 10);
  const currentDocumentID = searchParams.get('documentID') ?? null;

  const removePageFilter = () => {
    searchParams.delete('notes__pageId');
    setSearchParams(searchParams);
  };

  const removeDocFilter = () => {
    searchParams.delete('notes__docId');
    setSearchParams(searchParams);
  };

  const [noteSearchStr, setNoteSearchStr] = useState('');
  const [notesToRender, setNotesToRender] = useState({});
  const [noteUpdated, setNoteUpdated] = useState(false);
  const [noteCreated, setNoteCreated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteFeedbackOpen, setDeleteFeedbackOpen] = useState(false);
  const [noteCreationOpen, setNoteCreationOpen] = useState(false);

  async function handleDelete(noteID) {
    await deleteNote({ variables: { id: noteID } });
    setDeleteFeedbackOpen(true);
  }

  const { user } = useUser();
  const { data: userGroup } = useUserGroup();

  // TODO: Rewrite this function to be more efficient/clear
  async function displayNotes() {
    if (!notes || tagsLoading) {
      return;
    }
    let notesRenderObj = {};
    const parseAfterDate = parseISO(afterDate);
    const parseBeforeDate = parseISO(beforeDate);
    let filteredNotes = notes.filter(({ tags }) => {
      if (tags.length === 0) {
        return !tagsBeingFiltered.includes('untagged');
      }
      return tags.some((tag) => !tagsBeingFiltered.includes(tag.tagID));
    });
    //filter notes by either my notes only, or 'allNotes' for my notes and shared notes
    if (showOnlyMyNotes) {
      filteredNotes = filteredNotes.filter((note) => note.ownerID === user.username);
    } else {
      filteredNotes = filteredNotes.filter(
        (note) => note.ownerID === user.username || note.privateNote === 0,
      );
    }

    if (physician) {
      if (physician === 'No Physician') {
        filteredNotes = filteredNotes.filter((note) => !note.physician);
      } else {
        filteredNotes = filteredNotes.filter((note) => note.physician === physician);
      }
    }
    if (isBefore(parseBeforeDate, parseAfterDate)) {
      setIsDateRangeValid(false);
      filteredNotes = [{}];
    } else {
      setIsDateRangeValid(true);
      if (afterDate && !beforeDate) {
        filteredNotes = filteredNotes.filter((note) =>
          isAfter(parseISO(note.date), parseAfterDate),
        );
      }
      if (!afterDate && beforeDate) {
        filteredNotes = filteredNotes.filter((note) =>
          isBefore(parseISO(note.date), parseBeforeDate),
        );
      }
      if (afterDate && beforeDate) {
        filteredNotes = filteredNotes.filter((note) =>
          isWithinInterval(parseISO(note.date), {
            start: parseAfterDate,
            end: parseBeforeDate,
          }),
        );
      }
    }
    const uniqueDates = getNotesDateWithoutDuplicates(filteredNotes);
    if (noteSearchStr !== '') {
      filteredNotes = filteredNotes.filter(
        ({ title, physician, body }) =>
          body?.toLowerCase().includes(noteSearchStr.toLowerCase()) ||
          title?.toLowerCase().includes(noteSearchStr.toLowerCase()) ||
          physician?.toLowerCase().includes(noteSearchStr.toLowerCase()),
      );
    }

    if (docBeingFiltered) {
      filteredNotes = filterNotesByDocumentPage(docBeingFiltered, pageBeingFiltered, filteredNotes);
    }

    if (notesOrder === NotesListOrderEnum.Document) {
      notesRenderObj = orderNotesByDocument(filteredNotes, documentNames);
    } else if (notesOrder === NotesListOrderEnum.FirstTag) {
      notesRenderObj = orderNotesByFirstTag(filteredNotes, noteTags);
    } else if (notesOrder === NotesListOrderEnum.Physician) {
      notesRenderObj = orderNotesByPhysician(filteredNotes);
    } else {
      notesRenderObj = orderNotesByDate(filteredNotes, uniqueDates, notesOrder);
    }
    setNotesToRender(notesRenderObj);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    displayNotes();
  }, [
    notes,
    tagsBeingFiltered,
    noteTags,
    notesOrder,
    noteSearchStr,
    afterDate,
    beforeDate,
    showOnlyMyNotes,
    docBeingFiltered,
    pageBeingFiltered,
    physician,
  ]);
  const editNote = (noteID) => {
    setNoteBeingEdited(notes.find((n) => n.noteID === noteID));
  };
  const filtersApplied = useMemo(
    () => ({
      [`After: ${formatDisplayDate(afterDate)}`]: {
        value: afterDate,
        setValue: setAfterDate,
      },
      [`Before: ${formatDisplayDate(beforeDate)}`]: {
        value: beforeDate,
        setValue: setBeforeDate,
      },
      'My Notes': {
        value: showOnlyMyNotes,
        setValue: setShowOnlyMyNotes,
      },
      [`Doc: ${documentNames?.[docBeingFiltered] ?? ''}`]: {
        value: docBeingFiltered,
        setValue: removeDocFilter,
      },
      [`Page: ${pageBeingFiltered}`]: {
        value: pageBeingFiltered,
        setValue: removePageFilter,
      },
      [`${physician}`]: {
        value: physician,
        setValue: setPhysician,
      },
    }),
    [
      physician,
      docBeingFiltered,
      documentNames,
      pageBeingFiltered,
      beforeDate,
      afterDate,
      showOnlyMyNotes,
      setAfterDate,
      setBeforeDate,
      setShowOnlyMyNotes,
      removeDocFilter,
      removePageFilter,
      setPhysician,
    ],
  );
  const tagsFiltered = useMemo(
    () => noteTags?.filter((tag) => !tagsBeingFiltered.includes(tag.tagID)),
    [noteTags, tagsBeingFiltered],
  );

  if (tagsFiltered?.length !== noteTags?.length) {
    //if "show all tags" is not selected
    tagsFiltered?.forEach((tag) => {
      filtersApplied[`Tag: ${tag.title}`] = {
        value: 'tagHaveFilteringApplied',
        setValue: () => setTagsBeingFiltered([...tagsBeingFiltered, tag.tagID]),
      };
    });
  }

  //if no tags are selected show a chip for 'none'
  if (tagsBeingFiltered?.length === (noteTags?.length ?? 0) + 1) {
    filtersApplied[`Tags: None`] = {
      value: 'tagHaveFilteringApplied',
      setValue: () => setTagsBeingFiltered([]),
    };
  }

  //if untagged is selected and there are actually filters applied
  if (tagsBeingFiltered?.includes('untagged') && tagsBeingFiltered?.length > 0) {
    filtersApplied[`Tags: Untagged`] = {
      value: 'tagHaveFilteringApplied',
      setValue: () => setTagsBeingFiltered(tagsBeingFiltered.filter((tag) => tag !== 'untagged')),
    };
  }
  const filtersAppliedCount = useMemo(() => {
    let count = 0;
    Object.values(filtersApplied).forEach((filter) => {
      if (filter.value) {
        count += 1;
      }
    });
    return count;
  }, [filtersApplied]);
  const screenCaptures = useScreenCapStore.use
    .screenCaptures()
    .filter((screenCap) => screenCap.type === 'note');
  const maxCaptures = screenCaptures.length + (noteBeingEdited?.images?.length || 0) >= 9;

  useEffect(() => {
    if (!maxCaptures && (noteCreationOpen || noteBeingEdited)) {
      setIsScreenCapturing(true);
      setCapturingFor('note');
    } else {
      setIsScreenCapturing(false);
      setCapturingFor(undefined);
    }
  }, [noteCreationOpen, noteBeingEdited, setIsScreenCapturing]);

  const handleNoteEditClose = () => {
    setNoteBeingEdited(null);
    setNoteCreationOpen(false);
    setScreenCaptures([]);
    setIsScreenCapturing(false);
    setCapturingFor(undefined);
  };

  if (notesLoading || loading || saveNoteLoading || documentNames == null) {
    return (
      <div style={{ textAlign: 'center', marginTop: '10rem' }}>
        <Loading text="Loading Notes..." />
      </div>
    );
  }

  if (noteCreationOpen) {
    return (
      <NewNote
        open={noteCreationOpen}
        saveNote={saveNote}
        onClose={() => handleNoteEditClose()}
        onSuccess={() => setNoteCreated(true)}
        containerName={containerName}
      />
    );
  }

  if (noteBeingEdited !== null) {
    return (
      <EditNote
        noteBeingEdited={noteBeingEdited}
        onClose={() => handleNoteEditClose()}
        onSuccess={() => setNoteUpdated(true)}
        open={noteBeingEdited !== null}
        containerName={containerName}
      />
    );
  }

  return (
    <>
      <Box sx={{ backgroundColor: 'selectedGrey' }}>
        <Box
          sx={{
            padding: '1rem',
            backgroundColor: 'selectedGrey',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography color="primary" sx={{ color: 'primary', fontSize: '1.13rem' }}>
            {' '}
            Notes
          </Typography>
          {!reportEditorOpen && (
            <Button
              id={`${containerName}-tab-add-note-btn`}
              variant="contained"
              disabled={!pageID}
              size="small"
              sx={{ fontWeight: 600 }}
              onClick={() => {
                setNoteCreationOpen(true);
              }}
            >
              <AddCircleOutline sx={{ fontSize: '1.2rem', marginRight: '0.3rem' }} />
              Add Note
            </Button>
          )}
        </Box>
        <Divider sx={{ width: '90%', marginLeft: '5%', mt: '1.2%', mb: '4%' }} />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            padding: '.8rem',
            backgroundColor: 'selectedGrey',
            paddingTop: 0,
            overflow: 'hidden',
          }}
          id="notes-search-container"
        >
          <Searchbar
            label="Search Notes"
            searchStr={noteSearchStr}
            handleChange={(e) => setNoteSearchStr(e.target.value)}
            customStyles={{ flex: 90 }}
          />
          <FilterPopover filtersApplied={filtersAppliedCount > 0} containerName={containerName}>
            <NoteFilters
              showOnlyMyNotes={showOnlyMyNotes}
              setShowOnlyMyNotes={setShowOnlyMyNotes}
              afterDate={afterDate}
              setAfterDate={setAfterDate}
              beforeDate={beforeDate}
              setBeforeDate={setBeforeDate}
              physician={physician}
              setPhysician={setPhysician}
            />
          </FilterPopover>
        </Box>
        <Divider sx={{ width: '90%', marginLeft: '5%' }} />
      </Box>

      {userGroup !== 'Processors' && Object.keys(notesToRender).length > 0 ? (
        <>
          <Box
            id="notes-container"
            sx={{
              width: '100%',
              height: reportEditorOpen ? '77%' : 'calc(100% - 148px)',
              overflowY: 'auto',
              backgroundColor: 'selectedGrey',
            }}
          >
            {Object.keys(notesToRender).map((key) => (
              <Fragment key={`${key}_ID`}>
                <Box
                  sx={{
                    backgroundColor: 'selectedGrey',
                    margin: '0.5rem',
                    paddingTop: 0,
                    paddingBottom: '.5rem',
                  }}
                >
                  {notesToRender[key].map((note) => (
                    <NoteCard
                      note={note}
                      displayedName={key}
                      documentName={documentNames[note.documentID]}
                      key={note.noteID}
                      reportOpen={reportEditorOpen}
                      setSingleNoteToInsert={setSingleNoteToInsert}
                      textBeingSearched={noteSearchStr}
                      editNote={editNote}
                      handleDelete={handleDelete}
                      showInsertIntoReportIcon={showInsertIntoReportIcon}
                      currentDocumentID={currentDocumentID}
                      containerName={containerName}
                    />
                  ))}
                </Box>
              </Fragment>
            ))}
          </Box>
          {showInsertIntoReportIcon && (
            <InsertNotesButton notes={notesToRender} containerName={containerName} />
          )}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', width: '100%', marginTop: 10 }}>
          <NotesStyled />
          <>
            <Typography
              sx={{
                fontWeight: 600,
                fontColor: '#002147',
                fontSize: '0.9rem',
                marginTop: '1rem',
              }}
            >
              No Notes Found
            </Typography>
            <Typography
              sx={{
                fontColor: '#475467',
                fontSize: '0.75rem',
                width: '100%',
                px: '2.8rem',
              }}
            >
              {noteSearchStr !== '' || filtersAppliedCount.length > 0
                ? 'Please try updating your search or filters'
                : 'You haven’t created any notes yet! To create a note click the “Add Note” button above.'}
            </Typography>
          </>
        </Box>
      )}
      <FeedbackPopup
        text="Note updated successfully"
        open={noteUpdated}
        onClose={() => setNoteUpdated(false)}
      />
      <FeedbackPopup
        text="Note Successfully Deleted"
        open={deleteFeedbackOpen}
        onClose={() => setDeleteFeedbackOpen(false)}
      />
      <FeedbackPopup
        text="Note successfully created"
        open={noteCreated}
        onClose={() => setNoteCreated(false)}
      />
    </>
  );
}
