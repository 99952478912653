import { Box, Card, Stack, Typography, Tooltip, Checkbox } from '@mui/material';
import { useCallback, useMemo, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import IconButtonContainer from '../../components/common/IconButtonContainer';
import PageGridView from './Components/PageGridView';
import PageListView from './Components/PageListView';
import DocumentNameDisplay from './Components/DocumentNameDisplay';
import LinkWithQuery from './LinkWithQuery';
import CardMenu from './TimelineViewCardMenu';
import { TimelineEntry as TimelineEntryType } from './types/timelineTypes';
import useDisplayStore from './useDisplayStore';
import downloadSegment from './useSegment';
import { PageControls } from '../Page/types/pageTypes';
import { Note } from '../Notes/types/noteTypes';
// import MDocRules from './MDocRules';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import DuplicatesByDocument from '../../components/icons/DuplicatesByDocument';
import CaseContext from '../Case/CaseContext';
import Grouping from '../../components/icons/Grouping';
import useCaseFiles from '../Files/useCaseFiles';
import { SearchMatch } from '../../library/utilities/types/search';
import { useUpdateDocumentRotation } from '../ReportEditor/api-queries/useUpdateDocumentRotation';
import { convertDateStringToUTCDate } from '../../library/utilities/useDates';
import useBulkApplyStore from './useBulkApplyStore';

export default function TimelineEntry({
  entry,
  caseID,
  handleIsSegmentDownloading,
  showGroupingButton,
  handleOpenGroupingModal,
  pageControls,
  notes,
  currentDocumentID,
  pageSearchResultsMap,
}: EntryProps) {
  const { id: entryID, pages, startDate, endDate, documentTags } = entry;

  const showThumbnails = useDisplayStore((state) => state.showThumbnails);
  const { bulkApplyInProgress, bulkApplySelected, selectedDocuments, setSelectedDocuments } =
    useBulkApplyStore(
      (state) => ({
        bulkApplyInProgress: state.bulkApplyInProgress,
        bulkApplySelected: state.isDocumentSelected(String(entryID)),
        selectedDocuments: state.selectedDocuments,
        setSelectedDocuments: state.setSelectedDocuments,
      }),
      shallow,
    );

  const updateDocumentRotation = useUpdateDocumentRotation();

  const params = useParams();
  const { caseInstance } = useContext(CaseContext);
  const pageID = params.pageID ? +params.pageID : null;
  const currentEntryID = params.entryID ? +params.entryID : null;
  const currentPageID = params.pageID ? Number(params.pageID) : null;

  const isFileProcessor = useIsFileProcessor();
  const caseVersion = caseInstance?.version ?? 1;

  const isDocumentDuplicate = caseVersion === 2 && pages.every((page) => page.isDuplicate);

  const [disableLink, setDisableLink] = useState(false);

  const { data: caseFiles, isLoading: caseFilesLoading } = useCaseFiles(caseID);
  const getCaseFileIDFromFileName = (fileName: string) => {
    if (!caseFilesLoading) {
      return String(caseFiles?.find((file) => file.docFileName === fileName)?.documentID);
    }
    return '';
  };

  // const { count: missingInfoCount, fields: missingFields } = MDocRules({
  //   entry,
  //   pages,
  //   documentTags,
  // });
  // const tooltipContent = missingFields.join('\n ');
  const missingInfoCount = 0;
  const { border, borderColor } = getBorderStyles(isFileProcessor, missingInfoCount);

  const handleDownload = useCallback(async () => {
    handleIsSegmentDownloading(true);
    try {
      await downloadSegment(caseID, entryID);
    } catch (err) {
      console.error(err);
      toast.error('Error Downloading Segment...', {
        toastId: 'segment-download',
      });
    }
    handleIsSegmentDownloading(false);
  }, [handleIsSegmentDownloading, caseID, entryID]);

  const PageListComponent = useMemo(() => (showThumbnails ? PageGridView : PageListView), []);

  const openGroupingPageID = useMemo(() => {
    if (pageID) {
      // If a page is currently open, find it in the entry's pages
      const currentPage = entry.pages.find((page) => page.id === pageID);

      if (currentPage) {
        return currentPage.id;
      }
    }
    return entry.pages[0].id;
  }, [currentPageID, entry.pages, caseID]);

  const rotateDocument = useCallback(
    async (direction: 'clockwise' | 'counterclockwise') => {
      const pageIDs = entry.pages.map((page) => Number(page.id));
      await updateDocumentRotation(caseID, BigInt(entryID), pageIDs, direction);
    },
    [entryID, caseID, updateDocumentRotation],
  );

  const handleSelectDocumentForBulkApply = useCallback(
    (documentID: string, action: 'add' | 'remove') => {
      if (action === 'add' && !bulkApplySelected) {
        setSelectedDocuments([...selectedDocuments, documentID]);
      }
      if (action === 'remove') {
        setSelectedDocuments(selectedDocuments.filter((id: string) => id !== documentID));
      }
    },
    [selectedDocuments],
  );

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '95%',
        marginLeft: '0.25rem',
        px: '0.75rem',
        py: '0.25rem',
        marginTop: '0.5rem',
        width: '98%',
        border,
        borderColor,
        '&:hover': {
          border,
          borderColor,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          paddingLeft: '0.2rem',
          right: 13,
          top: 13,
          backgroundColor: 'white',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isDocumentDuplicate ? <DuplicatesByDocument /> : <Box style={{ height: '9.5px' }} />}
        {showGroupingButton && (
          <Tooltip title="Grouping" placement="top">
            <IconButtonContainer
              tooltipText="Grouping"
              tooltipPlacement="top"
              disableClick={false}
              size="small"
              icon={<Grouping />}
              customStyles={{ padding: '4px' }}
              onClick={() => {
                handleOpenGroupingModal(openGroupingPageID);
              }}
            />
          </Tooltip>
        )}
        <Typography
          component="div"
          color="#98A2B3"
          sx={{
            fontSize: '0.75rem',
            fontWeight: 500,
            display: 'inline-block',
          }}
        >
          {pages.length === 1 ? `${pages.length} Page` : `${pages.length} Pages`}
        </Typography>
        <CardMenu handleDownload={handleDownload} entry={entry} rotateDocument={rotateDocument} />
        {bulkApplyInProgress ? (
          <Checkbox
            sx={{
              width: 15,
              height: 15,
              '& .MuiSvgIcon-root': {
                fontSize: 20,
              },
            }}
            checked={bulkApplySelected}
            onChange={(event) =>
              handleSelectDocumentForBulkApply(
                String(entryID),
                event.target.checked ? 'add' : 'remove',
              )
            }
          />
        ) : (
          <div />
          /* {isFileProcessor && missingInfoCount > 0 && (
          <Tooltip title={tooltipContent}>
            <span>
              <Badge
                badgeContent={missingInfoCount}
                color="warning"
                sx={{
                  cursor: 'pointer',
                  fontSize: '4px',
                  top: '0.4rem',
                  right: '0.4rem',
                  zIndex: 1002,
                  width: '20px',
                  height: '20px',
                }}
              />
            </span>
          </Tooltip>
        )} */
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          paddingBottom: 0,
        }}
      >
        <Box>
          <LinkWithQuery
            style={{ textDecoration: 'none', color: '#344054' }}
            documentID={currentDocumentID}
            to={`../timeline/${entryID}/${pages[0]?.id}`}
            disableLink={disableLink}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '0.77rem',
                fontWeight: 900,
                display: 'inline-block',
                opacity: 0.5,
                mt: '0.3rem',
              }}
            >
              {startDate ? convertDateStringToUTCDate(startDate) : 'No Date'}
              {endDate ? ` - ${convertDateStringToUTCDate(endDate)}` : ''}
            </Typography>
          </LinkWithQuery>
        </Box>
      </Box>
      <Stack direction="row" sx={{ zIndex: 1001 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <DocumentNameDisplay
            caseID={caseID}
            fileID={getCaseFileIDFromFileName(pages[0]?.documentFileName)}
            entry={entry}
            disableLink={disableLink}
            setDisableLink={setDisableLink}
          />
        </Box>
      </Stack>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          marginLeft: '-1rem',
          paddingBottom: '0.5rem',
          gap: showThumbnails ? '1rem' : '0px',
          paddingX: showThumbnails ? '1rem' : '0rem',
          mt: '.5rem',
        }}
      >
        <PageListComponent
          pages={pages}
          count={pages?.length}
          pageSearchResultsMap={pageSearchResultsMap}
          currentPageID={pageID}
          isCurrentlyFocused={entryID === currentEntryID}
          isInTimelineView={true}
          pageControls={pageControls}
          notes={notes}
          currentDocumentID={currentDocumentID}
          isFileProcessor={isFileProcessor}
        />
      </Box>
      {!isDocumentDuplicate && <Box style={{ height: '12.5px' }} />}
    </Card>
  );
}

type EntryProps = {
  entry: TimelineEntryType;
  caseID: string;
  handleIsSegmentDownloading: Function;
  showGroupingButton: boolean;
  handleOpenGroupingModal: (value: any) => void;
  pageControls: PageControls;
  notes: Note[];
  currentDocumentID?: string;
  pageSearchResultsMap: Record<string, SearchMatch[]>;
};

export function getBorderStyles(isFileProcessor: boolean, missingInfoCount: number) {
  if (isFileProcessor && missingInfoCount > 0) {
    return {
      border: '1px solid',
      borderColor: 'status.warning',
    };
  }
  return {
    border: 'none',
    borderColor: 'none',
  };
}
