/* eslint-disable no-nested-ternary */
import {
  CircularProgress,
  Container,
  Typography,
  Button,
  Box,
  Snackbar,
  Alert,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { ControlPoint, Refresh } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateCase from './CreateCase';
import Announcement from '../../components/common/Announcement';
import CaseTableView from './CaseTableView';
import FileProcessorCaseTable from '../FileProcessors/CaseTable';
import { AuthContext, useUser } from '../../library/contexts/AuthContext';
import PageHeader from '../../components/common/PageHeader';
import { useGetCasesQuery, GetCasesDocument } from '../../__generated__/graphql';
import CaseFilterPopover from './CaseFilterPopover';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import AnnouncementSafari from '../../components/common/Alert';
import NewSearchBar from '../../components/Searchbar/NewSearchBar';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import useFeatureFlags from '../../config/useFeatureFlags';
import { useCaseAssignment } from './useCaseAssignment';
import { useCaseTypes } from './hooks/useGetAllCaseTypes';
import FilterButton from '../../components/common/FilterButton';

const myCasesStyles = {
  title: {
    fontSize: '1.875rem',
    display: 'inline-block',
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '1rem',
  },
  container: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  casesContainer: {
    marginTop: '2rem',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  displayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    width: '100%',
  },
};

function MyCases() {
  const { user } = useUser();
  const isFileProcessor = useIsFileProcessor();
  const logUserActivity = useActivityLog();
  useEffect(() => {
    logUserActivity({
      activity: 'cases',
    });
  }, []);

  if (isFileProcessor == null) {
    return <div />;
  }

  return (
    <Container maxWidth="lg" sx={myCasesStyles.container}>
      <PageHeader title={`Welcome Back, ${user?.given_name}!`} />
      {isFileProcessor ? <FileProcessorCaseTable /> : <ClientCases />}
    </Container>
  );
}

/**
 * My Cases page.
 */
function ClientCases() {
  const client = useApolloClient();
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isNotChromeOrEdge =
    !/Chrome/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent);

  const currentAnnouncement = {
    //title: 'uncomment this if you need to add title',
    v: 'announcement-v.1.10',
    body: "Let's ring in the New Year - share your thoughts in our quick NPS survey to start off 2025 on the right note!",
    link: 'https://forms.gle/5wy9B2umRGWvFghY7',
  };

  const isNotChromeOrEdgeAnnouncement = {
    v: 'safari-announcement-v.1.0',
    title: 'Attention Users!',
    body: `SiftMed is not optimized for your browser. If you experience any issues please try using one of our supported browsers: Google Chrome or Microsoft Edge.`,
  };

  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (!cookies[currentAnnouncement?.v] && cookies[currentAnnouncement?.v] !== 'dismissed') {
      setCookie(currentAnnouncement?.v, 'show', { path: '/' });
    }
  }, []);

  useEffect(() => {
    if (
      !cookies[isNotChromeOrEdgeAnnouncement?.v] &&
      cookies[isNotChromeOrEdgeAnnouncement?.v] !== 'dismissed'
    ) {
      setCookie(isNotChromeOrEdgeAnnouncement?.v, 'show', { path: '/' });
    }
  }, []);

  const onCloseAnnouncement = () => {
    setCookie(currentAnnouncement?.v, 'dismissed', { path: '/' });
  };

  const onCloseSaAnnouncement = () => {
    setCookie(isNotChromeOrEdgeAnnouncement?.v, 'dismissed', { path: '/' });
  };

  const { data: queryCaseList, loading, error } = useGetCasesQuery();
  const { data: caseTypesList = [] } = useCaseTypes();
  const { isLoading: featureFlagsLoading } = useFeatureFlags();
  const unfilteredCaseList = queryCaseList?.cases;

  const caseTypes = useMemo(() => [{ id: null, name: 'All' }, ...caseTypesList], [caseTypesList]);

  const shouldShowCaseTypes = caseTypes && caseTypes.length > 1;

  const [selectedTab, setSelectedTab] = useState(null);

  const [showAll, setShowAll] = useState(true);
  const [dueDateFilter, setDueDateFilter] = useState(null);
  const [showOpen, setShowOpen] = useState(true);
  const [showFilesRequired, setShowFilesRequired] = useState(true);
  const [showBeingProcessed, setShowBeingProcessed] = useState(true);
  const [showClosed, setShowClosed] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function caseFilter(caseToBeFiltered) {
    const caseName = caseToBeFiltered.caseName || '';
    const patientName = caseToBeFiltered.subjects?.[0].name || '';
    const matchesSearchTerm =
      caseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patientName.toLowerCase().includes(searchTerm.toLowerCase());

    return (
      (!showAll
        ? !(caseToBeFiltered.caseStatus === 'NEW' && !showFilesRequired) &&
          !(
            (caseToBeFiltered.caseStatus === 'PROCESSING' ||
              caseToBeFiltered.caseStatus === 'REQUIRES_PROCESSOR') &&
            !showBeingProcessed
          ) &&
          !(
            caseToBeFiltered.caseStatus === 'READY' &&
            !showOpen &&
            (caseToBeFiltered.pagesViewed < 1 || caseToBeFiltered.pagesViewed > 0)
          ) &&
          !(caseToBeFiltered.caseStatus === 'CLOSED' && !showClosed)
        : true) &&
      (dueDateFilter
        ? moment(caseToBeFiltered.dueDate).format('YYYY-MM-DD') <= dueDateFilter
        : true) &&
      matchesSearchTerm &&
      (shouldShowCaseTypes
        ? selectedTab === null || caseToBeFiltered.case_type_id === selectedTab
        : true)
    );
  }

  const caseList = unfilteredCaseList ? unfilteredCaseList.filter(caseFilter) : [];
  const { userList } = useCaseAssignment();

  const [tableView, setTableView] = useState(true);
  const [newCaseModalOpen, setModalOpen] = useState(null);
  const [newCaseDetails, setNewCaseDetails] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    const view = window.localStorage.getItem('casesView');
    if (view === null || view === undefined) {
      window.localStorage.setItem('casesView', tableView);
    } else {
      setTableView(JSON.parse(view));
    }
  }, [tableView]);

  const onCreateCase = (newCase = null) => {
    setModalOpen(false);
    setNewCaseDetails(newCase);
    if (newCase) {
      if (location.pathname.indexOf('/cases') > -1) {
        navigate(`/case/${newCase.id}/files`);
      }
    }
  };

  const caseTableRefreshTimer = () => {
    setTimeout(() => {
      refreshCaseTable();
    }, 20000);
  };

  const refreshCaseTable = async () => {
    client.refetchQueries({
      include: [GetCasesDocument],
    });
  };

  caseTableRefreshTimer();

  const handleFilterPopperClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setFiltersOpen(false);
  };

  const handleTabChange = useCallback((_, newValue) => {
    setSelectedTab(newValue === 'all' ? null : newValue);
  }, []);

  const renderCases = () => {
    if (error) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open
          autoHideDuration={3000}
        >
          <Alert severity="error">{error.message}</Alert>
        </Snackbar>
      );
    }

    if (loading || featureFlagsLoading) {
      return (
        <Box sx={myCasesStyles.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={myCasesStyles.casesContainer}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: shouldShowCaseTypes ? '1.5rem' : '0rem',
            width: '100%',
            paddingBottom: '0.5rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '70%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {newCaseModalOpen && <CreateCase onClose={onCreateCase} open={newCaseModalOpen} />}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'primary.light',
                  display: 'flex',
                  width: '140px',
                  marginBottom: '0.8rem',
                }}
                aria-label="start new case"
                onClick={() => setModalOpen(true)}
                startIcon={
                  <ControlPoint
                    fontSize="small"
                    sx={{ marginTop: '-0.1rem', marginRight: '0.2rem' }}
                  />
                }
              >
                New Case
              </Button>
              {shouldShowCaseTypes && (
                <Tabs
                  value={selectedTab === null ? 'all' : selectedTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="none"
                  textColor="primary"
                  sx={{
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                    '& .MuiTab-root': {
                      paddingLeft: '2rem',
                      paddingRight: '2rem',

                      textTransform: 'none',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      color: 'secondaryError.main',
                      '&.Mui-selected': {
                        color: 'primary.dark',
                        backgroundColor: 'duplicatesByDocumentBlue.light',
                        borderRadius: '8px',
                      },
                      '&:hover': {
                        color: 'primary.dark',
                      },
                      '&:focus': {
                        outline: 'none',
                      },
                    },
                  }}
                >
                  {caseTypes.map((type) => {
                    const count =
                      type.id === null
                        ? unfilteredCaseList.filter(
                            (caseItem) => !caseItem.archived_at && !caseItem.archived_by,
                          ).length
                        : unfilteredCaseList.filter(
                            (caseItem) =>
                              caseItem.case_type_id === type.id &&
                              !caseItem.archived_at &&
                              !caseItem.archived_by,
                          ).length;

                    return (
                      <Tab
                        key={type.id}
                        label={
                          <TabCountLabel
                            type={type}
                            count={count}
                            isActive={
                              selectedTab === type.id || (type.id === null && selectedTab === null)
                            }
                          />
                        }
                        value={type.id === null ? 'all' : type.id}
                      />
                    );
                  })}
                </Tabs>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                padding: '.8rem',
                backgroundColor: 'selectedGrey',
              }}
            >
              <NewSearchBar
                searchStr={searchTerm}
                searchingInProgress={loading}
                handleChange={handleSearchTermChange}
                label="Search Cases"
                customStyles={{ flex: 90 }}
              />
            </Box>
            <Box
              ref={anchorRef}
              sx={{
                display: 'inline',
                whiteSpace: 'nowrap',
                verticalAlign: 'middle',
                horizontalAlign: 'middle',
                marginBottom: '0.8rem',
                cursor: 'pointer',
              }}
            >
              <FilterButton
                filterCount={!showAll || dueDateFilter ? 1 : 0}
                dot={true}
                onClick={() => {
                  setFiltersOpen(true);
                }}
                containerName="client-cases"
              />
            </Box>
            <CaseFilterPopover
              filtersOpen={filtersOpen}
              anchorRef={anchorRef}
              setFiltersOpen={setFiltersOpen}
              showAll={showAll}
              setShowAll={setShowAll}
              dueDate={dueDateFilter}
              setDueDateFilter={setDueDateFilter}
              showFilesRequired={showFilesRequired}
              setShowFilesRequired={setShowFilesRequired}
              showBeingProcessed={showBeingProcessed}
              setShowBeingProcessed={setShowBeingProcessed}
              showClosed={showClosed}
              showOpen={showOpen}
              setShowOpen={setShowOpen}
              setShowClosed={setShowClosed}
              handleFilterPopperClose={handleFilterPopperClose}
            />
            <IconButton
              sx={{ marginLeft: '0.5rem', marginBottom: '0.7rem' }}
              onClick={() => refreshCaseTable()}
            >
              <Refresh sx={{ fontSize: '2rem' }} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={myCasesStyles.displayContainer}>
          {caseList.length > 0 ? (
            <CaseTableView
              caseList={caseList}
              userList={userList}
              searchTerm={searchTerm}
              onUploadFilesClick={(caseInstance) => setNewCaseDetails(caseInstance)}
            />
          ) : (
            <Box sx={{ margin: 'auto' }}>
              <Typography variant="h5">No Cases</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {currentAnnouncement?.v &&
        (cookies[currentAnnouncement?.v] === 'show' || !cookies[currentAnnouncement?.v]) && (
          <Announcement
            open={true}
            link={currentAnnouncement?.link}
            announcementTitle={currentAnnouncement?.title}
            announcementBody={currentAnnouncement?.body}
            onClose={() => onCloseAnnouncement()}
          />
        )}
      {isNotChromeOrEdge &&
        (cookies[isNotChromeOrEdgeAnnouncement?.v] === 'show' ||
          !cookies[isNotChromeOrEdgeAnnouncement?.v]) && (
          <AnnouncementSafari
            open={true}
            announcementTitle={isNotChromeOrEdgeAnnouncement?.title}
            announcementBody={isNotChromeOrEdgeAnnouncement?.body}
            onClose={() => onCloseSaAnnouncement()}
          />
        )}
      {renderCases()}
    </>
  );
}

export default MyCases;

function TabCountLabel({ type, count, isActive }) {
  return (
    <Box display="flex" alignItems="center">
      <Typography fontWeight="bold" sx={{ marginRight: 1, fontSize: '1rem' }}>
        {type.name}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 'fit-content',
          minWidth: 24,
          height: 24,
          padding: '5px',
          borderRadius: '50%',
          backgroundColor: isActive ? '#CEDCEB' : '#ECEEF0',
          color: 'duplicatesByDocumentBlue.main',
        }}
      >
        <Typography fontSize="0.8rem" fontWeight="bold">
          {count}
        </Typography>
      </Box>
    </Box>
  );
}
