// List of words that should not be capitalized
const exceptions = ['and', 'is', 'the', 'of', 'in', 'on', 'at', 'to', 'for', 'with', 'a', 'an'];
// List of common acronyms that should be capitalized
const mdocAcronyms = [
  'mri',
  'ct',
  'wo',
  'xr',
  'mmi',
  'rt',
  'lt',
  'mr',
  'tibfib',
  'ap',
  'pa',
  'wt',
  'ekg',
  'ecg',
  'w/o',
  'dx',
  'vw',
  'vws',
  'gad',
  'iv',
  'us',
  'ch',
  'ex',
  'ec',
  'mm',
  'bi',
  'br',
  'vc',
  'pe',
  'ne',
  'tmj',
];

export function convertToTitleCase(text: string) {
  if (text && text.length > 0) {
    return text
      .trim()
      .toLowerCase()
      .replace('\n', '')
      .split(' ')
      .map((word, index) => {
        if (mdocAcronyms.includes(word)) {
          return word.toUpperCase();
        }
        if (index === 0 || !exceptions.includes(word)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      })
      .join(' ');
  }
  return '';
}
