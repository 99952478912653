import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { useQueryClient } from '@tanstack/react-query';

import { EditDocumentDetails } from '../ReportEditor/IndexReports/DocumetPreviewer/EditDocumentDetails';
import { useFetchTimelineEntryDocumentDetails } from '../ReportEditor/api-queries/useGetTimelineEntriesForReportSection';
import { PreviewEntryDetails } from '../ReportEditor/IndexReports/IndexReportTable';
import { TimelineDetailsProps } from '../ReportEditor/IndexReports/DocumetPreviewer/DocumentPreviewer';
import { updateTimelineEntriesForReportSection } from '../../api';
import { useGetDocumentNames } from './api-queries/useGetDocumentNames';
import useTimelineStore from './useTimelineStore';
import { useAnnotationTextCaptureContext } from './hooks/useAnnotationTextCapture';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';

type DetailsViewProps = {
  caseID: string;
  entryID: string;
  pageID: string;
};

export default function DetailsView({ caseID, entryID, pageID }: DetailsViewProps) {
  const location = useLocation();
  const {
    data,
    refetch,
    isFetching: isFetchingDocumentDetails,
  } = useFetchTimelineEntryDocumentDetails(caseID, entryID, false);
  const queryClient = useQueryClient();
  const isFileProcessor = useIsFileProcessor();

  const { updatedTimelineEntry, setUpdatedTimelineEntry } = useTimelineStore(
    (state) => ({
      updatedTimelineEntry: state.updatedTimelineEntry,
      setUpdatedTimelineEntry: state.setUpdatedTimelineEntry,
    }),
    shallow,
  );

  const { capturedAuthorByDocumentID, capturedOrganizationByDocumentID, toggleIsScreenCapturing } =
    useAnnotationTextCaptureContext();

  const textCapturedAuthor: string | null = useMemo(() => {
    return capturedAuthorByDocumentID[entryID] ?? null;
  }, [capturedAuthorByDocumentID[entryID], entryID]);

  const textCapturedOrganization: string | null = useMemo(() => {
    return capturedOrganizationByDocumentID[entryID] ?? null;
  }, [capturedOrganizationByDocumentID[entryID], entryID]);

  const defaultCurrentEntryDetails: PreviewEntryDetails = {
    sourceID: 0,
    contentTags: [],
    sectionId: '',
    entryDate: '',
    documentName: '',
    author: { id: null, name: null, label: null },
    organization: { id: null, name: null, label: null },
    isHidden: false,
    monetary_total: '',
    markedImportant: false,
    extracted_dates: [],
    file_id: '',
    startDate: '',
    endDate: '',
  };
  const [currentEntryDetails, setCurrentEntryDetails] = useState<PreviewEntryDetails>(
    defaultCurrentEntryDetails,
  );

  const { refetch: refetchDocumentNames } = useGetDocumentNames(
    caseID ?? '',
    currentEntryDetails.file_id ?? '',
  );

  useEffect(() => {
    refetch();
  }, [updatedTimelineEntry]);

  useEffect(() => {
    if (location.pathname.indexOf('/documents') > -1) {
      setCurrentEntryDetails(defaultCurrentEntryDetails);
    }
  }, [location.pathname]);

  useEffect(() => {
    setCurrentEntryDetails({
      ...currentEntryDetails,
      sourceID: Number(data?.source_id),
      sectionId: data?.section_id,
      entryDate: data?.entry_date || '',
      documentName: data?.document_name || '',
      author: {
        id: data?.author_id ? Number(data?.author_id) : '',
        name: data?.author_name || '',
        label: data?.author_name || '',
      },
      organization: {
        id: data?.org_id ? Number(data?.org_id) : '',
        name: data?.organization_name || '',
        label: data?.organization_name || '',
      },
      isHidden: data?.is_hidden,
      monetary_total: data?.monetary_total || '',
      markedImportant: data?.marked_important,
      extracted_dates: data?.extracted_dates ?? [],
      file_id: data?.file_id || '',
      startDate: data?.start_date || '',
      endDate: data?.end_date || '',
    });
  }, [data, entryID, caseID]);

  const handleUpdateTimelineEntryFromDocumentPreview = async (
    valuesToUpdate: TimelineDetailsProps,
  ) => {
    try {
      const resp = await updateTimelineEntriesForReportSection({
        caseID,
        entryID,
        pageID,
        valuesToUpdate,
      });

      const documentNameFields = ['source'];
      if (documentNameFields.some((prop) => prop in valuesToUpdate)) {
        // document names sometimes fail to update properly without the timeout
        setTimeout(() => {
          refetchDocumentNames();
        }, 500);
      }
      if (!isFileProcessor) {
        toast.success('Successfully updated timeline entry.');
      }
      return resp?.data;
    } catch (error) {
      toast.error('There was an error updating the timeline entry.');
    }
  };

  useEffect(() => {
    async function updateAuthor() {
      if (textCapturedAuthor == null) {
        return;
      }
      const newAuthor = { name: textCapturedAuthor, label: textCapturedAuthor, id: null };
      const resp: any = await handleUpdateTimelineEntryFromDocumentPreview({
        author: newAuthor,
      });
      newAuthor.id = resp?.author_id ?? null;
      setCurrentEntryDetails({ ...currentEntryDetails, author: newAuthor });

      setUpdatedTimelineEntry({
        ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
        id: Number(entryID),
        authorID: newAuthor.id,
      });

      await queryClient.invalidateQueries(['entities', caseID, entryID]);
      toggleIsScreenCapturing('author');
    }

    updateAuthor();
  }, [textCapturedAuthor, entryID, caseID]);

  useEffect(() => {
    async function updateOrganization() {
      if (textCapturedOrganization == null) {
        return;
      }

      const newOrg = { name: textCapturedOrganization, label: textCapturedOrganization, id: null };
      const resp: any = await handleUpdateTimelineEntryFromDocumentPreview({
        organization: { name: textCapturedOrganization, label: textCapturedOrganization, id: null },
      });
      newOrg.id = resp?.org_id ?? null;
      setCurrentEntryDetails({ ...currentEntryDetails, organization: newOrg });

      setUpdatedTimelineEntry({
        ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
        id: Number(entryID),
        orgID: newOrg.id,
      });

      await queryClient.invalidateQueries(['entities', caseID, entryID]);
      toggleIsScreenCapturing('organization');
    }
    updateOrganization();
  }, [textCapturedOrganization, entryID, caseID]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',

        margin: '0.86rem 0.5rem 0 0.15rem',
        paddingTop: '1rem',
        borderRadius: '10px',
      }}
    >
      <EditDocumentDetails
        entryID={entryID}
        pageID={pageID}
        caseID={caseID}
        currentEntryDetails={currentEntryDetails}
        setCurrentEntryDetails={setCurrentEntryDetails}
        onUpdate={(updateCaseID, updateEntryID, valuesToUpdate) => {
          if (updateCaseID && updateEntryID) {
            return handleUpdateTimelineEntryFromDocumentPreview(valuesToUpdate);
          }
        }}
        isEditable={location.pathname.indexOf('/timeline') > -1 && !isFetchingDocumentDetails}
        isOnReviewTab={true}
      />
    </Box>
  );
}
