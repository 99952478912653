import { useState } from 'react';
import { Button, Tooltip } from '@mui/material';

import { FileStatus } from '../../../api';
import DialogModal from '../../../components/common/DialogModal';

type NextStepButtonProps = {
  fileStatus: FileStatus;
  onClick: () => void;
  sx?: Record<string, any>;
};

export function FileProcessorNextStepButton({ fileStatus, onClick, sx = {} }: NextStepButtonProps) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const buttonTextByStatus = {
    GROUPING: 'Set Grouping Done',
    TAGGING: 'Set Tagging Done',
    QA_REQUIRED: 'Approve',
  };

  if (
    fileStatus !== FileStatus.GROUPING &&
    fileStatus !== FileStatus.TAGGING &&
    fileStatus !== FileStatus.QA_REQUIRED
  ) {
    return null;
  }

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onConfirm = () => {
    setIsConfirmationDialogOpen(false);
    onClick();
  };

  return (
    <>
      <StepConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        fileStatus={fileStatus}
      />
      <Tooltip title={fileStatus === FileStatus.GROUPING ? 'Grouping in progress' : ''}>
        <span>
          <Button
            disabled={fileStatus !== FileStatus.QA_REQUIRED && fileStatus !== FileStatus.TAGGING}
            onClick={() => setIsConfirmationDialogOpen(true)}
            variant="outlined"
            sx={{
              minWidth: '160px',
              fontSize: '0.8rem',
              fontWeight: 400,
              backgroundColor: 'caseStatus.processorActionButtonBackground',
              color: 'caseStatus.processorActionButtonText',
              ...sx,
            }}
          >
            {buttonTextByStatus[fileStatus]}
          </Button>
        </span>
      </Tooltip>
    </>
  );
}

type StepConfirmationDialogProps = {
  open: boolean;
  fileStatus: FileStatus;
  onConfirm: () => void;
  onClose: () => void;
};

function StepConfirmationDialog({
  open,
  onClose,
  onConfirm,
  fileStatus,
}: StepConfirmationDialogProps) {
  const dialogTextOptions: Partial<Record<FileStatus, { title: string; text: string }>> = {
    [FileStatus.GROUPING]: {
      title: 'Mark as Ready for Tagging',
      text: 'Are you sure you want to mark this file as ready for tagging? This action cannot be undone.',
    },
    [FileStatus.TAGGING]: {
      title: 'Mark as Ready for QA',
      text: 'Are you sure you want to mark this file as ready for QA to review? This action cannot be undone.',
    },
    [FileStatus.QA_REQUIRED]: {
      title: 'Mark as Approved',
      text: 'Are you sure you want to mark this file as approved? This action cannot be undone.',
    },
  };

  return (
    <DialogModal
      open={open}
      header={dialogTextOptions[fileStatus]?.title}
      content={dialogTextOptions[fileStatus]?.text}
      onClose={onClose}
      actions={
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      }
    />
  );
}
