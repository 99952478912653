import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
  Backdrop,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { BulkUpdateTimelineEntriesDocument } from '../../__generated__/graphql';
import useTimelineStore from './useTimelineStore';
import useBulkApplyStore from './useBulkApplyStore';
import { useGetDocumentNames } from './api-queries/useGetDocumentNames';

type BulkApplyAllProps = {
  bulkDialogOpen: boolean;
  setbulkDialog: React.Dispatch<React.SetStateAction<boolean>>;
  currentTimelineEntry: any;
  caseID: string;
  timelineEntries: any[];
};

function BulkApplyAll(props: BulkApplyAllProps) {
  const { bulkDialogOpen, setbulkDialog, currentTimelineEntry, timelineEntries, caseID } = props;
  const [bulkUpdateTimelineEntries, { loading }] = useMutation(BulkUpdateTimelineEntriesDocument);

  const [checkedAttributes, setCheckedAttributes] = useState({
    author: false,
    org: false,
    entryDate: false,
    sourceID: false,
    documentTagsID: false,
    documentName: false,
  });

  const handleCheckboxChange = (attribute: string) => {
    setCheckedAttributes({
      ...checkedAttributes,
      [attribute]: !checkedAttributes[attribute],
    });
  };

  const { updatedTimelineEntry } = useTimelineStore(
    (state) => ({
      updatedTimelineEntry: state.updatedTimelineEntry,
    }),
    shallow,
  );

  const {
    bulkApplyInProgress,
    setBulkApplyInProgress,
    documentsToUpdate,
    setSelectedDocuments,
    isBulkApplyDisabled,
    disabledMessage,
  } = useBulkApplyStore(
    (state) => ({
      bulkApplyInProgress: state.bulkApplyInProgress,
      documentsToUpdate: state.selectedDocuments,
      setSelectedDocuments: state.setSelectedDocuments,
      setBulkApplyInProgress: state.setBulkApplyInProgress,
      isBulkApplyDisabled: state.isBulkApplyDisabled(),
      disabledMessage: state.disabledMessage(),
    }),
    shallow,
  );

  const handleCancel = () => {
    setbulkDialog(false);
    setSelectedDocuments([]);
    setBulkApplyInProgress(false);
  };

  const handleSelectAll = () => {
    setSelectedDocuments(timelineEntries.map((entry) => String(entry.id)));
  };

  const { data: documentData } = useGetDocumentNames(caseID, currentTimelineEntry?.documentID);
  const currentDocumentName =
    documentData?.find((doc: any) => doc.timelineEntryId === String(currentTimelineEntry?.id))
      ?.documentName ?? null;

  const handleBulkApply = async () => {
    try {
      let { author_id, org_id, startDate, sourceID, documentTags } = currentTimelineEntry;
      const timelineEntryId = currentTimelineEntry.id;

      if (updatedTimelineEntry && String(updatedTimelineEntry.id) === String(timelineEntryId)) {
        if ('authorID' in updatedTimelineEntry) {
          author_id = updatedTimelineEntry.authorID;
        }
        if ('orgID' in updatedTimelineEntry) {
          org_id = updatedTimelineEntry.orgID;
        }
        if ('sourceID' in updatedTimelineEntry) {
          sourceID = String(updatedTimelineEntry.sourceID);
        }
      }

      // Check if the attributes to be applied are not null
      if (
        (checkedAttributes.author && !author_id) ||
        (checkedAttributes.org && !org_id) ||
        (checkedAttributes.entryDate && !startDate) ||
        (checkedAttributes.sourceID && !sourceID) ||
        (checkedAttributes.documentTagsID && (!documentTags || documentTags.length === 0)) ||
        (checkedAttributes.documentName && !currentDocumentName)
      ) {
        toast.error('Cannot apply null attributes');
        return;
      }

      const currentTagIds = documentTags?.map((tag) => tag.tag_id) || [];

      let updateData = {} as {
        authorId?: number;
        orgId?: number;
        entryDate?: string;
        sourceID?: string;
        documentTagsID?: number[];
        documentName?: string;
      };

      if (checkedAttributes.author) {
        updateData.authorId = author_id;
      }
      if (checkedAttributes.org) {
        updateData.orgId = org_id;
      }
      if (checkedAttributes.entryDate) {
        updateData.entryDate = startDate;
      }
      if (checkedAttributes.sourceID && sourceID != null) {
        updateData.sourceID = String(sourceID);
      }
      if (checkedAttributes.documentTagsID && currentTagIds.length > 0) {
        updateData.documentTagsID = currentTagIds;
      }
      if (checkedAttributes.documentName && currentDocumentName != null) {
        updateData.documentName = currentDocumentName;
      }

      const response = await bulkUpdateTimelineEntries({
        variables: {
          input: {
            caseID,
            timelineId: timelineEntryId,
            documentsToUpdate,
            updateData,
          },
        },
      });

      if (response.data.bulkUpdateTimelineEntries.success) {
        toast.success('Bulk apply of all attributes successful');
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        toast.error('Bulk update failed');
        console.error('Bulk update failed:', response.data.bulkUpdateTimelineEntries.message);
      }
      setbulkDialog(false);
    } catch (error) {
      console.error('Error applying tags:', error);
      toast.error('Error applying tags');
    }
  };

  return (
    <>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 9999, color: '#fff' }}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center">
            <CircularProgress color="inherit" />
            <Typography
              variant="h6"
              component="div"
              color="textPrimary"
              sx={{ mt: 1, fontWeight: 'bold' }}
            >
              Updating all entries...
            </Typography>
          </Box>
        </Backdrop>
      )}
      {bulkApplyInProgress && (
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <span style={{ fontSize: '1rem' }}>Bulk Apply In Progress</span>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleSelectAll}
              sx={{ fontSize: '0.8rem', marginRight: '0.5rem', cursor: 'pointer' }}
            >
              Select All
            </Button>
            <Button
              onClick={handleCancel}
              sx={{ fontSize: '0.8rem', marginRight: '0.5rem', cursor: 'pointer' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setbulkDialog(true)}
              sx={{ fontSize: '0.8rem', cursor: 'pointer' }}
              disabled={isBulkApplyDisabled}
            >
              Apply
            </Button>
          </div>
          {disabledMessage && (
            <span style={{ fontSize: '0.8rem', color: 'red' }}>{disabledMessage}</span>
          )}
        </div>
      )}
      {!loading && (
        <Dialog open={bulkDialogOpen} onClose={handleCancel}>
          <DialogTitle>Confirm Bulk Apply</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select the attributes you want to apply from the current timeline entry to all other
              timeline entries within the file.
            </DialogContentText>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.author}
                    onChange={() => handleCheckboxChange('author')}
                  />
                }
                label="Author"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.org}
                    onChange={() => handleCheckboxChange('org')}
                  />
                }
                label="Organization"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.entryDate}
                    onChange={() => handleCheckboxChange('entryDate')}
                  />
                }
                label="Entry Date"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.sourceID}
                    onChange={() => handleCheckboxChange('sourceID')}
                  />
                }
                label="Source Type"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.documentTagsID}
                    onChange={() => handleCheckboxChange('documentTagsID')}
                  />
                }
                label="Document Tags"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.documentName}
                    onChange={() => handleCheckboxChange('documentName')}
                  />
                }
                label="Document Name"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleBulkApply} color="primary" disabled={loading}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default BulkApplyAll;
