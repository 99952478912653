import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { GridSortItem } from '@mui/x-data-grid';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { validateFileOrReportName } from '../../../utils/reportUtils';

import {
  getIndexReport,
  updateDuplicateDocument,
  updateTimelineEntriesForReportSection,
  updateDocumentTags,
  renameIndexReport,
  renameIndexReportSection,
  updateIndexSectionSortOrder,
  updateDocumentBookmark,
  updateIndexShowEmptySections,
} from '../../../api';
import { ChangeTimelineEntryDetailsInput } from '../../../__generated__/graphql';
import useReportsStore from '../useReportsStore';
import { customMultiSort, shouldUpdateTableRow } from './utils/tableUtils';
import { useAsync } from '../../../hooks/useAsync';
import { useUpdateDocumentName } from '../api-queries/useUpdateDocumentName';
import { useUpdateMonetaryTotal } from '../api-queries/useUpdateMonetaryTotal';
import useUpdateTimelineEntry from '../../Timeline/gql/updateTimelineEntry';
import { TimelineDetailsProps } from './DocumetPreviewer/DocumentPreviewer';
import { useUpdateMarkedImportant } from '../../../components/DocumentScrolling/useUpdateMarkedImportant';
import { useBulkUpdateDocumentHiddenStatus } from '../../Timeline/api-queries/useUpdateDocumentHiddenStatus';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import { IndexRow } from './IndexReportTable';

export function useIndexReport(reportId: string) {
  const [shouldShowDuplicates, setShouldShowDuplicates] = useState(false);
  const [shouldShowHiddenPages, setShouldShowHiddenPages] = useState(false);
  const [areSectionsGrouped, setAreSectionsGrouped] = useState(false);
  const [includesSummaries, setIncludesSummaries] = useState(false);
  const [hasCollapsedDocuments, setHasCollapsedDocuments] = useState(false);
  const [shouldShowEmptySections, setShouldShowEmptySections] = useState(false);
  const [indexReport, setIndexReport] = useState<any>(null);
  const [uploadDates, setUploadDates] = useState<string[]>([]);
  const [areIndexSectionsEmpty, setAreIndexSectionsEmpty] = useState<
    Record<string, boolean> | undefined
  >(undefined);
  const [sortModel, setSortModel] = useState<Record<string, GridSortItem[]>>({});
  const [
    { isFirstComparisonDocumentDuplicate, isSecondComparisonDocumentDuplicate },
    setComparisonDocumentsIsDuplicate,
  ] = useState<{
    isFirstComparisonDocumentDuplicate?: boolean;
    isSecondComparisonDocumentDuplicate?: boolean;
  }>({});
  const [documentComparerDocumentIds, setDocumentComparerDocumentIds] = useState<{
    firstDocumentID?: string;
    secondDocumentID?: string;
    sectionId?: string;
  }>({});
  const [isDocumentComparerModalOpen, setIsDocumentComparerModalOpen] = useState<boolean>(false);
  const [documentMap, setDocumentMap] = useState<Record<string, any>>({});
  const [closedRowIds, setClosedRowIds] = useState<string[]>([]);

  const editDocumentName = useUpdateDocumentName();
  const editMonetaryTotal = useUpdateMonetaryTotal();
  const [editTimelineEntry] = useUpdateTimelineEntry();

  const isFileProcessor = useIsFileProcessor();

  const { currentReport, setCurrentReport } = useReportsStore(
    (state) => ({
      currentReport: state.currentReport,
      setCurrentReport: state.setCurrentReport,
    }),
    shallow,
  );
  const { caseID = '' } = useParams<{ caseID: string }>();
  const queryClient = useQueryClient();
  const [indexReportResp, refreshIndexReport] = useAsync(
    () =>
      getIndexReport(
        caseID,
        reportId,
        !shouldShowDuplicates,
        shouldShowHiddenPages,
        areSectionsGrouped,
        uploadDates,
      ),
    [
      caseID,
      reportId,
      shouldShowDuplicates,
      shouldShowHiddenPages,
      uploadDates,
      areSectionsGrouped,
    ],
  );

  useEffect(() => {
    if (indexReportResp.status === 'resolved') {
      const hierarchicalIndexReport = {
        ...(indexReportResp.data?.data ?? {}),
        groups: indexReportResp.data?.data?.groups.map((group) => ({
          ...group,
          sections: group.sections.map((section) => ({
            ...section,
            rows: section.rows.flatMap((row) => [
              { ...row, hierarchy: [row.id] },
              ...(row.documents?.flatMap((document) => [
                { ...document, section_id: row.section_id, hierarchy: [row.id, document.id] },
                ...(document.documents?.map((attachment) => ({
                  ...attachment,
                  section_id: row.section_id,
                  hierarchy: [row.id, document.id, attachment.id],
                })) ?? []),
              ]) ?? []),
            ]),
          })),
        })),
      };
      setIndexReport(hierarchicalIndexReport);
      const newSortModel = { ...sortModel };
      let sortModelChanged = false;
      indexReportResp.data?.data?.groups[0].sections.forEach((section: any) => {
        if (!Object.keys(sortModel).includes(section.id)) {
          newSortModel[section.id] = [
            { field: section.order_by[0][1][1], sort: section.order_by[0][0] },
          ];
          sortModelChanged = true;
        }
      });
      if (sortModelChanged) {
        setSortModel(newSortModel);
      }
      setShouldShowEmptySections(indexReportResp.data?.data?.show_empty_sections);
    }
    const documentById: Record<string, any> = {};
    indexReportResp.data?.data?.groups?.forEach((group: any) => {
      group.sections?.forEach((section: any) => {
        section.rows?.forEach((document: any) => {
          documentById[document.id] = {
            ...document,
            section: section,
            group: group,
          };
        });
      });
    });
    setDocumentMap(documentById);
  }, [indexReportResp]);

  useEffect(() => {
    if (indexReport) {
      const areSectionsEmpty = indexReport.groups.every((group: any) =>
        group.sections.every((section: any) => section.rows.length === 0),
      );
      setAreIndexSectionsEmpty(areSectionsEmpty);
      if (areSectionsEmpty) {
        setShouldShowEmptySections(true);
      }
      const hasSummaryColumn = indexReport.groups.some((group: any) =>
        group.sections.some((section: any) => section.columns.includes('summary')),
      );
      setIncludesSummaries(hasSummaryColumn);
    }
  }, [indexReport]);

  useEffect(() => {
    if (Object.keys(documentMap).length > 0) {
      const collapsed = Object.values(documentMap).some(
        (document) => document.documents?.length > 0,
      );
      setHasCollapsedDocuments(collapsed);
    }
  }, [documentMap]);

  const updateIndexReportName = useCallback(
    async (reportName: string) => {
      if (!validateFileOrReportName(reportName)) {
        return;
      }
      try {
        await renameIndexReport(caseID, reportId, reportName);

        setCurrentReport({
          ...currentReport,
          reportName: reportName,
        });
        setIndexReport({
          ...indexReport,
          name: reportName,
        });
        if (!isFileProcessor) {
          toast.success('Successfully updated report name.');
        }
      } catch (error) {
        toast.error('There was an error updating the report name.');
      }
    },
    [caseID, reportId, currentReport, indexReport],
  );

  const updateIndexReportSectionName = useCallback(
    async (sectionId: string, sectionName: string) => {
      if (!sectionName) {
        return;
      }
      try {
        await renameIndexReportSection(caseID, reportId, sectionId, sectionName);

        const updatedIndexReport = {
          ...indexReport,
          groups: indexReport.groups.map((group) => ({
            ...group,
            sections: group.sections.map((section: any) => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  name: sectionName,
                };
              }
              return section;
            }),
          })),
        };

        setIndexReport(updatedIndexReport);
        toast.success('Successfully updated section name.');
      } catch (error) {
        toast.error('There was an error updating the section name.');
      }
    },
    [indexReport, caseID, reportId],
  );

  const updateIndexRow = async (updatedRow: any, originalRow: any) => {
    // 1. If shouldn't update, return
    if (!shouldUpdateTableRow(updatedRow, originalRow)) {
      return originalRow;
    }

    // 2. If the tags have changed, update them, then trigger refresh as
    // sections may change
    if (!_.isEqual(updatedRow['document-tags'], originalRow['document-tags'])) {
      try {
        await updateDocumentTags({
          entryID: updatedRow.id,
          tags: updatedRow['document-tags'].map((tag) => {
            return { tag_id: Number(tag.id) };
          }),
          tagType: ['Content', 'Specialist'],
        });
        refreshIndexReport();
        if (!isFileProcessor) {
          toast.success('Successfully updated tags.');
        }
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the tags.');
        return originalRow;
      }
    }

    // 3. if the document name has changed, update it
    if (updatedRow['document-name'] !== originalRow['document-name']) {
      try {
        await editDocumentName(updatedRow.id, updatedRow['document-name']);
        if (updatedRow['document-name'] === '' || updatedRow['document-name'] == null) {
          refreshIndexReport();
        } else {
          updateIndexRowCache(updatedRow.id, {
            'document-name': updatedRow['document-name'],
          });
        }
        if (!isFileProcessor) {
          toast.success('Successfully updated document name.');
        }
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the document name.');
        return originalRow;
      }
    }

    // 4. if the monetary value has changed, update it
    if (updatedRow['monetary-total'] !== originalRow['monetary-total']) {
      try {
        await editMonetaryTotal(updatedRow.id, updatedRow['monetary-total']);
        updateIndexRowCache(updatedRow.id, {
          'monetary-total': updatedRow['monetary-total'],
        });
        if (!isFileProcessor) {
          toast.success('Successfully updated monetary total.');
        }
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the monetary total.');
        return originalRow;
      }
    }

    // org, author, or date has changed,
    try {
      const updateData: ChangeTimelineEntryDetailsInput = {
        caseID: caseID,
        entryID: updatedRow.id,
        sourceID: updatedRow.source_id,
        organization: {
          name: updatedRow.organization,
        },
        author: {
          name: updatedRow.author,
        },
      };
      const resp = await editTimelineEntry(updateData);
      const updatedEntry = resp?.data?.changeTimelineEntryDetails;
      if (updatedEntry) {
        updateIndexRowCache(updatedRow.id, {
          'start-date': updatedRow['start-date'],
          'end-date': updatedRow['end-date'],
          organization: updatedEntry?.organizations?.value ?? null,
          org_id: updatedEntry?.organizations?.id ?? null,
          author: updatedEntry?.authors?.value ?? null,
          author_id: updatedEntry?.authors?.id ?? null,
        });
      }
      if (!isFileProcessor) {
        toast.success('Successfully updated entry details.');
      }
      queryClient.invalidateQueries(['entities', caseID]);
      return {
        ...updatedRow,
        'start-date': updatedRow['start-date'],
        'end-date': updatedRow['end-date'],
      };
    } catch (error) {
      toast.error('There was an error updating the entry details.');
      return originalRow;
    }
  };

  /*
    Custom table sort change implementation, removes 'unselected' sort model
    And instead toggles between 'asc' and 'desc' sort
  */
  const IndexSortFields = [
    'document-date',
    'document-name',
    'document-tags',
    'number-pages',
    'author',
    'organization',
    'summary',
  ];
  const handleSortChange = async (newSortModel: GridSortItem[], sectionID: string) => {
    // if unsorted, set to ascending instead
    const currentSortModel = sortModel[sectionID];
    if (newSortModel.length === 0) {
      newSortModel.push({
        field: sortModel[sectionID][0].field,
        sort: 'asc',
      });
    }
    if (IndexSortFields.includes(newSortModel[0].field)) {
      setSortModel({
        ...sortModel,
        [sectionID]: newSortModel,
      });
      try {
        if (!isFileProcessor) {
          await updateIndexSectionSortOrder(newSortModel[0], caseID, reportId, sectionID);
        }
      } catch {
        setSortModel({
          ...sortModel,
          [sectionID]: currentSortModel,
        });
        toast.error('Failed to update sorting');
      }
    }
  };

  const updateIndexRowCache = (rowId: string, updatedValues: any) => {
    let currentDocumentId = rowId;
    if (!Object.prototype.hasOwnProperty.call(documentMap, rowId)) {
      currentDocumentId =
        Object.keys(documentMap).find(
          (key) =>
            documentMap[key].documents?.some(
              (doc) =>
                doc.id === String(rowId) ||
                doc.documents?.some((attachment) => attachment.id === String(rowId)),
            ),
        ) ?? rowId;
    }
    const {
      group: selectedGroup,
      section: selectedSection,
      documents: attachedDocuments,
      ...document
    } = documentMap[currentDocumentId];

    const parentAttachment =
      attachedDocuments?.find(
        (doc) => doc.id === rowId || doc.documents?.some((attach) => attach.id === rowId),
      ) ?? rowId;

    const selectedSecond = parentAttachment.documents?.find((attach) => attach.id === rowId);

    let updatedAttachment = null;
    if (selectedSecond) {
      updatedAttachment = {
        ...selectedSecond,
        ...updatedValues,
      };
      const documentIndex = parentAttachment.documents?.findIndex((doc) => doc.id === rowId) ?? -1;
      if (documentIndex > -1) {
        parentAttachment.documents[documentIndex] = updatedAttachment;
      }
    }

    const rowIndex = selectedSection.rows.findIndex(
      (document) => document.id === currentDocumentId,
    );
    const selectedAttachment = attachedDocuments?.find((doc) => doc.id === rowId);
    let updatedDocument = null;
    if (selectedAttachment) {
      updatedDocument = {
        ...selectedAttachment,
        ...updatedValues,
      };
      const documentIndex = attachedDocuments?.findIndex((doc) => doc.id === rowId) ?? -1;
      if (documentIndex > -1) {
        attachedDocuments[documentIndex] = updatedDocument;
      }
    }

    const updatedRow = {
      ...document,
      documents: attachedDocuments,
      ...(currentDocumentId === rowId ? updatedValues : {}),
    };

    selectedSection.rows[rowIndex] = updatedRow;
    const updatedGroups = indexReport.groups.map((group) => {
      if (group.value !== selectedGroup.value) {
        return group;
      }

      return {
        ...group,
        sections: group.sections.map((section: any) => {
          if (section.id !== selectedSection.id) {
            return section;
          }
          return {
            ...selectedSection,
            rows: selectedSection.rows.flatMap((row) => [
              { ...row, hierarchy: [row.id] },
              ...(row.documents?.flatMap((document) => [
                { ...document, section_id: row.section_id, hierarchy: [row.id, document.id] },
                ...(document.documents?.map((attachment) => ({
                  ...attachment,
                  section_id: row.section_id,
                  hierarchy: [row.id, document.id, attachment.id],
                })) ?? []),
              ]) ?? []),
            ]),
          };
        }),
      };
    });

    setDocumentMap({
      ...documentMap,
      [currentDocumentId]: {
        ...updatedRow,
        group: selectedGroup,
        section: selectedSection,
      },
    });

    setIndexReport({
      ...indexReport,
      groups: updatedGroups,
    });
  };

  const handleUpdateDuplicateDocumentV1Cases = async (entryId: string, isDuplicate: boolean) => {
    await updateDuplicateDocument({
      entryId: entryId,
      isDuplicate,
    });

    if (entryId === documentComparerDocumentIds.firstDocumentID) {
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isFirstComparisonDocumentDuplicate: isDuplicate,
      }));
    } else if (entryId === documentComparerDocumentIds.secondDocumentID) {
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isSecondComparisonDocumentDuplicate: isDuplicate,
      }));
    }
    updateIndexRowCache(entryId, {
      all_pages_marked_duplicate: isDuplicate,
    });
  };

  const handleSetFirstDocumentComparisonID = useCallback(
    (documentID: bigint, isDocumentDuplicate: boolean) => {
      setDocumentComparerDocumentIds({
        firstDocumentID: String(documentID),
      });
      setComparisonDocumentsIsDuplicate({
        isFirstComparisonDocumentDuplicate: isDocumentDuplicate,
      });
      // this needs to be the section id
    },
    [],
  );

  const handleSetSecondDocumentComparisonIDAndOpenModal = useCallback(
    (documentID: string, isDocumentDuplicate: boolean, sectionId?: string) => {
      setDocumentComparerDocumentIds({
        ...documentComparerDocumentIds,
        secondDocumentID: String(documentID),
        sectionId: sectionId,
      });
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isSecondComparisonDocumentDuplicate: isDocumentDuplicate,
      }));

      setIsDocumentComparerModalOpen(true);
    },
    [documentComparerDocumentIds],
  );

  const clearDocumentComparisonIDs = useCallback(() => {
    setDocumentComparerDocumentIds({});
    setComparisonDocumentsIsDuplicate({});
  }, []);

  const handleUpdateTimelineEntryFromDocumentPreview = async (
    caseID: string,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => {
    try {
      await updateTimelineEntriesForReportSection({
        caseID,
        entryID,
        valuesToUpdate,
      });
      // may have to map the the cache values here
      if (
        (Object.prototype.hasOwnProperty.call(valuesToUpdate, 'documentName') &&
          (valuesToUpdate.documentName === '' || valuesToUpdate.documentName == null)) ||
        (!isFileProcessor &&
          valuesToUpdate.organization &&
          ((!valuesToUpdate.organization.id && valuesToUpdate.organization.name) ||
            valuesToUpdate.organization.label)) ||
        (!isFileProcessor &&
          valuesToUpdate.author &&
          !valuesToUpdate.author.id &&
          (valuesToUpdate.author.name || valuesToUpdate.author.label))
      ) {
        refreshIndexReport();
      } else {
        const { author, organization, date, ...updatedValues } = valuesToUpdate;
        updateIndexRowCache(String(entryID), {
          ...updatedValues,
          ...(organization && {
            organization: organization?.label ?? organization?.name,
            org_id: organization?.id,
          }),
          ...(author && {
            author: author?.label ?? author?.name,
            author_id: author?.id,
          }),
          ...(valuesToUpdate.documentName && {
            'document-name': valuesToUpdate.documentName,
          }),
          ...(valuesToUpdate.startDate && {
            'start-date': valuesToUpdate.startDate,
          }),
          ...(valuesToUpdate.endDate && {
            'end-date': valuesToUpdate.endDate,
          }),
          ...(valuesToUpdate.source && {
            source_id: valuesToUpdate.source,
          }),
          ...(valuesToUpdate.monetary_total && {
            'monetary-total': valuesToUpdate.monetary_total,
          }),
        });
      }
      if (!isFileProcessor) {
        toast.success('Successfully updated timeline entry.');
      }
    } catch (error) {
      toast.error('There was an error updating the document.');
    }
  };

  const closeDocumentComparerModal = useCallback(() => {
    setIsDocumentComparerModalOpen(false);
    clearDocumentComparisonIDs();
  }, [clearDocumentComparisonIDs]);

  const sortedIndexReport = useMemo(() => {
    return {
      ...indexReport,
      groups: indexReport?.groups?.map((group) => ({
        ...group,
        sections: group?.sections?.map((section: any) => {
          const currentSort = sortModel[section.id] ?? [{ field: 'document-date', sort: 'asc' }];
          const parentRows = section.rows.filter((row) => row.hierarchy.length === 1) ?? [];
          const sortedRows = customMultiSort(
            parentRows,
            currentSort[0].field,
            currentSort[0].sort ?? 'asc',
          ).flatMap((row) => [
            { ...row, hierarchy: [row.id] },
            ...(row.documents?.flatMap((document) => [
              { ...document, section_id: row.section_id, hierarchy: [row.id, document.id] },
              ...(document.documents?.map((attachment) => ({
                ...attachment,
                section_id: row.section_id,
                hierarchy: [row.id, document.id, attachment.id],
              })) ?? []),
            ]) ?? []),
          ]);
          return {
            ...section,
            rows: sortedRows,
          };
        }),
      })),
    };
  }, [indexReport, sortModel]);

  const totalPagesInIndex = useMemo(() => {
    return sortedIndexReport?.groups
      ?.flatMap((group) => [...group.sections.flatMap((section) => [...section.rows])])
      .reduce((total: number, document) => {
        if (
          (!shouldShowHiddenPages && document.is_hidden === 1) ||
          document['group-type'] === 'collapsed'
        ) {
          return total;
        }
        if (document['group-type'] === 'attachments') {
          return total + document['number-parent-pages'];
        }
        return total + document['number-pages'];
      }, 0);
  }, [sortedIndexReport]);

  const markImportant = useUpdateMarkedImportant();
  const bulkHideDocuments = useBulkUpdateDocumentHiddenStatus();

  const handleChangeImportanceMark = useCallback(
    async (documentId: string, newImportance: boolean) => {
      try {
        await markImportant.mutateAsync({
          entryID: documentId,
          markedImportant: newImportance,
        });
        refreshIndexReport();
        toast.success(`Document flagged as ${newImportance ? 'important' : 'not important'}`);
      } catch (error) {
        Sentry.captureException(error);
        toast.error('Error updating importance');
      }
    },
    [],
  );

  const handleUpdateDocumentBookmark = useCallback(
    async (documentID: string, isBookmarked: boolean) => {
      try {
        await updateDocumentBookmark(caseID, reportId, documentID, isBookmarked);
        toast.success('Successfully updated document bookmark.');
        updateIndexRowCache(documentID, { is_bookmarked: isBookmarked });
      } catch (error) {
        toast.error('There was an error bookmarking the document.');
      }
    },
    [caseID, reportId, documentMap],
  );

  const bulkHideSelectedDocuments = useCallback(async (selectedDocuments: any) => {
    const allSelectedDocumentsHidden = selectedDocuments.every((document) => document.is_hidden);
    const documentIDs = selectedDocuments.map((doc) => doc.id);
    await bulkHideDocuments(documentIDs, !allSelectedDocumentsHidden);
    refreshIndexReport();
  }, []);

  const getNextDocument = useCallback(
    (
      direction: 'next' | 'previous',
      currentDocumentId: string,
      sectionId: string,
    ): IndexRow | undefined => {
      if (!sortedIndexReport?.groups) {
        return;
      }

      let parentRowID = currentDocumentId;
      if (!Object.prototype.hasOwnProperty.call(documentMap, currentDocumentId)) {
        parentRowID =
          Object.keys(documentMap).find(
            (key) =>
              documentMap[key].documents?.some(
                (doc) =>
                  doc.id === String(currentDocumentId) ||
                  doc.documents?.some((attachment) => attachment.id === String(currentDocumentId)),
              ),
          ) ?? currentDocumentId;
      }

      const groupValue = documentMap[parentRowID].group.value;

      let currentGroupIndex = sortedIndexReport.groups.findIndex(
        (group) => group.value === groupValue,
      );
      if (currentGroupIndex === -1) {
        return;
      }

      let currentGroup = sortedIndexReport.groups[currentGroupIndex];
      let currentSectionIndex = sortedIndexReport.groups[currentGroupIndex].sections.findIndex(
        (s) => s.id === sectionId,
      );
      if (currentSectionIndex === -1) {
        return;
      }
      let currentSection = currentGroup.sections[currentSectionIndex];
      let documentIndex = currentSection.rows.findIndex(
        (document) => document.id === currentDocumentId,
      );

      if (documentIndex === -1) {
        return;
      }

      if (direction === 'next') {
        documentIndex++;
        while (
          currentSectionIndex < currentGroup.sections.length &&
          currentGroupIndex < sortedIndexReport.groups.length
        ) {
          if (documentIndex < currentSection.rows.length) {
            break;
          } else {
            currentSectionIndex++;
            if (currentSectionIndex < currentGroup.sections.length) {
              currentSection = currentGroup.sections[currentSectionIndex];
              documentIndex = 0;
            } else {
              currentGroupIndex++;
              if (currentGroupIndex < sortedIndexReport.groups.length) {
                currentGroup = sortedIndexReport.groups[currentGroupIndex];
                currentSectionIndex = 0;
                currentSection = currentGroup.sections[currentSectionIndex];
              } else {
                break;
              }
            }
          }
        }
      } else {
        documentIndex--;
        while (currentGroupIndex >= 0 && currentSectionIndex >= 0) {
          if (documentIndex >= 0) {
            break;
          } else {
            currentSectionIndex--;
            if (currentSectionIndex >= 0) {
              currentSection = currentGroup.sections[currentSectionIndex];
              documentIndex = currentSection.rows.length - 1;
            } else {
              currentGroupIndex--;
              if (currentGroupIndex >= 0) {
                currentGroup = sortedIndexReport.groups[currentGroupIndex];
                currentSectionIndex = currentGroup.sections.length - 1;
                if (currentSectionIndex >= 0) {
                  currentSection = currentGroup.sections[currentSectionIndex];
                  documentIndex = currentSection.rows.length - 1;
                }
              } else {
                return;
              }
            }
          }
        }
      }

      return currentSection.rows[documentIndex];
    },
    [sortedIndexReport, documentMap],
  );

  const lastDocumentOfReport = useMemo(
    () =>
      sortedIndexReport?.groups
        ?.slice(-1)[0]
        .sections?.slice()
        .reverse()
        .find((section) => section.rows.length > 0)
        ?.rows.slice(-1)[0],
    [sortedIndexReport, reportId],
  );

  const firstDocumentOfReport = useMemo(
    () =>
      sortedIndexReport?.groups?.[0].sections?.find((section) => section.rows.length > 0)?.rows[0],
    [sortedIndexReport, reportId],
  );

  const toggleShowEmptySections = useCallback(() => {
    if (areIndexSectionsEmpty) {
      return;
    }
    setShouldShowEmptySections(!shouldShowEmptySections);
    updateIndexShowEmptySections({
      caseID,
      indexID: reportId,
      shouldShowEmptySections: !shouldShowEmptySections,
    });
  }, [areIndexSectionsEmpty, setShouldShowEmptySections, shouldShowEmptySections]);

  const configureOptions = [
    {
      label: 'Show Duplicate Documents',
      onClick: () => setShouldShowDuplicates(!shouldShowDuplicates),
      checked: shouldShowDuplicates,
    },
    {
      label: 'Show Hidden Pages',
      onClick: () => setShouldShowHiddenPages(!shouldShowHiddenPages),
      checked: shouldShowHiddenPages,
    },
    {
      label: 'Show Empty Sections',
      onClick: toggleShowEmptySections,
      checked: shouldShowEmptySections,
    },
    {
      label: 'Group by Upload Date',
      onClick: () => setAreSectionsGrouped(!areSectionsGrouped),
      checked: areSectionsGrouped,
    },
  ];

  return {
    data: sortedIndexReport,
    isLoading: indexReportResp.status === 'pending' && sortedIndexReport == null,
    isError: indexReportResp.status === 'rejected',
    shouldShowDuplicates,
    setShouldShowDuplicates,
    shouldShowHiddenPages,
    setShouldShowHiddenPages,
    areSectionsGrouped,
    setAreSectionsGrouped,
    shouldShowEmptySections,
    setShouldShowEmptySections,
    configureOptions,
    uploadDates,
    setUploadDates,
    updateIndexReportName,
    updateIndexReportSectionName,
    areIndexSectionsEmpty,
    hasCollapsedDocuments,
    sortModel,
    handleSortChange,
    updateIndexRow,
    updateIndexRowCache,
    handleUpdateDuplicateDocumentV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    setComparisonDocumentsIsDuplicate,
    clearDocumentComparisonIDs,
    closeDocumentComparerModal,
    handleSetFirstDocumentComparisonID,
    handleSetSecondDocumentComparisonIDAndOpenModal,
    isDocumentComparerModalOpen,
    documentComparerDocumentIds,
    setDocumentComparerDocumentIds,
    handleUpdateTimelineEntryFromDocumentPreview,
    refreshIndexReport,
    handleChangeImportanceMark,
    getNextDocument,
    lastDocumentOfReport,
    firstDocumentOfReport,
    handleUpdateDocumentBookmark,
    closedRowIds,
    setClosedRowIds,
    bulkHideSelectedDocuments,
    totalPagesInIndex,
    includesSummaries,
  };
}
