import { create } from 'zustand';
import { Page } from './types/timelineTypes';

const useTimelineStore = create<TimelineStoreTypes>((set, get) => ({
  hiddenEntities: [],
  pages: {},
  startDate: '',
  source: '',
  author: { id: null, name: null },
  organization: { id: null, name: null },
  subject: { id: null, name: null },
  currentEntry: null,
  tooManyError: false,
  marked_important: false,
  updatedTimelineEntry: null,
  setHiddenEntities: (hiddenEntities: (string | number)[]) => set({ hiddenEntities }),

  setDate: (date: string) => set(() => ({ startDate: date })),

  setSource: (sourceID: string) => set(() => ({ source: sourceID })),

  setAuthor: (author: EntityObject) => set(() => ({ author: author })),

  setOrganization: (organization: EntityObject) => set(() => ({ organization: organization })),

  setSubject: (subject: EntityObject) => set(() => ({ subject: subject })),

  // used to update the document details when the timeline is updated and vice versa
  setUpdatedTimelineEntry: (entry: UpdatedTimelineEntryObject | null) =>
    set(() => ({ updatedTimelineEntry: entry })),
}));

export default useTimelineStore;

interface TimelineStoreTypes {
  hiddenEntities: (string | number)[];
  pages: PageObject;
  source: string;
  author: object;
  organization: object;
  subject: object;
  currentEntry: number | null;
  tooManyError: boolean;
  updatedTimelineEntry: UpdatedTimelineEntryObject | null;
  setHiddenEntities: (hiddenEntities: (string | number)[]) => void;
  setDate: (date: string) => void;
  setSource: (sourceID: string) => void;
  setAuthor: (author: EntityObject) => void;
  setOrganization: (org: EntityObject) => void;
  setSubject: (subject: EntityObject) => void;
  setUpdatedTimelineEntry: (entry: UpdatedTimelineEntryObject | null) => void;
}

type PageObject = {
  [pageID: number]: MergePageType;
};

type EntityObject = {
  id: number | null;
  name: string | null;
};

export type UpdatedTimelineEntryObject = {
  id: number;
  startDate?: string;
  endDate?: string;
  sourceID?: string;
  authorID?: number;
  orgID?: number;
};

interface MergePageType extends Page {
  selected: boolean;
}
