import { useCallback, useMemo } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

export default function PagesPieChart({
  data,
  colors,
}: {
  data: { value: number; name: string }[];
  colors: string[];
}) {
  const pieData = useMemo(() => {
    return data
      .map((item, index) => ({ ...item, color: colors[index % colors.length] }))
      .filter((item) => item.value > 0)
      .sort((a, b) => b.value - a.value);
  }, [data]);

  const renderLegend = useCallback((props) => {
    const { payload } = props;
    return (
      <div style={{ padding: '16px' }}>
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}
          >
            <div
              style={{
                width: '14px',
                height: '14px',
                backgroundColor: entry.color,
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
            <span style={{ color: 'black', fontSize: '14px' }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }, []);

  return (
    <PieChart width={400} height={250}>
      <Pie
        data={pieData}
        cx="50%"
        cy="50%"
        outerRadius={80}
        fill="white"
        dataKey="value"
        endAngle={90}
        startAngle={-270}
      >
        {pieData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Legend
        layout="vertical"
        align="right"
        verticalAlign="middle"
        iconType="circle"
        content={renderLegend}
      />
      <Tooltip />
    </PieChart>
  );
}
