import { CSSProperties, useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { AutoAwesome } from '@mui/icons-material';
import { Button, useMediaQuery, CircularProgress, Tooltip } from '@mui/material';
import { fetchDocumentSummary } from '../Summarization/fetchDocumentSummary';
import {
  updateDocumentSummary,
  generateDocumentSummary,
  getDocumentSummary,
  canGenerateSummary,
} from '../../api';

type DocumentSummaryProps = {
  caseID: string;
  entryID: string;
  isInPreviewer?: boolean;
  updateIndexRowCache?: (rowId: string, updatedValues: any) => void;
};

DocumentSummary.defaultProps = {
  isInPreviewer: false,
  updateIndexRowCache: () => {},
};

export default function DocumentSummary({
  caseID,
  entryID,
  isInPreviewer,
  updateIndexRowCache,
}: DocumentSummaryProps) {
  const [summary, setSummary] = useState<string>('');
  const [fetchedSummary, setFetchedSummary] = useState<string>('');
  const [summarizeLoading, setSummarizeLoading] = useState(false);
  const [documentTooLarge, setDocumentTooLarge] = useState(false);

  const isLargeScreen = useMediaQuery('(min-height: 900px)');
  const dynamicStyles = {
    height: (() => {
      if (isLargeScreen) {
        return isInPreviewer ? '32rem' : '37rem';
      }
      return isInPreviewer ? '21rem' : '27rem';
    })(),
  };

  useEffect(() => {
    const checkSummaryPermission = async () => {
      const summaryPermitted = await canGenerateSummary(caseID, entryID);
      if (!summaryPermitted?.data) {
        setDocumentTooLarge(true);
      }
    };
    if (entryID && caseID) {
      checkSummaryPermission();
    }
  }, [entryID, caseID]);

  const summarizeDocument = useCallback(async () => {
    let summaryTaskId = '';
    try {
      setSummarizeLoading(true);
      const response = await generateDocumentSummary(caseID, entryID.toString(), true);
      summaryTaskId = response.data as string;
    } catch (error) {
      setSummarizeLoading(false);
      toast.error('Failed to summarize document. Please refresh and try again.');
      return { status: 'rejected' };
    }
    const summaryResponse = await fetchDocumentSummary(summaryTaskId, caseID, entryID.toString());
    setSummary(summaryResponse);
    setFetchedSummary(summaryResponse);
    if (isInPreviewer && updateIndexRowCache) {
      updateIndexRowCache(String(entryID), {
        summaryResponse,
      });
    }
    setSummarizeLoading(false);
  }, [entryID, caseID]);

  const onSave = useCallback(async () => {
    try {
      await updateDocumentSummary(caseID, entryID, summary);
      toast.success('Summary successfully updated', {
        toastId: 'summary-update-success',
      });
      if (isInPreviewer && updateIndexRowCache) {
        updateIndexRowCache(String(entryID), {
          summary,
        });
      }
    } catch (error) {
      toast.error('Failed to update summary', {
        toastId: 'summary-update-failure',
      });
    }
  }, [updateIndexRowCache, caseID, entryID, summary, isInPreviewer]);

  useEffect(() => {
    (async () => {
      if (!caseID || !entryID) {
        return;
      }
      const summaryResponse = await getDocumentSummary(caseID, entryID?.toString());
      const summaryData = summaryResponse?.data as string;
      setSummary(summaryData);
      setFetchedSummary(summaryData);
    })();
  }, [caseID, entryID]);

  return (
    <div style={{ width: '100%' }}>
      <Tooltip title={documentTooLarge ? 'Document is too large to summarize' : ''} placement="top">
        <span>
          <Button
            id="generate-new-document-summary"
            color="themePurple"
            variant="contained"
            onClick={() => summarizeDocument()}
            disabled={summarizeLoading || documentTooLarge}
            sx={styles.summaryButton}
          >
            Summarize Document
            {summarizeLoading ? (
              <CircularProgress size={11} sx={{ ml: '0.4rem', color: 'white' }} />
            ) : (
              <AutoAwesome sx={{ ml: '0.2rem', fontSize: '0.85rem' }} />
            )}
          </Button>
        </span>
      </Tooltip>
      <div style={{ ...styles.container, ...dynamicStyles }} role="textbox" tabIndex={0}>
        <textarea
          style={styles.textarea}
          value={summary}
          disabled={documentTooLarge}
          onChange={(e) => setSummary(e.target.value)}
        />
      </div>
      <Button
        disabled={fetchedSummary === summary}
        size="small"
        variant="contained"
        onClick={() => onSave()}
      >
        Save
      </Button>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    border: '1px solid #D1D5DB',
    borderRadius: '10px',
    padding: '12px',
    height: '32rem',
    width: '100%',
    cursor: 'text',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: '0.5rem',
    marginBottom: '1rem',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  textarea: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    backgroundColor: 'transparent',
    font: 'inherit',
    padding: '0',
    fontSize: '0.75rem',
  },
  summaryButton: {
    color: 'white',
    height: '1.5rem',
    fontSize: '0.65rem',
    alignSelf: 'flex-end',
    paddingTop: '0.15rem',
    marginLeft: 'auto',
    display: 'block',
    marginTop: '0.5rem',
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
  },
};
