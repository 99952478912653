import { WordBlock } from '../../../api';
import PageHighlight from './PageHighlight';

interface WordHighlightsProps {
  wordHighlights?: WordBlock[];
  pageNumber: number;
  rotationAngle: number;
}

export function WordHighlights({ wordHighlights, pageNumber, rotationAngle }: WordHighlightsProps) {
  if (!wordHighlights?.length) {
    return null;
  }

  const pageWordHighlights = wordHighlights.filter(
    (wordHighlight) => Number(wordHighlight.Page) === Number(pageNumber),
  );

  if (!pageWordHighlights.length) {
    return null;
  }
  return (
    <>
      {pageWordHighlights.map(({ BoundingBox }) => {
        return (
          <PageHighlight
            key={`${BoundingBox.Left}-${BoundingBox.Top}`}
            boundingBox={{
              Left: BoundingBox.Left,
              Top: BoundingBox.Top,
              Width: BoundingBox.Width + 0.003,
              Height: BoundingBox.Height,
            }}
            rotation={rotationAngle}
            selected={false}
            sx={{
              backgroundColor: 'rgba(255, 255, 0, 0.4)',
              padding: '4px',
            }}
          />
        );
      })}
    </>
  );
}
