import { Box, InputLabel, Menu, Typography, Stack, Switch } from '@mui/material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';
import {
  formatSegmentDate,
  isValidDate,
  convertDateStringToUTCDate,
} from '../../../library/utilities/useDates';
import useUpdateEntryDates from '../gql/useUpdateEntryDates';
import { useAnnotations, Extraction } from '../../../components/DocumentScrolling/useAnnotations';
import useTimelineStore from '../useTimelineStore';

export default function DateRangeSelector(params: Props) {
  const {
    entryID,
    entryStartDate,
    entryEndDate,
    orientation,
    isPopUp = true,
    shouldOpenByDefault = false,
    hideTitle = false,
    onChange,
    setNewStartDate,
    setNewEndDate,
    setDates,
  } = params;

  const handleIndexInputChange = useCallback(
    (newStartDate: string, newEndDate: string) => {
      const { api, id, field } = params;

      if (api == null || id == null || field == null) {
        return;
      }

      const startDateString = newStartDate ?? entryStartDate;
      const endDateString = newEndDate ?? entryEndDate;

      const displayDate = `${
        startDateString ? convertDateStringToUTCDate(startDateString) : 'No Date'
      }${endDateString ? ` - ${convertDateStringToUTCDate(endDateString)}` : ''}`;
      api.setEditCellValue({ id, field, value: displayDate });

      const updates: { id: GridRowId; 'start-date'?: string; 'end-date'?: string } = {
        id,
      };
      if (newStartDate !== entryStartDate) {
        updates['start-date'] = newStartDate;
      }
      if (newEndDate !== entryEndDate) {
        updates['end-date'] = newEndDate;
      }
      api.updateRows([updates]);
      if (newStartDate !== entryStartDate || newEndDate !== entryEndDate) {
        toast.success('Successfully updated entry details.');
      }
    },
    [params, entryStartDate, entryEndDate],
  );

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const anchorRef = useRef(null);
  const [startDate, setStartDate] = useState<string>(entryStartDate);
  const [endDate, setEndDate] = useState<string>(entryEndDate);
  const [useRange, setUseRange] = useState<boolean>(!!entryEndDate);
  const [showError, setShowError] = useState<boolean>(false);
  const { caseID } = useParams();

  const { updatedTimelineEntry, setUpdatedTimelineEntry } = useTimelineStore(
    (state) => ({
      updatedTimelineEntry: state.updatedTimelineEntry,
      setUpdatedTimelineEntry: state.setUpdatedTimelineEntry,
    }),
    shallow,
  );

  useEffect(() => {
    if (entryEndDate) {
      setUseRange(true);
    }
  }, [entryEndDate]);

  const { data: fetchedDates } = useAnnotations(
    {
      caseID: caseID ?? '',
      documentId: entryID,
      type: 'date',
    },
    (shouldOpenByDefault === true || Boolean(anchorEl)) && !!caseID && !!entryID,
  );
  const dates: Extraction[] = fetchedDates ?? [];

  useEffect(() => {
    if (setNewStartDate && setNewEndDate && setDates) {
      setNewStartDate(startDate);
      setNewEndDate(endDate);
      setDates(dates);
    }
  }, [startDate, endDate, dates]);

  const { updateEntryDates } = useUpdateEntryDates();

  const openMenu = useCallback((e: any) => {
    setAnchorEl(e.target);
  }, []);

  useEffect(() => {
    if (shouldOpenByDefault) {
      openMenu({ target: anchorRef.current });
    }
  }, [shouldOpenByDefault]);

  useEffect(() => {
    setStartDate(entryStartDate);

    setEndDate(entryEndDate);
  }, [entryStartDate, entryEndDate]);

  const onMenuClose = useCallback(async () => {
    setAnchorEl(null);
    setShowError(false);
    const newStartDate = startDate;
    const newEndDate = endDate;

    const updateEntryDatesInput: {
      newStartDate: string;
      newEndDate: string;
      entryStartDate: string;
      entryEndDate: string;
      dates: Extraction[];
      entryID: number;
    } = {
      entryID,
      dates,
      newStartDate,
      newEndDate,
      entryStartDate,
      entryEndDate,
    };
    const isStartDateChanged = newStartDate !== entryStartDate;
    const isEndDateChanged = newEndDate !== entryEndDate;

    if (!isStartDateChanged && !isEndDateChanged) {
      return;
    }

    await updateEntryDates(updateEntryDatesInput);
    handleIndexInputChange(newStartDate, newEndDate);

    if (onChange) {
      onChange({
        ...(isStartDateChanged && { startDate: newStartDate }),
        ...(isEndDateChanged && { endDate: newEndDate }),
      });
    }

    setUpdatedTimelineEntry({
      ...(updatedTimelineEntry?.id === Number(entryID) ? updatedTimelineEntry : {}),
      id: Number(entryID),
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }, [startDate, entryStartDate, entryID, endDate, entryEndDate, updateEntryDates, dates]);

  const display = entryEndDate
    ? `${formatSegmentDate(entryStartDate)} - ${formatSegmentDate(entryEndDate)}`
    : formatSegmentDate(entryStartDate);

  const handleDateChange = useCallback(
    (isStartDate: boolean, newDate: Dayjs) => {
      if (!isValidDate(newDate)) {
        return;
      }
      const formattedDate = newDate.format('YYYY-MM-DD');
      const isRangeValid = isStartDate
        ? validateDateRange(formattedDate, endDate)
        : validateDateRange(startDate, formattedDate);

      if (!isRangeValid) {
        setShowError(true);
        return;
      }
      setShowError(false);
      if (isStartDate) {
        setStartDate(formattedDate);
      } else {
        setEndDate(formattedDate);
      }
    },
    [startDate, endDate],
  );

  const embeddedDateRangeComponent = (
    <Box
      sx={{
        padding: '.5rem',
        marginTop: '-4px',
        width: orientation === 'horizontal' && useRange ? '23rem' : '14rem',
      }}
    >
      {!hideTitle && (
        <Typography variant="h5" sx={{ m: '0.5rem', mb: '-0.5rem' }}>
          Edit Date
        </Typography>
      )}
      {showError && (
        <Typography
          sx={{
            m: '0.5rem',
            mb: '-0.5rem',
            fontSize: '0.7rem',
            color: 'error.main',
            fontWeight: 'bold',
          }}
        >
          Start date must be earlier than end date
        </Typography>
      )}
      <Stack
        direction="row"
        width="100%"
        justifyContent="flex-end"
        paddingRight="0.5rem"
        sx={{ mb: '-0.5rem' }}
      >
        <Switch
          sx={{ transform: 'scale(0.7)' }}
          checked={useRange}
          onClick={() => {
            if (useRange === true && showError) {
              setShowError(false);
            }
            setUseRange(!useRange);
            setEndDate('');
          }}
        />
        <InputLabel sx={{ fontSize: '0.7rem', mt: '0.7rem' }}>Use Range</InputLabel>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          direction={orientation === 'horizontal' ? 'row' : 'column'}
          sx={{ paddingX: '0.5rem' }}
          spacing={2}
        >
          <Box width="10rem">
            <InputLabel sx={{ fontSize: '0.7rem' }}>Start Date</InputLabel>
            <DatePicker
              label="Start Date"
              format="YYYY-MM-DD"
              value={
                startDate != null && startDate !== '1900-01-01'
                  ? dayjs(startDate?.split(' ')[0])
                  : ''
              }
              minDate={dayjs('1000-01-01')}
              maxDate={dayjs('2099-12-31')}
              onChange={(e) => handleDateChange(true, dayjs(e))}
            />
            <Typography
              sx={{
                paddingBottom: '.5rem',
                mt: '0.5rem',
                fontSize: '12px',
                color: 'primary.light',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setShowError(false);
                setStartDate('');
              }}
            >
              Set as Unknown
            </Typography>
          </Box>
          {useRange && (
            <Box width="10rem">
              <InputLabel sx={{ fontSize: '0.7rem' }}>End Date</InputLabel>
              <DatePicker
                label="Start Date"
                format="YYYY-MM-DD"
                value={
                  endDate != null && endDate !== '1900-01-01' ? dayjs(endDate?.split(' ')[0]) : ''
                }
                minDate={dayjs('1000-01-01')}
                maxDate={dayjs('2099-12-31')}
                onChange={(e) => handleDateChange(false, dayjs(e))}
              />
              <Typography
                sx={{
                  paddingBottom: '.5rem',
                  fontSize: '12px',
                  mt: '0.5rem',
                  color: 'primary.light',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setShowError(false);
                  setEndDate('');
                }}
              >
                Set as Unknown
              </Typography>
            </Box>
          )}
        </Stack>
      </LocalizationProvider>
    </Box>
  );

  if (!isPopUp) {
    return embeddedDateRangeComponent;
  }
  return (
    <Box onClick={(e) => e.preventDefault()}>
      <Box onClick={openMenu} ref={anchorRef} sx={{ cursor: 'pointer', alignItems: 'center' }}>
        <Typography
          // @ts-ignore
          variant="h7"
          sx={{
            color: display !== 'Invalid date' ? 'text.light' : 'text.disabled',
            '&:hover': {
              color: 'primary.light',
            },
          }}
          className="unmask"
        >
          {display === 'Invalid date' ? 'No Date' : display}
        </Typography>
      </Box>
      {anchorEl && (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onMenuClose}>
          {embeddedDateRangeComponent}
        </Menu>
      )}
    </Box>
  );
}

const validateDateRange = (startDate: string, endDate: string) => {
  if (startDate && endDate) {
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      return false;
    }
  }
  return true;
};

type Props = {
  entryID: number;
  entryStartDate: string;
  entryEndDate: string;
  orientation: 'horizontal' | 'vertical';
  isPopUp: boolean;
  shouldOpenByDefault: boolean;
  hideTitle?: boolean;
  onChange?: (dates: { startDate?: string; endDate?: string }) => void;
  setNewStartDate?: (newStartDate: string) => void;
  setNewEndDate?: (newEndDate: string) => void;
  setDates?: (dates: Extraction[]) => void;
} & Partial<GridRenderCellParams>;
