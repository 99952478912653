import React, { useState } from 'react';

import { Typography, Box, IconButton, Tooltip, TextField, Backdrop, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

import Export from '../../../../components/icons/Export';
import Pencil from '../../../../components/icons/Pencil';
import Delete from '../../../../components/icons/Delete';
import FileUploadBox from '../../../../components/FileUploadBox';
import DeleteDialog from '../../../../components/common/DeleteDialog';
import { useCaseRelatedFiles } from './useCaseRelatedFiles';
import { useUploadCaseRelatedFile } from './useUploadCaseRelatedFile';
import { useDownloadCaseRelatedFile } from './useDownloadCaseRelatedFile';
import { useDeleteCaseRelatedFile, editFileName } from './useModifyCaseRelatedFile';
import { NormalizedCaseRelatedFile } from './useFileNormalize';
import { useUser } from '../../../../library/contexts/AuthContext';




interface CaseRelatedFileManagementProps {
  userID: string;
  caseID: string;
  caseOwnerID: string;
}

function CaseRelatedFileManagement({
  userID,
  caseID,
  caseOwnerID,
}: CaseRelatedFileManagementProps) {
  const { data: caseFiles, refetch } = useCaseRelatedFiles(caseID);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<NormalizedCaseRelatedFile | null>(null);
  const { uploadCaseRelatedFiles, isUploading } = useUploadCaseRelatedFile(
    userID,
    caseID,
    caseOwnerID,
  );
  const { downloadCaseRelatedFile } = useDownloadCaseRelatedFile(caseID);
  const { mutate: deleteFile } = useDeleteCaseRelatedFile();
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [newFileName, setNewFileName] = useState<string>('');
  const { user } = useUser();

  const handleDownloadFile = (row: any) => {
    if (!userID) {
      toast.error('User ID is not found');
      return;
    }
    const filePath = `${caseOwnerID}/${row.caseID}/Case_Related_Files/${row.fileName}.${row.fileType}`;
    downloadCaseRelatedFile(filePath);
  };

  const handleDeleteClick = (row: any) => {
    setSelectedFile(row);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedFile) {
      return;
    }
    try {
      await deleteFile({
        caseID: selectedFile.caseID,
        fileId: selectedFile.fileID,
        filePath: `${caseOwnerID}/${selectedFile.caseID}/Case_Related_Files/${selectedFile.fileName}.${selectedFile.fileType}`,
      });

      toast.success('File deleted successfully!');
      setDeleteModalOpen(false);

      refetch();
    } catch (error) {
      toast.error('Failed to delete file');
    }
  };

  const handleEditClick = (row: any) => {
    setEditingRow(row.fileID);
    setNewFileName(row.fileName);
  };

  const handleSaveFileName = async (row: any) => {
    const oldFileName = row.fileName;
    if (!newFileName.trim() || newFileName === row.fileName) {
      setEditingRow(null);
      toast.error('File name cannot be empty or same as the previous name');
      return;
    }
    const success = await editFileName({
      caseID: row.caseID,
      fileId: row.fileID,
      fileName: newFileName,
      oldFilePath: `${caseOwnerID}/${row.caseID}/Case_Related_Files/${oldFileName}.${row.fileType}`,
      newFilePath: `${caseOwnerID}/${row.caseID}/Case_Related_Files/${newFileName}.${row.fileType}`,
    });
    if (success) {
      setEditingRow(null);
      refetch();
    }
  };

  const columns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      width: 400,
      renderCell: (params: any) =>
        editingRow === params.row.fileID ? (
          <TextField
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                handleSaveFileName(params.row);
              }
            }}
            onBlur={() => handleSaveFileName(params.row)}
            size="small"
            autoFocus
            variant="standard" 
            InputProps={{
              sx: { fontSize: '0.875rem', padding: '0 !important' },
              disableUnderline: true,
            }}
            sx={{
              width: '100%',
              '.MuiInputBase-input': {
                padding: '2px 4px', 
              },
            }}
          />
        ) : (
          <Typography sx={{ fontSize: '0.875rem' }}>{params.value}</Typography>
        ),
    },
    { field: 'fileType', headerName: 'File Type', width: 200 },
    {
      field: 'uploadedByName',
      headerName: 'Uploaded By',
      width: 300,
    },
    {
      field: 'uploadedDate',
      headerName: 'Uploaded Date',
      width: 200,
      renderCell: (params: any) => {
        const date = new Date(params.value).toLocaleDateString('en-GB');
      return <Typography sx={{ fontSize: '0.875rem' }}>{date}</Typography>;
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      sortable: false,
      renderCell: (params: any) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Download">
            <IconButton color="primary" onClick={() => handleDownloadFile(params.row)}>
              <Export width={19} height={19} tooltip="Download" color="#002147" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit File Name">
            <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
              <Pencil width={19} height={19} tooltip="Edit File Name" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => handleDeleteClick(params.row)}>
              <Delete width={19} height={19} tooltip="Delete" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <FileUploadBox showFormats={false} uploadFiles={uploadCaseRelatedFiles} />
      {isUploading && (
        <Backdrop open={isUploading} sx={{ color: '#fff', zIndex: 1300 }}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Uploading files, please wait...
          </Typography>
        </Backdrop>
      )}
      <Box sx={{ mt: 3, width: '100%' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={caseFiles ?? []}
            columns={columns}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 15]}
            getRowId={(row) => row.fileID}
          />
        </div>
      </Box>

      <DeleteDialog
        open={deleteModalOpen}
        title="Delete File"
        content="Are you sure you want to delete this file?"
        contentText="This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        confirmDisabled={false}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
}

export default CaseRelatedFileManagement;
