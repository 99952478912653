import { useQuery } from '@tanstack/react-query';
import { getCaseFileDocumentNames, DocumentNameEntry } from '../../../api';

export function useGetDocumentNames(
  caseID?: string,
  fileID?: string,
): {
  data: DocumentNameEntry[] | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
} {
  const { data, isLoading, isError, refetch } = useQuery(
    ['getCaseFileDocumentNames', fileID],
    () => getCaseFileDocumentNames(fileID as string, caseID as string),
    {
      enabled: !!fileID,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return { data: data?.data, isLoading, isError, refetch };
}
