import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { WarningRounded } from '@mui/icons-material';
import Export from '../../../components/icons/Export';
import TimelineDocumentViewSelector from '../Components/TimelineDocumentViewSelector';
import TimelineSettings from '../TimelineSettings';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import TimelinePageRange from './TimelinePageRange';
import { FileStatus } from '../../../api';
import useCaseFiles, { useUpdateFileStatus } from '../../Files/useCaseFiles';
import BulkApplyAll from '../BulkApplyEntries';
import { FileProcessorNextStepButton } from '../../Files/tableComponents/FileProcessorNextStepButton';
import { useProcessorFileNavigation } from '../../../hooks/useProcessorFileNavigation';
import useBulkApplyStore from '../useBulkApplyStore';

export default function TimelineHeader({
  exportFilteredSortedTimeline,
  caseID,
  currentTimelineEntry,
  timelineEntries,
  bulkDialog,
  setbulkDialog,
}: TimelineHeaderProps) {
  const isFileProcessor = useIsFileProcessor();
  const [searchParams] = useSearchParams();
  const currentDocumentID = searchParams.get('documentID') ?? null;

  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);

  const { data: caseFiles } = useCaseFiles(caseID);

  const { mutateAsync: updateFileStatus } = useUpdateFileStatus();

  const { bulkApplyInProgress, setBulkApplyInProgress, isDisabled } = useBulkApplyStore(
    (state) => ({
      bulkApplyInProgress: state.bulkApplyInProgress,
      setBulkApplyInProgress: state.setBulkApplyInProgress,
      isDisabled: state.isBulkApplyDisabled(),
    }),
    shallow,
  );

  const currentDocument = useMemo(() => {
    if (!caseFiles) {
      return null;
    }
    return caseFiles.find((doc) => doc.documentID === currentDocumentID);
  }, [caseFiles, currentDocumentID]);

  const { navigateToNextFile } = useProcessorFileNavigation(
    currentDocument?.fileStatus ?? FileStatus.TAGGING,
  );

  const handleFileStatusChange = async (id: string, currentStatus: any) => {
    const nextStatusByCurrentStatus = {
      [FileStatus.PENDING]: FileStatus.GROUPING,
      [FileStatus.GROUPING]: FileStatus.TAGGING,
      [FileStatus.TAGGING]: FileStatus.QA_REQUIRED,
      [FileStatus.QA_REQUIRED]: FileStatus.APPROVED,
    };

    if (!Object.keys(nextStatusByCurrentStatus).includes(currentStatus)) {
      return;
    }
    await updateFileStatus({
      caseID,
      fileID: id,
      status: nextStatusByCurrentStatus[currentStatus],
    });
    await navigateToNextFile();
    // reset the page range when navigating to a new file
    setStartPage(1);
    setEndPage(1);
  };

  const onButtonClick = () => {
    if (currentDocument) {
      handleFileStatusChange(currentDocument.documentID, currentDocument.fileStatus);
    }
  };

  return (
    <>
      <Stack direction="row" paddingX=".5rem" sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <TimelineDocumentViewSelector />
          {!isFileProcessor && (
            <Tooltip
              title={timelineEntries.length === 0 ? 'No Entries to export' : 'Export Timeline'}
            >
              <span>
                <Button
                  id="review-tab-export-timeline-btn"
                  variant="outlined"
                  onClick={exportFilteredSortedTimeline}
                  sx={{
                    ml: 1,
                    width: 36,
                    height: 36,
                    minWidth: 36,
                    padding: 1,
                    marginTop: 0.3,
                    bgcolor: '#FFFFFF',
                  }}
                  disabled={timelineEntries.length === 0}
                >
                  <Export fill="none" />
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
        {isFileProcessor ? (
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TimelineSettings />
            <div>
              <Tooltip title="Select documents for Bulk Apply">
                <span>
                  <IconButton onClick={() => setBulkApplyInProgress(true)}>
                    <WarningRounded fontSize="medium" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </Stack>
        ) : (
          <TimelineSettings />
        )}
      </Stack>
      {isFileProcessor && currentDocumentID && (
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TimelinePageRange
            documentID={currentDocumentID}
            startPage={startPage}
            setStartPage={setStartPage}
            endPage={endPage}
            setEndPage={setEndPage}
          />
          <FileProcessorNextStepButton
            fileStatus={currentDocument?.fileStatus as FileStatus}
            sx={{
              fontSize: '0.65rem',
              mt: '0.35rem',
              mb: '-0.5rem',
              height: '1.5rem',
              minWidth: '5rem',
            }}
            onClick={onButtonClick}
          />
          {currentDocument?.fileStatus === FileStatus.APPROVED && (
            <Typography variant="caption" sx={{ mt: '0.5rem', color: 'newSuccess.main' }}>
              Approved
            </Typography>
          )}
        </Stack>
      )}
      {bulkApplyInProgress && (
        <BulkApplyAll
          currentTimelineEntry={currentTimelineEntry}
          caseID={caseID}
          timelineEntries={timelineEntries}
          bulkDialogOpen={bulkDialog && !isDisabled}
          setbulkDialog={setbulkDialog}
        />
      )}
    </>
  );
}

interface TimelineHeaderProps {
  exportFilteredSortedTimeline: () => void;
  caseID: string;
  currentTimelineEntry: any;
  timelineEntries: any[];
  bulkDialog: boolean;
  setbulkDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
