import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import useScreenCaptureStore from '../../ScreenCapture/useScreenCaptureStore';
import usePDFViewerStore from '../../Case/usePDFViewerStore';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import { convertToTitleCase } from '../../../library/utilities/useTextFormatting';

type Annotation = 'document-name' | 'author' | 'organization';

function useAnnotationTextCapture() {
  const isFileProcessor = useIsFileProcessor();
  const { screenCaptures, setScreenCaptures, setCapturingFor } = useScreenCaptureStore(
    (state) => ({
      screenCaptures: state.screenCaptures,
      setScreenCaptures: state.setScreenCaptures,
      capturingFor: state.capturingFor,
      setCapturingFor: state.setCapturingFor,
    }),
    shallow,
  );

  const { isScreenCapturing, setIsScreenCapturing } = usePDFViewerStore(
    (state) => ({
      isScreenCapturing: state.isScreenCapturing,
      setIsScreenCapturing: state.setIsScreenCapturing,
    }),
    shallow,
  );

  const toggleIsScreenCapturing = useCallback(
    (type: Annotation) => {
      setScreenCaptures([]);
      setIsScreenCapturing(!isScreenCapturing);
      setCapturingFor(isScreenCapturing ? undefined : type);
    },
    [isScreenCapturing, setIsScreenCapturing, setScreenCaptures, setCapturingFor],
  );

  const capturedDocumentNameByDocumentID = useMemo(() => {
    const textByDocumentID: Record<string, string | null> = {};
    screenCaptures
      .filter((cap) => cap.type === 'document-name')
      .forEach((screenCap) => {
        textByDocumentID[screenCap.documentID] = screenCap.capturedText
          ? convertToTitleCase(screenCap.capturedText)
          : null;
      });
    return textByDocumentID;
  }, [screenCaptures, isFileProcessor]);

  const capturedAuthorByDocumentID = useMemo(() => {
    const textByDocumentID: Record<string, string | null> = {};
    screenCaptures
      .filter((cap) => cap.type === 'author')
      .forEach((screenCap) => {
        textByDocumentID[screenCap.documentID] = screenCap.capturedText
          ? convertToTitleCase(screenCap.capturedText)
          : null;
      });
    return textByDocumentID;
  }, [screenCaptures, isFileProcessor]);

  const capturedOrganizationByDocumentID = useMemo(() => {
    const textByDocumentID: Record<string, string | null> = {};
    screenCaptures
      .filter((cap) => cap.type === 'organization')
      .forEach((screenCap) => {
        textByDocumentID[screenCap.documentID] = screenCap.capturedText
          ? convertToTitleCase(screenCap.capturedText)
          : null;
      });
    return textByDocumentID;
  }, [screenCaptures, isFileProcessor]);

  return {
    toggleIsScreenCapturing,
    capturedDocumentNameByDocumentID,
    capturedAuthorByDocumentID,
    capturedOrganizationByDocumentID,
    setScreenCaptures,
  };
}

export type AnnotationTextCaptureState = ReturnType<typeof useAnnotationTextCapture>;
const AnnotationTextCaptureContext = createContext<AnnotationTextCaptureState>({} as any);

export function AnnotationTextCaptureWrapper({ children }: React.PropsWithChildren<{}>) {
  const state = useAnnotationTextCapture();

  return (
    <AnnotationTextCaptureContext.Provider value={state}>
      {children}
    </AnnotationTextCaptureContext.Provider>
  );
}

export function useAnnotationTextCaptureContext() {
  return useContext(AnnotationTextCaptureContext);
}
