import React, { useState, useMemo, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Tooltip,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ControlPoint, Delete, Edit, Check } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import PageHeader from '../../components/common/PageHeader';
import AdminHeader from './AdminHeader';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useAdminUsers } from './api-hooks/useAdminUsers';
import { useIsSiftMedAdmin } from '../AccountSettings/useAdmin';
import { User } from '../../api';

function UserManagement() {
  const {
    userList,
    resetPassword,
    createNewUserFunc,
    deleteUserFunc,
    updateUserFunc,
    userGroups,
    resetMFA,
    createNewUserGroup,
  } = useAdminUsers();
  const windowSize = useDisplayStore((state) => state.windowSize);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [resetMfaDialogOpen, setResetMfaDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState({ first_name: '', last_name: '', email: '' });

  const [selectedGroup, setSelectedGroup] = useState('');

  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');

  const handleGroupChange = (event: SelectChangeEvent<string>) => {
    setSelectedGroup(event.target.value);
  };

  const openDeleteDialog = useCallback((user: User) => {
    setSelectedUser(user);
    setDeleteUserDialogOpen(true);
  }, []);

  const openMFADialog = useCallback((user: User) => {
    setSelectedUser(user);
    setResetMfaDialogOpen(true);
    setSuccessMessage('');
  }, []);

  const closeMFADialog = useCallback(() => {
    setResetMfaDialogOpen(false);
    setSelectedUser(null);
    setSuccessMessage('');
  }, []);

  const handleDeleteUser = useCallback(async () => {
    if (!selectedUser) {
      return;
    }

    try {
      await deleteUserFunc(selectedUser.user_id);
      setDeleteUserDialogOpen(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  }, [deleteUserFunc, selectedUser]);

  const isAuthorized = useIsSiftMedAdmin();

  const openDialog = useCallback((user: User) => {
    setSelectedUser(user);
    setDialogOpen(true);
    setSuccessMessage('');
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
    setSelectedUser(null);
    setSuccessMessage('');
    setLoading(false);
  }, []);

  const confirmResetPassword = useCallback(async () => {
    if (!selectedUser) {
      return;
    }

    setLoading(true);
    try {
      await resetPassword(selectedUser.user_id, selectedUser.email);
      setTimeout(() => {
        setSuccessMessage(
          `Temporary password has been sent to ${selectedUser.given_name} ${selectedUser.family_name}`,
        );
      }, 500);
    } catch (error) {
      console.error('Error resetting password:', error);
      setSuccessMessage('Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
      setDialogOpen(false);
    }
  }, [resetPassword, selectedUser]);

  const confirmResetMFA = useCallback(async () => {
    if (!selectedUser) {
      return;
    }

    setLoading(true);
    try {
      await resetMFA(selectedUser.user_id);
      setSuccessMessage('MFA has been reset for this user.');
    } catch (error) {
      console.error('Error resetting MFA:', error);
    } finally {
      setLoading(false);
      setResetMfaDialogOpen(false);
    }
  }, [selectedUser]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateUser = async () => {
    if (!selectedGroup) {
      console.error('Group must be selected before creating a user');
      toast.error('Please select a group before adding a user.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newUser.email)) {
      toast.error('Invalid email format. Please enter a valid email.');
      return;
    }

    const userData = {
      first_name: newUser.first_name,
      last_name: newUser.last_name,
      email: newUser.email,
      group_name: selectedGroup,
    };

    try {
      await createNewUserFunc(userData);
      setAddUserDialogOpen(false);
      setNewUser({ first_name: '', last_name: '', email: '' });
      setSelectedGroup('');
    } catch (error) {
      console.error('Error creating user:', error);
      toast.error('Failed to create user. Please try again.');
    }
  };

  const validateGroupName = (name: string): boolean => {
    if (!name || name.length < 1) {
      setGroupNameError('Group name is required');
      return false;
    }
    if (name.length > 128) {
      setGroupNameError('Group name must be 128 characters or less');
      return false;
    }
    if (!/^[a-zA-Z0-9\-_.,!?()[\]{}'"]+$/.test(name)) {
      setGroupNameError(
        'Group name can only contain letters, numbers, and some special characters',
      );
      return false;
    }
    if (userGroups?.data?.includes(name)) {
      setGroupNameError('Group name already exists');
      return false;
    }
    setGroupNameError('');
    return true;
  };

  const handleCreateGroup = async () => {
    if (!validateGroupName(newGroupName)) {
      return;
    }

    try {
      await createNewUserGroup(newGroupName);
      setCreateGroupDialogOpen(false);
      setNewGroupName('');
    } catch (error) {
      toast.error('Failed to create group. Please try again.');
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'given_name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params: { value: string; row: User }) => (
          <EditUserData
            defaultValue={params.value}
            onSave={(value: string) => updateUserFunc(params.row.user_id, { given_name: value })}
          />
        ),
      },
      {
        field: 'family_name',
        headerName: 'Surname',
        flex: 1,
        renderCell: (params: { value: string; row: User }) => (
          <EditUserData
            defaultValue={params.value}
            onSave={(value: string) => updateUserFunc(params.row.user_id, { family_name: value })}
          />
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1.5,
        renderCell: (params: { value: string }) => (
          <Typography sx={userStyles.text}>{params.value}</Typography>
        ),
      },

      {
        field: 'user_groups',
        headerName: 'User Group',
        flex: 1,
        sortable: true,
        renderCell: (params: { value: string }) => (
          <Typography sx={userStyles.text}>{params.value}</Typography>
        ),
      },
      {
        field: 'account_status',
        headerName: 'Account Status',
        flex: 1,
        sortable: true,
        renderCell: (params: { row: User }) => (
          <Chip
            label={params.row.last_login === null ? 'Inactive' : 'Activated'}
            variant="outlined"
            sx={{
              borderColor: params.row.last_login === null ? 'warning.main' : 'secondary.main',
              color: params.row.last_login === null ? 'warning.main' : 'secondary.main',
              borderRadius: '8px',
              fontSize: '0.8rem',
              minWidth: '80px',
            }}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        renderCell: (params: { row: User }) => (
          <>
            <Button
              variant="contained"
              sx={{ fontSize: '0.8rem', mr: '0.5rem' }}
              onClick={() => openDialog(params.row)}
            >
              Reset Password
            </Button>
            <Button
              variant="contained"
              sx={{ fontSize: '0.8rem', mr: '0.5rem' }}
              onClick={() => openMFADialog(params.row)}
            >
              Reset MFA
            </Button>
            <Tooltip title="Delete User" placement="top">
              <Delete
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => openDeleteDialog(params.row)}
              />
            </Tooltip>
          </>
        ),
      },
    ],
    [openDialog, updateUserFunc],
  );

  if (!isAuthorized) {
    return <div>Unauthorized. Please contact SiftMed support</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ marginTop: '2rem' }}>
      <AdminHeader />
      <PageHeader title="User Management Dashboard" />
      <Box sx={{ display: 'flex', gap: 2, marginBottom: '0.8rem', marginTop: '1rem' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'primary.light',
            display: 'flex',
          }}
          onClick={() => setAddUserDialogOpen(true)}
          startIcon={
            <ControlPoint fontSize="small" sx={{ marginTop: '-0.1rem', marginRight: '0.2rem' }} />
          }
        >
          Add New User
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'primary.light',
            display: 'flex',
          }}
          onClick={() => setCreateGroupDialogOpen(true)}
          startIcon={
            <ControlPoint fontSize="small" sx={{ marginTop: '-0.1rem', marginRight: '0.2rem' }} />
          }
        >
          Create Group
        </Button>
      </Box>
      <Box sx={userStyles.displayContainer}>
        {userList?.length ? (
          <DataGrid
            disableColumnMenu
            // @ts-ignore
            columns={columns}
            rows={userList}
            getRowId={(row) => row.user_id}
            pageSizeOptions={[5, 10, 50, 100]}
            sx={{
              width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
              border: '1px solid #00214733',
              borderRadius: '8px',
              fontSize: '0.8rem',
              boxShadow: 'none',
              backgroundColor: 'white',
              '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
            }}
          />
        ) : (
          <Box sx={{ margin: 'auto' }}>
            <Typography variant="h5">No Users</Typography>
          </Box>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {' '}
          <Typography sx={userStyles.text}>
            Click confirm to reset the password and send an new password by email.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" disabled={loading}>
            {successMessage ? 'Close' : 'Cancel'}
          </Button>
          {!successMessage && (
            <Button onClick={confirmResetPassword} color="primary" disabled={loading}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={resetMfaDialogOpen} onClose={closeMFADialog}>
        <DialogTitle>Reset MFA</DialogTitle>
        <DialogContent>
          <Typography sx={userStyles.text}>Click confirm to reset MFA for this user.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMFADialog} color="primary" disabled={loading}>
            {successMessage ? 'Close' : 'Cancel'}
          </Button>
          {!successMessage && (
            <Button onClick={confirmResetMFA} color="primary" disabled={loading}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={deleteUserDialogOpen} onClose={() => setDeleteUserDialogOpen(false)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this user? This action will permanently delete the user.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteUserDialogOpen(false)}>Close</Button>
          <Button onClick={() => handleDeleteUser()}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addUserDialogOpen}
        onClose={() => setAddUserDialogOpen(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '450px',
            maxWidth: '80%',
          },
        }}
      >
        <DialogTitle sx={{ paddingTop: '1rem' }}>Add New User</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <input
              style={userStyles.inputContainer}
              type="text"
              name="first_name"
              placeholder="First Name"
              value={newUser.first_name}
              onChange={handleInputChange}
            />
            <input
              style={userStyles.inputContainer}
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={newUser.last_name}
              onChange={handleInputChange}
            />
            <input
              style={userStyles.inputContainer}
              type="email"
              name="email"
              placeholder="Email"
              value={newUser.email}
              onChange={handleInputChange}
            />
            <FormControl fullWidth>
              <InputLabel id="group-select-label">Select a User Group</InputLabel>
              <Select
                labelId="group-select-label"
                value={selectedGroup}
                onChange={(event: SelectChangeEvent<string>) => handleGroupChange(event)}
                label="Select a User Group"
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <MenuItem value="" disabled>
                  Select a user group
                </MenuItem>
                {userGroups?.data?.map((group: string) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleCreateUser()}
            disabled={!newUser.first_name || !newUser.last_name || !newUser.email || !selectedGroup}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createGroupDialogOpen}
        onClose={() => {
          setCreateGroupDialogOpen(false);
          setNewGroupName('');
          setGroupNameError('');
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Group Name"
              value={newGroupName}
              onChange={(e) => {
                setNewGroupName(e.target.value);
                if (groupNameError) {
                  validateGroupName(e.target.value);
                }
              }}
              error={!!groupNameError}
              helperText={groupNameError}
              sx={{ mt: 1 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateGroupDialogOpen(false);
              setNewGroupName('');
              setGroupNameError('');
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleCreateGroup} disabled={!newGroupName.trim() || !!groupNameError}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

function SuccessContent({ message }: { message: string }) {
  return (
    <Typography sx={userStyles.text} color="success.main">
      {message}
    </Typography>
  );
}

export default UserManagement;

const userStyles = {
  title: { fontSize: '1.875rem', display: 'inline-block', fontWeight: 500 },
  subtitle: { fontSize: '1rem' },
  text: { fontSize: '0.8rem', wordSpace: 'normal', wordBreak: 'break-word' },
  container: { marginTop: '2rem', marginBottom: '2rem' },
  casesContainer: { marginTop: '2rem' },
  loadingContainer: { display: 'flex', justifyContent: 'center', marginTop: '10px' },
  displayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    width: '100%',
    marginTop: '2rem',
  },
  inputContainer: {
    width: '100%',
    borderRadius: '8px',
    padding: '0.5rem',
    border: '1px solid #00214733',
  },
};

function EditUserData({
  defaultValue,
  onSave,
}: {
  defaultValue: string;
  onSave: (value: string) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleSave = () => {
    if (value !== defaultValue) {
      onSave(value);
    }
    setIsEditing(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      }}
      onMouseEnter={(e) => {
        const icon: HTMLElement | null = e.currentTarget.querySelector('.edit-icon');
        if (icon) {
          icon.style.visibility = 'visible';
        }
      }}
      onMouseLeave={(e) => {
        const icon: HTMLElement | null = e.currentTarget.querySelector('.edit-icon');
        if (icon) {
          icon.style.visibility = 'hidden';
        }
      }}
    >
      {isEditing ? (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            size="small"
            onBlur={handleSave}
            autoFocus
            sx={{ flex: 1 }}
          />
          <IconButton onClick={handleSave} size="small" color="primary">
            <Check />
          </IconButton>
        </div>
      ) : (
        <>
          <span style={{ flex: 1 }} onClick={() => setIsEditing(true)}>
            {defaultValue || '-'}
          </span>
          <IconButton
            size="small"
            color="default"
            className="edit-icon"
            onClick={() => setIsEditing(true)}
            sx={{
              position: 'absolute',
              right: 0,
              visibility: 'hidden',
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </>
      )}
    </div>
  );
}
