import { documentRotationValues } from '../Case/usePDFViewerStore';
import { BoundingBoxType } from './types';

export function calculateClickPositionRelativeToParent(
  clickEvent: MouseEvent,
  captureAreaRef: any,
) {
  const { x, y } = captureAreaRef.current.getBoundingClientRect();
  const posX = clickEvent.clientX - x;
  const posY = clickEvent.clientY - y;
  return [posX, posY];
}

export function computeBorderWidth(
  boundingBox: BoundingBoxType,
  parentWidth: number,
  parentHeight: number,
) {
  const { Left, Top, Width, Height } = getAbsoluteBoundingBoxInPx(boundingBox);
  return `${Top}px ${parentWidth - Width}px ${parentHeight - Height}px ${Left}px`;
}

export function getAbsoluteBoundingBoxInPx({
  Left: startX,
  Top: startY,
  Width: endX,
  Height: endY,
}: BoundingBoxType) {
  const Left = Math.min(startX, endX);
  const Top = Math.min(startY, endY);
  const Width = Math.max(endX, startX);
  const Height = Math.max(endY, startY);
  return { Left, Top, Width, Height };
}

/**
 *
 * This function returns the bounding box coordinates as a percentage of the parent container.
 */
export function getCaptureAreaAsPercentageOfParent(
  boundingBox: BoundingBoxType,
  width: number,
  height: number,
): BoundingBoxType {
  const { Left, Top, Width, Height } = getAbsoluteBoundingBoxInPx(boundingBox);
  const percentageStartX = +(Left / width);
  const percentageStartY = +(Top / height);
  const percentageEndX = +(Width / width);
  const percentageEndY = +(Height / height);

  return convertBoundingBoxValuesToFixedDecimal({
    Left: percentageStartX,
    Top: percentageStartY,
    Width: percentageEndX,
    Height: percentageEndY,
  });
}

export function getCaptureAreaRelativeToRotation(
  { Left, Top, Width, Height }: BoundingBoxType,
  pageRotation: documentRotationValues,
) {
  let startX = Left;
  let startY = Top;
  let endX = Width;
  let endY = Height;
  if (pageRotation === 90) {
    startX = Top;
    startY = 1 - Width;
    endX = Height;
    endY = 1 - Left;
  } else if (pageRotation === 180) {
    startY = 1 - Height;
    endY = 1 - Top;
    startX = 1 - Width;
    endX = 1 - Left;
  } else if (pageRotation === 270) {
    startX = 1 - Height;
    startY = Left;
    endX = 1 - Top;
    endY = Width;
  }
  return convertBoundingBoxValuesToFixedDecimal({
    Left: startX,
    Top: startY,
    Width: endX,
    Height: endY,
  });
}

function convertBoundingBoxValuesToFixedDecimal({
  Left,
  Top,
  Width,
  Height,
}: BoundingBoxType): BoundingBoxType {
  return {
    Left: +Left.toFixed(2),
    Top: +Top.toFixed(2),
    Width: +Width.toFixed(2),
    Height: +Height.toFixed(2),
  };
}
